(function () {
  namespace("PatientPrism.Common.UI.ProfileEntries", init);

  var self = {
    modify_modal: {
      $modal: null,
      $entry_title: null,
      $entry_data: null,
    },
  };

  function init() {
    initProfilePanels();

    self.modify_modal.$modal = $("#modal-modify-profile-entry");
    self.modify_modal.$entry_title = $(
      "#modify-profile-entry-title",
      self.modify_modal.$modal
    );
    self.modify_modal.$entry_data = $(
      "#modify-profile-entry-data",
      self.modify_modal.$modal
    );

    if (self.modify_modal.$entry_data.length) {
      tinymce.init({
        target: self.modify_modal.$entry_data[0],
        menubar: false,
        toolbar: [
          "formatselect | bold italic underline | hr | bullist numlist | link | backcolor | table",
          "",
        ],
        plugins: ["hr lists link table textcolor colorpicker"],
        invalid_elements: "script",
        link_assume_external_targets: true,
        anchor_bottom: false,
        anchor_top: false,
      });
    }

    $("#toggle-profile-entries").click(function (e) {
      e.preventDefault();

      if ($(this).text() == "Show All") {
        $('[profile-element="data-container"]').slideDown();
        $(this).text("Hide All");
      } else {
        $('[profile-element="data-container"]').slideUp();
        $(this).text("Show All");
      }
    });

    $("#select-location").change(function (e) {
      location.href = _router.route("locations.profile.show", {
        location_id: $(this).val(),
      });

      $(this).attr("disabled", true);
    });
  }

  /**
   * Converts the HTML table into a DataTable.
   *
   */
  function initProfilePanels() {
    $(".panel-profile-entry").each(function (e) {
      var entry = this;

      $('[profile-role="toggle-data"]', entry).click(function (e) {
        $('[profile-element="data-container"]', entry).slideToggle();
      });

      $('[profile-role="destroy"]', entry).click(function (e) {
        PatientPrism.Common.UI.Loader.show();

        var api_callback = function (data, textStatus, jqXHR) {
          if (textStatus === "success") {
            location.reload();
          } else if (textStatus === "error") {
            PatientPrism.Common.UI.Loader.hide();

            swal({
              title: "An Error Occurred",
              text:
                "There was an error while trying to remove the profile entry.",
              type: "error",
              html: true,
            });
          }
        };

        PatientPrism.API.ProfileEntries.destroy(
          $(entry).attr("profile-entry-id"),
          api_callback,
          null
        );
      });

      $('[profile-role="modify"]', entry).click(function (e) {
        $("#profile-entry-id", self.modify_modal.$modal).val(
          $(entry).attr("profile-entry-id")
        );
        $("#modify-profile-entry-title", self.modify_modal.$modal).val(
          $(".panel-heading", entry).html()
        );
        tinyMCE
          .get(self.modify_modal.$entry_data.attr("id"))
          .setContent($('[profile-element="data-content"]', entry).html());

        self.modify_modal.$modal.modal("show");
      });
    });

    $("#btn-save-entry", self.modify_modal.$modal).click(function (e) {
      e.preventDefault();

      PatientPrism.Common.UI.Loader.show();

      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          location.reload();
        } else if (textStatus === "error") {
          PatientPrism.Common.UI.Loader.hide();

          swal({
            title: "An Error Occurred",
            text:
              "There was an error while trying to update the profile entry.",
            type: "error",
            html: true,
          });
        }
      };

      var request_body = {
        title: self.modify_modal.$entry_title.val(),
        data: tinyMCE
          .get(self.modify_modal.$entry_data.attr("id"))
          .getContent(),
      };

      PatientPrism.API.ProfileEntries.update(
        $("#profile-entry-id", self.modify_modal.$modal).val(),
        api_callback,
        request_body
      );
    });
  }
})();
