(function()
{
    namespace("PatientPrism.API.TrainingVideos", all, create, update, destroy, get);

    var self = {
        //
    }

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/training-videos', callback, body)
    }

    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/training-videos', callback, body)
    }

    function update(training_video_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/training-videos/' + training_video_id , callback, body)
    }

    function destroy(call_audio_score_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-audio-scores/' + call_audio_score_id , callback, body)
    }

    function get(training_video_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/training-videos/' + training_video_id , callback, body)
    }
})();