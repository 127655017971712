(function()
{
    namespace("PatientPrism.Admin.Reports.Calls.ReloPerformance", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init(report_data)
    {
        self.report_data = JSON.parse(report_data);

        initChart();
    }

    function initChart()
    {
        $('#ttr-chart').highcharts({
            chart: {
                zoomType: 'x'
            },
            title: {
                text: 'Time to RELO'
            },
            subtitle: {
                text: 'A Difference of Call Received Time to RELO Submitted Time'
            },
            xAxis: [{
                categories: _.map(self.report_data.ttr_counts, 'ttr'),
                crosshair: true,
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                }
            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 100,
                verticalAlign: 'top',
                y: 30,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            series: [{
                name: 'Total Calls',
                type: 'areaspline',
                data: _.map(self.report_data.ttr_counts, 'total'),
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[1]],
                        [0, Highcharts.getOptions().colors[1]],
                    ]
                }
            }]
        });
    }
})();