(function()
{
    namespace("PatientPrism.Modules.Manage.Company.ModifyCard", init);


    function init(companyId)
    {
        $('#modal-edit-company-payments').on('show.bs.modal', function (event) {
			var button = $(event.relatedTarget) ;
			var id = button.closest('tr').data('id');
            $('#payment-edit-cardholder').val(button.data('name'));
            $('#payment-edit-city').val(button.data('city'));
            $('#payment-edit-address').val(button.data('address'));
            $('#payment-edit-zip').val(button.data('zip'));
            $('#payment-edit-state').val(button.data('state'));
            $('#payment-edit-month').val(button.data('month'));
            $('#payment-edit-year').val(button.data('year'));
            $('#payment-edit-source-token').val(id);
        });
        
        $('#btn-modify-payment-edit-method').click(function(e) {
            e.preventDefault();
            let that = $(this);
            let data = {
                    card:  {
                        exp_month: $('#payment-edit-month').val(),
                        exp_year: $('#payment-edit-year').val()
                    },
                    billing_details: {
                    	name: $('#payment-edit-cardholder').val(),
	                    address: {
	                        city: $('#payment-edit-city').val(),
	                        line1: $('#payment-edit-address').val(),
	                        postal_code: $('#payment-edit-zip').val(),
	                        state: $('#payment-edit-state').val()
	                    }
                    }
            };
            
            PatientPrism.API.Companies.paymentSourceUpdate(companyId, $('#payment-edit-source-token').val(), PatientPrism.Modules.Manage.Company.PaymentMethods.api_callback, data);
            return false;
        });
    }
})();