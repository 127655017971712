(function()
{
    namespace("PatientPrism.Common.UI.SignificantEvents", init);

    var self = {
        data: {
            significant_events: null
        },
        el: {
            $container: null,
            $alert: null,
            $count: null,
            $date_range: null,

            modals: {
                calendar: {
                    $modal: null,
                    $calendar: null
                }
            }
        }
    }

    function init()
    {
        self.el.$container  = $('#significant-events-alert-container');
        self.el.$alert      = $('#significant-events-alert', self.el.$container);
        self.el.$count      = $('#significant-events-alert-count', self.el.$alert);
        self.el.$date_range = $('#significant-events-alert-date-range', self.el.$alert);

        self.el.modals.calendar.$modal    = $('#significant-events-calendar-modal');
        self.el.modals.calendar.$calendar = $('#significant-events-calendar', self.el.modals.calendar.$modal);

        getSignificantEvents(loadSignificantEvents);
        bindCountClick();
    }

    function getSignificantEvents(callback)
    {
        var location_groups = PatientPrism.Common.Session.get('filters')['location-groups'];
        var locations       = PatientPrism.Common.Session.get('filters')['locations'];
        var date_start      = PatientPrism.Common.Session.get('filters')['date-start'];
        var date_end        = PatientPrism.Common.Session.get('filters')['date-end'];

        if ((!location_groups && !locations) || (!date_start || !date_end))
            return;

        var this_callback = function(data, textStatus, jqXHR)
        {
            if (textStatus == 'success')
            {
                self.data.significant_events = data.data;
                callback();
            }
        }

        var body = {
            'location_group_id': location_groups,
            'location_id':       locations,
            'date_start':        date_start.date,
            'date_end':          date_end.date
        };

        PatientPrism.API.SignificantEvents.all(this_callback, body);
    }

    function loadSignificantEvents()
    {
        if (!self.data.significant_events.length)
            return;

        self.el.$count.text(self.data.significant_events.length + ' Significant ' + (self.data.significant_events.length > 1 ? 'Events' : 'Event'));

        var date_start =
            moment.tz(PatientPrism.Common.Session.get('filters')['date-start'].date, PatientPrism.Common.Session.get('filters')['date-start'].timezone)
                .clone().tz(PatientPrism.Common.Session.get('current').user.timezone)
                .format('MMMM D, YYYY');

        var date_end =
            moment.tz(PatientPrism.Common.Session.get('filters')['date-end'].date, PatientPrism.Common.Session.get('filters')['date-end'].timezone)
                .clone().tz(PatientPrism.Common.Session.get('current').user.timezone)
                .format('MMMM D, YYYY');

        self.el.$date_range.text( date_start + ' - ' + date_end);

        self.el.$container.fadeIn();
    }

    function bindCountClick()
    {
        self.el.$count.unbind('click')
            .click(function(e)
            {
                e.preventDefault();

                self.el.modals.calendar.$calendar.fullCalendar('destroy');

                self.el.modals.calendar.$calendar.fullCalendar({
                    header: {
                        left: 'title',
                        right: 'today prev,next',
                        center: null,
                    },
                    themeSystem: 'bootstrap3',
                    buttonText: {
                      today: 'This Month',
                      list:  'List'
                    },
                    defaultDate: moment(),
                    defaultView: 'listMonth',
                    allDayDefault: true,
                    editable: false,
                    eventLimit: true,
                    events: _.map(self.data.significant_events, function(event)
                    {
                        return {
                            id:         event.id,
                            title:      event.title,
                            start:      moment(event.started_at).startOf('day').format('YYYY-MM-DD'),
                            end:        moment(event.ended_at).endOf('day').add(1, 'day').format('YYYY-MM-DD'),
                            locations:  event.locations,
                            created_by: event.created_by
                        }
                    }),
                });

            self.el.modals.calendar.$modal.modal('show');
        })
    }
})();