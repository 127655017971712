(function()
{
    namespace("PatientPrism.Common.UI.ElementFocus", focus, blur);

    var self = {
        $overlay: null,
        elements: null
    }

    /**
     * Show the loading overlay.
     *
     */
    function focus(elements)
    {
        if (typeof elements == 'undefined')
            return;

        self.elements = [elements];
        self.$overlay = $('<div id="element-focus-overlay"></div>');

        $('body').append(self.$overlay);
        $('body').css('overflow', 'hidden');

        self.$overlay.fadeIn();

        $.each(self.elements, function (index, $element)
        {
            $element.addClass('focused-element');
        });
    }

    /**
     * Hide the loading overlay.
     *
     */
    function blur()
    {
        self.$overlay.fadeOut(function(e)
        {
            $.each(self.elements, function (index, $element)
            {
                $element.removeClass('focused-element');
            });

            self.$overlay.remove();
            $('body').css('overflow', 'auto');
        });
    }

})();