(function()
{
    namespace("PatientPrism.Modules.Manage.Users.Queues", init);

    var self = {
        user_id: null,
        $table: null,
        $select_queue: null,
        $btn_add_to_queue: null
    };

    function init(user_id)
    {
        self.user_id = user_id;

        initLocationsDataTables();
        initSelectQueue();
        bindRemoveButton();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initLocationsDataTables()
    {
        var oLanguage = {
            "sLengthMenu": "Show _MENU_ queues per page",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Queues",
            "sInfoEmpty": "",
            "sInfoFiltered": " - filtered from _MAX_ total queues",
            "sEmptyTable": "Not Assigned to a Queue"
        };

        self.$table = $('table#queues');

        if (self.$table.length)
        {
            self.$table =
                self.$table.DataTable({
                    searching: false,
                    paging: false,
                    processing: true,
                    rowReorder: {
                        snapX: 0,
                        selector: 'td:nth-child(1)'
                    },
                    columnDefs: [
                    {
                        visible: false,
                        orderable: true,
                        targets: [0]
                    },
                    {
                        orderable: false,
                        targets: [1, 2, 3]
                    },
                    {
                        className: 'text-center text-link',
                        targets: [3]
                    },
                    {
                        className: 'text-center cursor-reorder',
                        targets: [1]
                    }],
                    "oLanguage": oLanguage,
                    "sPaginationType": "simple"
                });

            self.$table.on('row-reordered', function ( e, diff, edit )
            {
                self.$table.processing(true);
                $(self.$table.table().node()).css('pointer-events', 'none');

                setTimeout(function() {
                    var order =
                        $.map(self.$table.rows().nodes(), function (item) {
                            return $(item).data('call-queue-id');
                        });

                    var api_callback = function (data, textStatus, jqXHR)
                    {
                        self.$table.processing(false);
                        $(self.$table.table().node()).css('pointer-events', 'all');

                        if (textStatus === 'success')
                        {
                            //
                        }
                        else if (textStatus === 'error')
                        {
                            swal({
                                title: "An Error Occurred",
                                text: "There was an error while trying to modify the call queue order.",
                                type: "error",
                                html: true
                            });
                        }
                    }

                    var request_body = {
                        call_queue_order: order
                    };

                    PatientPrism.API.Users.update_call_queues(self.user_id, api_callback, request_body);
                }, 20);
            });
        }
    }

    function bindRemoveButton()
    {
        $('[remove-call-queue]').unbind('click')
            .click(function(e)
            {
                e.preventDefault();

                self.$table.processing(true);
                $(self.$table.table().node()).css('pointer-events', 'none');

                var $row = $(this).closest('tr');

                $row.addClass('pending-removal disabled');

                var api_callback = function (data, textStatus, jqXHR)
                {
                    self.$table.processing(false);
                    $(self.$table.table().node()).css('pointer-events', 'all');

                    if (textStatus === 'success')
                    {
                        self.$table.row('.pending-removal').remove().draw( false );
                    }
                    else if (textStatus === 'error')
                    {
                        swal({
                            title: "An Error Occurred",
                            text: "There was an error while trying to remove the Call Queue",
                            type: "error",
                            html: true
                        });
                    }
                }

                var request_body = {
                    call_queue_id: [$row.attr('data-call-queue-id')]
                };

                PatientPrism.API.Users.remove_call_queues(self.user_id, api_callback, request_body);
            })
    }

    function initSelectQueue()
    {
        self.$select_queue = $('select#select-queue');
        self.$btn_add_to_queue = $('button#btn-add-user-to-queue');

        self.$btn_add_to_queue.click(function(e)
        {
            e.preventDefault();

            var call_queue_id = $('option:selected', self.$select_queue).attr('data-call-queue-id');
            var call_queue_name = $('option:selected', self.$select_queue).val();

            var api_callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    var max_order_value = parseInt(self.$table.column(0).data().sort().reverse()[0] ? self.$table.column(0).data().sort().reverse()[0] : 0);

                    var node =
                    self.$table.row.add( [max_order_value + 1, '<span class="fa fa-bars"></span>', call_queue_name, '<span remove-call-queue>Remove</span>' ] ).node();
                    $(node).attr('data-call-queue-id', call_queue_id);

                    self.$table.draw();

                    bindRemoveButton();
                }
                else if (textStatus === 'error')
                {
                    swal({
                        title: "An Error Occurred",
                        text: "There was an error while trying to add the Call Queue",
                        type: "error",
                        html: true
                    });
                }
            }

            var request_body = {
                call_queue_id: [call_queue_id]
            };

            PatientPrism.API.Users.add_call_queues(self.user_id, api_callback, request_body);
        });
    }
})();