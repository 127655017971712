(function()
{
    namespace("PatientPrism.Common.UI.BlockedCallers", initBlockedCallers);

    function initBlockedCallers()
    {
        bindBlockCallerTargets();
        bindUnblockCallerTargets();
    }

    function bindBlockCallerTargets()
    {
        var $block_caller_targets = $('.block-caller');

        if (!$block_caller_targets.length)
            return;

        $block_caller_targets.click(function(e)
        {
            e.preventDefault();

            var phone_number = $(this).attr('data-phone-number');

            if (!phone_number)
            {
                console.log('Attribute "data-phone-number" is not set');
                return;
            }

            swal({
                title: "Are you sure?",
                text: "This number will be blocked and any calls received in the future will be rejected.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: false
            },
            function(isConfirm)
            {
                if (isConfirm)
                {
                    swal("Stand By", "We're blocking the caller.", "success");

                    var callback = function(data, textStatus, jqXHR)
                    {
                        if (textStatus === 'success')
                        {
                            window.location.reload();
                        }
                        else
                        {
                            swal.close();
                            create_notification('error', data.error.message);
                        }
                    };

                    var body = {
                        phone_number: phone_number,
                    };

                    PatientPrism.API.BlockedCallers.block(callback, body);
                }
                else
                {
                    swal("Cancelled", "The caller was not blocked.", "error");
                }
            });
        });
    }

    function bindUnblockCallerTargets()
    {
        var $unblock_caller_targets = $('.unblock-caller');

        if (!$unblock_caller_targets.length)
            return;

        $unblock_caller_targets.click(function(e)
        {
            e.preventDefault();

            var blocked_caller_id = $(this).attr('data-blocked-caller-id');

            if (!blocked_caller_id)
            {
                console.log('Attribute "data-blocked-caller-id" is not set');
                return;
            }

            swal({
                title: "Are you sure?",
                text: "This number will be unblocked and any calls received in the future will be connected.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: false
            },
            function(isConfirm)
            {
                if (isConfirm)
                {
                    swal("Stand By", "We're unblocking the caller.", "success");

                    var callback = function(data, textStatus, jqXHR)
                    {
                        if (textStatus === 'success')
                        {
                            window.location.reload();
                        }
                        else
                        {
                            swal.close();
                            create_notification('error', data.error.message);
                        }
                    };

                    PatientPrism.API.BlockedCallers.unblock(blocked_caller_id, callback);
                }
                else
                {
                    swal("Cancelled", "The caller was not unblocked.", "error");
                }
            });
        });
    }
})();