(function()
{
    namespace("PatientPrism.Modules.Manage.SignificantEvents.Index", init);

    var self = {
        $calendar: null,
        add_event: {
            $container:       null,
            $title:           null,
            $date_select:     null,
            $location_select: null,
            $btn_submit:      null
        },
        modals: {
            edit_event: {
                $modal:           null,
                $title:           null,
                $date_select:     null,
                $location_select: null,
                $created_by:      null,
                $btn_submit:      null,
                $btn_delete:      null
            }
        },
        data: {
            significant_events: null
        }
    };

    function init()
    {
        initCreateEvent();
        initEditEvent();

        getSignificantEvents(function(significant_events)
        {
            initEventsCalendar(_.map(significant_events, function(event)
            {
                return {
                    id:         event.id,
                    title:      event.title,
                    start:      moment(event.started_at).startOf('day').format('YYYY-MM-DD'),
                    end:        moment(event.ended_at).endOf('day').add(1, 'day').format('YYYY-MM-DD'),
                    locations:  event.locations,
                    created_by: event.created_by
                }
            }))
        });
    }

    function initCreateEvent()
    {
        self.add_event.$container = $('div#create-event');

        self.add_event.$title           = $('input#event-title', self.add_event.$container);
        self.add_event.$date_select     = $('#select-date', self.add_event.$container);
        self.add_event.$location_select = $('#select-location', self.add_event.$container);
        self.add_event.$btn_submit      = $('#btn-add-event', self.add_event.$container);

        // Initialize date picker
        self.add_event.$date_select.daterangepicker({
            startDate: moment(),
            endDate:   moment(),
            ranges:    {
               'Today':        [moment(), moment()],
               'Yesterday':    [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               'Last 7 Days':  [moment().subtract(6, 'days'), moment()],
               'Last 30 Days': [moment().subtract(29, 'days'), moment()],
               'This Month':   [moment().startOf('month'), moment().endOf('month')],
               'Last Month':   [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            showDropdowns:       true,
            autoApply:           true,
            alwaysShowCalendars: true,
            linkedCalendars:     false,
            opens:               'left'
        },
        function(start, end, label)
        {
            $('.action-text', self.add_event.$date_select)
                .text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
        });

        // Hide mini inputs in date range popup
        $('.daterangepicker_input').hide();

        // Initialize location selection
        self.add_event.$location_select.selectize({
            placeholder: 'Select Event Locations'
        });

        // Submit click
        self.add_event.$btn_submit.click(function(e)
        {
            e.preventDefault();

            var callback = function(data, textStatus, jqXHR)
            {
                if (textStatus == 'success')
                {
                    var event = {
                        id:        data.data.id,
                        title:     data.data.title,
                        start:     moment(data.data.started_at.date).startOf('day').format('YYYY-MM-DD'),
                        end:       moment(data.data.ended_at.date).endOf('day').add(1, 'day').format('YYYY-MM-DD'),
                        locations: data.data.locations,
                        created_by: data.data.created_by
                    };

                    addEventToCalendar(event);

                    resetCreateEvent();
                }
            }

            var body = {
                title:       self.add_event.$title.val(),
                location_id: self.add_event.$location_select[0].selectize.getValue(),
                date_start:  self.add_event.$date_select.data('daterangepicker').startDate.format('YYYY-MM-DD'),
                date_end:    self.add_event.$date_select.data('daterangepicker').endDate.format('YYYY-MM-DD'),
                expand:      ['locations', 'created_by']
            };

            PatientPrism.API.SignificantEvents.create(callback, body);
        })
    }

    function initEditEvent()
    {
        self.modals.edit_event.$modal = $('#modal-edit-significant-event');

        self.modals.edit_event.$title           = $('input#edit-title', self.modals.edit_event.$modal);
        self.modals.edit_event.$date_select     = $('#edit-date', self.modals.edit_event.$modal);
        self.modals.edit_event.$location_select = $('#edit-location', self.modals.edit_event.$modal);
        self.modals.edit_event.$created_by      = $('#created-by', self.modals.edit_event.$modal);
        self.modals.edit_event.$btn_submit      = $('#btn-edit-event', self.modals.edit_event.$modal);
        self.modals.edit_event.$btn_delete      = $('#btn-delete-event', self.modals.edit_event.$modal);

        // Initialize date picker
        self.modals.edit_event.$date_select.daterangepicker({
            startDate: moment(),
            endDate:   moment(),
            ranges:    {
               'Today':        [moment(), moment()],
               'Yesterday':    [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               'Last 7 Days':  [moment().subtract(6, 'days'), moment()],
               'Last 30 Days': [moment().subtract(29, 'days'), moment()],
               'This Month':   [moment().startOf('month'), moment().endOf('month')],
               'Last Month':   [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            showDropdowns:       true,
            autoApply:           true,
            alwaysShowCalendars: true,
            linkedCalendars:     false,
            opens:               'left'
        },
        function(start, end, label)
        {
            $('.action-text', self.modals.edit_event.$date_select)
                .text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
        });

        // Hide mini inputs in date range popup
        $('.daterangepicker_input').hide();

        // Initialize location selection
        self.modals.edit_event.$location_select.selectize({
            placeholder: 'Select Event Locations'
        });
    }

    function getSignificantEvents(callback)
    {
        var locations = PatientPrism.Common.Session.get('available')['locations'];

        if (!locations)
            return;

        var this_callback = function(data, textStatus, jqXHR)
        {
            if (textStatus == 'success')
            {
                self.data.significant_events = data.data;
                callback(self.data.significant_events);
            }
        }

        var body = {
            'location_id': _.map(locations, 'id'),
            'expand': ['locations', 'created_by']
        };

        PatientPrism.API.SignificantEvents.all(this_callback, body);
    }

    function initEventsCalendar(events)
    {
        self.$calendar = $('div#event-calendar');

        self.$calendar.fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'listMonth,month'
            },
            themeSystem: 'bootstrap3',
            buttonText: {
              today: 'Today',
              month: 'Month',
              list:  'List'
            },
            defaultDate: moment(),
            defaultView: 'month',
            allDayDefault: true,
            editable: false,
            eventLimit: true,
            events: events,
            eventClick: function(event)
            {
                editEvent(event);
                return false;
            },
        });
    }

    function resetCreateEvent()
    {
        self.add_event.$title.val('');

        self.add_event.$date_select
            .data('daterangepicker')
            .setStartDate(moment());

        self.add_event.$date_select
            .data('daterangepicker')
            .setEndDate(moment());

        $('.action-text', self.add_event.$date_select)
            .text(moment().format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY'));

        self.add_event.$location_select[0].selectize.clear();
    }

    function editEvent(event)
    {
        self.modals.edit_event.$title.val(event.title);

        self.modals.edit_event.$date_select
            .data('daterangepicker')
            .setStartDate(event.start);

        self.modals.edit_event.$date_select
            .data('daterangepicker')
            .setEndDate(event.end.subtract(1, 'day'));

        $('.action-text', self.modals.edit_event.$date_select)
            .text(event.start.format('MMMM D, YYYY') + ' - ' + event.end.format('MMMM D, YYYY'));

        self.modals.edit_event.$location_select[0].selectize.setValue(_.map(event.locations, 'id'));

        $('span', self.modals.edit_event.$created_by).text(event.created_by.name);

        self.modals.edit_event.$btn_submit.unbind('click')
        .click(function(e)
        {
            e.preventDefault();

            var callback = function(data, textStatus, jqXHR)
            {
                if (textStatus == 'success')
                {
                    event.title     = data.data.title;
                    event.start     = moment(data.data.started_at.date).startOf('day').format('YYYY-MM-DD');
                    event.end       = moment(data.data.ended_at.date).endOf('day').add(1, 'day').format('YYYY-MM-DD');
                    event.locations = data.data.locations;

                    updateEventInCalendar(event);

                    self.modals.edit_event.$modal.modal('hide');
                }
            }

            var body = {
                title:       self.modals.edit_event.$title.val(),
                location_id: self.modals.edit_event.$location_select[0].selectize.getValue(),
                date_start:  self.modals.edit_event.$date_select.data('daterangepicker').startDate.format('YYYY-MM-DD'),
                date_end:    self.modals.edit_event.$date_select.data('daterangepicker').endDate.format('YYYY-MM-DD'),
                expand:      ['locations', 'created_by']
            };

            PatientPrism.API.SignificantEvents.update(event.id, callback, body);
        });

        self.modals.edit_event.$btn_delete.unbind('click')
        .click(function(e)
        {
            e.preventDefault();

            var callback = function(data, textStatus, jqXHR)
            {
                if (textStatus == 'success')
                {
                    self.modals.edit_event.$modal.modal('hide');

                    removeEventFromCalendar(event);
                }
            }

            var body = {};

            PatientPrism.API.SignificantEvents.destroy(event.id, callback, body);
        });

        self.modals.edit_event.$modal.modal('show');
    }

    function addEventToCalendar(event)
    {
        self.$calendar.fullCalendar('renderEvent', event);
    }

    function updateEventInCalendar(event)
    {
        self.$calendar.fullCalendar('updateEvent', event);
    }

    function removeEventFromCalendar(event)
    {

        self.$calendar.fullCalendar('removeEvents', event._id);
    }

})();