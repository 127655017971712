(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.FollowUp", init);

    let self = {
        $table: null,
        report_data: null,
        initComplete: function(row, data, start, end, display, api, table, totals) {
            // var api = this.api();
            var footer = table.append('<tfoot><tr></tr></tfoot>');

            if (typeof self.report_data.enterprise === 'undefined')
                return;

            api.columns().every(function ()
            {
                let column = this;
                let data = '';

                let sum = column
                    .data()
                    .reduce(function(a, b) {
                        var x = parseFloat(a) || 0;
                        var y = parseFloat(b) || 0;
                        return x + y;
                    }, 0);

                if ($(column.header()).hasClass('sum-column'))
                {
                    let plural = sum > 1 ? 's' : '';

                    if ($(column.header()).hasClass('percent-column'))
                    {
                        var percent = Math.round((sum/totals[column.index()]) * 100);
                        data = `${sum} Call${plural} <br/> <small class="text-muted">${percent}%</small>`
                    }
                    else if ($(column.header()).hasClass('money-column'))
                    {
                        var total = sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        data = `$${total}`
                    }
                    else if ($(column.header()).hasClass('percent-as-value'))
                    {
                        data = sum.toFixed(2) + '%';
                    }
                    else
                    {
                        data = `${sum} Call${plural}`
                    }
                }

                if ($(column.header()).hasClass('average-column'))
                {
                    let average = sum / column.data().length;

                    if ($(column.header()).hasClass('percent-as-value'))
                    {
                        data = average.toFixed(2) + '%';
                    }
                }

                if ($(column.header()).hasClass('average-percent'))
                {
                    let current_index = $(column.header()).index();
                    let follow_up_column = column.columns(current_index - 2).data()[0];
                    let converted_column = column.columns(current_index - 1).data()[0];
                    let follow_up_sum = follow_up_column.reduce((a, b) => a + b, 0);
                    let converted_sum = converted_column.reduce((a, b) => a + b, 0);
                    let average = (converted_sum / follow_up_sum) * 100;

                    data = average.toFixed(2) + '%';
                }

                $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
            });
        },
        $insightsFollowUpsTable: null,
        locations: null,
        location_groups: null
    };

    function init(data)
    {
        self.report_data = JSON.parse(data);
        self.locations = self.report_data.locations_list;
        self.location_groups = self.report_data.location_groups;

        initStaffMetricsDataTable();
        initInsightsRelosTables();
        initInsightsFollowUpsTables();
        initCallRecordPerformanceDataTable();
        initFollowUpFlowChart();
        initConversionFlowChart();
        bindUIElements();
        initTooltips();
    }

    let formatters = PatientPrism.Modules.Analize.Helpers.Formatters;
    let renderers = PatientPrism.Modules.Analize.Helpers.Renderers;

    let filter_status = {
        'insights_relos_table': [],
        'insightsFollowUpsTable': []
    };

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initStaffMetricsDataTable()
    {
        self.$table = $('table#staff-metrics');

        if (self.$table.length)
        {
            self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                responsive: true,
                'order': [
                    [0, 'desc']
                ],
                "columnDefs": [
                {
                    "targets": [],
                    "orderable": false
                },
                {
                    "className": "text-center",
                    "targets": [1,2,3,4,5,6]}
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple",
                "drawCallback": function( settings ) {
                    PatientPrism.Common.UI.UserInsights.bindPopovers();
                }
            });
        }
    }

    function initInsightsRelosTables()
    {
        self.$insights_relos_table = $('table#insights-relos');

        if (!self.$insights_relos_table.length) {
            return;
        }

        filter_status.insights_relos_table = [
            // Do not remove this definition
        ];

        let data = [];

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_relos',
            self.locations,
            self.location_groups,
            self.report_data.enterprise,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let report_data = data_converter.get_data('insights_relos');

        _.each(report_data, function(metric)
        {
            data.push(metric);
        });

        if ($.fn.DataTable.isDataTable(self.$insights_relos_table))
        {
            self.$insights_relos_table.DataTable().destroy();
            self.$insights_relos_table.empty();
        }

        self.$insights_relos_table.DataTable({
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            columns: [{
                title: 'Location',
                data: 'location.name'
            },
            {
                title: 'Total',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            relo_notification: 1,
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_relos')] = row.location.id;
                    return formatters.calls(
                        row.relo_notification.total_relo,
                        type,
                        null,
                        'insights_relos',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                }
            },
            {
                title: 'New Opps.',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            relo_notification: 1,
                            classification: 'New Opportunity',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_relos')] = row.location.id;
                    return formatters.calls(
                        row.relo_notification.new_opps_relo,
                        type,
                        null,
                        'insights_relos',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                }
            },
            {
                title: 'Unknown Opps.',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            relo_notification: 1,
                            classification: 'Unknown Opportunity',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_relos')] = row.location.id;
                    return formatters.calls(
                        row.relo_notification.unknown_opp_relo,
                        type,
                        null,
                        'insights_relos',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                }
            },
            {
                title: 'Not Conn.',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            relo_notification: 1,
                            classification: 'Not Connected',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_relos')] = row.location.id;
                    return formatters.calls(
                        row.relo_notification.not_connected_relo,
                        type,
                        null,
                        'insights_relos',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                }
            },
            {
                title: 'Follow Ups',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            follow_up_recommended: 1,
                        },
                        'follow-ups': 1,
                        weighted: 1,
                        relo: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_relos')] = row.location.id;
                    return formatters.call_count(
                        row.followed_up,
                        row.relo_notification.total_relo,
                        type,
                        'insights_relos',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(data, row.followed_up, row.relo_notification.total_relo, type, 'insights_relos');
                }
            },
            {
                title: 'Converted',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            follow_up_recommended: 1,
                        },
                        'follow-up-conversion': 1,
                        relo: 1,
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_relos')] = row.location.id;
                    return formatters.call_count(
                        row.relo_converted,
                        row.relo_notification.total_relo,
                        type,
                        'insights_relos',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(data, row.relo_converted, row.relo_notification.total_relo, type, 'insights_relos');
                }
            },
            {
                title: 'Success Rate',
                data: function ( row, type, val, meta ) {
                    if (row.relo_converted === 0 && row.followed_up === 0) {
                        return formatters.calls(0, type, null, 'insights_relos');
                    }
                    var success_rate = (row.relo_converted / row.followed_up) * 100;

                    return formatters.percent(success_rate, type, row);
                }
            },
            {
                title: 'Opp. Recovered',
                data: 'sellables.won',
                render: formatters.money
            },
            {
                title: 'Opp. Lost',
                data: 'sellables.lost',
                render: formatters.money
            }],
            columnDefs:[
            {
                className: 'text-center sum-column',
                targets: [1, 2, 3, 4, 5, 6, 8, 9],
            },
            {
                className: 'money-column',
                targets: [8, 9],
            },
            {
                className: 'percent-column',
                targets: [5, 6],
            },
            {
                className: 'text-center average-percent',
                targets: [7],
            },
            {
                targets: [8],
                createdCell: function (td, cellData, rowData, row, col) {
                    if (cellData > 0) {
                        $(td).addClass('positive');
                    }
                }
            },
            {
                targets: [9],
                createdCell: function (td, cellData, rowData, row, col) {
                    if (cellData > 0) {
                        $(td).addClass('negative');
                    }
                }
            }
            ],
            data: data,
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(function() {
                        initInsightsRelosTables(
                            ...filter_status.insights_relos_table
                        );
                    },
                    'insights_relos'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(function() {
                        initInsightsRelosTables(
                            ...filter_status.insights_relos_table
                        );
                    },
                    'insights_relos'
                ),
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {

                $(this).find('thead').prepend('<tr>' +
                    '<th></th>' +
                    '<th colspan="4" class="text-center">RELOs</th>' +
                    '<th colspan="5"></th>' +
                    '</tr>');

                let totals = {};
                if (typeof self.report_data.enterprise !== 'undefined') {
                    totals = {
                        5: _.sumBy(Object.keys(self.report_data.enterprise), function (metric) {
                            return self.report_data.enterprise[metric].relo_notification.total_relo;
                        }),
                        6: _.sumBy(Object.keys(self.report_data.enterprise), function (metric) {
                            return self.report_data.enterprise[metric].relo_notification.total_relo;
                        })
                    };
                }

                self.initComplete(row, data, start, end, display, this.api(), $(this), totals);
            }
        });
    }

    function initInsightsFollowUpsTables(data_key, filter_name)
    {
        self.$insightsFollowUpsTable = $('table#insights-follow-ups');

        if (!self.$insightsFollowUpsTable.length) {
            return;
        }

        if ($.fn.DataTable.isDataTable('table#insights-follow-ups'))
        {
            self.$insightsFollowUpsTable.DataTable().destroy();
            self.$insightsFollowUpsTable.empty();
        }

        if (typeof data_key == 'undefined') {
            data_key = 'new_opportunities';
        }
        if (typeof filter_name == 'undefined') {
            filter_name = 'New Opportunity';
        }

        filter_status.insightsFollowUpsTable = [
            data_key,
            filter_name
        ];

        let data = [];

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_follow_ups',
            self.locations,
            self.location_groups,
            self.report_data.enterprise,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let report_data = data_converter.get_data('insights_follow_ups');

        _.each(report_data, function(metric)
        {
            let location = metric.location;
            metric = metric.follow_ups_details[data_key];
            metric.location = location;
            data.push(metric);
        });

        self.$insightsFollowUpsTable.DataTable({
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            columns: [
                {
                    title: 'Location',
                    data: 'location.name'
                },
                {
                    title: 'Total',
                    data: function ( row, type, val, meta ) {
                        let route_params = {
                            ignore_locations: 1,
                            metadata: {
                                follow_up_recommended: 1,
                                classification: filter_name,
                            },
                            'follow-ups': (('Existing Opportunity' === filter_name) ? 0 : 1),
                            weighted: 1,
                        };
                        route_params[data_converter.get_first_index_key('insights_follow_ups')] = row.location.id;
                        return formatters.calls(
                            row.total,
                            type,
                            null,
                            'insights_follow_ups',
                            {
                                route: 'calls.search',
                                route_params: route_params,
                            },
                        );
                    },
                },
                {
                    title: 'Converted',
                    data: function ( row, type, val, meta ) {
                        let route_params = {
                            ignore_locations: 1,
                            metadata: {
                                follow_up_recommended: 1,
                                classification: filter_name,
                            },
                            'follow-up-conversion': 1,
                            weighted: 1,
                        };
                        route_params[data_converter.get_first_index_key('insights_follow_ups')] = row.location.id;
                        return formatters.call_count(
                            row.converted,
                            row.total,
                            type,
                            'insights_follow_ups',
                            {
                                route: 'calls.search',
                                route_params: route_params,
                            },
                        );
                    },
                    render: function (data, type, row, meta) {
                        return renderers.call_count(data, row.converted, row.total, type, 'insights_follow_ups');
                    }
                },
                {
                    title: 'Success Rate',
                    data: function ( row, type, val, meta ) {
                        return formatters.percent(row.fsr, type, row);
                    }
                    // data: 'fsr'
                },
                {
                    title: 'Opp. Recovered',
                    data: 'won',
                    render: formatters.money
                },
                {
                    title: 'Opp. Lost',
                    data: 'lost',
                    render: formatters.money
                }
            ],
            columnDefs:[
                {
                    className: 'text-center sum-column',
                    targets: [1, 2, 4, 5],
                },
                {
                    className: 'money-column',
                    targets: [4, 5],
                },
                {
                    className: 'percent-column',
                    targets: [2],
                },
                {
                    className: 'text-center average-percent',
                    targets: [3],
                },
                {
                    targets: [4],
                    createdCell: function (td, cellData, rowData, row, col) {
                        if (cellData > 0) {
                            $(td).addClass('positive');
                        }
                    }
                },
                {
                    targets: [5],
                    createdCell: function (td, cellData, rowData, row, col) {
                        if (cellData > 0) {
                            $(td).addClass('negative');
                        }
                    }
                }
            ],
            data: data,
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(function() {
                        initInsightsFollowUpsTables(
                            ...filter_status.insightsFollowUpsTable
                        );
                    },
                    'insights_follow_ups'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(function() {
                        initInsightsFollowUpsTables(
                            ...filter_status.insightsFollowUpsTable
                        );
                    },
                    'insights_follow_ups'
                ),
                {
                    extend:    'collection',
                    text:      '<i class="fa fa-filter"></i> ' + filter_name + ' <span class="caret"></span>',
                    className: 'btn-sm action-button dt-button enterprise-button',
                    buttons:   [
                        {
                            text: 'New Opportunity',
                            action: function ( e, dt, node, config ) {
                                filter_status.insightsFollowUpsTable = [
                                    'new_opportunities',
                                    'New Opportunity'
                                ];
                                initInsightsFollowUpsTables(...filter_status.insightsFollowUpsTable);
                            }
                        },
                        {
                            text: 'Existing Opportunity',
                            action: function ( e, dt, node, config )
                            {
                                filter_status.insightsFollowUpsTable = [
                                    'existing_opportunities',
                                    'Existing Opportunity'
                                ];
                                initInsightsFollowUpsTables(...filter_status.insightsFollowUpsTable);
                            }
                        },
                        {
                            text: 'Unknown Opportunity',
                            action: function ( e, dt, node, config ) {
                                filter_status.insightsFollowUpsTable = [
                                    'unknown_opportunities',
                                    'Unknown Opportunity'
                                ];
                                initInsightsFollowUpsTables(...filter_status.insightsFollowUpsTable);
                            }
                        },
                        {
                            text: 'Not Connected',
                            action: function ( e, dt, node, config )
                            {
                                filter_status.insightsFollowUpsTable = [
                                    'not_connected',
                                    'Not Connected'
                                ];
                                initInsightsFollowUpsTables(...filter_status.insightsFollowUpsTable);
                            }
                        },
                        /*
                        {
                            text: 'Unweighted',
                            action: function ( e, dt, node, config )
                            {
                                initInsightsFollowUpsTables('unweighted', 'Unweighted');
                            }
                        }
                        */
                    ]
                },
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {
                let totals = {};
                if (typeof self.report_data.enterprise !== 'undefined') {
                    totals = {
                        2: _.sumBy(Object.keys(self.report_data.enterprise), function (metric) {
                            return self.report_data.enterprise[metric].follow_ups_details[data_key].total;
                        })
                    };
                }
                self.initComplete(row, data, start, end, display, this.api(), $(this), totals);
            }
        });
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initCallRecordPerformanceDataTable()
    {
        self.$table = $('table#call-record-performance');

        if (self.$table.length)
        {
            self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                responsive: true,
                'order': [
                    [5, 'asc']
                ],
                buttons: [
                    {
                        extend:        'colvis',
                        text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                        className:     'btn-sm action-button dt-button',
                        columns:       ':not(.no-toggle-visibility)'

                    }
                ],
                "columnDefs": [
                    {
                        "targets": [10],
                        "orderable": false
                    },
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }


    function initFollowUpFlowChart() {

        var follow_up_helpers = [];

        _.each(self.report_data.staff_performance, function(item)
        {
            _.each(item.follow_up.helpers, function(helper)
            {
                follow_up_helpers.push([item.user.name, ' ' + helper.author.name, helper.total]);
            });
        });

        $('#helper-flow-chart').highcharts({
            title: {
                text: 'Follow Up Actions'
            },
            subtitle: {
                text: 'A Flow of Actions/Responses to Recommendations'
            },
            chart: {
                events: {
                    render: function() {
                        if (follow_up_helpers.length)
                            this.hideNoData();  // hide no data message
                    }
                }
            },
            series: [{
                keys: ['from', 'to', 'weight'],
                data: follow_up_helpers,
                type: 'sankey',
                name: 'Call Answered By → Action/Response By'
            }]
        });
    }

    function initConversionFlowChart() {

        var conversions = [];

        _.each(self.report_data.staff_performance, function(item)
        {
            if (item.follow_up.converted > 0)
                conversions.push([item.user.name, 'Converted', item.follow_up.converted]);

            if (item.follow_up.not_converted > 0)
                conversions.push([item.user.name, 'Not Converted', item.follow_up.not_converted]);
        });

        $('#conversion-flow-chart').highcharts({
            title: {
                text: 'Follow Up Outcomes'
            },
            subtitle: {
                text: 'A Flow of Booking Outcomes to Follow Up Actions'
            },
            chart: {
                events: {
                    render: function() {
                        if (conversions.length)
                            this.hideNoData();  // hide no data message
                    }
                }
            },
            series: [{
                keys: ['from', 'to', 'weight'],
                data: conversions,
                type: 'sankey',
                name: 'Follow Up Outcome'
            }]
        });
    }

    function bindUIElements()
    {
        //
    }

    function initTooltips()
    {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="tooltip"]', self.$table.DataTable().rows().nodes()).tooltip();
    }
})();