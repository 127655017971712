(function()
{
    namespace("PatientPrism.Admin.Entities.Locations", init);

    function init()
    {
        let $locations_table = $('table#datatable-locations').DataTable({
            'order': [
                [0, 'asc']
            ],
            "columnDefs": [
            {
                "targets": 0,
                "orderable": false
            }],
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            buttons: [
                {
                  extend: "csv",
                  text: '<i class="fa fa-file-text-o"></i> Export CSV',
                  filename: "search-results",
                  className: "btn-sm action-button dt-button",
                  exportOptions: {
                    orthogonal: "export",
                    columns: [":visible :not(.no-export)"],
                  },
                }],
            "sPaginationType": "simple"
        });

        var body = {
            expand: ['users', 'company']
        };

        var callback = function(data)
        {
            let current_user_tz = PatientPrism.Common.Session.get('current').user.timezone,
                date_format = 'MM/DD/YYYY hh:mm A zz';

            if (!data.data || data.data.length == 0) {
                $locations_table.settings()[0].oLanguage.sEmptyTable = 'No Locations Available';
                $locations_table.draw();

                return;
            }

            _.each(data.data, function(location)
            {
                let location_name_link = _router.link_to_route('manage.locations.summary', location.name, {
                        'location_id': location.id
                    }),
                    company_name_link = location.company && _router.link_to_route('settings.set.company', location.company.name, {
                        'company_id': location.company_id
                    }),
                    users_link = _router.link_to_route('manage.locations.users', location.users.length || '0', {
                        'location_id': location.id
                    });

                $locations_table.row.add([
                    location_name_link,
                    company_name_link,
                    users_link,
                    moment(location.created_at + 'Z').utc().tz(current_user_tz).format(date_format),
                ]);
            });
            $locations_table.draw();
        };

        PatientPrism.API.Locations.all(callback, body);
    }
})();