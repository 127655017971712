(function()
{
    namespace("PatientPrism.Modules.Calls.Live", init);

    var self = {
        $table: null
    };

    function init()
    {
        PatientPrism.Common.UI.BlockedCallers.initBlockedCallers();
        initLiveCallsDataTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initLiveCallsDataTable()
    {
        self.$table = $('table#live-calls');

        if (self.$table.length)
        {
            self.$table.DataTable({
                responsive: true,
                "pageLength": 25,
                'order': [
                    [5, 'asc']
                ],
                "columnDefs": [
                {
                    "targets": [],
                    "orderable": false
                }],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "No Calls Available"
                },
                "sPaginationType": "simple"
            });
        }
    }

})();