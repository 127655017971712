(function()
{
    namespace("PatientPrism.Config", get);

    var self = {
	    config: {
	    	datetimepicker : {
	    		format:       'MM/DD/YYYY',
	    		month_format: 'MM/YYYY',
	    		full_format:  'MM/DD/YYYY LT'
	    	},
	    	callviz: {
		        waveColor:          '#3C97D3',
		        progressColor:      '#243140',
		        cursorColor:        '#296993',
		        dragSelectionColor: 'rgba(0, 255, 0, 0.4)',
		        normalize:          true,
		        skipLength:         10,
		        region_colors:      {
		            positive: 'rgba(0, 255, 0, 0.4)',
		            negative: 'rgba(255, 0, 0, 0.4)',
		            neutral:  'rgba(153, 153, 153, 0.4)',
		            relo:     'rgba(168, 141, 250, 0.4)',
		            tip:      'rgba(255, 255, 0, 0.4)',
		            hold:     'rgba(255, 0, 0, 0.4)'
		        },
		        region_min_duration: 3, // Percent of total call duration
		        showTimecode:        true,
		        responsive:          true,
		        hold_time_responses: [
		        	'Connected and Immediately Placed On Hold',
		        	'Call Transfer in Progress',
		        	'Waiting for Response to Question',
		        	'Waiting for Spanish Speaker',
		        ]
		    }
	    }
	};

    function get(config_property)
    {
    	return Object.byString(self.config, config_property);
    }

    Object.byString = function(o, s)
    {
	    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	    s = s.replace(/^\./, '');           // strip a leading dot
	    var a = s.split('.');

	    for (var i = 0, n = a.length; i < n; ++i)
	    {
	        var k = a[i];

	        if (k in o) {
	            o = o[k];
	        } else {
	            return;
	        }
	    }

	    return o;
	}
})();