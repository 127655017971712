(function()
{
    namespace("PatientPrism.Common.Vendor.DataTable.ActionColumn.Sellables", init);

    var self = {
        $table:          null,
        datatable:       null,
        $column_header:  null,
    }

    function init(table)
    {
        self.$table          = table;
        self.datatable       = self.$table.DataTable();

        self.$column_header = $('.action-column-header', self.$table);

        $('.toggle-select', self.$column_header).click( function (event)
        {
            event.preventDefault();
            event.stopPropagation();

            toggleSelectAllRows();

            $(this).text((self.$column_header.attr('data-selected') == 1 ? 'Deselect' : 'Select') + ' All')
        });

        // A checkbox change event has fired
        $('td.action-column > input[type="checkbox"].selection-checkbox', self.datatable.rows().nodes()).on('change', function (event)
        {
            // Mark row as selected
            $(this).is(':checked') ? $(this).closest('tr').addClass('selected') : $(this).closest('tr').removeClass('selected');

            var selected_row_count = getSelectedRowCount();

            // Update row count text in dropdown menu
            $('.selected-row-count', self.$column_header).text(selected_row_count ? selected_row_count : 'No');

            // Enable action items if more than 0 rows are selected
            selected_row_count ? $('.action-item', self.$column_header).removeClass('disabled') : $('.action-item', self.$column_header).addClass('disabled');
        });

        $('.action-item[data-action="sellables-delete"]', self.$table).click(function(event)
        {
            event.preventDefault();

            if ($(this).hasClass('disabled'))
                return;

            var callback = function (data, textStatus, jqXHR)
            {
                swal.close();

                if (textStatus === 'success')
                {
                    $(self.datatable.rows('.selected').nodes()).fadeOut('fast', function()
                    {
                        // Remove selected rows from the table
                        self.datatable.rows('.selected')
                            .remove()
                            .draw(false);
                    });

                    PatientPrism.Common.UI.Notification.create('success', data.message);
                }
                else
                {
                    PatientPrism.Common.UI.Notification.create('error', data.error.message);
                }
            }

            var request_body = {
                sellable_id: getSelectedRowIds()
            };

            swal({
                title: "Are you sure?",
                text: "This will permanently delete the procedure?<br/>Historical reporting will not be affected.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, Delete the Procedure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: false,
                html: true
            },
            function(isConfirm)
            {
                isConfirm ? PatientPrism.API.Sellables.destroy(null, callback, request_body) : swal.close();
            });
        })
    }

    function toggleSelectAllRows()
    {
        // Get all available rows
        var available_rows = self.datatable.rows({ 'search': 'applied' }).nodes();

        // Check/uncheck checkboxes for all rows in the table
        $('td.action-column > input[type="checkbox"].selection-checkbox', available_rows)
            .prop('checked', self.$column_header.attr('data-selected') == 1 ? 0 : 1)
            .trigger('change');

        self.$column_header.attr('data-selected', (self.$column_header.attr('data-selected') == 1 ? 0 : 1));
    }

    function getSelectedRowCount()
    {
        return self.datatable.rows('.selected').data().length;
    }

    function getSelectedRowIds()
    {
        var ids = [];

        $(self.datatable.rows('.selected').nodes()).each(function(row)
        {
            ids.push($(this).closest('tr').attr('data-id'));
        });

        return ids;
    }
})();