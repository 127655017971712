(function()
{
    namespace("PatientPrism.Modules.Calls.Reviews.AfterHours", init);

    var self = {
        $table: null
    };

    function init()
    {
        PatientPrism.Common.UI.BlockedCallers.initBlockedCallers();
        initNoRecordingCallsDataTable();

        PatientPrism.Common.Vendor.DataTable.ActionColumn.CallRecords.init(self.$table);
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initNoRecordingCallsDataTable()
    {
        self.$table = $('table#after-hours-calls');

        if (self.$table.length)
        {
            self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                buttons: [
                    {
                        extend:        'csv',
                        text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                        filename:      'after-hour',
                        className:     'btn-sm action-button dt-button',
                        exportOptions: {
                            orthogonal: 'export',
                            columns: [ ':visible :not(.no-export)' ]
                        },
                    },
                ],
                'order': [
                    [5, 'desc']
                ],
                "columnDefs": [
                {
                    "targets": [1,6],
                    "orderable": false
                }],
                language: {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "No Calls Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }

})();