(function()
{
    namespace("PatientPrism.Common.Vendor.DataTable.ActionColumn.PhoneNumbers", init);

    var self = {
        $table:          null,
        datatable:       null,
        $column_header:  null,
    }

    function init(table)
    {
        self.$table          = table;
        self.datatable       = self.$table.DataTable();

        self.$column_header = $('.action-column-header', self.$table);

        $('.toggle-select', self.$column_header).click( function (event)
        {
            event.preventDefault();
            event.stopPropagation();

            toggleSelectAllRows();

            $(this).text((self.$column_header.attr('data-selected') == 1 ? 'Deselect' : 'Select') + ' All')
        });
        var phone_numbers_convert = $('.action-item[data-action="phone-numbers-convert"]', self.$column_header);

        // A checkbox change event has fired
        $('td.action-column > input[type="checkbox"].selection-checkbox', self.datatable.rows().nodes()).on('change', function (event)
        {
            // Mark row as selected
            $(this).is(':checked') ? $(this).closest('tr').addClass('selected') : $(this).closest('tr').removeClass('selected');

            var selected_row_count_num = getSelectedRowCount();
            var action_items = $('.action-item', self.$column_header);
            var selected_row_count = $('.selected-row-count', self.$column_header);
            
            // Enable action items if more than 0 rows are selected
            if (selected_row_count_num) {
            	selected_row_count.text(selected_row_count_num);
                action_items.removeClass('disabled');
                var cells = _.map(getTypeCells().find('.type-title'),function (a) {
                	return $(a).text();
                });
                //check if all fetched values are unique
                if ($.unique(cells).length === 1) { 
                	//invert text label
	                var uniqueText = cells[0] == "Dynamic" ? "Static" : "Dynamic";
	                phone_numbers_convert.find('a').text("Convert to " + uniqueText);
                } else {
                	phone_numbers_convert.addClass('disabled');
                }
            } else {
                // Update row count text in dropdown menu
            	selected_row_count.text('No');
                action_items.addClass('disabled');
                phone_numbers_convert.find('a').text("Toggle Type");
            }
            
        });
        
        phone_numbers_convert.click(function(event) {
            event.preventDefault();
            var that = $(this);
            if (that.hasClass('disabled')) {
                return;
            }
            var text = '';
        	var cells = getTypeCells();
        	var submitText = "Yes, Convert the Numbers";
        	var isStatic = that.text().indexOf("Static") !== -1;
            //conversion destination
            //convert to static
            if (isStatic) {
                var count = _.reduce(cells.find('.children-cnt'),function (count, a) {
                	return count += $(a).text() * 1;
                },0);
            	text = "This will convert "
            		+ cells.length
            		+ " parent phone numbers, and "
            		+ count
            		+ " of their children from Static to Dynamic and cannot be undone.";
            //convert to dynamic
            } else {
            	text = "This will convert "
            		+ cells.length
            		+ " phone numbers from Dynamic to Static and cannot be undone.";
            }

            var callback = function (data, textStatus, jqXHR)
            {
                swal.close();

                if (textStatus === 'success')
                {
                    PatientPrism.Common.UI.Notification.create('success', data.message);
                    setTimeout(function() {
                    	location.reload();
                    },1000);
                }
                else
                {
                    PatientPrism.Common.UI.Notification.create('error', data.error.message);
                }
            }

            var request_body = {
                phone_number_id: getSelectedRowIds()
            };

            swal(swalConfig(text, submitText),
            function(isConfirm)
            {
                if (isConfirm) {
                	isStatic ? PatientPrism.API.PhoneNumbers.makeStatic(callback, request_body) : PatientPrism.API.PhoneNumbers.makeDynamic(callback, request_body);
                	return;
                }
                swal.close();
            });
        });

        $('.action-item[data-action="phone-numbers-delete"]', self.$table).click(function(event)
        {
            event.preventDefault();

            if ($(this).hasClass('disabled'))
                return;

            var callback = function (data, textStatus, jqXHR)
            {
                swal.close();

                if (textStatus === 'success')
                {
                    $(self.datatable.rows('.selected').nodes()).fadeOut('fast', function()
                    {
                        // Remove selected rows from the table
                        self.datatable.rows('.selected')
                            .remove()
                            .draw(false);
                    });

                    PatientPrism.Common.UI.Notification.create('success', data.message);
                }
                else
                {
                    PatientPrism.Common.UI.Notification.create('error', data.error.message);
                }
            }

            var request_body = {
                phone_number_id: getSelectedRowIds()
            };

            swal(swalConfig(
            		"This will release the number from the carrier and cannot be undone. This number will be forfeit and may be unrecoverable.",
            		"Yes, Release the Number"),
            	function(isConfirm) {
                    isConfirm ? PatientPrism.API.PhoneNumbers.destroy(null, callback, request_body) : swal.close();
                }
            );
        })
    }
    
    /**
     * prepare swal config
     */
    function swalConfig(text, submitText)
    {
    	return {
            title: "Are you sure?",
            text: text,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: submitText,
            cancelButtonText: "No, Cancel",
            showLoaderOnConfirm: true,
            closeOnConfirm: false,
            closeOnCancel: false
        };
    }
    
    /**
     * get type cell nodes from the datatable
     */
    function getTypeCells()
    {
        var dt = self.datatable;
        //get all sec=lected rows
        var rows = dt.rows('.selected').indexes();
        //fetch data from column 2 of the rowsswalConfig(text, submitText)
        return $(dt.cells(rows,2).nodes());
    }

    function toggleSelectAllRows()
    {
        // Get all available rows
        var available_rows = self.datatable.rows({ 'search': 'applied' }).nodes();

        // Check/uncheck checkboxes for all rows in the table
        $('td.action-column > input[type="checkbox"].selection-checkbox', available_rows)
            .prop('checked', self.$column_header.attr('data-selected') == 1 ? 0 : 1)
            .trigger('change');

        self.$column_header.attr('data-selected', (self.$column_header.attr('data-selected') == 1 ? 0 : 1));
    }

    function getSelectedRowCount()
    {
        return self.datatable.rows('.selected').data().length;
    }

    function getSelectedRowIds()
    {
        var ids = [];

        $(self.datatable.rows('.selected').nodes()).each(function(row)
        {
            ids.push($(this).closest('tr').attr('data-id'));
        });

        return ids;
    }
})();