(function() {
    namespace("PatientPrism.Common.UI.Filters.PredefinedBuilder", init);

    var _settings = {
            conditions : [ 'AND' ],
            allow_groups : 0,
            //allow only unique selectors in results
            plugins : ['unique-filter']
    }
    
    var _actionContainer = $('#inner-action-builder-container');
    var _resultContainer = $('#inner-result-builder-container');
    var _actionInput = $('#action-builder-modal [name=action]');
    var _resultInput = $('#action-builder-modal [name=result]');
    var _idInput = $('#action-builder-modal [name=id]');
    
    /**
     * Apply query builder rules to given container of elements
     */
    function setRules() {
            rules = PatientPrism.Common.UI.Filters.PredefinedRules.parseRules(_actionInput);
            if (rules == null) {
                rules = [{empty: true}];
            }
            _actionContainer.queryBuilder('setRules', rules);
            rules = PatientPrism.Common.UI.Filters.PredefinedRules.parseRules(_resultInput);
            if (rules == null) {
                rules = [{empty: true}];
            }
            _resultContainer.queryBuilder('setRules', rules);
    }
    
    /**
     * Init the page
     */
    function init() {
    	//show loader
    	PatientPrism.Common.UI.Loader.init();
    	PatientPrism.Common.UI.Loader.show();
        //define variable
        var table = $('#datatable-predefined-rules');
        var token = $('#predefined-rules-form [name=_token]').val();
        
        //click on save button
        table.on('click','.btn-primary',function() {
            var that = $(this);
            var parent = that.closest('tr');
            //clone row values to the modal
            _actionInput.val(parent.find('[name=action]').val());
            _resultInput.val(parent.find('[name=result]').val());
            _idInput.val(parent.find('[name=id]').val());
            setRules();
        //click on delete button
        }).on('click','.btn-danger',function() {
            var that = $(this);
            var parent = that.closest('tr');
            //send AJAX request to add new predefined rule
            $.ajax({
                type: 'DELETE',
                url: $('#predefined-rules-delete-link').val().replace("!--id--",parent.find("[name=id]").val()),
                data: {
                    _token : token
                }, 
                success: function(response) {
                    //delete row
                    table.DataTable().row(parent).remove().draw();
                    //show success alert
                    PatientPrism.Common.UI.Filters.PredefinedRules.showBlinkMessage($('#page-body .alert'), true, response.message);
                },
                error : function(response) {
                    //show error
                    PatientPrism.Common.UI.Filters.PredefinedRules.showBlinkMessage($('#page-body .alert'), false, response.message);
                }
            });
        });
        
        //call global hangdler to launch the promicess
        //and pass the callback
        //filters - filters got from promices
        PatientPrism.Common.UI.Filters.BuilderGlobal.init(function(filters) {
            _settings.filters = PatientPrism.Common.UI.Filters.PredefinedRules.promiseCallback(table, filters);
            
            //init query builder

            // action part should have several filters
            let action_filters = _.filter(_settings.filters, function (item) {
                return !_.includes(
                    [
                        'answered_by',
                        'answered_by_unlisted',
                        'apt_time',
                        'caller_name',
                        'opportunity_panel',
                        'btn_submit_review_with_relo'
                    ],
                    item.id
                );
            });
            let action_settings = Object.assign({}, _settings);
            action_settings.filters = action_filters;
            _actionContainer.queryBuilder(action_settings);

            //clonning settings
            var settings = Object.assign({}, _settings);
            //delete keyword from result since it should be applied to certain second only
            var exclude = [ 'keyword' ];
            filters = _.map(settings.filters, function(item) {
                //allow only unique selectors in results
                switch (item.id) {
                    case 'answered_by':
                    case 'apt_time':
                    case 'caller_name':
                        item.operators = ['disabled'];
                        break;
                    case 'call_basic':
                        item.operators = ['in', 'disabled'];
                        break;
                    default:
                        item.operators = ['equal', 'disabled'];
                        break;
                }

                return item;
            });
            settings.filters = _.filter(filters, function(item) {
                return exclude.indexOf(item.id) == -1;
            });

            _resultContainer.queryBuilder(settings);

            // add custom 'disabled' operator
            _resultContainer[0].queryBuilder.operators.push({
                type: 'disabled',
                optgroup: null,
                nb_inputs: 0,
                multiple: false,
                apply_to: ["string", "number", "datetime", "boolean"]
            });

            setRules();
            
            //this should be called after all query builder elevemnts  rendered
            PatientPrism.Common.UI.Filters.PredefinedRules.initRulesDataTables(table, {
                columns: [
                    { "orderable": false, className: "rule-block" },
                    { "orderable": false, className: "rule-block" },
                    { 
                        "orderable"            : false,
                        "width"                : "135px",
                        "data"                : null,
                        "defaultContent"    : 
                            '<button type="button" class="btn btn-danger">Delete</button>&nbsp;'
                            + '<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#action-builder-modal">Edit</button>'
                    }
                ]
            });

            checkIfRulesUnique();
            
            //on modal form submit
            $('#action-builder-modal-form').submit(function(e) {
                e.preventDefault();
                var form = $(this);
                //get action and result values
                var action = JSON.stringify(_actionContainer
                        .queryBuilder('getRules'));
                var result = JSON.stringify(_resultContainer
                        .queryBuilder('getRules'));
                //populate hidden inputs with the values
                _actionInput.val(action);
                _resultInput.val(result);
                //values should not be empty and should be valid JSON
                if (action == 'null' || result == 'null') {
                    return false;
                }
                //send AJAX request to add new predefined rule
                var id = _idInput.val();
                var method = id ? 'PUT' : 'POST';
                var url = id ? $('#predefined-rules-edit-link').val().replace("!--id--",id) : form.attr('action');
                $.ajax({
                    type: method,
                    url: url,
                    data: form.serialize(), 
                    success: function(response) {
                        //show success alert
                        PatientPrism.Common.UI.Filters.PredefinedRules.showBlinkMessage(form.find('.alert'), true, response.message, function() {
                            //do close
                            form.closest('.modal').modal('toggle');
                            //reset modal after successfull save
                            _actionContainer.queryBuilder('reset');
                            _resultContainer.queryBuilder('reset');
                            _actionInput.val('');
                            _resultInput.val('');
                            _idInput.val('');
                        });
                        //add callback
                        var newRow = null;
                        if (id) {
                            newRow = table.find('[name=id][value='+id+']').closest('tr');
                            newRow.find('[name=action]').val(_actionInput.val());
                            newRow.find('[name=result]').val(_resultInput.val());
                            newRow.find('.rule-text').html('');
                        } else {
                            //add new row and return node
                            newRow = table.DataTable().row.add( [
                                '<input type="hidden" name="id" value="'+response.data.id+'" />'
                                + $("<p>").append(_actionInput.clone()).html(),
                                $("<p>").append(_resultInput.clone()).html()
                            ] ).draw( false ).node();
                            newRow = $(newRow);
                        }
                        //apply text transform for new row
                        PatientPrism.Common.UI.Filters.PredefinedRules.transformToText(newRow, _settings.filters);
                    },
                    error : function(response) {
                        //show error
                        PatientPrism.Common.UI.Filters.PredefinedRules.showBlinkMessage(form.find('.alert'), false, response.message);
                    }
                });
                return false;
            });
        	//hide loader
        	PatientPrism.Common.UI.Loader.hide();
        });
    }
    function checkIfRulesUnique() {
        function merge_array(array1, array2) {
            var result_array = [];
            var arr = array1.concat(array2);
            var len = arr.length;
            var assoc = {};

            while(len--) {
                var item = arr[len];

                if(!assoc[item]) {
                    result_array.unshift(item);
                    assoc[item] = true;
                }
            }

            return result_array;
        }

        function applyUniqueFilter() {
            var used_filters = merge_array(
                Object.keys(_actionContainer[0].queryBuilder.status.used_filters),
                Object.keys(_resultContainer[0].queryBuilder.status.used_filters)
            );

            $('.rule-filter-container select option.used-unique').removeClass('used-unique').removeAttr("disabled");

            used_filters.forEach(function (used_filter) {
                $('.rule-filter-container select option[value="' + used_filter + '"]')
                    .prop("disabled", true)
                    .addClass('used-unique');
            });
        }

        var events = ['afterDeleteRule', 'afterUpdateRuleFilter', 'afterCreateRuleFilters'].map(function(e){
            return e + '.queryBuilder.additionalUniqueness';
        }).join(' ');

        _actionContainer.off(events).on(events, applyUniqueFilter);
        _resultContainer.off(events).on(events, applyUniqueFilter);
    }
})();