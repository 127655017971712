(function()
{
    //universal JS plugin for combination of datatable and th import
    namespace("PatientPrism.Common.Vendor.DataTable.ActionColumn.Th", init);


    var _parent = $("#action-column-general");
    var _table = _parent.closest('table');
    
    
    function init() {
        //we click to show drop down
        _parent.on('click', '.dropdown-toggle', function() {
            var datatable = _table.DataTable();
            //count of items in the datatable
            var available_rows = datatable.rows().nodes().length;
            //count of selected items
            var length = $(":checked", datatable.column(0).nodes()).length;
            //if any item selected - show numbers, else show "No"
            $('.selected-row-count',_parent).text(length ? length : "No");
            //if all items selected - deselect all option enabled, otherwise - select all 
            $('.toggle-select',_parent).text(available_rows == length ? "Deselect All" : "Select All");
        // Select/Deselct all inited
        }).on('click', '.toggle-select', function(e) {
            //prevent from link redirect
            e.preventDefault();
            var datatable = _table.DataTable();
            //get all items
            var available_rows = datatable.rows().nodes();
            //if toggle text is select All - select all, otherwise - deselct all
            $(':checkbox', available_rows).prop('checked', $(this).text() == "Select All").trigger('change');
            
        }).on('click', '.save-action-item', function(e) {
            //prevent from link redirect
            e.preventDefault();
            var datatable = _table.DataTable();
            var form = _parent.closest('form');
            //get all selected checkbox values
            var ids = $(":checked", datatable.column(0).nodes()).map( function( i, element ) {
                return element.value;
            });
            $.post(form.attr('action'), {
                //convert these values to array
                ids: $.makeArray(ids),
                //get CSRF token
                _token: form.find('[name=_token]').val()
            },function(data) {
                PatientPrism.Common.UI.Filters.PredefinedRules.showBlinkMessage($('.alert',form),true,data.message);
            }).fail(function(data) {
                PatientPrism.Common.UI.Filters.PredefinedRules.showBlinkMessage($('.alert',form),false,data.message);
              });
        });
    }
})();