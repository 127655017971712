(function()
{
    namespace("PatientPrism.Modules.Calls.Reviews.Fields.Unweighted", init, showFormLinks);

    let self = {
        $el: null,
        $label_block: null,
        $form_block: null,
        call_record_id: null,
        unweighted_metadata_id: null,
    };

    function init($hidden, call_record_id, unweighted_metadata_id)
    {
        self.$el = $('#call-analysis-panel-form');
        self.$form_block = $("#unweighted_form_group");
        self.$label_block = $("#unweighted_label");
        self.call_record_id = call_record_id;
        self.unweighted_metadata_id = unweighted_metadata_id;

        if (typeof $hidden === 'undefined' || $hidden == false) {
            self.$form_block.show();
        }
    }

    function showFormLinks()
    {
        if (self.$label_block.length) {
            $('<small id="edit-unweighted" class="text-link">&nbsp;Edit</small>')
                .insertAfter(self.$label_block.find('label'))
                .on('click', function () {
                    beginFieldEdition();
                });
        }

        if (self.$form_block.length) {
            self.$form_block.removeClass('col-xs-6')
                .addClass('col-xs-12 col-sm-6 col-md-4');
            $('<small id="cancel-unweighted" class="text-link margin-left-xs">&nbsp;Cancel</small>')
                .insertAfter(self.$form_block.find('label'))
                .on('click', function () {
                    endFieldEdition();
                });
            $('<small id="submit-unweighted" class="text-link margin-left-xs">&nbsp;Submit</small>')
                .insertAfter(self.$form_block.find('label'))
                .on('click', function () {
                    submitFieldEdition();
                });
        }
    }

    function beginFieldEdition()
    {
        $('small[id^=cancel]:visible').trigger('click');
        self.$label_block.hide();
        self.$form_block.show();
    }

    function endFieldEdition()
    {
        self.$form_block.hide();
        self.$label_block.show();
    }

    function submitFieldEdition()
    {
        let callback = function (data, textStatus, jqXHR) {
            if (textStatus === 'success') {
                self.$label_block.find('p').text(data.data.call_analysis.unweighted);
            } else if (textStatus === 'error') {
                throw new Error(data.error.message);
            }

            endFieldEdition();
        };

        let formData = {
            'call_record_id': self.call_record_id,
            'metadata': {
                [self.unweighted_metadata_id]: $('select#unweighted').val()
            }
        };

        PatientPrism.API.HTTP.Socket.patch('/call-records/' + self.call_record_id + '/call-reviews/call-analysis', callback, formData);
    }
})();
