(function()
{
    namespace("PatientPrism.Admin.Videos.TrainingVideos", init);

    var self = {
        $video_form: null,
        $thumbnail_form : null,
        $edit_thumbnail_form: null,
        $data_form: null,
        upload_filename: Date.now()
    };

    function init(data)
    {
        // Prevent the default action when a file is dropped on the window
        $(document).on('drop dragover', function (e) {
            e.preventDefault();
        });

        self.$data_form = $('#training-video-data-form');

        initVideoUploader();
        initThumbnailUploader();
        initEditThumbnailUploader();
        initVideoKeywordsSelectize();
        initCallKeywordsSelectize();
        bindUIElements();
        initEditTriggers();
    }

    function bindUIElements()
    {
        $('#btn-add-training-video').click( function(e)
        {
            var ladda_btn = Ladda.create($(this)[0]);

            // Set button to loading state
            ladda_btn.start();

            var callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    location.reload();
                }
                else if (textStatus === 'error')
                {

                }

                ladda_btn.stop();
            }

            var body = {
                title:          $('#training-video-title').val(),
                description:    $('#training-video-description').val(),
                file_name:      $('#video-filename').val(),
                thumbnail:      $('#thumbnail-filename').val(),
                video_keywords: $('#training-video-keywords').val(),
                call_keywords:  $('#training-video-call-keywords').val()
            };

            PatientPrism.API.TrainingVideos.create(callback, body);
        });

        $('#btn-edit-training-video').click( function(e)
        {
            var $edit_video_modal = $('#modal-admin-edit-training-video');
            var ladda_btn = Ladda.create($(this)[0]);

            // Set button to loading state
            ladda_btn.start();

            var callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    location.reload();
                }
                else if (textStatus === 'error')
                {

                }

                ladda_btn.stop();
            }

            var body = {
                title:          $('#training-video-title', $edit_video_modal).val(),
                description:    $('#training-video-description', $edit_video_modal).val(),
                thumbnail:      $('#thumbnail-filename', $edit_video_modal).val(),
                video_keywords: $('#edit-training-video-keywords', $edit_video_modal).val(),
                call_keywords:  $('#edit-training-video-call-keywords', $edit_video_modal).val()
            };

            PatientPrism.API.TrainingVideos.update($('#video-id', $edit_video_modal).val(), callback, body);
        });
    }

    function initVideoUploader()
    {
        // Assigned to variable for later use.
        self.$video_form = $('#video-upload-form');

        $('.browse-button', self.$video_form).click(function(){
            // Simulate a click on the file input button
            // to show the file browser dialog
            $(this).parent().find('input').click();
        });


        var filesUploaded = [];

        // Place any uploads within the descending folders
        // so ['test1', 'test2'] would become /test1/test2/filename
        var folders = ['training-videos'];

        self.$video_form.fileupload({
            dropZone: $('.dropzone', self.$video_form),
            url: self.$video_form.attr('action'),
            type: self.$video_form.attr('method'),
            datatype: 'xml',
            add: function (event, data)
            {
                // Show warning message if your leaving the page during an upload.
                window.onbeforeunload = function () {
                    return 'The file upload is not complete.';
                };

                $('.dropzone', self.$video_form).slideUp( 'fast', function()
                {
                    $('.dropzone-files', self.$video_form).slideDown();
                });

                var tpl = $('<li class="working"><input type="text" value="0" data-width="48" data-height="48"'+
                ' data-fgColor="#0788a5" data-readOnly="1" data-bgColor="#3e4043" /><p></p><span class="status-icon"></span></li>');

                // Append the file name and file size
                tpl.find('p').text('Uploading ' + data.files[0].name)
                             .append('<i>' + formatFileSize(data.files[0].size) + '</i>');

                // Add the HTML to the UL element
                data.context = tpl.appendTo($('.dropzone-files', self.$video_form));

                // Initialize the knob plugin
                data.context.find('input').knob();

                // Listen for clicks on the cancel icon
                data.context.find('.status-icon').click( function()
                {
                    if(data.context.hasClass('working')){
                        jqXHR.abort();

                        $('.dropzone-files', self.$video_form).slideUp(function(){
                            data.context.remove();

                            $('.dropzone', self.$video_form).slideDown();
                        });


                    }
                });

                // Give the file which is being uploaded it's current content-type (It doesn't retain it otherwise)
                // and give it a unique name (so it won't overwrite anything already on s3).
                var file = data.files[0];
                var filename = self.upload_filename + '.' + file.name.split('.').pop();

                self.$video_form.find('input[name="Content-Type"]').val(file.type);
                self.$video_form.find('input[name="key"]').val((folders.length ? folders.join('/') + '/' : '') + filename);

                // Actually submit to form to S3.
                var jqXHR = data.submit();
            },
            progress: function (e, data) {
                // Calculate the completion percentage of the upload
                var progress = parseInt(data.loaded / data.total * 100, 10);

                // Update the hidden input field and trigger a change
                // so that the jQuery knob plugin knows to update the dial
                data.context.find('input').val(progress).change();

                data.context.find('i').remove();
                data.context.find('p').append('<i>' + formatFileSize(data._progress.loaded) + ' of ' + formatFileSize(data.files[0].size) + '</i>');

                if(progress == 100)
                {
                    data.context.removeClass('working');
                }
            },
            fail: function (e, data) {
                // Something has gone wrong!
                data.context.addClass('error');

                // Remove the 'unsaved changes' message.
                window.onbeforeunload = null;
                $('.progress[data-mod="'+data.files[0].size+'"] .bar').css('width', '100%').addClass('red').html('');
            },
            done: function (event, data) {
                data.context.find('p').text('Video Upload Complete');

                window.onbeforeunload = null;

                // Upload Complete, show information about the upload in a textarea
                // from here you can do what you want as the file is on S3
                // e.g. save reference to your server using another ajax call or log it, etc.
                var original = data.files[0];
                var s3Result = data.result.documentElement.children;

                $('#video-filename', self.$data_form).val(/[^/]*$/.exec(s3Result[2].innerHTML)[0]);

                checkForAllUploadsComplete();

                filesUploaded.push({
                    "original_name": original.name,
                    "s3_name": s3Result[2].innerHTML,
                    "size": original.size,
                    "url": s3Result[0].innerHTML
                });

                $('#uploaded').html(JSON.stringify(filesUploaded, null, 2));
            }
        });
    }

    function initThumbnailUploader()
    {
        // Assigned to variable for later use.
        self.$thumbnail_form = $('#thumbnail-upload-form');

        $('.browse-button', self.$thumbnail_form).click(function(){
            // Simulate a click on the file input button
            // to show the file browser dialog
            $(this).parent().find('input').click();
        });


        var filesUploaded = [];

        // Place any uploads within the descending folders
        // so ['test1', 'test2'] would become /test1/test2/filename
        var folders = ['training-videos'];

        self.$thumbnail_form.fileupload({
            dropZone: $('.dropzone', self.$thumbnail_form),
            url: self.$thumbnail_form.attr('action'),
            type: self.$thumbnail_form.attr('method'),
            datatype: 'xml',
            add: function (event, data)
            {
                // Show warning message if your leaving the page during an upload.
                window.onbeforeunload = function () {
                    return 'The file upload is not complete.';
                };

                $('.dropzone', self.$thumbnail_form).slideUp( 'fast', function()
                {
                    $('.dropzone-files', self.$thumbnail_form).slideDown();
                });

                var tpl = $('<li class="working"><input type="text" value="0" data-width="48" data-height="48"'+
                ' data-fgColor="#0788a5" data-readOnly="1" data-bgColor="#3e4043" /><p></p><span class="status-icon"></span></li>');

                // Append the file name and file size
                tpl.find('p').text('Uploading ' + data.files[0].name)
                             .append('<i>' + formatFileSize(data.files[0].size) + '</i>');

                // Add the HTML to the UL element
                data.context = tpl.appendTo($('.dropzone-files', self.$thumbnail_form));

                // Initialize the knob plugin
                data.context.find('input').knob();

                // Listen for clicks on the cancel icon
                data.context.find('.status-icon').click( function()
                {
                    if(data.context.hasClass('working')){
                        jqXHR.abort();

                        $('.dropzone-files', self.$thumbnail_form).slideUp(function(){
                            data.context.remove();

                            $('.dropzone', self.$thumbnail_form).slideDown();
                        });


                    }
                });

                // Give the file which is being uploaded it's current content-type (It doesn't retain it otherwise)
                // and give it a unique name (so it won't overwrite anything already on s3).
                var file = data.files[0];
                var filename = self.upload_filename + '.' + file.name.split('.').pop();

                self.$thumbnail_form.find('input[name="Content-Type"]').val(file.type);
                self.$thumbnail_form.find('input[name="key"]').val((folders.length ? folders.join('/') + '/' : '') + filename);

                // Actually submit to form to S3.
                var jqXHR = data.submit();
            },
            progress: function (e, data) {
                // Calculate the completion percentage of the upload
                var progress = parseInt(data.loaded / data.total * 100, 10);

                // Update the hidden input field and trigger a change
                // so that the jQuery knob plugin knows to update the dial
                data.context.find('input').val(progress).change();

                data.context.find('i').remove();
                data.context.find('p').append('<i>' + formatFileSize(data._progress.loaded) + ' of ' + formatFileSize(data.files[0].size) + '</i>');

                if(progress == 100)
                {
                    data.context.removeClass('working');


                }
            },
            fail: function (e, data) {
                // Something has gone wrong!
                data.context.addClass('error');

                // Remove the 'unsaved changes' message.
                window.onbeforeunload = null;
                $('.progress[data-mod="'+data.files[0].size+'"] .bar').css('width', '100%').addClass('red').html('');
            },
            done: function (event, data) {
                data.context.find('p').text('Thumbnail Upload Complete');

                window.onbeforeunload = null;

                // Upload Complete, show information about the upload in a textarea
                // from here you can do what you want as the file is on S3
                // e.g. save reference to your server using another ajax call or log it, etc.
                var original = data.files[0];
                var s3Result = data.result.documentElement.children;

                $('#thumbnail-filename', self.$data_form).val(/[^/]*$/.exec(s3Result[2].innerHTML)[0]);
                $('#video-thumbnail-preview').attr('src', s3Result[0].innerHTML);

                checkForAllUploadsComplete();

                filesUploaded.push({
                    "original_name": original.name,
                    "s3_name": s3Result[2].innerHTML,
                    "size": original.size,
                    "url": s3Result[0].innerHTML
                });

                $('#uploaded').html(JSON.stringify(filesUploaded, null, 2));
            }
        });
    }

    function initEditThumbnailUploader()
    {
        // Assigned to variable for later use.
        self.$edit_thumbnail_form = $('#edit-thumbnail-upload-form');

        $('.browse-button', self.$thumbnail_form).click(function(){
            // Simulate a click on the file input button
            // to show the file browser dialog
            $(this).parent().find('input').click();
        });


        var filesUploaded = [];

        // Place any uploads within the descending folders
        // so ['test1', 'test2'] would become /test1/test2/filename
        var folders = ['training-videos'];

        self.$edit_thumbnail_form.fileupload({
            dropZone: $('.dropzone', self.$edit_thumbnail_form),
            url: self.$edit_thumbnail_form.attr('action'),
            type: self.$edit_thumbnail_form.attr('method'),
            datatype: 'xml',
            add: function (event, data)
            {
                // Show warning message if your leaving the page during an upload.
                window.onbeforeunload = function () {
                    return 'The file upload is not complete.';
                };

                $('.dropzone', self.$edit_thumbnail_form).slideUp( 'fast', function()
                {
                    $('.dropzone-files', self.$edit_thumbnail_form).slideDown();
                });

                var tpl = $('<li class="working"><input type="text" value="0" data-width="48" data-height="48"'+
                ' data-fgColor="#0788a5" data-readOnly="1" data-bgColor="#3e4043" /><p></p><span class="status-icon"></span></li>');

                // Append the file name and file size
                tpl.find('p').text('Uploading ' + data.files[0].name)
                             .append('<i>' + formatFileSize(data.files[0].size) + '</i>');

                // Add the HTML to the UL element
                data.context = tpl.appendTo($('.dropzone-files', self.$edit_thumbnail_form));

                // Initialize the knob plugin
                data.context.find('input').knob();

                // Listen for clicks on the cancel icon
                data.context.find('.status-icon').click( function()
                {
                    if(data.context.hasClass('working')){
                        jqXHR.abort();

                        $('.dropzone-files', self.$edit_thumbnail_form).slideUp(function(){
                            data.context.remove();

                            $('.dropzone', self.$edit_thumbnail_form).slideDown();
                        });


                    }
                });

                // Give the file which is being uploaded it's current content-type (It doesn't retain it otherwise)
                // and give it a unique name (so it won't overwrite anything already on s3).
                var file = data.files[0];
                var filename = self.upload_filename + '.' + file.name.split('.').pop();

                self.$edit_thumbnail_form.find('input[name="Content-Type"]').val(file.type);
                self.$edit_thumbnail_form.find('input[name="key"]').val((folders.length ? folders.join('/') + '/' : '') + filename);

                // Actually submit to form to S3.
                var jqXHR = data.submit();
            },
            progress: function (e, data) {
                // Calculate the completion percentage of the upload
                var progress = parseInt(data.loaded / data.total * 100, 10);

                // Update the hidden input field and trigger a change
                // so that the jQuery knob plugin knows to update the dial
                data.context.find('input').val(progress).change();

                data.context.find('i').remove();
                data.context.find('p').append('<i>' + formatFileSize(data._progress.loaded) + ' of ' + formatFileSize(data.files[0].size) + '</i>');

                if(progress == 100)
                {
                    data.context.removeClass('working');


                }
            },
            fail: function (e, data) {
                // Something has gone wrong!
                data.context.addClass('error');

                // Remove the 'unsaved changes' message.
                window.onbeforeunload = null;
                $('.progress[data-mod="'+data.files[0].size+'"] .bar').css('width', '100%').addClass('red').html('');
            },
            done: function (event, data) {
                data.context.find('p').text('Thumbnail Upload Complete');

                window.onbeforeunload = null;

                // Upload Complete, show information about the upload in a textarea
                // from here you can do what you want as the file is on S3
                // e.g. save reference to your server using another ajax call or log it, etc.
                var original = data.files[0];
                var s3Result = data.result.documentElement.children;

                $('#thumbnail-filename', $('#modal-admin-edit-training-video')).val(/[^/]*$/.exec(s3Result[2].innerHTML)[0]);
                $('#video-thumbnail-preview', $('#modal-admin-edit-training-video')).attr('src', s3Result[0].innerHTML);


            }
        });
    }

    // Helper function that formats the file sizes
    function formatFileSize(bytes) {
        if (typeof bytes !== 'number') {
            return '';
        }

        if (bytes >= 1000000000) {
            return (bytes / 1000000000).toFixed(2) + ' GB';
        }

        if (bytes >= 1000000) {
            return (bytes / 1000000).toFixed(2) + ' MB';
        }

        return (bytes / 1000).toFixed(2) + ' KB';
    }

    function checkForAllUploadsComplete()
    {
        if ($('#thumbnail-filename').val() && $('#video-filename').val())
        {
            $('#video-upload-container').fadeOut( 'fast', function()
            {
                $('#video-data-container').fadeIn();
            });

            $('#btn-add-training-video').attr('disabled', false);
        }
    }

    function initVideoKeywordsSelectize()
    {
        $('#training-video-keywords').selectize({
            hideSelected: true,
            create: true,
            placeholder: 'Search or Create Video Keywords'
        });

        $('#edit-training-video-keywords').selectize({
            hideSelected: true,
            create: true,
            placeholder: 'Search or Create Video Keywords'
        });
    }

    function initCallKeywordsSelectize()
    {
        $('#training-video-call-keywords').selectize({
            hideSelected: true,
            create: true,
            placeholder: 'Search or Create Related Call Keywords'
        });

        $('#edit-training-video-call-keywords').selectize({
            hideSelected: true,
            create: true,
            placeholder: 'Search or Create Related Call Keywords'
        });
    }

    function initEditTriggers()
    {
        $( "[trigger-edit-video]" ).click(function(e)
        {
            e.preventDefault();

            var $this = $(this);
            var $edit_video_modal = $('#modal-admin-edit-training-video');

            if (!$this.attr('video-id'))
            {
                console.warn('A video-id must be set to edit a video.')
                return;
            }

            var callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    $('#video-id', $edit_video_modal).val(data.data.id);
                    $('#thumbnail-filename', $edit_video_modal).val(data.data.thumbnail);
                    $('#training-video-title', $edit_video_modal).val(data.data.title);
                    $('#training-video-description', $edit_video_modal).val(data.data.description);
                    $('#video-thumbnail-preview', $edit_video_modal).attr('src', data.data.thumbnail_uri);

                    var video_keywords = [];

                    _.each(data.data.video_keywords, function(keyword)
                    {
                        video_keywords.push(keyword.keyword);
                    });

                    $('#edit-training-video-keywords', $edit_video_modal)[0].selectize.setValue(video_keywords);

                    var call_keywords = [];

                    _.each(data.data.call_keywords, function(keyword)
                    {
                        call_keywords.push(keyword.keyword);
                    });

                    $('#edit-training-video-call-keywords', $edit_video_modal)[0].selectize.setValue(call_keywords);

                    $edit_video_modal.modal('show');
                }
                else if (textStatus === 'error')
                {

                }
            }

            var body = {
                expand: 'video_keywords,call_keywords',
            };

            PatientPrism.API.TrainingVideos.get($this.attr('video-id'), callback, body);
        });
    }
})();