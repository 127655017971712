(function()
{
    namespace("PatientPrism.Common.UI.UserInsights", init, bindPopovers);

    var self = {
        //
    }

    function init()
    {
        initUserInsights();
    }

    function bindPopovers()
    {
        $('[trigger-user-insights]').popover('destroy');

        $('[trigger-user-insights]').popover({
            html: true,
            trigger: 'manual',
            placement: 'right',
            title: '<div class="text-center">User Insight</div>',
            template: '<div class="popover user-insight-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
            content: function()
            {
                var user_id = $(this).attr('data-user-id');
                var container =  "user-insights-container-" + $.now();

                return getUserInsights(container, user_id, $(this));
            }
        });
    }

    function initUserInsights()
    {
        bindPopovers();

        $('html').on('click', function(e) {
            if (typeof $(e.target).attr('trigger-user-insights') == 'undefined' && !$(e.target).parents().is('.popover.in'))
            {
                $('[trigger-user-insights]').popover('hide');
            }
        });

        var timer;
        var delay = 500;

        $('html').on('mouseenter', '[trigger-user-insights]', function()
        {
            var el = $(this);

            timer = setTimeout(function() {
                $("[trigger-user-insights]").not(el).popover('hide');
                el.popover('show');
            }, delay);
        });

        $('html').on('mouseleave', '[trigger-user-insights]', function()
        {
            // on mouse out, cancel the timer
            clearTimeout(timer);
        });

        $('html').on('click', '[trigger-user-insights]', function(e)
        {
            clearTimeout(timer);
            $("[trigger-user-insights]").not($(this)).popover('hide');
            $(this).popover('show');
        })
    }

    function getUserInsights(container_id, user_id, el)
    {
        if (typeof user_id == 'undefined' || !user_id)
            return;

        var callback = function(data, textStatus, jqXHR)
        {
            if (textStatus == 'success')
            {
                var template =
                    '<hr style="margin: 5px 0 10px 0;"/><p class="text-center"><strong>' + data.data.name + '</strong></p><hr style="margin: 5px 0 10px 0;"/>\
                    <div class="panel panel-default">\
                        <div class="panel-heading">Locations</div>\
                        <table class="table table-bordered table-striped">\
                        <tbody>';

                var loop_count = 1;

                _.each(data.data.locations, function(location)
                {
                    if (loop_count == 1)
                        template = template + '<tr>';

                    template = template + '<td>' + location.name + '</td>';

                    if (loop_count == 2)
                    {
                        template = template + '</tr>';
                        loop_count = 0;
                    }

                    loop_count++;
                })

                if (data.data.locations.length % 2 != 0)
                    template = template + '<td></td>';

                template = template + '</tbody></table></div>';

                $('#' + container_id).html(template);

                el.popover('reposition');
            }
        }

        var body = {
            'expand': 'locations'
        };

        PatientPrism.API.Users.get(user_id, callback, body);

        return '<div id="'+ container_id +'">Loading...</div>';
    }
})();