(function()
{
    namespace("PatientPrism.Common.UI.AjaxLoader", init);

    function AjaxLoader(action, errorMessage) {
        var $container = $(this);

        if (!action)
            return $container;

        switch (action) {
            case 'show':
                $container.find('.ajax-loader-overlay').show();
                break;
            case 'hide':
                $container.find('.ajax-loader-overlay').hide();
                break;
            case 'error': // show error message
                $container.children().hide();
                $container.find(".alert-danger").remove();
                $container.append("<div class=\"alert alert-danger\" role=\"alert\">\n" + errorMessage +"</div>");
                break;
            default:
                console.error("This action " + action + " doesn't exist in AjaxLoader lib");
        }

        return $container;
    }

    /**
     * Init ajax loader for ajax requests
     */
    function init()
    {
        $.fn.ajaxLoader = AjaxLoader;

        $('.ajax-loader').each(function () {
            $(this).append("<div class=\"ajax-loader-overlay\">\n" +
                    "<div class=\"ajax-loader-spinner\"></div>\n" +
                    "</div>");
        });
    }
})();