(function()
{
    namespace("PatientPrism.Modules.Calls.Spreadsheets", init);

    var self = {
    };

    function init()
    {

    }
})();