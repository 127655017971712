(function()
{
    namespace("PatientPrism.Modules.Manage.Users.Locations", init);

    var self = {
        user_id: null,
        $table: null,
        $select_location: null,
        $toggle_locations: null,
        forms: {
            $add_to_location: null
        }
    };

    function init(userId)
    {
        self.user_id = userId;
        initLocationsDataTables();
        initSelectLocation();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initLocationsDataTables()
    {
        var oLanguage = {
            "sLengthMenu": "Show _MENU_ locations per page",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Locations",
            "sInfoEmpty": "",
            "sInfoFiltered": " - filtered from _MAX_ total locations",
            "sEmptyTable": "Not Assigned to a Location"
        };

        self.$table = $('table#locations');

        if (self.$table.length)
        {
            self.$table.DataTable({
                "drawCallback": function( settings ) {
                    removeLocationEventSubscribing();
                },
                columnDefs: [{
                    orderable: false,
                    targets: 1
                }],
                'order': [
                    [0, 'asc']
                ],
                "oLanguage": oLanguage,
                "sPaginationType": "simple"
            });
        }
    }

    let removeLocationEventSubscribing = function () {

        let removeLocationButtons = $('.btn-remove-location');
        removeLocationButtons.off('click');

        removeLocationButtons.click(function (e) {
            var location_id = $(this).data('location-id');

            e.preventDefault();

            swal({
                title: "Are you sure?",
                text: "This will delete the location and stop tracking all locations of the current user",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: false
            }, function (isConfirm) {
                if (isConfirm) {
                    location.href =
                        _router.route("manage.locations.users.destroy.user", {
                            'user_id': self.user_id,
                            'location_id': location_id
                        });
                    swal("Stand By", "Stop tracking locations", "success");
                } else {
                    swal("Cancelled", "Tracking was not stopped", "error");
                }
            });
        });
    };

    function initSelectLocation()
    {
        self.$select_location = $('select#select-location');
        self.forms.$add_to_location = $('form#add-to-location');
        self.$toggle_locations = $('#btn-toggle-all-locations-to-user');

        self.$select_location.change( function(e)
        {
            self.forms.$add_to_location.attr('action', $('option:selected', this).attr('data-url'));
        });

        self.$toggle_locations.click(function(e)
        {
            e.preventDefault();

            var toggleBtnData = {
                0: {
                    'text': "This will assign all company's locations to the current user and track them",
                    'standby_text': "We're assigning locations",
                    'cancelled_text': "The locations were not assigned",
                    'route': "manage.users.locations.assign-all",
                },
                1: {
                    'text': "This will stop tracking all locations of the current user",
                    'standby_text': "Stop tracking locations",
                    'cancelled_text': "Tracking was not stopped",
                    'route': "manage.users.locations.clear-all",
                }
            }[self.$toggle_locations.data('locations')];

            swal({
                title: "Are you sure?",
                text: toggleBtnData['text'],
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: false
            }, function(isConfirm) {
                if (isConfirm) {
                    location.href =
                        _router.route(toggleBtnData['route'], {
                            'user_id': self.user_id
                        });
                    swal("Stand By", toggleBtnData['standby_text'], "success");
                } else {
                    swal("Cancelled", toggleBtnData['cancelled_text'], "error");
                }
            });
        })
    }
})();