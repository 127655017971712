(function()
{
    namespace("PatientPrism.API.MicroReports", sellables, bookings, opportunities, call_records, location_scores, leaderboard, not_connected, top_phone_numbers, top_keywords, calls_hourly, calls_daily, follow_ups);

    var self = {
        //
    }

    function call_records(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/call-records', callback, body)
    }

    function bookings(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/bookings', callback, body)
    }

    function opportunities(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/opportunities', callback, body)
    }

    function sellables(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/sellables', callback, body)
    }

    function location_scores(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/location-scores', callback, body)
    }

    function leaderboard(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/leaderboard', callback, body)
    }

    function not_connected(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/not-connected', callback, body)
    }

    function top_phone_numbers(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/top-phone-numbers', callback, body)
    }

    function top_keywords(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/top-keywords', callback, body)
    }

    function calls_hourly(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/calls-hourly', callback, body)
    }

    function calls_daily(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/calls-daily', callback, body)
    }

    function follow_ups(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/follow-ups', callback, body)
    }
})();