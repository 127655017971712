(function()
{
    namespace("PatientPrism.Modules.Dashboard.Daily", init);

    var self = {
        dashboard_uri: '/dashboard/',
        $date_selector: null,
        $table: null,
        tables: {
            $top_phone_numbers: null
        },
        report: null,
    };

    function init(report)
    {
        self.report = JSON.parse(report);

        bindDateSelector();
        initCallRecordsChart();
        initFollowUpsTotalChart();
        initFollowUpsConvertedChart();
        initReloChart();
        initTooltips();
        initTopPhoneNumbersTable();
        initFollowUpRecapDataTable();
    }

     /**
     * Converts element into a datepicker.
     */
    function bindDateSelector()
    {
        self.$date_selector = $('#date-selector');

        if (self.$date_selector.length)
        {
            self.$date_selector.datepicker({
                format: "yyyy-mm-dd"
            })
            .on('changeDate', function (e)
            {
                window.location.href = self.dashboard_uri + e.format();
                return false;
            });
        }
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initFollowUpRecapDataTable()
    {
        self.$table = $('table#follow-up-recap');

        if (self.$table.length)
        {
            self.$table.DataTable({
                responsive: true,
                'order': [
                    [0, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available"
                },
                "sPaginationType": "simple",
                "sPaginationType": "simple",
                "bLengthChange":   false,
                "bFilter":         false,
                "pageLength": 5
            });
        }
    }

   /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initCallRecordsChart()
    {
        self.$table = $('table#call-records');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [5, 'desc']
                ],
                "columnDefs": [
                {
                    "targets": [7],
                    "orderable": false
                }],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "No Calls Available"
                },
                "sPaginationType": "simple",
                "sPaginationType": "simple",
                "bLengthChange":   false,
                "bFilter":         false,
                "pageLength": 5
            });
        }
    }

    function initReloChart()
    {
        $('#relo-chart').highcharts({
            chart: {
                zoomType: 'xy',
                type: 'solidgauge',
                height: 225
            },
            title: {
                text: ''
            },
            pane: {
                center: ['50%', '85%'],
                size: '130%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            // the value axis
            yAxis: {
                stops: [
                    [0.3, '#DDDF0D'], // yellow
                    [0.5, '#DF5353'] // red
                ],
                lineWidth: 0,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                },
                min: 0,
                max: self.report.all.total || 1,
                tickInterval: Math.floor(self.report.all.total / 4)
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            series: [{
                name: 'RELO',
                data: [self.report.all.relo],
                dataLabels: {
                    format: '<div style="text-align:center;cursor:pointer;"><span style="font-size:25px;color:' +
                        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span><br/>' +
                           '<span style="font-size:12px;color:silver;text-decoration:underline;">RELOs</span></div>'
                },
                tooltip: {
                    valueSuffix: ' RELOs'
                },
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e) {
                            e.preventDefault();

                            location.href =
                            encodeURI(
                                _router.route('calls.search', {
                                    'relo':     1,
                                    'weighted': 1
                                })
                            );
                        }
                    }
                }
            }],
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            }
        });
    }

    function initFollowUpsTotalChart()
    {
        $('#follow-ups-total-chart').highcharts({
            chart: {
                zoomType: 'xy',
                type: 'solidgauge',
                height: 225
            },
            title: {
                text: ''
            },
            pane: {
                center: ['50%', '85%'],
                size: '130%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            // the value axis
            yAxis: {
                stops: [
                    [0.25, '#DF5353'], // red
                    [0.50, '#DDDF0D'], // yellow
                    [0.75, '#55BF3B'] // green
                ],
                lineWidth: 0,

                title: {
                    y: -70
                },
                labels: {
                    y: 16
                },
                min: 0,
                max: self.report.all.relo || 1,
                tickInterval: Math.floor(self.report.all.relo / 4)
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            series: [{
                name: 'Follow Ups',
                data: [self.report.all.follow_up_total],
                dataLabels: {
                    format: '<div style="text-align:center;cursor:pointer;"><span style="font-size:25px;color:' +
                        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span><br/>' +
                        '<span style="font-size:12px;color:silver;text-decoration:underline;">Follow Ups</span>' +
                        '<br><span style="font-size:12px;color:silver;">' +
                        (self.report.all.relo
                            ? (self.report.all.follow_up_total / self.report.all.relo * 100).toFixed(2)
                            : 0)
                            + '%' +
                        '</span></div>'
                },
                tooltip: {
                    valueSuffix: ' Follow Ups'
                },
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e) {
                            e.preventDefault();

                            location.href =
                                encodeURI(
                                    _router.route('calls.search', {
                                        'relo': 1,
                                        'follow-ups':   1,
                                        'weighted': 1
                                    })
                                );
                        }
                    }
                }
            }],
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            }
        });
    }

    function initFollowUpsConvertedChart()
    {
        $('#follow-ups-converted-chart').highcharts({
            chart: {
                zoomType: 'xy',
                type: 'solidgauge',
                height: 225
            },
            title: {
                text: ''
            },
            pane: {
                center: ['50%', '85%'],
                size: '130%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            // the value axis
            yAxis: {
                stops: [
                    [0.25, '#DF5353'], // red
                    [0.50, '#DDDF0D'], // yellow
                    [0.75, '#55BF3B'] // green
                ],
                lineWidth: 0,

                title: {
                    y: -70
                },
                labels: {
                    y: 16
                },
                min: 0,
                ceiling: true,
                max: self.report.all.follow_up_total || 1,
                tickInterval: Math.floor(self.report.all.follow_up_total / 4)
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            series: [{
                name: 'Converted',
                data: [self.report.all.follow_up_converted],
                dataLabels: {
                    format: '<div style="text-align:center;cursor:pointer;"><span style="font-size:25px;color:' +
                        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span><br/>' +
                        '<span style="font-size:12px;color:silver;text-decoration:underline;">Converted</span>' +
                        '<br><span style="font-size:12px;color:silver;">' +
                        (self.report.all.follow_up_total
                            ? (self.report.all.follow_up_converted / self.report.all.follow_up_total * 100).toFixed(2)
                            : 0)
                            + '%' +
                        '</span></div>'
                },
                tooltip: {
                    valueSuffix: ' Follow Ups'
                },
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e) {
                            e.preventDefault();

                            location.href =
                                encodeURI(
                                    _router.route('calls.search', {
                                        'relo': 1,
                                        'follow-up-conversion':   1,
                                        'weighted': 1
                                    })
                                );
                        }
                    }
                }
            }],
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            }
        });
    }

    function initTopPhoneNumbersTable()
    {
        self.tables.$top_phone_numbers = $('table#top-phone-numbers-table');

        if (self.tables.$top_phone_numbers.length)
        {
            self.tables.$top_phone_numbers =
            self.tables.$top_phone_numbers.DataTable({
                'order': [
                    [2, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ numbers per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Numbers",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total numbers",
                    "sEmptyTable": "No Data Available"
                },
                "sPaginationType": "simple",
                "bLengthChange":   false,
                "bFilter":         false,
                "pageLength": 5
            });
        }
    }

    function initTooltips()
    {
        $('[data-toggle="tooltip"]', self.$table.DataTable().rows().nodes()).tooltip();
    }
})();