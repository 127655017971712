(function()
{
    namespace("PatientPrism.API.BlockedCallers", block, unblock);

    var self = {
        //
    }

    function block(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/blocked-callers', callback, body)
    }

    function unblock(blocked_caller_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/blocked-callers/' + blocked_caller_id , callback, body)
    }
})();