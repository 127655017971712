(function () {
  namespace("PatientPrism.Modules.Manage.Locations.Profile", init);

  var self = {
    location: null,
    $profile_entry_title: null,
    $profile_entry_data: null,
  };

  function init(location) {
    self.location = location;
    initProfileEntry();
  }

  function initProfileEntry() {
    self.$profile_entry_title = $("#profile-entry-title");
    self.$profile_entry_data = $("#profile-entry-data");

    tinymce.init({
      target: self.$profile_entry_data[0],
      menubar: false,
      toolbar: [
        "formatselect | bold italic underline | hr | bullist numlist | link | backcolor | table",
        "",
      ],
      plugins: ["hr lists link table textcolor colorpicker"],
      invalid_elements: "script",
      link_assume_external_targets: true,
      anchor_bottom: false,
      anchor_top: false,
    });

    $("#btn-add-profile-entry").click(function (e) {
      e.preventDefault();

      PatientPrism.Common.UI.Loader.show();

      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          location.reload();
        } else if (textStatus === "error") {
          PatientPrism.Common.UI.Loader.hide();

          swal({
            title: "An Error Occurred",
            text: "There was an error while trying to add the profile entry.",
            type: "error",
            html: true,
          });
        }
      };

      var request_body = {
        location_id: self.location.id,
        title: self.$profile_entry_title.val(),
        data: tinyMCE.get(self.$profile_entry_data.attr("id")).getContent(),
      };

      PatientPrism.API.ProfileEntries.create(api_callback, request_body);
    });

    $(".profile-panel-list").sortable({
      placeholder: "profile-panel-sort-highlight",
      handle: ".panel-heading",
      update: function (event, ui) {
        var api_callback = function (data, textStatus, jqXHR) {
          if (textStatus === "success") {
            //
          } else if (textStatus === "error") {
            PatientPrism.Common.UI.Loader.hide();

            swal({
              title: "An Error Occurred",
              text: "There was an error while trying to add the profile entry.",
              type: "error",
              html: true,
            });
          }
        };

        var sort_order = $(this).sortable("toArray", {
          attribute: "profile-entry-id",
        });

        for (var i = 0; i < sort_order.length; i++) {
          var request_body = {
            sort_order: i,
          };

          PatientPrism.API.ProfileEntries.update(
            sort_order[i],
            api_callback,
            request_body
          );
        }
      },
    });

    $(".panel-heading", ".profile-panel-list").disableSelection();
  }
})();
