(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.Custom", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init()
    {
        initCallRecordsDataTable();
        initTooltips();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initCallRecordsDataTable()
    {
        self.$table = $('table#call-records');

        if (self.$table.length)
        {
            self.$table.DataTable({
                responsive: true,
                deferRender: true,
                'order': [
                    [17, 'desc']
                ],
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                bStateSave: true,
                buttons: [
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }],
                "columnDefs": [
                    {
                        "targets": 19,
                        "orderable": false,
                        "className": 'no-toggle-visibility'
                    },
                    {
                        "targets": [ 3, 6, 11, 13, 14, 15, 16 ],
                        "visible": false,
                        "searchable": true
                    },
                    {
                        "targets": [13, 14],
                        "className": 'text-center'
                    }
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total calls",
                    "sEmptyTable": "No Call Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                initComplete: function(settings, json) {
                   this.show();
                }
            });
        }
    }


    function initTooltips()
    {
        $('[data-toggle="tooltip"]').tooltip();
    }
})();