(function()
{
    namespace("PatientPrism.Admin.Reports.Calls.Queues", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init()
    {
        initCallQueuesTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initCallQueuesTable()
    {
        self.$table = $('table#call-queues');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'searching': false,
                'paging': false,
                'info': false,
            });
        }
    }
})();