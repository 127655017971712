(function()
{
    namespace("PatientPrism.Common.UI.VideoPlayer", init, play);

    var self = {
        $modal:                null,
        $video_player:         null,
        video_player_instance: null
    }

    var video_player_options = {
        controls: true,
        autoplay: false,
        preload:  'auto',
        poster:   null,
        src:      null,
        fluid:    true
    }

    function init()
    {
        initPlayTriggers();
    }

    function initPlayTriggers()
    {
        $( "[trigger-video]" ).click(function(e)
        {
            e.preventDefault();

            var $this = $(this);

            var video_title = 'Untitled Video';
            var video_description = '';

            if (!$this.attr('video-uri'))
            {
                console.warn('A video-uri must be set to play a video.')
                return;
            }

            if (!$this.attr('thumbnail-uri'))
            {
                console.warn('A thumbnail-uri must be set to play a video.')
                return;
            }

            if (!$this.attr('video-title'))
            {
                console.warn('A video-title is not set.')
            }
            else
            {
                video_title = $this.attr('video-title');
            }

            if (!$this.attr('video-description'))
            {
                console.warn('A video-description is not set.')
            }
            else
            {
                video_description = $this.attr('video-description');
            }

            play($this.attr('video-uri'), $this.attr('thumbnail-uri'), video_title, video_description);
        });
    }

    function play(video_uri, poster_uri, video_title, video_description)
    {
        self.$modal = $('#modal-video-player');
        self.$video_player = $('#video-player', self.$modal);

        // Pause the video when the modal is hidden
        self.$modal.on('hide.bs.modal', function () {
            if (self.video_player_instance)
                self.video_player_instance.pause();
        })

        if (!self.video_player_instance)
        {
            self.video_player_instance = videojs(self.$video_player[0], video_player_options, function()
            {
                // Get's called after the video player is initialized
                self.video_player_instance.src(video_uri);
                self.video_player_instance.poster(poster_uri);
                self.$modal.modal('show');
            });
        }
        else
        {
            self.video_player_instance.src(video_uri);
            self.video_player_instance.poster(poster_uri);
            self.$modal.modal('show');
        }
    }
})();