(function () {
  namespace("PatientPrism.Modules.Calls.Reports.Staff", init);

  var self = {
    $table: null,
    report_data: null,
  };

  function init(data) {
    self.report_data = JSON.parse(data);

    initStaffMetricsDataTable();
    initCallBookingSummaryChart();
    bindUIElements();
    initTooltips();
    PatientPrism.Common.UI.CallBasicsExplorer.init(
      self.report_data.call_basic_category_performance,
      self.report_data.call_basic_performance,
      self.report_data.staff_performance
    );
  }

  /**
   * Converts the HTML table into a DataTable.
   *
   */
  function initStaffMetricsDataTable() {
    self.$table = $("table#staff-metrics");

    if (self.$table.length) {
      self.$table.DataTable({
        dom:
          "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        responsive: true,
        order: [[6, "desc"]],
        columnDefs: [
          {
            targets: [],
            orderable: false,
          },
          {
            className: "text-center",
            targets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
        ],
        language: {
          sLengthMenu: "Show _MENU_ metrics per page",
          sInfo: "Showing _START_ to _END_ of _TOTAL_ Metrics",
          sInfoEmpty: "",
          sInfoFiltered: " - filtered from _MAX_ total metrics",
          sEmptyTable: "No Metric Data Available",
          search: "_INPUT_",
          searchPlaceholder: "Search Table...",
        },
        sPaginationType: "simple",
        drawCallback: function (settings) {
          PatientPrism.Common.UI.UserInsights.bindPopovers();
        },
        initComplete: function () {
          var footer = $(this).append("<tfoot><tr></tr></tfoot>");

          this.api()
            .columns()
            .every(function () {
              var column = this,
                data = "",
                calcSum = $(column.header()).hasClass("sum-column"),
                calcAvg = $(column.header()).hasClass("average-column"),
                calcSumMoney =
                  $(column.header()).hasClass("money-column") && calcSum;

              if (calcSum || calcAvg) {
                var sum = column
                  .nodes()
                  .reduce(
                    (accumulator, currentNode) =>
                      accumulator +
                      (parseFloat($(currentNode).data("order")) || 0),
                    0
                  );
              }

              if (calcSum) {
                var plural = sum > 1 ? "s" : "";

                if (calcSumMoney) {
                  var total = sum
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  data = `$${total}`;
                } else {
                  data = `${sum} Call${plural}`;
                }
              }

              if (calcAvg) {
                var divisibleColumns = column
                  .nodes()
                  .reduce(function (accumulator, currentNode) {
                    return (
                      accumulator +
                      (parseFloat($(currentNode).data("order")) > 0 ? 1 : 0)
                    );
                  }, 0);

                var average = divisibleColumns > 0 ? sum / divisibleColumns : 0;

                if ($(column.header()).hasClass("percent-as-value")) {
                  data = average.toFixed(2) + "%";
                }
              }

              $("tfoot tr", footer).append(
                '<th class="text-center bg-muted">' + data + "</th>"
              );
            });
        },
      });
    }
  }

  function initCallBookingSummaryChart() {
    $("#call-booking-summary-chart").highcharts({
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "Booking Summary",
      },
      subtitle: {
        text: "An Overview of Staff Booking Performance",
      },
      xAxis: [
        {
          categories: _.map(
            self.report_data.staff_performance,
            function (item) {
              return item.user.name;
            }
          ),
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: "{value}",
          },
          title: {
            text: "Number of Calls",
          },
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "vertical",
        align: "left",
        x: 100,
        verticalAlign: "top",
        y: 30,
        floating: true,
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
          "#FFFFFF",
      },
      series: [
        {
          name: "Booked",
          type: "areaspline",
          data: _.map(self.report_data.staff_performance, function (item) {
            return (
              item.new_opportunity.booked + item.existing_opportunity.booked
            );
          }),
          tooltip: {
            valueSuffix: " Calls",
          },
          shadow: true,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[11]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[11],
                  -0.5
                ),
              ],
            ],
          },
        },
        {
          name: "Not Booked",
          type: "areaspline",
          data: _.map(self.report_data.staff_performance, function (item) {
            return (
              item.new_opportunity.not_booked +
              item.existing_opportunity.not_booked
            );
          }),
          tooltip: {
            valueSuffix: " Calls",
          },
          shadow: true,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[8]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[8],
                  -0.5
                ),
              ],
            ],
          },
        },
      ],
    });
  }

  function initDailyCallSummaryChart() {
    $("#call-booking-summary-chart").highcharts({
      chart: {
        type: "column",
        zoomType: "xy",
      },
      title: {
        text: "Patient Booking Summary",
      },
      subtitle: {
        text: "An Overview of Staff Booking Performance",
      },
      xAxis: {
        categories: _.map(self.report_data.staff_performance, function (item) {
          return item.user.name;
        }),
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Total Call Percentage",
        },
        stackLabels: {
          enabled: true,
          format: "{total} Total Calls",
        },
      },
      legend: {
        floating: false,
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormatter: function () {
          var calls_text = this.y > 1 ? " Calls" : " Call";
          return (
            "<b>" +
            this.y +
            calls_text +
            " <i>(" +
            Math.round((this.y / this.total) * 100) +
            "%)</i></b>: " +
            this.series.name +
            "<br/>"
          );
        },
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "percent",
          dataLabels: {
            enabled: true,
            color:
              (Highcharts.theme && Highcharts.theme.dataLabelsColor) || "white",
            style: {
              textShadow: "0 0 3px black",
            },
            formatter: function () {
              return Math.round((this.y / this.total) * 100) + "%";
              //return this.y + (this.y > 1 ? ' Calls' : ' Call');
            },
          },
        },
      },
      series: [
        {
          name: "New Patients: Booked",
          data: _.map(self.report_data.staff_performance, function (user) {
            return user.new_opportunity.booked > 0
              ? user.new_opportunity.booked
              : null;
          }),
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[1]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[1],
                  -0.5
                ),
              ],
            ],
          },
        },
        {
          name: "New Patients: Not Booked",
          data: _.map(self.report_data.staff_performance, function (user) {
            return user.new_opportunity.not_booked > 0
              ? user.new_opportunity.not_booked
              : null;
          }),
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[6]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[6],
                  -0.5
                ),
              ],
            ],
          },
        },
        {
          name: "Existing Patients: Booked",
          data: _.map(self.report_data.staff_performance, function (user) {
            return user.existing_opportunity.booked > 0
              ? user.existing_opportunity.booked
              : null;
          }),
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[2]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[2],
                  -0.5
                ),
              ],
            ],
          },
        },
        {
          name: "Existing Patients: Not Booked",
          data: _.map(self.report_data.staff_performance, function (user) {
            return user.existing_opportunity.not_booked > 0
              ? user.existing_opportunity.not_booked
              : null;
          }),
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[10]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[10],
                  -0.5
                ),
              ],
            ],
          },
        },
        {
          stack: "totals",
          name: "Total Booked",
          data: _.map(self.report_data.staff_performance, function (user) {
            var total =
              user.existing_opportunity.booked + user.new_opportunity.booked;

            return total > 0 ? total : null;
          }),
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[7]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[7],
                  -0.5
                ),
              ],
            ],
          },
        },
        {
          stack: "totals",
          name: "Total Not Booked",
          data: _.map(self.report_data.staff_performance, function (user) {
            var total =
              user.existing_opportunity.not_booked +
              user.new_opportunity.not_booked;

            return total > 0 ? total : null;
          }),
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[8]],
              [
                1,
                PatientPrism.Utility.Color.shadeColor(
                  Highcharts.getOptions().colors[8],
                  -0.5
                ),
              ],
            ],
          },
        },
      ],
    });
  }

  function bindUIElements() {
    $("table#staff-metrics tbody").on(
      "click",
      ".callviz-performance-trigger",
      function (e) {
        PatientPrism.Common.UI.Loader.show();
        getCallvizPerformance($(this).attr("data-user-id"));
      }
    );
  }

  function getCallvizPerformance(user_id) {
    var callback = function (data, textStatus, jqXHR) {
      if (textStatus === "success") {
        $modal_callviz_performance = $("#modal-callviz-performance");

        $("#success-notes", $modal_callviz_performance).empty();
        $("#danger-notes", $modal_callviz_performance).empty();

        _.each(data.data, function (call_audio_note) {
          if (
            call_audio_note.type != "positive" &&
            call_audio_note.type != "negative"
          )
            return;

          var note_type = "info";

          switch (call_audio_note.type) {
            case "positive":
              note_type = "success";
              break;

            case "negative":
              note_type = "danger";
              break;

            default:
              break;
          }

          var alert_html =
            '<div class="alert alert-' +
            note_type +
            '" role="alert">' +
            call_audio_note.data +
            '<br/><small><a href="/calls/' +
            call_audio_note.call_record_id +
            '">View Call</a></small>' +
            "</div>";

          $("#" + note_type + "-notes", $modal_callviz_performance).append(
            alert_html
          );
        });

        $modal_callviz_performance.modal("show");
      } else if (textStatus === "error") {
        create_notification("error", "An error has occurred");
      }

      PatientPrism.Common.UI.Loader.hide();
    };

    var search_criteria = {
      date_start: PatientPrism.Common.Session.get("filters")["date-start"].date,
      date_end: PatientPrism.Common.Session.get("filters")["date-end"].date,
      location_id: PatientPrism.Common.Session.get("filters")["locations"],
      location_group_id: PatientPrism.Common.Session.get("filters")[
        "location-groups"
      ],
    };

    PatientPrism.API.Users.get_call_audio_notes(
      user_id,
      callback,
      search_criteria
    );
  }

  function initTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
  }
})();
