(function()
{
    namespace("PatientPrism.Modules.Calls.Reviews.Fields.AnsweredBy", init, showFormLinks, getSelectize, reset);

    let self = {
        $el: null,
        $label_block: null,
        $form_block: null,
        $chkbx_unlisted_answered_by: null,
        $selectize: null,
        call_record_id: null,
        unlisted_answered_by_metadata_id: null,
        unlisted_suggested_name_metanote_id: null,
    };

    function init($hidden, call_record_id, unlisted_answered_by_metadata_id, unlisted_suggested_name_metanote_id)
    {
        self.$el = $('#call-analysis-panel-form');
        self.$form_block = $("#answered_by_form_group");
        self.$label_block = $("#answered_by_label");
        self.$selectize = $("#answered-by-id").selectize({
            duplicates: false,
            hideSelected: true,
        });

        self.call_record_id = call_record_id;
        self.unlisted_answered_by_metadata_id = unlisted_answered_by_metadata_id;
        self.unlisted_suggested_name_metanote_id = unlisted_suggested_name_metanote_id;
        
        if (typeof $hidden === 'undefined' || $hidden == false) {
            self.$form_block.show();
        }

        self.$chkbx_unlisted_answered_by = $('#checkbox-unlisted-answered-by');

        if (self.$chkbx_unlisted_answered_by.length)
        {
            self.$chkbx_unlisted_answered_by.click( function()
            {
                handleUnlistedAnsweredByCLick($(this));
            });

            if (self.$chkbx_unlisted_answered_by.attr('data-checked'))
                self.$chkbx_unlisted_answered_by.trigger('click');
        }
    }

    function handleUnlistedAnsweredByCLick(element)
    {
        if (element.is(':checked')) {
            $('#unlisted-answered-by').attr('name', 'metadata[]');
            self.$selectize[0].selectize.clear();
            self.$selectize[0].selectize.disable();
            $('#suggested-staff-name').attr('name', 'metanotes[' + $('#suggested-staff-name').attr('metanote-id') + ']');
            $('#answered-by-id')
                .attr('disabled', 'disabled')
            $('#answered_by_form_group .selectize-control').hide();

            $('#suggested-staff-name').show();
        } else {
            switchToSuggestedStuffList();
        }
    }
    
    function switchToSuggestedStuffList() {
        $('#unlisted-answered-by').attr('name', '');
        $('#answered-by-id').attr('disabled', false);
        self.$selectize[0].selectize.enable();
        $('#suggested-staff-name')
            .attr('name', '')
            .hide();

        $('#answered_by_form_group .selectize-control').show();
    }

    function reset() {
        if (self.$chkbx_unlisted_answered_by.is(':checked')) {
            switchToSuggestedStuffList();
            self.$chkbx_unlisted_answered_by.prop( "checked", false );
        }
    }

    function showFormLinks()
    {
        if (self.$label_block.length) {
            $('<small id="edit-answered-by" class="text-link">&nbsp;Edit</small>')
                .insertAfter(self.$label_block.find('label'))
                .on('click', function () {
                    beginFieldEdition();
                });
        }

        if (self.$form_block.length) {
            self.$form_block.removeClass('col-xs-6')
                .addClass('col-xs-12 col-sm-6 col-md-4');
            $('<small id="submit-answered-by" class="text-link margin-left-xs">&nbsp;Submit</small>')
                .insertBefore(self.$form_block.find('label div'))
                .on('click', function () {
                    submitFieldEdition();
                });
            $('<small id="cancel-answered-by" class="text-link margin-left-xs">&nbsp;Cancel</small>')
                .insertBefore(self.$form_block.find('label div'))
                .on('click', function () {
                    endFieldEdition();
                });

            if (!$('#unlisted-answered-by').length) {
                $('<input type="hidden" id="unlisted-answered-by" value="63" data-should-reset />')
                    .insertBefore(self.$form_block.find('label div'));
            }
        }
    }

    function beginFieldEdition()
    {
        $('small[id^=cancel]:visible').trigger('click');
        self.$label_block.hide();
        self.$form_block.show();
        handleUnlistedAnsweredByCLick(self.$chkbx_unlisted_answered_by);
    }

    function endFieldEdition()
    {
        self.$form_block.hide();
        self.$label_block.show();
        $('#answered_by_loader_overlay').hide();
    }

    function submitFieldEdition()
    {
        $('#answered_by_loader_overlay').show();

        let callback = function (data, textStatus, jqXHR) {
            if (textStatus === 'success') {
                self.$label_block.find('p').text(data.data.call_analysis.answered_by);
            } else if (textStatus === 'error') {
                throw new Error(data.error.message);
            }

            endFieldEdition();

            if ($('#checkbox-unlisted-answered-by').prop('checked') && $('#suggested-staff-name').val() !== '') {
                swal({
                    title: "Request a User",
                    text: "Please make a request at <a href='https://support.patientprism.com/hc/en-us/requests/new' target='_blank'>support.patientprism.com</a> to add team members to Patient Prism",
                    type: "info",
                    html: true,
                });
            }
        };

        let checked_unlisted = $('#checkbox-unlisted-answered-by').prop('checked');
        let unlisted_suggested_name_metanote = checked_unlisted ? ($('#suggested-staff-name:visible').val() ?? null) : undefined;

        let formData = {
            'call_record_id': self.call_record_id,
            'answered_by_id': $('#answered-by-id').val(),
            'metadata': [
                $('#unlisted-answered-by[name^=metadata]').val(),
            ],
            'metanotes': {
                [self.unlisted_suggested_name_metanote_id]: unlisted_suggested_name_metanote
            },
            'metadata': {
                [self.unlisted_answered_by_metadata_id]: checked_unlisted ? 1 : null
            }
        };

        PatientPrism.API.HTTP.Socket.patch('/call-records/' + self.call_record_id + '/call-reviews/call-analysis', callback, formData);
    }

    function getSelectize() {
        return self.$selectize;
    }
})();
