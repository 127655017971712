(function() {
    namespace("PatientPrism.Common.UI.CallTranscript", init);

    function init() {
        $('a.call-timing').on('click', function () {
            let timeStart = parseFloat($(this).data('time'));
            if (window.opener && timeStart) {
                window.opener.PatientPrism.Modules.Calls.CallViz.getPlayer().play($(this).data('time'));
            }
            return false;
        });

        $('.mark').popover({
            placement: 'bottom'
        });
    }
})();
