(function()
{
    namespace("PatientPrism.API.Locations", all, get, setBusinessHours);

    var self = {
        //
    }

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations', callback, body)
    }

    function get(location_id, callback, expand)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/'
            + location_id
            + ((expand instanceof Array ) ? '?expand=' + expand.join(',') : ''),
            callback);
    }
    
    function setBusinessHours(location_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/locations/' + location_id + '/business-hours', callback, body)
    }
})();