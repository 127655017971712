(function()
{
    namespace("PatientPrism.Modules.Calls.Common", initializeFilterDateTimePickers);

    function initializeFilterDateTimePickers()
    {
        var $start_datetime_picker = $('#filter-start-datetime');
        var $end_datetime_picker = $('#filter-end-datetime');

        PatientPrism.Vendor.DateTimePicker.initPicker($start_datetime_picker);
        PatientPrism.Vendor.DateTimePicker.initPicker($end_datetime_picker);

        PatientPrism.Vendor.DateTimePicker.linkPickers($start_datetime_picker, $end_datetime_picker);
    }
})();