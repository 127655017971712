(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.DSODashboard", init);

    var self = {
        report_data: null,
        charts: {
            sellables:       null,
            opportunities:   null,
            bookings:        null,
            call_records:    null,
            location_scores: null,
            leaderboard:     null,
            not_connected:   null,
            calls_hourly:    null,
            calls_daily:     null
        },
        tables: {
            $top_phone_numbers: null,
            $top_keywords:      null
        },
        filter_location_ids: null
    };

    function init()
    {
        self.filter_location_ids = PatientPrism.Common.Session.get('filters').locations;

        initGridstack();
        initSellablesChart();
        initOpportunitiesChart();
        initBookingsChart();
        initCallRecordsChart();
        initLocationScoresChart();
        initLeaderboardChart();
        initNotConnectedChart();
        initTopPhoneNumbersTable();
        initTopKeywordsTable();
        initCallsHourlyChart();
        initCallsDailyChart();
        initFollowUpsTable();
    }

    function initSellablesChart()
    {
        self.charts.sellables = new Highcharts.Chart({
            title: {
                text: 'Won vs. Lost'
            },
            subtitle: {
                text: 'Opportunities Analysis'
            },
            chart: {
                renderTo: 'won-vs-lost-chart',

                type: 'line'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            xAxis: {
                tickmarkPlacement: 'on',
                lineWidth: 0
            },

            yAxis: {
                lineWidth: 0,
                min: 0
            },

            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{series.color}">{series.name}: <b>${point.y:,.0f}</b><br/>'
            },

            series: [{
                name: 'Opp. Won',
                pointPlacement: 'on',
                color: '#26B894'
            }, {
                name: 'Opp. Lost',
                pointPlacement: 'on',
                color: '#E54C3B'
            }]
        });

        self.charts.sellables.showLoading('Analyzing Data')

        var callback = function(data, textStatus, jqXHR)
        {
            var location_names = [];
            var sellables_won = [];
            var sellables_lost = [];

            _.each(data.data.locations, function(location)
            {
                if (!location.all.sellables.won && !location.all.sellables.won)
                    return;

                location_names.push(location.location.name);
                sellables_won.push(location.all.sellables.won);
                sellables_lost.push(location.all.sellables.lost);
            });

            self.charts.sellables.xAxis[0].setCategories(location_names);
            self.charts.sellables.series[0].setData(sellables_won);
            self.charts.sellables.series[1].setData(sellables_lost);

            data.data.locations.length ? self.charts.sellables.hideLoading() : self.charts.sellables.showLoading('No Data');
        }

        PatientPrism.API.MicroReports.sellables(callback, getDefaultBody());
    }

    function initOpportunitiesChart()
    {
        self.charts.opportunities = new Highcharts.Chart({
            title: {
                text: 'Opportunities'
            },
            subtitle: {
                text: 'New Opportunities'
            },
            chart: {
                renderTo: 'opportunities-chart',
                type: 'areaspline'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            xAxis: {
                tickmarkPlacement: 'on',
                lineWidth: 0
            },

            yAxis: {
                lineWidth: 0,
                min: 0
            },

            tooltip: {
                shared: true,
                pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f} Opportunities</b><br/>'
            },
             plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            series: [{
                name: 'New Opportunities',
                pointPlacement: 'on',
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[11]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[11], 0)]
                    ]
                }
            }]
        });

        self.charts.opportunities.showLoading('Analyzing Data')

        var callback = function(data, textStatus, jqXHR)
        {
            var location_names            = [];
            var new_opportunities = [];

            _.each(data.data.locations, function(location)
            {
                location_names.push(location.location.name);
                new_opportunities.push(location.new_opportunity.total ? location.new_opportunity.total : 0);
            });

            self.charts.opportunities.xAxis[0].setCategories(location_names);
            self.charts.opportunities.series[0].setData(new_opportunities);

            data.data.locations.length ? self.charts.opportunities.hideLoading() : self.charts.opportunities.showLoading('No Data');
        }

        PatientPrism.API.MicroReports.opportunities(callback, getDefaultBody());
    }

    function initBookingsChart()
    {
        self.charts.bookings = new Highcharts.Chart({
            chart: {
                type: 'line',
                renderTo: 'bookings-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Bookings'
            },
            subtitle: {
                text: 'Booked vs. Not Booked'
            },
            xAxis: {
                categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Bookings'
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                    }
                },
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
                        style: {
                            textShadow: '0 0 3px black'
                        }
                    }
                }
            },
            tooltip: {
                shared: true,
                formatter: function() {
                      var s = '<b>'+ this.x +'</b>';
                      var total = _.sumBy(this.points, 'y');

                      $.each(this.points, function(i, point) {
                          s += '<br/>'+ point.series.name +': '+ point.y + ' Calls - ' + Math.round((point.y/total) * 100) + '%';
                      });

                      return s;
                  },
            },
            series: [{
                name: 'Booked',
                color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[11]],
                            [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[11], -0.5)]
                        ]
                    }
            }, {
                name: 'Not Booked',
                color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[8]],
                            [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[8], -0.5)]
                        ]
                    }
            }]
        });

        self.charts.bookings.showLoading('Analyzing Data')

        var callback = function(data, textStatus, jqXHR)
        {
            var location_names = [];
            var booked = [];
            var not_booked = [];

            _.each(data.data.locations, function(location)
            {
                location_names.push(location.location.name);
                booked.push(typeof location.all.booked != 'undefined' ? location.all.booked : 0);
                not_booked.push(typeof location.all.not_booked != 'undefined' ? location.all.not_booked : 0);
            });

            self.charts.bookings.xAxis[0].setCategories(location_names);
            self.charts.bookings.series[0].setData(booked);
            self.charts.bookings.series[1].setData(not_booked);

            data.data.locations.length ? self.charts.bookings.hideLoading() : self.charts.bookings.showLoading('No Data');
        }

        PatientPrism.API.MicroReports.bookings(callback, getDefaultBody());
    }

    function initCallRecordsChart()
    {
        self.charts.call_records = new Highcharts.Chart({
            chart: {
                type: 'column',
                renderTo: 'call-records-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Calls'
            },
            subtitle: {
                text: 'Connected vs. Not Connected'
            },
            xAxis: {

            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Calls',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' millions'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            tooltip: {
                shared: true,
            },
            series: [{
                name: 'Connected',
                color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[11]],
                            [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[11], -0.5)]
                        ]
                    }
            }, {
                name: 'Not Connected',
                color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[8]],
                            [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[8], -0.5)]
                        ]
                    }
            }]
        });

        self.charts.call_records.showLoading('Analyzing Data')

        var callback = function(data, textStatus, jqXHR)
        {
            var location_names = [];
            var total          = [];
            var connected      = [];
            var not_connected  = [];

            _.each(data.data.locations, function(location)
            {
                location_names.push(location.location.name);
                total.push(location.all.total ? location.all.total : 0);
                connected.push(location.all.connected ? location.all.connected : 0);
                not_connected.push(location.all.not_connected ? location.all.not_connected : 0);
            });

            self.charts.call_records.xAxis[0].setCategories(location_names);
            self.charts.call_records.series[0].setData(connected);
            self.charts.call_records.series[1].setData(not_connected);

            data.data.locations.length ? self.charts.call_records.hideLoading() : self.charts.call_records.showLoading('No Data');
        }

        PatientPrism.API.MicroReports.call_records(callback, getDefaultBody());
    }

    function initLocationScoresChart()
    {
        self.charts.location_scores = new Highcharts.Chart({
            chart: {
                type: 'column',
                renderTo: 'location-scores-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Scores'
            },
            subtitle: {
                text: 'Location Averages'
            },
            xAxis: {

            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Score',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' millions'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            tooltip: {
                shared: true,
            },
            series: [{
                name: 'Average Score',
            }]
        });

        self.charts.location_scores.showLoading('Analyzing Data')

        var callback = function(data, textStatus, jqXHR)
        {
            var location_names = [];
            var scores         = [];

            _.each(data.data.locations, function(location)
            {
                if (!location.all.average_score)
                    return;

                location_names.push(location.location.name);
                scores.push(parseFloat(location.all.average_score ? location.all.average_score.toFixed(2) : 0.00));
            });

            self.charts.location_scores.xAxis[0].setCategories(location_names);
            self.charts.location_scores.series[0].setData(scores);

            data.data.locations.length ? self.charts.location_scores.hideLoading() : self.charts.location_scores.showLoading('No Data');
        }

        PatientPrism.API.MicroReports.location_scores(callback, getDefaultBody());
    }

    function initLeaderboardChart()
    {
        self.charts.leaderboard = new Highcharts.Chart({
            chart: {
                type: 'column',
                renderTo: 'leaderboard-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Staff Leaderboard'
            },
            subtitle: {
                text: 'Top 25 by Average Score'
            },
            xAxis: {

            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Score',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' millions'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            tooltip: {
                shared: true,
            },
            series: [{
                name: 'Average Score',
            }]
        });

        self.charts.leaderboard.showLoading('Analyzing Data')

        var callback = function(data, textStatus, jqXHR)
        {
            var user_names = [];
            var scores     = [];

            var i = 0;

            _.each(data.data.users, function(user)
            {
                if (!user.all.score_average)
                    return;

                if (i === 25)
                    return false;

                i++;

                user_names.push(user.user.name);
                scores.push(user.all.score_average ? parseFloat(user.all.score_average) : 0.00);
            });

            self.charts.leaderboard.xAxis[0].setCategories(user_names);
            self.charts.leaderboard.series[0].setData(scores);

            data.data.users.length ? self.charts.leaderboard.hideLoading() : self.charts.leaderboard.showLoading('No Data');
        }

        PatientPrism.API.MicroReports.leaderboard(callback, getDefaultBody());
    }

    function initNotConnectedChart()
    {
        self.charts.not_connected = new Highcharts.Chart({
            chart: {
                type: 'column',
                renderTo: 'not-connected-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Voicemails'
            },
            subtitle: {
                text: 'Voicemails Received Business Hours'
            },
            xAxis: {

            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Calls',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' millions'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            tooltip: {
                shared: true,
            },
            series: [{
                name: 'Total Calls',
                color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[8]],
                            [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[8], -0.5)]
                        ]
                    }
            }]
        });

        self.charts.not_connected.showLoading('Analyzing Data')

        var callback = function(data, textStatus, jqXHR)
        {
            var location_names = [];
            var calls      = [];

            _.each(data.data.locations, function(location)
            {
                var voicemail_count = 0;

                _.each(location.all.reason_not_connected, function (item)
                {
                    if (item.value == 'Reached Voicemail')
                        voicemail_count += item.total;
                });

                if (voicemail_count)
                {
                    location_names.push(location.location.name);
                    calls.push(voicemail_count);
                }
            });

            self.charts.not_connected.xAxis[0].setCategories(location_names);
            self.charts.not_connected.series[0].setData(calls);

            self.charts.not_connected.series[0].data.length ? self.charts.not_connected.hideLoading() : self.charts.not_connected.showLoading('No Data');
        }

        PatientPrism.API.MicroReports.not_connected(callback, getDefaultBody());
    }

    function initTopPhoneNumbersTable()
    {
        self.tables.$top_phone_numbers = $('table#top-phone-numbers-table');

        if (self.tables.$top_phone_numbers.length)
        {
            self.tables.$top_phone_numbers =
            self.tables.$top_phone_numbers.DataTable({
                'order': [
                    [2, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ numbers per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Numbers",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total numbers",
                    "sEmptyTable": "No Data Available"
                },
                "sPaginationType": "simple",
                "bLengthChange":   false,
                "bFilter":         false,
                "pageLength": 5
            });
        }

        var callback = function(data, textStatus, jqXHR)
        {
            _.each(data.data.phone_numbers, function(metric)
            {
                self.tables.$top_phone_numbers.row.add([
                    metric.parent_phone_number.name,
                    metric.parent_phone_number.location.name,
                    metric.total
                ]).draw(false);
            });
        }

        PatientPrism.API.MicroReports.top_phone_numbers(callback, getDefaultBody());
    }

    function initTopKeywordsTable()
    {
        self.tables.$top_keywords = $('table#top-keywords-table');

        if (self.tables.$top_keywords.length)
        {
            self.tables.$top_keywords =
            self.tables.$top_keywords.DataTable({
                'order': [
                    [1, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ keywords per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Keywords",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total keywords",
                    "sEmptyTable": "No Data Available"
                },
                "sPaginationType": "simple",
                "bLengthChange":   false,
                "bFilter":         false,
                "pageLength": 5
            });
        }

        var callback = function(data, textStatus, jqXHR)
        {
            _.each(data.data.keywords, function(metric)
            {
                self.tables.$top_keywords.row.add([
                    metric.keyword,
                    metric.total
                ]).draw(false);
            });
        }

        PatientPrism.API.MicroReports.top_keywords(callback, getDefaultBody());
    }
    
    function initFollowUpsTable()
    {
        let followUpsTable = $('#follow-ups-table');

        if (!followUpsTable.length) {
        	return false;
        }
        
        followUpsTable = followUpsTable.DataTable({
        	  	"columns": [
        	  		null,
        	  		{ "width": "100px" },
        	  		{ "width": "100px" },
        	  		{ "width": "100px" },
        	  		{ "width": "100px" }
        		],
                'order': [
                    [1, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ follow ups per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Follow ups",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total Follow ups",
                    "sEmptyTable": "No Data Available"
                },
                "sPaginationType": "simple",
                "bLengthChange":   false,
                "bFilter":         false,
                "pageLength": 5
        });

        var callback = function(data, textStatus, jqXHR)
        {
            _.each(data.data.locations, function(metric)
            {
            	followUpsTable.row.add([
                    metric.location.name,
                    metric.recommendations,
                    metric.followUps,
                    metric.converted,
                    metric.opportunities
                ]).draw(false);
            });
        }

        PatientPrism.API.MicroReports.follow_ups(callback, getDefaultBody());
    }
    
    function getDefaultBody()
    {
    	return {
            date_start: PatientPrism.Common.Session.get('filters')['date-start'].date,
            date_end: PatientPrism.Common.Session.get('filters')['date-end'].date,
            location_id: PatientPrism.Common.Session.get('filters')['locations'],
            location_group_id: PatientPrism.Common.Session.get('filters')['location-groups'],
            user_type: PatientPrism.Common.Session.get('filters')['user-type']
        };
    }
    
    let calls_hourly_chart_calls = $("select#calls-hourly-chart-calls");
    let hourly_performance_report_data = [];
    let calls_daily_chart_calls = $("select#calls-daily-chart-calls");
    let daily_performance_report_data = [];

    function initCallsHourlyChart()
    {
        self.charts.calls_hourly = new Highcharts.Chart({
            chart: {
                type: 'heatmap',
                renderTo: 'calls-hourly-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Calls Hourly'
            },
            subtitle: {
                text: 'Calls Grouped By Hour Received'
            },
            xAxis: {
                categories: [
                    '12 AM', '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM', '09 AM', '10 AM', '11 AM',
                    '12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Locations',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            colorAxis: {
                min: 0,
                minColor: 'rgba(255, 255, 255, 0.05)',
                maxColor: 'rgba(255, 255, 255, 1)',
                shadow: false,
                gridLineColor: '#25323f',
                labels: {
                    style: {
                        color: '#FFF'
                    }
                },
                marker: {
                    color: '#FFF'
                }
            },
            legend: {

            },
            tooltip: {
                formatter: function () {
                    let calls_type = calls_hourly_chart_calls.find(":selected").text() || 'Total';
                    if ('Total' === calls_type) {
                        calls_type = '';
                    }
                    return '<b>' + this.series.yAxis.categories[this.point.y] + '</b> received <br><b>' +
                        this.point.value + '</b> ' + calls_type + ' calls during the <br><b>' + this.series.xAxis.categories[this.point.x] + '</b> hour';
                }
            },
            series: [{
                name: 'Calls By Hour',
                borderWidth: 1,
                borderColor: '#25323f',
                shadow: false,
            }]
        });

        self.charts.calls_hourly.showLoading('Analyzing Data');

        var callback = function(data, textStatus, jqXHR)
        {
            hourly_performance_report_data = JSON.parse(JSON.stringify(data.data));
            drawHourlyPerformanceReport(hourly_performance_report_data);
        };

        PatientPrism.API.MicroReports.calls_hourly(callback, getDefaultBody());
    }

    calls_hourly_chart_calls.change(function (e) {
        drawHourlyPerformanceReport(hourly_performance_report_data);
    });

    function drawHourlyPerformanceReport(data)
    {
        let location_names = [];
        let calls          = [];
        let key = calls_hourly_chart_calls.val() || 'total';

        _.each(data.locations, function(location, index)
        {
            location_names.push(location.location.name);

            _.each(location.hourly_performance, function (item)
            {
                calls.push([parseInt(item['24_hour']), index, item[key]]);
            });
        });

        self.charts.calls_hourly.yAxis[0].setCategories(location_names);
        self.charts.calls_hourly.series[0].setData(calls);

        self.charts.calls_hourly.series[0].data.length ? self.charts.calls_hourly.hideLoading() : self.charts.calls_hourly.showLoading('No Data');
    }

    function initCallsDailyChart()
    {
        self.charts.calls_daily = new Highcharts.Chart({
            chart: {
                type: 'heatmap',
                renderTo: 'calls-daily-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Calls Daily'
            },
            subtitle: {
                text: 'Calls Grouped By Day Received'
            },
            xAxis: {
                categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Locations',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            colorAxis: {
                min: 0,
                minColor: 'rgba(255, 255, 255, 0.05)',
                maxColor: 'rgba(255, 255, 255, 1)',
                shadow: false,
                gridLineColor: '#25323f',
                labels: {
                    style: {
                        color: '#FFF'
                    }
                },
                marker: {
                    color: '#FFF'
                }
            },
            legend: {

            },
            tooltip: {
                formatter: function () {
                    let calls_type = calls_daily_chart_calls.find(":selected").text() || 'Total';
                    if ('Total' === calls_type) {
                        calls_type = '';
                    }
                    return '<b>' + this.series.yAxis.categories[this.point.y] + '</b> received <br><b>' +
                        this.point.value + '</b> ' + calls_type + ' calls on <br><b>' + this.series.xAxis.categories[this.point.x] + '</b>';
                }
            },
            series: [{
                name: 'Calls By Day',
                borderWidth: 1,
                borderColor: '#25323f',
                shadow: false,
            }]
        });

        self.charts.calls_daily.showLoading('Analyzing Data');

        let callback = function(data, textStatus, jqXHR)
        {
            daily_performance_report_data = JSON.parse(JSON.stringify(data.data));
            drawDailyPerformanceReport(daily_performance_report_data);
        };

        PatientPrism.API.MicroReports.calls_daily(callback, getDefaultBody());
    }

    function drawDailyPerformanceReport(data)
    {
        let location_names = [];
        let calls          = [];
        let key = calls_daily_chart_calls.val() || 'total';

        _.each(data.locations, function(location, index)
        {
            location_names.push(location.location.name);

            _.each(location.day_of_week_performance, function (item)
            {
                calls.push([parseInt(item['day'])-1, index, item[key]]);
            });
        });

        self.charts.calls_daily.yAxis[0].setCategories(location_names);
        self.charts.calls_daily.series[0].setData(calls);

        self.charts.calls_daily.series[0].data.length ? self.charts.calls_daily.hideLoading() : self.charts.calls_daily.showLoading('No Data');
    }

    calls_daily_chart_calls.change(function (e) {
        drawDailyPerformanceReport(daily_performance_report_data);
    });

    function initGridstack()
    {
        var options = {
            alwaysShowResizeHandle: true,
            animate: true,

        };

        $('.grid-stack').gridstack(options)
            .on('resize', function(e,i)
            {
                window.dispatchEvent(new Event('resize'));
            })
            .on('change', function(e,i)
            {
                window.dispatchEvent(new Event('resize'));
            })
            .on('resizestop', function(e,i)
            {
                window.dispatchEvent(new Event('resize'));
            })
            .on('dragstop', function(e,i)
            {
                window.dispatchEvent(new Event('resize'));
            });
    }
})();