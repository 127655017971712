(function() {

	namespace("PatientPrism.Common.UI.Filters.BuilderGlobal", init);

	function init(callback) {
		var getMetadata = new Promise(function(resolve, reject) {
			PatientPrism.API.Metadata.all(resolve);
		});

		var getPhoneNumbers = new Promise(
				function(resolve, reject) {
					var session = PatientPrism.Common.Session.get('current'),
						company_id = session.company && session.company.id;

					if (company_id) {
                        var body = {
                            parent : true,
                            company_id : PatientPrism.Common.Session.get('current').company.id,
                            expand : [ 'location' ]
                        };

                        PatientPrism.API.PhoneNumbers.all(resolve, body);
					} else {
                        resolve();
					}
				});

		var getKeywords = new Promise(function(resolve, reject) {
			var body = {};

			PatientPrism.API.CallKeywords.all(resolve, body);
		});

		var getCallBasics = new Promise(function(resolve, reject) {
            var body = {};

            PatientPrism.API.CallBasics.all(resolve, body);
        });

		Promise.all([ getMetadata, getPhoneNumbers, getKeywords, getCallBasics ]).then(
				function(results) {
					var data = {
						metadata : _.groupBy(results[0].data, 'key'),
						phone_numbers : results[1] && results[1].data,
						keywords : results[2].data,
						call_basics : results[3].data,
					};
					initBuilder(data, callback);
				});
	}

	function initBuilder(data, callback) {
		var filters = [
				{
					id : 'booking_status',
					label : 'Booking Status',
					type : 'integer',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.booking_status,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'benefits_coverage',
					label : 'Insurance Coverage',
					type : 'integer',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.benefits_coverage,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'classification',
					label : 'Classification',
					type : 'integer',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.classification,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'connection_status',
					label : 'Connection Status',
					type : 'integer',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.connection_status,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'reason_not_booked',
					label : 'Reason Not Booked',
					type : 'integer',
					test : 'metadata',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.reason_not_booked,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'reason_not_connected',
					label : 'Reason Not Connected',
					type : 'integer',
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.reason_not_connected,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'call_referer',
					label : 'Referring Source',
					type : 'integer',
					operators : [ 'equal', 'not_equal', "disabled" ],
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.call_referer,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'gender',
					label : 'Gender',
					type : 'integer',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.metadata.gender,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'metadata'
					}
				},
				{
					id : 'phone_number',
					label : 'Phone Number',
					type : 'integer',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.phone_numbers,
						optgroups : _.map(data.phone_numbers, function(item) {
							return {
								value : item.location.id,
								label : item.location.name
							};
						}),
						optgroupField : 'location_id',
						valueField : 'id',
						labelField : 'name',
						searchField : [ 'name', 'phone_number', 'friendly_name' ],
						maxItems : 1,
						render : {
							option : function(item, escape) {
								return '<div>' + '<strong>' + escape(item.name)
										+ '</strong>'
										+ '<span class="caption">'
										+ escape(item.friendly_name)
										+ '</span>' + '</div>';
							}
						}
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'parent_phone_number'
					}
				},
				{
					id : 'keyword',
					label : 'Keyword',
					type : 'integer',
					operators : [ 'equal', 'not_equal' ],
					plugin : 'selectize',
					plugin_config : {
						options : data.keywords,
						valueField : 'id',
						labelField : 'keyword',
						searchField : [ 'keyword' ],
						sortField : [ {
							field : 'keyword',
							direction : 'asc'
						} ],
						placeholder : 'Search Keywords',
						maxItems : 1,
						render : {
							option : function(item, escape) {
								return '<div>' + '<strong>'
										+ escape(item.keyword) + '</strong>'
										+ '</div>';
							}
						}
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container input')[0].selectize
								.setValue(value);
					},
					data : {
						type : 'keyword'
					}
				},
				{
					id : 'weight',
					label : 'Weighted',
					type : 'integer',
					input : 'radio',
					values : {
						0 : 'Yes',
						1 : 'No'
					},
					operators : [ 'equal' ],
					data : {
						type : 'binary_metadata',
						key : 'unweighted'
					}
				}, {
					id : 'relo_notification',
					label : 'RELO',
					type : 'integer',
					input : 'radio',
					values : {
						1 : 'Yes',
						0 : 'No'
					},
					operators : [ 'equal' ],
					data : {
						type : 'binary_metadata',
						key : 'relo_notification'
					}
				}, {
					id : 'target_call',
					label : 'Target Call',
					type : 'integer',
					input : 'radio',
					values : {
						1 : 'Yes',
						0 : 'No'
					},
					operators : [ 'equal' ],
					data : {
						type : 'binary_metadata',
						key : 'target_call'
					}
				}, {
					id : 'follow_up_recommended',
					label : 'Follow Up Recommendation',
					type : 'integer',
					input : 'radio',
					values : {
						1 : 'Yes',
						0 : 'No'
					},
					operators : [ 'equal' ],
					data : {
						type : 'binary_metadata',
						key : 'follow_up_recommended'
					}
				},{
					id : 'opportunities_won',
					label : 'Opportunities Won',
					type : 'integer',
					operators : [ 'less_or_equal', 'greater_or_equal' ],
					data : {
						type : 'opportunities_won'
					}
				},{
					id : 'opportunities_lost',
					label : 'Opportunities Lost',
					type : 'integer',
					operators : [ 'less_or_equal', 'greater_or_equal' ],
					data : {
						type : 'opportunities_lost'
					}
				},{
					id : 'language',
					label : 'Language',
					plugin : 'selectize',
                    input: 'select',
					plugin_config : {
						options : [{
							id : 'en',
							value: 'English'
						},
						{
							id : 'es',
							value: 'Spanish'
						}],
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						maxItems : 1
					},
					operators : [ 'equal' ],
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container select')[0].selectize
							.setValue(value);
					},
					data : {
						type : 'language'
					}
				},
				{
					id : 'call_basic',
					label : 'Call Basics',
					type : 'integer',
					operators : [ 'in', 'not_in' ],
					plugin : 'selectize',
                    input: 'select',
					multiple: true,
					plugin_config : {
						options : data.call_basics,
						valueField : 'id',
						labelField : 'value',
						searchField : 'value',
						sortField : [ {
							field : 'value',
							direction : 'asc'
						} ],
						placeholder : 'Search Call Basics',
						maxItems : data.call_basics.length
					},
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container select')[0].selectize
							.setValue(value);
					},
					data : {
						type : 'call_basic'
					}
				},
				{
					id : 'answered_by',
					label : 'Answered By',
					type : 'boolean',
					operators : [ 'disabled'],
					data : {
						type : 'answered_by'
					}
				},
				{
					id : 'apt_time',
					label : 'Appointment Date/Time',
					type : 'boolean',
					operators : [ 'disabled'],
					data : {
						type : 'apt_time'
					}
				},
				{
					id : 'caller_name',
					label : 'Caller Name',
					type : 'boolean',
					operators : [ 'disabled'],
					data : {
						type : 'caller_name'
					}
				},
				{
					id : 'opportunity_panel',
					label : 'Opportunities',
					type : 'boolean',
					operators : [ 'disabled']
				},
				{
					id : 'period',
					label : 'Period',
					plugin : 'selectize',
					input: 'select',
					plugin_config : {
						options : [{
							id : 'standard',
							value: 'Standard'
						},
							{
								id : 'after-hours',
								value: 'After Hours'
							}],
						valueField : 'id',
						labelField : 'value',
						searchField : 'id',
						maxItems : 1
					},
					operators : [ 'equal', 'not_equal' ],
					valueSetter : function(rule, value) {
						rule.$el.find('.rule-value-container select')[0].selectize
							.setValue(value);
					},
					data : {
						type : 'period'
					}
				},
				{
					id : 'btn_submit_review_with_relo',
					label : 'Submit With RELO',
					type : 'boolean',
					operators : [ 'disabled'],
				}, {
					id : 'answered_by_unlisted',
					label : 'Answered by Unlisted User',
					type : 'integer',
					input : 'radio',
					operators : [ 'equal'],
					values : {
						1 : 'Yes',
						0 : 'No'
					},
					data : {
						type : 'answered_by_unlisted'
					}
				}
		];

		callback(_.orderBy(filters, 'label'));
	}
})();