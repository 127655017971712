(function()
{
    namespace("PatientPrism.Common.InactivityTimer", init);

    var self = {}

    function init()
    {
        $(document).idle(
        {
            idle: 600000,
            events: 'mousemove keydown mousedown touchstart scroll wheel',
            onIdle: function()
            {
                window.location.href = '/logout';
            }
        });
    }

})();