(function()
{
    namespace("PatientPrism.Common.UI.Browser.Alerts", init);
    
    var self = {
    	unsupported: ["isIE"]
    };

    function init()
    {
    	// Opera 8.0+
    	let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    	// Firefox 1.0+
    	let isFirefox = typeof InstallTrigger !== 'undefined';

    	// Safari 3.0+ "[object HTMLElementConstructor]" 
    	let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    	// Internet Explorer 6-11
    	let isIE = /*@cc_on!@*/false || !!document.documentMode;

    	// Edge 20+
    	let isEdge = !isIE && !!window.StyleMedia;

    	// Chrome 1 - 71
    	let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    	// Blink engine detection
    	let isBlink = (isChrome || isOpera) && !!window.CSS;
    	for (let i in self.unsupported) {
    		let browser = self.unsupported[i];
    		if (eval(browser)) {
    			$('body').prepend('<div class="alert alert-warning text-center">You are using an outdated web browser that is not supported by Patient Prism</div>');
    			return;
    		}
    	}
    }
})();