(function()
{
    namespace("PatientPrism.Common.UI.Filters.ChartUser", init);

    function init()
    {
        let chart_user_container = $("div#action-chart-user-container");
        if (!chart_user_container.length) {
            throw "Can't find container for chart users. The container has to be a \"div\" element with the id=\"action-chart-user-container\"";
        }

        let chart_user_filter_selected_text = chart_user_container.find("span.action-text");
        if (!chart_user_filter_selected_text.length) {
            throw "Can't find container for the selected user text. The container has to be a \"span\" element and have a class \"action-text\"";
        }

        let chart_user_filter_dropdown = chart_user_container.find("ul.dropdown-menu");

        chart_user_filter_dropdown.on('click', 'a.chart-user-selection-link', function(e) {
            e.preventDefault();
            $(document).trigger('filter.ChartUser.change', {
                key: $(e.target).data('chart-user-id'),
                value: $(e.target).data('chart-user-name')
            });
        });

        $(document).on('filter.ChartUser.change', function (e, data) {
            chart_user_filter_selected_text.text(data.value)
                .data('chart-user-id', data.id)
                .data('chart-user-name', data.value);
        });


        let event_data = {
            key: chart_user_filter_selected_text.data('chart-user-id'),
            value: chart_user_filter_selected_text.data('chart-user-name')
        };

        $(document).trigger('filter.ChartUser.init-complete', event_data);
    }
})();
