(function()
{
    namespace("PatientPrism.Admin.CallKeywords.Index", init);

    let self = {
        call_keywords: null,
        btn_add_keyword: null,
        tables: {
            $call_keywords: null
        },
    };

    function init(companies)
    {
        bindUIElements();
        initCallKeywordsTable();
    }

    function bindUIElements()
    {
        self.tables.$call_keywords = $('table#call-keywords');
        self.btn_add_keyword = $('#btn-add-keyword');

        self.btn_add_keyword.click(function(e)
        {
            addKeyword();
        });
    }

    function addKeyword()
    {
        swal({
            title: "Enter a Keyword",
            type: "input",
            showCancelButton: true,
            cancelOnConfirm: false,
            showLoaderOnConfirm: true
            }, function (inputValue) {
                if (inputValue === false || inputValue === "") return false;

                let callback = function(data, textStatus, jqXHR)
                {
                    if (textStatus === 'success')
                    {
                        swal("Keyword Added", null, 'success');
                    }
                };

                PatientPrism.API.CallKeywords.create(callback, {
                    keyword: inputValue
                });
            });
    }

    function initCallKeywordsTable()
    {
        let formatters =
        {
            call_keyword: function (data, type, row)
            {
                return '<span class="call-keyword text-link">' + data + '</span>';
            }
        };

        self.tables.$call_keywords.DataTable({
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            deferRender: true,
            order: [
                [0, 'asc']
            ],
            columnDefs: [
                {
                    "render": formatters.call_keyword,
                    "targets": 0
                },
                {
                    targets: [2],
                    width: '200px',
                    className: "text-center",
                    render: function (data, type, row) {
                        return `<input type="checkbox" class="btn btn-primary" data-toggle="toggle" 
                            data-on="Yes" data-onstyle="success" data-off="No" ${data === 1 ? 'checked': ''}>`;
                    }
                },
                {
                    "targets": 1,
                    "className": 'text-center',
                },
                {
                    "targets": 3,
                    "orderable": false,
                    "className": 'text-center text-red'
                },
            ],
            columns: [
                {data: 'keyword.keyword'},
                {data: 'total'},
                {data: 'precoaching_allowed'},
                {data: 'delete_link'},
            ],
            oLanguage: {
                "sLengthMenu": "Show _MENU_ keywords per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Keywords",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ Total Keywords",
                "sEmptyTable": "Loading Keywords"
            },
            sPaginationType: "simple",
            createdRow: function ( row_node, data, index )
            {
                $('.call-keyword', row_node).editable({
                    type: 'text',
                    title: 'Edit Keyword',
                    placement: 'right',
                    success: function(response, new_value)
                    {
                        let callback = function(data, textStatus, jqXHR)
                        {
                            if (jqXHR.status === 200)
                            {
                                $('.call-keyword', row_node).removeClass('editable-unsaved');
                            }

                            // Keyword was merged
                            if (jqXHR.status === 204)
                            {
                                create_notification('success', 'Keywords Merged');
                                row_node.remove();
                            }
                        };

                        let body = {
                            keyword: new_value
                        };

                        PatientPrism.API.CallKeywords.update(data.keyword.id, callback, body);
                    }
                });

                $('.btn-delete-call-keyword', row_node).click(function(e){
                    let callback = function(data, textStatus, jqXHR)
                        {
                            if (jqXHR.status === 200)
                            {
                                create_notification('success', 'Keywords Deleted');
                                row_node.remove();
                            }
                        };

                        PatientPrism.API.CallKeywords.destroy(data.keyword.id, callback);
                });
            },
            drawCallback: function (settings) {
                let toggleButtons = $('input[data-toggle=toggle]');
                initToggleButton(toggleButtons);
            }
        });

        let callback = function(data, textStatus, jqXHR)
        {
            _.each(self.call_keywords, function(call_keyword)
            {
                addCallKeywordToTable(call_keyword);
            });

            self.tables.$call_keywords.DataTable().draw();
        };

        getCallKeywords(callback);
    }

    function initToggleButton(toggleButtons)
    {
        toggleButtons.unbind('change');
        toggleButtons.bootstrapToggle('destroy');

        toggleButtons.bootstrapToggle();
        toggleButtons.change(function () {
            toggle_button_change($(this));
        });
    }

    function toggle_button_change(toggleButton)
    {
        let tr = toggleButton.closest('tr');
        let row = self.tables.$call_keywords.DataTable().row(tr);

        let precoaching_allowed_value = (toggleButton.prop('checked') ? 1 : 0);
        let body = {
            properties: {
                precoaching_allowed: precoaching_allowed_value,
            }
        };

        let callback = function (data, textStatus, jqXHR) {
            if (data.status !== "success") {
                swal("An error occurred", "Couldn't change the field's value", "error");
                // if an error occurred we should return the table to initial view
                self.tables.$call_keywords.DataTable().clear();
                _.each(self.call_keywords, function(call_keyword)
                {
                    addCallKeywordToTable(call_keyword);
                });
                self.tables.$call_keywords.DataTable().draw();
            } else {
                let index = _.findIndex(self.call_keywords, function (item) {
                    return item.id === parseInt(row.id());
                });
                if (index > -1) {
                    self.call_keywords[index].precoaching_allowed = precoaching_allowed_value;
                }
            }
        };

        PatientPrism.API.CallKeywords.update(row.id(), callback, body);
    }

    function addCallKeywordToTable(call_keyword)
    {
        self.tables.$call_keywords.DataTable().row.add({
            DT_RowId: call_keyword.id,
            keyword:     call_keyword,
            total: call_keyword.total,
            precoaching_allowed: call_keyword.precoaching_allowed,
            delete_link: '<di class="btn btn-danger btn-sm btn-delete-call-keyword">Delete</div>',
        });
    }

    function getCallKeywords(optional_callback)
    {
        let callback = function (data, textStatus, jqXHR)
        {
            self.call_keywords = data.data;

            if(typeof optional_callback != 'undefined')
                optional_callback(data, textStatus, jqXHR);
        };

        let body = {};

        PatientPrism.API.CallKeywords.summary(callback);
    }

    function deleteCallKeywordFromTable(call_keyword, row_node)
    {
        swal({
            title: "Are you sure?",
            text: "This will remove all associations to call records for this keyword.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, I'm Sure",
            cancelButtonText: "No, Cancel",
            showLoaderOnConfirm: true,
            closeOnConfirm: false,
            closeOnCancel: false
        },
        function(isConfirm)
        {
            if (isConfirm)
            {
                let callback = function(data, textStatus, jqXHR)
                {
                    getCategories();

                    $(row_node).fadeOut(function()
                    {
                        self.tables.$categories.DataTable().row(row_node).remove().draw(false);
                    });

                    initCallBasicsTable();

                    swal("Success", category.name + ' has been deleted.', "success");
                };

                PatientPrism.API.CallBasicCategories.destroy(category.id, callback);
            }
        });
    }
})();
