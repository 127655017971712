(function()
{
    namespace("PatientPrism.Modules.Manage.Users.Companies", init);

    let self = {
        user_id: null,
        whitelist_table: null,
        blacklist_table: null
    };

    function init(user_id)
    {
        self.user_id = user_id;

        init_table('whitelist');
        init_table('blacklist');

        PatientPrism.API.Users.assigned_companies(self.user_id, update_tables, {});

        $(document).on('click', 'a#all_to_blacklist', function (e) {
            e.preventDefault();
            if (!self.whitelist_table.length) {
                return;
            }
            self.whitelist_table.DataTable().rows().select();
            $('a#selected_to_blacklist').trigger('click');
        });

        $(document).on('click', 'a#selected_to_blacklist', function (e) {
            e.preventDefault();
            if (!self.whitelist_table.length) {
                return;
            }
            let datatable = self.whitelist_table.dataTable();
            if (!datatable.$('tr.selected').length) {
                return;
            }

            self.whitelist_table.DataTable().processing(true);
            self.blacklist_table.DataTable().processing(true);

            let body = {
                companies: _.map(datatable.$('tr.selected'), 'id'),
            };

            PatientPrism.API.Users.forbid_companies(self.user_id, update_tables, body);
        });

        $(document).on('click', 'a#all_to_whitelist', function (e) {
            e.preventDefault();
            if (!self.blacklist_table.length) {
                return;
            }
            self.blacklist_table.DataTable().rows().select();
            $('a#selected_to_whitelist').trigger('click');
        });

        $(document).on('click', 'a#selected_to_whitelist', function (e) {
            e.preventDefault();
            if (!self.blacklist_table.length) {
                return;
            }
            let datatable = self.blacklist_table.dataTable();
            if (!datatable.$('tr.selected').length) {
                return;
            }

            self.whitelist_table.DataTable().processing(true);
            self.blacklist_table.DataTable().processing(true);

            let body = {
                companies: _.map(datatable.$('tr.selected'), 'id'),
            };

            PatientPrism.API.Users.permit_companies(self.user_id, update_tables, body);
        });
    }

    function init_table(selector)
    {
        let table = $("table#" + selector);

        if (table.length)
        {
            let datatable = table.DataTable({
                'order': [
                    [0, 'asc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ companies per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Companies",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total companies",
                    "sEmptyTable": "Loading data...",
                },
                "sPaginationType": "simple",
                "processing": true,
            });

            datatable.on('click', 'tr', function () {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected');
                }
                else {
                    $(this).addClass('selected');
                }
            })
        }

        self[selector + "_table"] = table;
    }

    function update_tables(data)
    {
        if (!data || data.data.length === 0) {
            return;
        }
        data = data.data;

        let datatable;
        if (self.whitelist_table.length) {
            datatable = self.whitelist_table.DataTable();
            datatable.clear();
            fill_table(datatable, data.permitted);
        }

        if (self.blacklist_table.length) {
            datatable = self.blacklist_table.DataTable();
            datatable.clear();
            fill_table(datatable, data.forbidden);
        }
    }

    function fill_table(datatable, data)
    {
        datatable.processing(false);
        if (data.length === 0) {
            datatable.settings()[0].oLanguage.sEmptyTable = 'No Companies Available';
        }

        _.each(data, function (item) {
            let row = datatable.row.add([
                item.name,
            ]).nodes()
                .to$();

            row.attr('id', item.id)
                .attr('data-id', item.id);
        });
        datatable.draw();
    }
})();
