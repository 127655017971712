(function()
{
    namespace("PatientPrism.Modules.Manage.Locations.Sellables", init);

    var self = {
        $table: null
    };

    function init()
    {
        bindEditSellableModal();
        initProceduresDataTables();
        PatientPrism.Common.Vendor.DataTable.ActionColumn.Sellables.init(self.$table);
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initProceduresDataTables()
    {
        var oLanguage = {
            "sLengthMenu": "Show _MENU_ procedures per page",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Procedures",
            "sInfoEmpty": "",
            "sInfoFiltered": " - filtered from _MAX_ total procedures",
            "sEmptyTable": "No Procedure Data Available"
        };

        self.$table = $('table#procedures');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [1, 'asc']
                ],
                "columnDefs": [
                {
                    "targets": [0],
                    "orderable": false
                }],
                "oLanguage": oLanguage,
                "sPaginationType": "simple"
            });
        }
    }

    function bindEditSellableModal()
    {
        $('#modal-manage-edit-sellable').on('show.bs.modal', function (event) {
            var $data_row = $(event.relatedTarget).closest('.sellable-row');
            var $modal = $(this)

            $('#id', $modal).attr('value', $data_row.data('id'));
            $('#name', $modal).val($data_row.data('name'));
            $('#cost', $modal).val($data_row.data('cost'));
        })
    }
})();