(function()
{
    namespace("PatientPrism.Admin.Entities.Users", init);

    function init()
    {
        let $users_table = $('table#datatable-all-users').DataTable({
            'order': [
                [0, 'asc']
            ],
            "columnDefs": [
            {
                "targets": 0,
                "orderable": false
            }],
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            buttons: [
                {
                  extend: "csv",
                  text: '<i class="fa fa-file-text-o"></i> Export CSV',
                  filename: "search-results",
                  className: "btn-sm action-button dt-button",
                  exportOptions: {
                    orthogonal: "export",
                    columns: [":visible :not(.no-export)"],
                  },
                }],
            "sPaginationType": "simple"
        });

        var body = {
            expand: ['locations'],
            withTrashed: 1
        };

        PatientPrism.Modules.Manage.Users.Dashboard.initEnablingUser($users_table);

        var callback = function(data)
        {
            let current_user_tz = PatientPrism.Common.Session.get('current').user.timezone,
                date_format = 'MM/DD/YYYY hh:mm A zz';

            if (!data.data || data.data.length == 0) {
                $users_table.settings()[0].oLanguage.sEmptyTable = 'No Users Available';
                $users_table.draw();

                return;
            }

            _.each(data.data, function(user)
            {
                let user_name_link = _router.link_to_route('manage.users.summary', user.name, {
                        'user_id': user.id
                    }),
                    locations_link = _router.link_to_route('manage.users.locations', user.locations.length || '0', {
                        'user_id': user.id
                    });

                $users_table.row.add([
                    user_name_link,
                    user.email,
                    user.verified ? 'Yes' : 'No',
                    locations_link,
                    moment(user.created_at + 'Z').utc().tz(current_user_tz).format(date_format),
                    user.logged_in_at && moment(user.logged_in_at + 'Z').utc().tz(current_user_tz).format(date_format),
                    '<a href="#" class="delete-link" data-id="' + user.id + '">' + (user.deleted_at ? 'Enable' : 'Disable') + '</a>'
                ]);
            });
            $users_table.draw();
        };

        PatientPrism.API.Users.all(callback, body);
    }
})();