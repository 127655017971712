(function()
{
    namespace("PatientPrism.Common.UI.Filters.ChartDay", init);

    function init()
    {
        let chart_day_container = $("div#action-chart-day-container");
        if (!chart_day_container.length) {
            throw "Can't find container for chart days. The container has to be a \"div\" element with the id=\"action-chart-day-container\"";
        }

        let chart_day_filter_selected_text = chart_day_container.find("span.action-text");
        if (!chart_day_filter_selected_text.length) {
            throw "Can't find container for the selected day text. The container has to be a \"span\" element and have a class \"action-text\"";
        }

        let chart_day_filter_dropdown = chart_day_container.find("ul.dropdown-menu");

        chart_day_filter_dropdown.on('click', 'a.chart-day-selection-link', function(e) {
            e.preventDefault();
            $(document).trigger('filter.ChartDay.change', {
                key: $(e.target).data('chart-day-id'),
                value: $(e.target).data('chart-day-name')
            });
        });

        $(document).on('filter.ChartDay.change', function (e, data) {
            chart_day_filter_selected_text.text(data.value)
                .data('chart-day-id', data.id)
                .data('chart-day-name', data.value);
        });


        let event_data = {
            key: chart_day_filter_selected_text.data('chart-day-id'),
            value: chart_day_filter_selected_text.data('chart-day-name')
        };

        $(document).trigger('filter.ChartDay.init-complete', event_data);
    }
})();
