(function() {
    namespace("PatientPrism.Modules.Analize.Helpers.DataConverter", init, get_data, get_first_index, get_first_index_key);

    let reports_data = [];
    let keys = [
        'location_id',
        'location_group_id',
    ];

    function init(
        report_table,
        locations,
        location_groups,
        data_root,
        get_location_callback,
        get_location_group_callback,
        user_fact_groups
    ) {
        if (reports_data[report_table]) {
            return;
        }
        reports_data[report_table] = {
            first_index: 0,
            second_index: 1,
            first_index_key: keys[0],
            leaves: [],
            data: [],
            locations: locations,
            location_groups: location_groups,
            data_root: data_root,
            prepare_data_callbacks: {
                locations: get_location_callback,
                location_groups: get_location_group_callback
            },
            user_fact_groups: user_fact_groups,
        };

        reports_data[report_table].data = [
            prepare_locations(report_table),
            null
        ];
    }

    $(document).on('enterprise_insights_display_by_groups_or_locations_changed', function (e, data) {
        let self = reports_data[data.report_table];
        self.first_index = parseInt(data.index);
        self.second_index = Math.abs(self.first_index - 1);
        self.first_index_key = keys[self.first_index];
    });

    function get_data(report_table)
    {
        let self = reports_data[report_table];
        if (!self.data[self.first_index]) {
            self.data[1] = prepare_location_groups(report_table);
        }
        return self.data[self.first_index];
    }

    /**
     * Returns current index of the dropdown; it's used in f.i. the dropdown's builder
     *
     * @param report_table
     * @returns integer
     */
    function get_first_index(report_table)
    {
        return reports_data[report_table].first_index;
    }

    function get_first_index_key(report_table)
    {
        return reports_data[report_table].first_index_key;
    }

    /**
     * Prepares locations for showing in the report table
     *
     * This method uses a callback which is passed as a parameter during the tool initialization.
     *
     * @param report_table
     * @returns array
     */
    function prepare_locations(report_table)
    {
        let self = reports_data[report_table];
        let data = [];

        _.each(self.locations, function(location)
        {
            if (typeof self.data_root[location.id] === 'undefined')
                return;

            let location_data = JSON.parse(JSON.stringify(self.prepare_data_callbacks['locations'](self.data_root, location)));
            if (!location_data.hasOwnProperty('location')) {
                location_data.location = location;
            }

            data.push(location_data);
        });
        return data;
    }

    function prepare_location_groups(report_table)
    {
        let self = reports_data[report_table];

        if (self.data_root.length === 0) {
            return [];
        }

        // we need filter only location_groups from the list `self.location_groups`
        // because there are both kinds: locations and groups
        let location_groups = self.user_fact_groups;

        // now we can work with location_groups only
        let location_group_data = [];

        _.each(self.locations, function(location) {
            if (location.hasOwnProperty('location_groups')
                || (typeof self.data_root[location.id] === 'undefined')
                    && _.find(location_groups, function (location_group) {
                        return _.includes(location_group.locations, {id: location.id});
                    })
            ) {
                return;
            }

            let location_data = self.prepare_data_callbacks['location_groups'](self.data_root, location);
            if (!location_data) {
                return;
            }
            location_data = JSON.parse(JSON.stringify(location_data));
            _.each(location_groups, function (location_group) {
                location_group = JSON.parse((JSON.stringify(location_group)));
                let item = _.find(location_group.locations, {id: location.id});
                if (item) {
                    location_data = JSON.parse(JSON.stringify(location_data));
                    location_data.location = location_group;
                    location_group_data.push(location_data);
                }
            });
        });

        /*
         * now we're looking for all the leaves of data structure. A leave == full path from the top level to the deepest one.
         * f.i. the structure of view:
         * {
         *     new_opps: {
         *         booked: 10,
         *         total: 20
         *     },
         *     exist_opps: {
         *         booked: 30,
         *         total: 40
         *     }
         *     booked: 40
         *     total: 60
         * }
         *
         * will return the following array of leaves:
         * [
         *     'new_opps.booked',
         *     'new_opps.total',
         *     'exist_opps.booked',
         *     'exist_opps.total',
         *     'booked',
         *     'total'
         * ]
         *
         * Whatever data structure we have we always can duplicate it and harvest data from all the according leaves
         * into the new structure
         */

        find_all_leaves(self, location_group_data[0]);

        let data = [];

        _.each(location_groups, function (location) {
            let location_id = location.id;
            // clone new structure object
            let new_item = {};
            // go through leaves
            _.each(self.leaves, function (keys) {
                let value = new_item;
                keys = keys.split('.');
                // parse the path and copy its structure to the new object
                let lastKeyIndex = keys.length-1;
                for (let i = 0; i < lastKeyIndex; ++ i) {
                    let key = keys[i];
                    if (!(key in value)) {
                        value[key] = {};
                    }
                    value = value[key];
                }
                // here we go through the duplicated structure however in the original array
                // and reduce the leave into the single value
                let v = _.reduce(location_group_data, function (sum, item) {
                    if (item.location.id !== location_id) {
                        return sum;
                    }
                    if (!new_item.location) {
                        new_item.location = item.location;
                    }
                    let lki = keys.length-1;
                    for (let i = 0; i < lki; ++ i) {
                        let key = keys[i];
                        if (key in item) {
                            item = item[key];
                        }
                    }

                    return sum + (item[keys[lki]] || 0);
                }, 0);
                // save the value in the leave of new structure
                value[keys[lastKeyIndex]] = v;
            });
            // add new item into the dta array
            data.push(new_item);
        });

        return data;
    }

    function find_all_leaves(self, object, parent_key = '')
    {
        if (parent_key.match("^location")) {
            return;
        }
        _.each(object, function (body, key) {
            key = parent_key + key;
            if (typeof body == 'object') {
                find_all_leaves(self, body, key + '.');
                return;
            }
            self.leaves.push(key);
        });
    }
})();
