(function() {
    namespace("PatientPrism.Admin.CallData.OnlineSource", init);

    function init(call_record_id)
    {
        let callback = function (data, textStatus, jqXHR)
        {
            let online_source_block = $('p#online-source');
            let timeline = null;

            online_source_block.find('img.loader').remove();

            if ('error' === data.status) {
                timeline = [];
            } else {
                timeline = _.sortBy((data.data.analytic_token ? data.data.analytic_token.web_records : []), ['created_at']);
            }

            if (timeline.length && timeline[0].hasOwnProperty('source') && '' !== timeline[0].source) {
                online_source_block.append(timeline[0].source);
            } else {
                online_source_block.append('None');
            }
        };

        PatientPrism.API.CallRecords.get(
            call_record_id,
            callback,
            null,                                   // appends
            ['analytic_token.web_records.terms']    // expands
        );
    }
})();
