(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.NewOpportunities", init);

    var self = {
        $table: null
    };

    function init()
    {
    	initPhoneNumberMetricsDataTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initPhoneNumberMetricsDataTable()
    {
        self.$table = $('table#new-patients');

        if (self.$table.length)
        {
            self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                responsive: true,
                'order': [
                    [6, 'desc']
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ opportunities per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Opportunities",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total opportunities",
                    "sEmptyTable": "No Opportunity Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                buttons: [
                    {
                        extend:        'colvis',
                        text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                        className:     'btn-sm action-button dt-button',
                        columns:       ':not(.no-toggle-visibility)'

                    }],
                "sPaginationType": "simple"
            });
        }
    }
})();
