(function()
{
    namespace("PatientPrism.API.PhoneNumbers", all, search, create, destroy, makeStatic, makeDynamic);

    var self = {
        //
    }

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/phone-numbers', callback, body)
    }

    function search(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/phone-numbers/search', callback, body)
    }

    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/phone-numbers', callback, body)
    }

    function destroy(phone_number_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/phone-numbers' + (phone_number_id ? '/' + phone_number_id : ''), callback, body)
    }
    
    function makeStatic(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/phone-numbers/singleton', callback, body)
    }
    
    function makeDynamic(callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/phone-numbers/singleton', callback, body)
    }
})();