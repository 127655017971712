(function()
{
    namespace("PatientPrism.API.CallKeywords", create, update, destroy, all, summary, broad_matches, broad_matches_details);

    var self = {
        //
    }

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/call-keywords', callback, body)
    }

    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/call-keywords', callback, body)
    }

    function update(call_keyword_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-keywords/' + call_keyword_id , callback, body)
    }

    function destroy(call_keyword_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-keywords/' + call_keyword_id , callback, body)
    }

    function summary(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/call-keywords?summary=1', callback, body)
    }

    function broad_matches(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/reports/call-keywords/broad-matches', callback, body);
    }

    function broad_matches_details(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/reports/call-keywords/broad-match-details', callback, body);
    }
})();
