(function()
{
    namespace("PatientPrism.Modules.Manage.Locations.Settings", init);

    var self = {
        location: null,
    };

    function init(location)
    {
        self.location = location;
        attachCheckboxesHandlers();
    }

    function attachCheckboxesHandlers() {
        $('#record-calls-checkbox').on('click', function (e) {
            let isChecked = $(this).is(':checked');

            $('#record-calls').val(isChecked ? 1 : 0);

            if (!isChecked) {
                $('#record-outside-business-hours-checkbox').prop('checked', false);
                $('#transcribe-outside-business-hours-checkbox').prop('checked', false);
                $('#record-outside-business-hours').val(0);
                $('#transcribe-outside-business-hours').val(0);
            }

            $('#record-outside-business-hours-checkbox').attr('disabled', !isChecked);
            $('#transcribe-outside-business-hours-checkbox').attr('disabled', !isChecked);
        });

        $('#record-outside-business-hours-checkbox').on('click', function (e) {
            let isChecked = $(this).is(':checked');

            $('#record-outside-business-hours').val(isChecked ? 1 : 0);

            if (!isChecked) {
                $('#transcribe-outside-business-hours-checkbox').prop('checked', false);
                $('#transcribe-outside-business-hours').val(0);
            }

            $('#transcribe-outside-business-hours-checkbox').attr('disabled', !isChecked);
        });

        $('#transcribe-outside-business-hours-checkbox').on('click', function (e) {
            $('#transcribe-outside-business-hours').val($(this).is(':checked') ? 1 : 0);
        });
    }
})();