(function()
{
    namespace("PatientPrism.Common.UI.UserVerification", init);

    var self = {
        data: {
            significant_events: null
        },
        el: {
            $alert: null,
            $btn: null
        }
    }

    function init()
    {
        self.el.$alert = $('#user-verification-alert');
        self.el.$btn   = $('#send-user-verification', self.el.$alert);

        bindResendButton();
    }

    function bindResendButton()
    {
        self.el.$btn.click(function(e)
        {
            self.el.$alert
                .html('<span class="fa fa-refresh fa-spin"></span> Sending Email')

            var this_callback = function(data, textStatus, jqXHR)
            {
                if (textStatus == 'success')
                {
                    self.el.$alert
                        .removeClass('alert-warning')
                        .addClass('alert-success')
                        .html('Verification email has been sent. <br/> <strong>Please check your email.</strong>')
                }
                else
                {
                    self.el.$alert
                        .removeClass('alert-warning')
                        .addClass('alert-danger')
                        .html('An error occurred processing the request.')
                }
            }

            var body = {
                'email': PatientPrism.Common.Session.get('current').user.email,
            };

            PatientPrism.API.Users.resend_verification(this_callback, body);
        });
    }
})();