(function()
{
    namespace("PatientPrism.API.Metadata", all);

    var self = {
        //
    }

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/metadata', callback, body)
    }
})();