(function()
{
    namespace("PatientPrism.Common.Vendor.DataTable.ActionColumn.CallRecords", init);

    var self = {
        $table:          null,
        datatable:       null,
        $column_header:  null,
    }

    function init(table)
    {
        self.$table          = table;
        self.datatable       = self.$table.DataTable();

        self.$column_header = $('.action-column-header', self.$table);

        // handle 'Select/Deselect All Pages' event
        $('.toggle-select', self.$column_header).click( function (event)
        {
            event.preventDefault();
            event.stopPropagation();
            toggleSelectAllRows();
        });

        // handle 'Select/Deselect All Rows on a Page' event
        $('.toggle-select-page', self.$column_header).click( function (event)
        {
            event.preventDefault();
            event.stopPropagation();
            toggleSelectAllRowsOnPage();
        });

        // handle 'switch page' eevent
        self.$table.on("page.dt", function () {
            toggleSelectAllRowsOnPageBtn(getCurrentPageAttrName(self.datatable));
        });

        // A checkbox change event has fired
        $('td.action-column > input[type="checkbox"].selection-checkbox', self.datatable.rows().nodes()).on('change', function (event)
        {
            // Mark row as selected
            $(this).is(':checked') ? $(this).closest('tr').addClass('selected') : $(this).closest('tr').removeClass('selected');

            var selected_row_count = getSelectedRowCount();

            // Update row count text in dropdown menu
            $('.selected-row-count', self.$column_header).text(selected_row_count ? selected_row_count : 'No');

            // Enable action items if more than 0 rows are selected
            selected_row_count ? $('.action-item', self.$column_header).removeClass('disabled') : $('.action-item', self.$column_header).addClass('disabled');
        });

        $('.action-item[data-action="call-records-delete"]', self.$table).click(function(event)
        {
            event.preventDefault();

            if ($(this).hasClass('disabled'))
                return;

            var callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    $(self.datatable.rows('.selected').nodes()).fadeOut('fast', function()
                    {
                        // Remove selected rows from the table
                        self.datatable.rows('.selected')
                            .remove()
                            .draw(false);
                    });

                    PatientPrism.Common.UI.Notification.create('success', data.message);
                }
                else
                {
                    PatientPrism.Common.UI.Notification.create('error', data.error.message);
                }
            }

            var request_body = {
                call_record_id: getSelectedRowIds()
            };

            PatientPrism.API.CallRecords.destroy(null, callback, request_body);
        })
    }

    /**
     * Check/uncheck checkboxes for rows in the table
     *
     * @param page_selector
     * @param selected_rows
     */
    function toggleCheckboxes(page_selector, selected_rows) {
        $('td.action-column > input[type="checkbox"].selection-checkbox', selected_rows)
            .prop('checked', self.$column_header.attr(page_selector) == 1 ? 0 : 1)
            .trigger('change');
    }

    /**
     * Select/deselect all rows and update corresponded attributes and buttons
     */
    function toggleSelectAllRows()
    {
        // Get all available rows
        var available_rows = self.datatable.rows({ 'search': 'applied' }).nodes();

        toggleCheckboxes('data-selected', available_rows);
        clearAttributes();
        toggleAttribute('data-selected');
        toggleSelectAllRowsBtn();
    }

    /**
     * Select/deselect all rows on a current page and update corresponded attributes and buttons
     */
    function toggleSelectAllRowsOnPage()
    {
        // Get all rows on a page
        var available_rows = self.datatable.rows("*", { page: 'current' }).nodes(),
            page_selector = getCurrentPageAttrName(self.datatable);

        toggleCheckboxes(page_selector, available_rows);
        toggleAttribute(page_selector);
        toggleSelectAllRowsOnPageBtn(page_selector);
    }

    /**
     * Add/Remove 'selected' attribute
     *
     * @param selector
     */
    function toggleAttribute(selector) {
        self.$column_header.attr(selector, (self.$column_header.attr(selector) == 1 ? 0 : 1));
    }

    /**
     * Update 'Select/Deselect All' button
     */
    function toggleSelectAllRowsBtn() {
        $('.toggle-select').text((self.$column_header.attr('data-selected') == 1 ? 'Deselect' : 'Select') + ' All');
    }

    /**
     * Update 'Select/Deselect All Rows On A Page' button
     */
    function toggleSelectAllRowsOnPageBtn(page_selector) {
        $('.toggle-select-page').text((self.$column_header.attr(page_selector) == 1 ? 'Deselect' : 'Select') + ' All Rows On Page');
    }

    /**
     * Clear all 'data-page-{n}-selected' attributes given to selected pages
     */
    function clearAttributes() {
        let page_selector, attr;

        for (let i = 0; i < self.datatable.page.info().pages; i++) {
            page_selector = 'data-page-' + i + '-selected';
            attr = self.$column_header.attr(page_selector);
            if (typeof attr !== typeof undefined && attr !== false) {
                self.$column_header.removeAttr(page_selector);
            }
        }
    }

    /**
     * Get selected rows number
     * @returns {*}
     */
    function getSelectedRowCount()
    {
        return self.datatable.rows('.selected').data().length;
    }

    /**
     * Get selected rows IDs
     * @returns {Array}
     */
    function getSelectedRowIds()
    {
        var ids = [];

        $(self.datatable.rows('.selected').nodes()).each(function(row)
        {
            ids.push($(this).closest('tr').attr('data-id'));
        });

        return ids;
    }

    /**
     * Get the attribute name of the current page
     *
     * @param $datatable
     * @returns {string}
     */
    function getCurrentPageAttrName($datatable) {
        return 'data-page-' + $datatable.page() + '-selected';
    }
})();