(function()
{
    namespace("PatientPrism.Common.UI.Navbar", init);

    var self = {
        $navbar: null
    }

    function init()
    {
        self.$navbar = $('#navbar-primary');

    	initProfileTooltip();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initProfileTooltip()
    {
        $('#navbar-location-profile', self.$navbar).click(function (e)
        {
            e.preventDefault();

            var w = 450
            var h = window.screen ? window.screen.availHeight : 1000

            var profile_popup = window.open($(this).attr('data-profile-url'), 'Profile', 'width=' + w + ',height=' + h + ',top=0,left=0');

            if (window.focus) {
                profile_popup.focus()
            }
        });
    }
})();