(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.Performance", init);

    var self = {
        $table: null,
        $phone_numbers_table: null,
        $insights_not_booked_table: null,
        report_data: null,
        locations: [],
        location_groups: [],
        formatters:  PatientPrism.Modules.Analize.Helpers.Formatters,
        renderers: PatientPrism.Modules.Analize.Helpers.Renderers
    };

    function init(data)
    {
        self.report_data = JSON.parse(data);

        _.each(self.report_data.all.locations, function(location)
        {
            self.locations.push(location);
        });

        _.each(self.report_data.all.location_groups, function (location_group)
        {
            self.location_groups.push(location_group);
            _.each(location_group.locations, function (location)
            {
                if (_.find(self.locations, {id: location.id}))
                    return;

                self.locations.push(location);
            });
        });

    	initCallRecordPerformanceDataTable();
        initPhoneNumberPerformanceDataTable();
        initTooltips();
        initDailyCallSummaryChart();
        initHourlyCallSummaryChart();
        initReferralMetricsChart();
        initReferralMetricsDataTable();
        initReasonsNotBookedChart();
        initReasonsNotConnectedChart();
        initInsightsPerformanceTable();
        initInsightsNotBookedTable();
        initInsightsNotConnectedTable();
        initTopPhoneNumbersTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initCallRecordPerformanceDataTable()
    {
        self.$table = $('table#call-record-performance');

        if (self.$table.length)
        {
            self.$table.DataTable({
                responsive: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                'order': [
                    [5, 'desc']
                ],
                "columnDefs": [
                {
                    "targets": [9],
                    "orderable": false
                }],
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initPhoneNumberPerformanceDataTable()
    {
        self.$table = $('table#phone-number-performance');

        if (self.$table.length)
        {
            self.$table.DataTable({
                responsive: true,
                'order': [
                    [2, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available"
                },
                "sPaginationType": "simple"
            });
        }
    }

    function initDailyCallSummaryChart() {
        $('#daily-call-summary-chart').highcharts({
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Daily Call Summary'
            },
            subtitle: {
                text: 'A Breakdown of Calls Per Day'
            },
            xAxis: [{
                categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                }
            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 100,
                verticalAlign: 'top',
                y: 30,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            series: [{
                name: 'Total Calls',
                type: 'areaspline',
                data: [
                    self.report_data.all.day_of_week_performance.hasOwnProperty('Monday') ? self.report_data.all.day_of_week_performance.Monday.total : 0,
                    self.report_data.all.day_of_week_performance.hasOwnProperty('Tuesday') ? self.report_data.all.day_of_week_performance.Tuesday.total : 0,
                    self.report_data.all.day_of_week_performance.hasOwnProperty('Wednesday') ? self.report_data.all.day_of_week_performance.Wednesday.total : 0,
                    self.report_data.all.day_of_week_performance.hasOwnProperty('Thursday') ? self.report_data.all.day_of_week_performance.Thursday.total : 0,
                    self.report_data.all.day_of_week_performance.hasOwnProperty('Friday') ? self.report_data.all.day_of_week_performance.Friday.total : 0,
                    self.report_data.all.day_of_week_performance.hasOwnProperty('Saturday') ? self.report_data.all.day_of_week_performance.Saturday.total : 0,
                    self.report_data.all.day_of_week_performance.hasOwnProperty('Sunday') ? self.report_data.all.day_of_week_performance.Sunday.total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[1]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[1], 0)]
                    ]
                }
            }, {
                name: 'After-Hours Calls',
                type: 'areaspline',
                data: [
                    self.report_data.all.after_hour_calls.day_of_week_performance.hasOwnProperty('Monday') ? self.report_data.all.after_hour_calls.day_of_week_performance.Monday.total : 0,
                    self.report_data.all.after_hour_calls.day_of_week_performance.hasOwnProperty('Tuesday') ? self.report_data.all.after_hour_calls.day_of_week_performance.Tuesday.total : 0,
                    self.report_data.all.after_hour_calls.day_of_week_performance.hasOwnProperty('Wednesday') ? self.report_data.all.after_hour_calls.day_of_week_performance.Wednesday.total : 0,
                    self.report_data.all.after_hour_calls.day_of_week_performance.hasOwnProperty('Thursday') ? self.report_data.all.after_hour_calls.day_of_week_performance.Thursday.total : 0,
                    self.report_data.all.after_hour_calls.day_of_week_performance.hasOwnProperty('Friday') ? self.report_data.all.after_hour_calls.day_of_week_performance.Friday.total : 0,
                    self.report_data.all.after_hour_calls.day_of_week_performance.hasOwnProperty('Saturday') ? self.report_data.all.after_hour_calls.day_of_week_performance.Saturday.total : 0,
                    self.report_data.all.after_hour_calls.day_of_week_performance.hasOwnProperty('Sunday') ? self.report_data.all.after_hour_calls.day_of_week_performance.Sunday.total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[3]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[3], 0)]
                    ]
                }
            }, {
                name: 'New Opportunities',
                type: 'column',
                data: [
                    self.report_data.new_opportunity.day_of_week_performance.hasOwnProperty('Monday') ? self.report_data.new_opportunity.day_of_week_performance.Monday.total : 0,
                    self.report_data.new_opportunity.day_of_week_performance.hasOwnProperty('Tuesday') ? self.report_data.new_opportunity.day_of_week_performance.Tuesday.total : 0,
                    self.report_data.new_opportunity.day_of_week_performance.hasOwnProperty('Wednesday') ? self.report_data.new_opportunity.day_of_week_performance.Wednesday.total : 0,
                    self.report_data.new_opportunity.day_of_week_performance.hasOwnProperty('Thursday') ? self.report_data.new_opportunity.day_of_week_performance.Thursday.total : 0,
                    self.report_data.new_opportunity.day_of_week_performance.hasOwnProperty('Friday') ? self.report_data.new_opportunity.day_of_week_performance.Friday.total : 0,
                    self.report_data.new_opportunity.day_of_week_performance.hasOwnProperty('Saturday') ? self.report_data.new_opportunity.day_of_week_performance.Saturday.total : 0,
                    self.report_data.new_opportunity.day_of_week_performance.hasOwnProperty('Sunday') ? self.report_data.new_opportunity.day_of_week_performance.Sunday.total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[11]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[11], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            }, {
                name: 'Not Connected',
                type: 'column',
                data: [
                    self.report_data.not_connected.day_of_week_performance.hasOwnProperty('Monday') ? self.report_data.not_connected.day_of_week_performance.Monday.total : 0,
                    self.report_data.not_connected.day_of_week_performance.hasOwnProperty('Tuesday') ? self.report_data.not_connected.day_of_week_performance.Tuesday.total : 0,
                    self.report_data.not_connected.day_of_week_performance.hasOwnProperty('Wednesday') ? self.report_data.not_connected.day_of_week_performance.Wednesday.total : 0,
                    self.report_data.not_connected.day_of_week_performance.hasOwnProperty('Thursday') ? self.report_data.not_connected.day_of_week_performance.Thursday.total : 0,
                    self.report_data.not_connected.day_of_week_performance.hasOwnProperty('Friday') ? self.report_data.not_connected.day_of_week_performance.Friday.total : 0,
                    self.report_data.not_connected.day_of_week_performance.hasOwnProperty('Saturday') ? self.report_data.not_connected.day_of_week_performance.Saturday.total : 0,
                    self.report_data.not_connected.day_of_week_performance.hasOwnProperty('Sunday') ? self.report_data.not_connected.day_of_week_performance.Sunday.total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[8]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[8], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            }]
        });
    }

    function initHourlyCallSummaryChart() {
        $('#hourly-call-summary-chart').highcharts({
            chart: {
                zoomType: 'xy',
            },
            title: {
                text: 'Hourly Call Summary'
            },
            subtitle: {
                text: 'A Breakdown of Calls Per Hour'
            },
            xAxis: [{
                categories: [
                    '12 AM', '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM', '09 AM', '10 AM', '11 AM',
                    '12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM'
                ],
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                }
            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 100,
                verticalAlign: 'top',
                y: 30,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            series: [{
                name: 'Total Calls',
                type: 'areaspline',
                data: [
                    self.report_data.all.hourly_performance.hasOwnProperty('12 AM') ? self.report_data.all.hourly_performance['12 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('1 AM') ? self.report_data.all.hourly_performance['1 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('2 AM') ? self.report_data.all.hourly_performance['2 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('3 AM') ? self.report_data.all.hourly_performance['3 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('4 AM') ? self.report_data.all.hourly_performance['4 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('5 AM') ? self.report_data.all.hourly_performance['5 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('6 AM') ? self.report_data.all.hourly_performance['6 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('7 AM') ? self.report_data.all.hourly_performance['7 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('8 AM') ? self.report_data.all.hourly_performance['8 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('9 AM') ? self.report_data.all.hourly_performance['9 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('10 AM') ? self.report_data.all.hourly_performance['10 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('11 AM') ? self.report_data.all.hourly_performance['11 AM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('12 PM') ? self.report_data.all.hourly_performance['12 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('1 PM') ? self.report_data.all.hourly_performance['1 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('2 PM') ? self.report_data.all.hourly_performance['2 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('3 PM') ? self.report_data.all.hourly_performance['3 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('4 PM') ? self.report_data.all.hourly_performance['4 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('5 PM') ? self.report_data.all.hourly_performance['5 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('6 PM') ? self.report_data.all.hourly_performance['6 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('7 PM') ? self.report_data.all.hourly_performance['7 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('8 PM') ? self.report_data.all.hourly_performance['8 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('9 PM') ? self.report_data.all.hourly_performance['9 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('10 PM') ? self.report_data.all.hourly_performance['10 PM'].total : 0,
                    self.report_data.all.hourly_performance.hasOwnProperty('11 PM') ? self.report_data.all.hourly_performance['11 PM'].total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[1]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[1], 0)]
                    ]
                }
            }, {
                name: 'After-Hours Calls',
                type: 'areaspline',
                data: [
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('12 AM') ? self.report_data.all.after_hour_calls.hourly_performance['12 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('1 AM') ? self.report_data.all.after_hour_calls.hourly_performance['1 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('2 AM') ? self.report_data.all.after_hour_calls.hourly_performance['2 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('3 AM') ? self.report_data.all.after_hour_calls.hourly_performance['3 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('4 AM') ? self.report_data.all.after_hour_calls.hourly_performance['4 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('5 AM') ? self.report_data.all.after_hour_calls.hourly_performance['5 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('6 AM') ? self.report_data.all.after_hour_calls.hourly_performance['6 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('7 AM') ? self.report_data.all.after_hour_calls.hourly_performance['7 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('8 AM') ? self.report_data.all.after_hour_calls.hourly_performance['8 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('9 AM') ? self.report_data.all.after_hour_calls.hourly_performance['9 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('10 AM') ? self.report_data.all.after_hour_calls.hourly_performance['10 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('11 AM') ? self.report_data.all.after_hour_calls.hourly_performance['11 AM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('12 PM') ? self.report_data.all.after_hour_calls.hourly_performance['12 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('1 PM') ? self.report_data.all.after_hour_calls.hourly_performance['1 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('2 PM') ? self.report_data.all.after_hour_calls.hourly_performance['2 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('3 PM') ? self.report_data.all.after_hour_calls.hourly_performance['3 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('4 PM') ? self.report_data.all.after_hour_calls.hourly_performance['4 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('5 PM') ? self.report_data.all.after_hour_calls.hourly_performance['5 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('6 PM') ? self.report_data.all.after_hour_calls.hourly_performance['6 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('7 PM') ? self.report_data.all.after_hour_calls.hourly_performance['7 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('8 PM') ? self.report_data.all.after_hour_calls.hourly_performance['8 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('9 PM') ? self.report_data.all.after_hour_calls.hourly_performance['9 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('10 PM') ? self.report_data.all.after_hour_calls.hourly_performance['10 PM'].total : 0,
                    self.report_data.all.after_hour_calls.hourly_performance.hasOwnProperty('11 PM') ? self.report_data.all.after_hour_calls.hourly_performance['11 PM'].total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[3]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[3], 0)]
                    ]
                }
            }, {
                name: 'New Opportunities',
                type: 'column',
                data: [
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('12 AM') ? self.report_data.new_opportunity.hourly_performance['12 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('1 AM') ? self.report_data.new_opportunity.hourly_performance['1 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('2 AM') ? self.report_data.new_opportunity.hourly_performance['2 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('3 AM') ? self.report_data.new_opportunity.hourly_performance['3 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('4 AM') ? self.report_data.new_opportunity.hourly_performance['4 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('5 AM') ? self.report_data.new_opportunity.hourly_performance['5 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('6 AM') ? self.report_data.new_opportunity.hourly_performance['6 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('7 AM') ? self.report_data.new_opportunity.hourly_performance['7 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('8 AM') ? self.report_data.new_opportunity.hourly_performance['8 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('9 AM') ? self.report_data.new_opportunity.hourly_performance['9 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('10 AM') ? self.report_data.new_opportunity.hourly_performance['10 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('11 AM') ? self.report_data.new_opportunity.hourly_performance['11 AM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('12 PM') ? self.report_data.new_opportunity.hourly_performance['12 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('1 PM') ? self.report_data.new_opportunity.hourly_performance['1 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('2 PM') ? self.report_data.new_opportunity.hourly_performance['2 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('3 PM') ? self.report_data.new_opportunity.hourly_performance['3 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('4 PM') ? self.report_data.new_opportunity.hourly_performance['4 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('5 PM') ? self.report_data.new_opportunity.hourly_performance['5 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('6 PM') ? self.report_data.new_opportunity.hourly_performance['6 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('7 PM') ? self.report_data.new_opportunity.hourly_performance['7 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('8 PM') ? self.report_data.new_opportunity.hourly_performance['8 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('9 PM') ? self.report_data.new_opportunity.hourly_performance['9 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('10 PM') ? self.report_data.new_opportunity.hourly_performance['10 PM'].total : 0,
                    self.report_data.new_opportunity.hourly_performance.hasOwnProperty('11 PM') ? self.report_data.new_opportunity.hourly_performance['11 PM'].total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[11]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[11], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            }, {
                name: 'Not Connected',
                type: 'column',
                data: [
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('12 AM') ? self.report_data.not_connected.hourly_performance['12 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('1 AM') ? self.report_data.not_connected.hourly_performance['1 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('2 AM') ? self.report_data.not_connected.hourly_performance['2 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('3 AM') ? self.report_data.not_connected.hourly_performance['3 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('4 AM') ? self.report_data.not_connected.hourly_performance['4 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('5 AM') ? self.report_data.not_connected.hourly_performance['5 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('6 AM') ? self.report_data.not_connected.hourly_performance['6 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('7 AM') ? self.report_data.not_connected.hourly_performance['7 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('8 AM') ? self.report_data.not_connected.hourly_performance['8 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('9 AM') ? self.report_data.not_connected.hourly_performance['9 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('10 AM') ? self.report_data.not_connected.hourly_performance['10 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('11 AM') ? self.report_data.not_connected.hourly_performance['11 AM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('12 PM') ? self.report_data.not_connected.hourly_performance['12 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('1 PM') ? self.report_data.not_connected.hourly_performance['1 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('2 PM') ? self.report_data.not_connected.hourly_performance['2 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('3 PM') ? self.report_data.not_connected.hourly_performance['3 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('4 PM') ? self.report_data.not_connected.hourly_performance['4 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('5 PM') ? self.report_data.not_connected.hourly_performance['5 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('6 PM') ? self.report_data.not_connected.hourly_performance['6 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('7 PM') ? self.report_data.not_connected.hourly_performance['7 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('8 PM') ? self.report_data.not_connected.hourly_performance['8 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('9 PM') ? self.report_data.not_connected.hourly_performance['9 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('10 PM') ? self.report_data.not_connected.hourly_performance['10 PM'].total : 0,
                    self.report_data.not_connected.hourly_performance.hasOwnProperty('11 PM') ? self.report_data.not_connected.hourly_performance['11 PM'].total : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[8]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[8], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            }]
        });
    }

    function initReasonsNotBookedChart()
    {
        // Radialize the colors
        Highcharts.getOptions().colors = Highcharts.map(Highcharts.getOptions().colors, function (color) {
            return {
                radialGradient: {
                    cx: 0.5,
                    cy: 0.3,
                    r: 0.7
                },
                stops: [
                    [0, color],
                    [1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
                ]
            };
        });

        $('#reasons-not-booked-chart').highcharts({
            chart: {
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                }
            },
            title: {
                text: 'Reasons Not Booked'
            },
            subtitle: {
                text: 'A Breakdown of Why Calls Were Not Booked'
            },
            tooltip: {
                pointFormat: '{series.name}: <strong>{point.percentage:.1f}%</strong> <br/> Total Calls: <strong>{point.y} Calls</strong></b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: <b>{point.percentage:.1f}%</b>'
                    },
                }
            },
            series: [{
                type: 'pie',
                name: 'Total Share',
                data: _.map(typeof self.report_data.all.reason_not_booked !== 'undefined' ? self.report_data.all.reason_not_booked.total : [], function(item) {
                        return {
                            y:           item.count,
                            name:        item.value,
                            metadata_id: item.id
                        }
                    }).filter(function(data) {return data.y > 0}),
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e) {
                            e.preventDefault();

                            location.href =
                            encodeURI(
                                _router.route('calls.search', {
                                    'metadata':    this.metadata_id
                                })
                            );
                        }
                    }
                }
            }]
        });
    }

    function initReasonsNotConnectedChart()
    {

        $('#reasons-not-connected-chart').highcharts({
            chart: {
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                }
            },
            title: {
                text: 'Reasons Not Connected'
            },
            subtitle: {
                text: 'A Breakdown of Why Calls Were Not Connected'
            },
            tooltip: {
                pointFormat: '{series.name}: <strong>{point.percentage:.1f}%</strong> <br/> Total Calls: <strong>{point.y} Calls</strong></b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: <b>{point.percentage:.1f}%</b>'
                    },

                }
            },
            series: [{
                type: 'pie',
                name: 'Total Share',
                data: _.map(typeof self.report_data.all.reason_not_connected !== 'undefined' ? self.report_data.all.reason_not_connected.total : [], function(item) {
                        return {
                            y:           item.count,
                            name:        item.value,
                            metadata_id: item.id
                        }
                    }).filter(function(data) {return data.y > 0}),
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e) {
                            e.preventDefault();

                            location.href =
                            encodeURI(
                                _router.route('calls.search', {
                                    'metadata':    this.metadata_id
                                })
                            );
                        }
                    }
                }
            }]
        });
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initReferralMetricsDataTable()
    {
        self.$table = $('table#referral-metrics');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [4, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available"
                },
                "sPaginationType": "simple"
            });
        }
    }

    function initReferralMetricsChart()
    {
        $('#referral-metrics-chart').highcharts({
            title: {
                text: 'Conversion Breakdown'
            },
            subtitle: {
                text: 'A Breakdown of Calls to Conversion'
            },
            xAxis: {
                categories: _.map(self.report_data.sources, function (item, key)
                    {
                      return key;
                    }),
                crosshair: true
            },
            yAxis: [{
                min: 0,
                title: {
                    text: 'Calls'
                }
            }, {
                title: {
                    text: 'Conversion %'
                },
                opposite: true
            }],
            tooltip: {
                shared: true,
            },
            series: [
            {
                name: 'Calls',
                type: 'areaspline',
                data:
                    _.map(self.report_data.sources, function (source)
                    {
                        return source.calls;
                    }),
                shadow: true,
                tooltip: {
                    pointFormatter: function() {
                        var calls_text = this.y > 1 ? ' Calls' : ' Call';
                        return '<b>' + this.y + calls_text +'</b><br/>';
                    }
                }
            },
            {
                name: 'Conversion',
                type: 'areaspline',
                data:
                    _.map(self.report_data.sources, function (source)
                    {
                        return source.conversion_percent;
                    }),
                tooltip: {
                    pointFormat: ' <i>({point.y}% Conversion)</i>'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[1]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[1], 0)]
                    ]
                },
                yAxis: 1
            }]
        });
    }

    function initTopPhoneNumbersTable()
    {
        self.$phone_numbers_table = $('table#top-phone-numbers-table');

        if (self.$phone_numbers_table.length)
        {
            self.$phone_numbers_table =
            self.$phone_numbers_table.DataTable({
                'order': [
                    [2, 'desc']
                ],
                oLanguage: {
                    sLengthMenu: "Show _MENU_ numbers per page",
                    sInfo: "Showing _START_ to _END_ of _TOTAL_ Numbers",
                    sInfoEmpty: "",
                    sInfoFiltered: " - filtered from _MAX_ total numbers",
                    sEmptyTable: "No Data Available"
                },
                dom:
                    "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                buttons: [
                    {
                        extend: "csv",
                        text: '<i class="fa fa-file-text-o"></i> Export CSV',
                        filename: "performance-report",
                        className: "btn-sm action-button dt-button",
                        exportOptions: {
                        orthogonal: "export",
                        columns: [":visible :not(.no-export)"],
                        },
                    },
                ],
                sPaginationType: "simple",
                bLengthChange:   false,
                bFilter:         false,
                sSearch:         false,
                pageLength: 5
            });
        }
    }

    function initInsightsPerformanceTable()
    {
        self.$insights_performance_table = $('table#insights-performance');

        if (!self.$insights_performance_table.length)
            return;

        let formatters = self.formatters;
        let renderers = self.renderers;

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_performance',
            self.locations,
            self.location_groups,
            self.report_data.all.call_insights,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let data = data_converter.get_data('insights_performance');

        if ($.fn.DataTable.isDataTable(self.$insights_performance_table))
        {
            self.$insights_performance_table.DataTable().destroy();
            self.$insights_performance_table.empty();
        }

        self.$insights_performance_table.DataTable({
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            columns: [{
                title: 'Location',
                data: 'location.name'
            },
            {
                title: 'Total',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            classification: 'New Opportunity',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_performance')] = row.location.id;
                    return formatters.calls(
                        row.new_opportunities.total,
                        type,
                        null,
                        'insights_performance',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.calls(data, type, null, 'insights_performance');
                }
            },
            {
                title: 'Booked',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            classification: 'New Opportunity',
                            booking_status: 'Booked',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_performance')] = row.location.id;
                    return formatters.call_count(
                        row.new_opportunities.booked,
                        row.new_opportunities.total,
                        type,
                        'insights_performance',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(data, row.new_opportunities.booked, row.new_opportunities.total, type, 'insights_performance');
                }
            },
            {
                title: 'Avg. Schedule Lead',
                className: 'text-center average-days-column',
                data: function ( row, type, val, meta ) {
                    return row.days_to_place ?? -1;
                },
                render: function (data, type, row, meta) {
                    if (row.days_to_place) {
                        return row.days_to_place + ' Days';
                    } else {
                        return '-';
                    }
                }
            },
            {
                title: 'Total',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            classification: 'Existing Opportunity',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_performance')] = row.location.id;
                    return formatters.calls(
                        row.existing_opportunities.total,
                        type,
                        null,
                        'insights_performance',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.calls(data, type, null, 'insights_performance');
                }
            },
            {
                title: 'Booked',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            classification: 'Existing Opportunity',
                            booking_status: 'Booked',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_performance')] = row.location.id;
                    return formatters.call_count(
                        row.existing_opportunities.booked,
                        row.existing_opportunities.total,
                        type,
                        'insights_performance',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(data, row.existing_opportunities.booked, row.existing_opportunities.total, type, 'insights_performance');
                }
            },
            {
                title: 'Not Connected',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            classification: 'Not Connected',
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_performance')] = row.location.id;
                    return formatters.call_count(
                        row.not_connected,
                        row.total,
                        type,
                        'insights_performance',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(data, row.not_connected, row.total, type, 'insights_performance');
                }
            },
            {
                title: 'Canceled',
                data: function ( row, type, val, meta ) {
                    return formatters.call_count(row.cancellation, row.total, type, 'insights_performance');
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(data, row.cancellation, row.total, type, 'insights_performance');
                }
            },
            {
                title: 'Total',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_performance')] = row.location.id;
                    return formatters.calls(
                        row.total,
                        type,
                        null,
                        'insights_performance',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.calls(data, type, null, 'insights_performance');
                }
            }],
            columnDefs:[
            {
                className: 'text-center sum-column',
                targets: [1,2,4,5,6,7,8],
            },
            {
                className: 'percent-column',
                targets: [2,4,5,6],
            }
            ],
            data: data,
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(
                    initInsightsPerformanceTable,
                    'insights_performance'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(
                    initInsightsPerformanceTable,
                    'insights_performance'
                ),
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {

                var api = this.api();

                $(this).find('thead').prepend('<tr>' +
                    '<th></th>' +
                    '<th colspan="3" class="text-center"> New Opportunities</th>' +
                    '<th colspan="2" class="text-center"> Existing Opportunities</th>' +
                    '<th colspan="3"></th>' +
                    '</tr>');

                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = {
                    2: self.report_data.new_opportunity.total,
                    4: self.report_data.existing_opportunity.total,
                    5: self.report_data.all.total,
                    6: self.report_data.all.total,
                };

                this.api().columns().every(function ()
                {
                    var column = this;
                    var data = '';

                    if ($(column.header()).hasClass('sum-column')) {
                        let sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        if ($(column.header()).hasClass('percent-column')) {
                            data = formatters.call_count(sum, totals[column.index()], 'display', 'insights_performance');
                        } else {
                            data = formatters.calls(sum, 'display', null, 'insights_performance');
                        }
                    }

                    if ($(column.header()).hasClass('average-days-column')) {
                        var days = column.data().filter(function(value) {
                            return value >= 0;
                        });

                        data = days.length ? days.reduce((a, b) => a + b, 0) / days.length + ' Days' : '-';
                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            },
        });
    }

    function initInsightsNotBookedTable()
    {
        self.$insights_not_booked_table = $('table#insights-not-booked');

        if (!self.$insights_not_booked_table.length)
            return;

        let formatters = self.formatters;
        let renderers = self.renderers;

        var columns = [];

        columns.push({
            title: 'Location',
            data: 'location.name'
        });

        _.each(typeof self.report_data.all.reason_not_booked !== 'undefined' ? self.report_data.all.reason_not_booked.total : [], function(metadata)
        {
            if (metadata.count == 0)
                return;

            columns.push({
                title: metadata.value,
                className: 'text-center sum-column',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            booking_status: 'Not Booked',
                            reason_not_booked: metadata.value,
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('insights_not_booked')] = row.location.id;
                    return formatters.call_count(
                        row.reason_not_booked[metadata.value],
                        _.sum(_.values(row.reason_not_booked)),
                        type,
                        'insights_not_booked',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(data, row.reason_not_booked[metadata.value], _.sum(_.values(row.reason_not_booked)), type, 'insights_not_booked');
                }
            });
        });

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_not_booked',
            self.locations,
            self.location_groups,
            self.report_data.all.reason_not_booked,
            function (data, location) {
                return {
                    reason_not_booked: data[location.id]
                };
            },
            function (data, location) {
                return {
                    reason_not_booked: data[location.id]
                };
            },
            self.report_data.all.user_fact_groups,
        );
        let data = data_converter.get_data('insights_not_booked');

        if ($.fn.DataTable.isDataTable(self.$insights_not_booked_table))
        {
            self.$insights_not_booked_table.DataTable().destroy();
            self.$insights_not_booked_table.empty();
        }

        self.$insights_not_booked_table.DataTable({
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            columns: columns,
            data: data,
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(
                    initInsightsNotBookedTable,
                    'insights_not_booked'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(
                    initInsightsNotBookedTable,
                    'insights_not_booked'
                ),
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {

                if (typeof self.report_data.all.reason_not_booked === 'undefined')
                    return;

                var api = this.api();
                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = _.sumBy(_.values(self.report_data.all.reason_not_booked.total), 'count');

                this.api().columns().every(function ()
                {
                    let column = this;
                    let data = '';

                    if ($(column.header()).hasClass('sum-column')) {
                        let sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        data = formatters.call_count(sum, totals, 'display', 'insights_not_booked');
                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            }
        });
    }

    function initInsightsNotConnectedTable()
    {
        self.$insights_not_connected_table = $('table#insights-not-connected');

        if (!self.$insights_not_connected_table.length)
            return;

        let formatters = self.formatters;
        let renderers = self.renderers;

        var columns = [];

        columns.push({
            title: 'Location',
            data: 'location.name'
        });

        _.each(typeof self.report_data.all.reason_not_connected !== 'undefined' ? self.report_data.all.reason_not_connected.total : [], function(metadata)
        {
            if (metadata.count == 0)
                return;

            columns.push({
                title: metadata.value,
                className: 'text-center sum-column',
                data: function ( row, type, val, meta ) {
                    let route_params = {
                        ignore_locations: 1,
                        metadata: {
                            classification: 'Not Connected',
                            reason_not_connected: metadata.value,
                        },
                        weighted: 1,
                    };
                    route_params[data_converter.get_first_index_key('reason_not_connected')] = row.location.id;

                    return formatters.call_count(
                        row.reason_not_connected[metadata.value],
                        _.sum(_.values(row.reason_not_connected)),
                        type,
                        'reason_not_connected',
                        {
                            route: 'calls.search',
                            route_params: route_params,
                        },
                    );
                },
                render: function (data, type, row, meta) {
                    return renderers.call_count(
                        data,row.reason_not_connected[metadata.value],
                        _.sum(_.values(row.reason_not_connected)),
                        type,
                        'reason_not_connected'
                    );
                }
            });
        });

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'reason_not_connected',
            self.locations,
            self.location_groups,
            self.report_data.all.reason_not_connected,
            function (data, location) {
                return {
                    reason_not_connected: data[location.id]
                };
            },
            function (data, location) {
                return {
                    reason_not_connected: data[location.id]
                };
            },
            self.report_data.all.user_fact_groups,
        );
        let data = data_converter.get_data('reason_not_connected');

        if ($.fn.DataTable.isDataTable(self.$insights_not_connected_table))
        {
            self.$insights_not_connected_table.DataTable().destroy();
            self.$insights_not_connected_table.empty();
        }

        self.$insights_not_connected_table.DataTable({
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            columns: columns,
            data: data,
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(
                    initInsightsNotConnectedTable,
                    'reason_not_connected'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(
                    initInsightsNotConnectedTable,
                    'reason_not_connected'
                ),
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            "sPaginationType": "simple",

            initComplete: function(row, data, start, end, display) {

                if (typeof self.report_data.all.reason_not_connected === 'undefined')
                    return;

                var api = this.api();
                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = _.sumBy(_.values(self.report_data.all.reason_not_connected.total), 'count');

                this.api().columns().every(function () {
                    let column = this;
                    let data = '';

                    if ($(column.header()).hasClass('sum-column')) {
                        let sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        data = formatters.call_count(sum, totals, 'display', 'reason_not_connected');
                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            }
        });
    }

    function initTooltips()
    {
        $('[data-toggle="tooltip"]', self.$table.DataTable().rows().nodes()).tooltip();
    }
})();
