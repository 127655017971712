(function()
{
    namespace("PatientPrism.Modules.Manage.Company.AddCard", init);

    function init(stripePK, companyId) {
    	let stripe = Stripe(stripePK);
        let elements = stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        let elementStyles = {
            base: {
                color: '#32325D',
                fontWeight: 500,
                fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
                fontSize: '16px',
                fontSmoothing: 'antialiased',

                '::placeholder': {
                    color: '#CFD7DF',
                },
                ':-webkit-autofill': {
                    color: '#e39f48',
                },
            },
            invalid: {
                color: '#E25950',

                '::placeholder': {
                    color: '#FFCCA5',
                },
            },
        };

        let elementClasses = {
            focus: 'focused',
            empty: 'empty',
            invalid: 'invalid',
        };

        let cardNumber = elements.create('cardNumber', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardNumber.mount('#payment-add-card-number');

        let cardExpiry = elements.create('cardExpiry', {
            style: elementStyles,
            classes: elementClasses,
        });

        cardExpiry.mount('#payment-add-card-expiry');

        let cardCvc = elements.create('cardCvc', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardCvc.mount('#payment-add-card-cvc');
    	
    	
    	
        $('#modal-add-company-payments').on('show.bs.modal', function() {
            $('#payment-add-cardholder').val('');
            $('#payment-add-city').val('');
            $('#payment-add-ress').val('');
            $('#payment-add-zip').val('');
            $('#payment-add-state').val('');
            $(this).find('form')[0].reset();
        });


        $('#btn-add-payment-add-method').click(function(e) {
            e.preventDefault();
            stripe.createSource(cardNumber, {
                owner: {
                    name: $('#payment-add-cardholder').val(),
                    address: {
                        city: $('#payment-add-city').val(),
                        line1: $('#payment-add-address').val(),
                        postal_code: $('#payment-add-zip').val(),
                        state: $('#payment-add-state').val()
                    }
                }
            }).then(function(result) {
                PatientPrism.API.Companies.paymentSourceAdd(companyId, result.source.id, PatientPrism.Modules.Manage.Company.PaymentMethods.api_callback);
            });
            return false;
        });
    }

})();