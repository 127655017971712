(function()
{
    namespace("PatientPrism.Admin.Reports.Customers.Success", init);

    function init(data)
    {
        initDataTables();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initDataTables()
    {
    	var table = $('#success-report');
    	var datatable = table.DataTable({
            'scrollX': true,
            fixedColumns:   {
                leftColumns: 2
            },
            'order': [
                [1, 'asc']
            ],
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            bStateSave: true,
            buttons: [
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            "sPaginationType": "simple"
        });

    	let intVal = function ( i ) {
            if (typeof i === 'string')
            {
                var value = i.replace(/[\$,]/g, '').match(/\d+/);

                return value ? parseInt(value[0]) : 0;
            }

            if (typeof i === 'object')
            {
                if (typeof i['@data-order'] !== 'undefined')
                {
                    return parseInt(i['@data-order']);
                }
            }

            return typeof i === 'number' ? i : 0;
        };

        $('tbody', table).on( 'click', 'tr', function () {
            $(this).toggleClass('selected');
            let length = datatable.columns()[0].length;
            let carry = new Array(length).fill(0,0,length);
            let selectedRows = datatable.rows('.selected');
            let result = selectedRows.data().reduce(function (carry, item) {
            	for (let i = 2; i < length; ++i) {
            		carry[i] += intVal(item[i]);
            	}
            	return carry;
            }, carry);

            for (let i = 2; i < length; ++i) {
        		let column = $(datatable.columns(i).footer());
        		let data = result[i];

        		if (column.data('money')) {
        			data = "$" + result[i].toLocaleString('us', {minimumFractionDigits: 0});
        		} else if (column.data('average')) {

        			data = (data / selectedRows[0].length).toFixed(2);
        		} else if (column.data('booked-percentage')) {
        		    // count all booked new opportunities
                    let totalBooked = $('tr.selected td[data-booked]').toArray().reduce(function (carry, item) {
                        carry += $(item).data('booked');

                        return carry;
                    }, 0);

                    // get total number of new opportunities from previous column
                    let totalNewOpportunities = result[i - 1];

                    data = ((totalBooked / totalNewOpportunities) * 100).toFixed(2);
                }
        		column.html(isNaN(data) ? 0 : data);
        	}
        });
     }
})();