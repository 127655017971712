(function()
{
    namespace("PatientPrism.Admin.Reports.Staff.Efficiency", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init(data)
    {
        self.report_data = JSON.parse(data);

        initDataTables();
        initHeatMap();
    }

    function initHeatMap()
    {
        var $chart = $('#efficiency-chart');

        $chart = new Highcharts.Chart({
            chart: {
                type: 'heatmap',
                renderTo: 'efficiency-chart'
            },
            loading: {
                labelStyle: {
                    fontStyle: 'bold',
                    color: '#c8f0ff'
                },
                style: {
                    position: 'absolute',
                    backgroundColor: '#243140',
                    opacity: 1,
                    textAlign: 'center'
                }
            },
            title: {
                text: 'Efficiency Heatmap'
            },
            subtitle: {
                text: 'Values are CEM Percentages'
            },
            xAxis: {
                categories: [
                    'New Opportunity - Booked',
                    'New Opportunity - Not Booked',
                    'Existing Opportunity - Booked',
                    'Existing Opportunity - Not Booked',
                    'Not Connected',
                    'General'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Coaches',
                },
                labels: {
                    overflow: 'justify'
                }
            },
            colorAxis: {
                min: 0,
                max: 100,
                stops: [
                    [0, '#00B796'],
                    [0.5, '#fffbbc'],
                    [1.5, '#FE696E']
                ],
                shadow: false,
                gridLineColor: '#25323f',
                labels: {
                    style: {
                        color: '#FFF'
                    }
                },
                marker: {
                    color: '#FFF'
                }
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.series.yAxis.categories[this.point.y] + '</b><br/>' +
                    '<b>CEM:</b> ' + this.point.value  + '%'+
                    '<br><b>Classification: </b>' + this.series.xAxis.categories[this.point.x] ;
                }
            },
            series: [{
                name: 'Calls By Hour',
                borderWidth: 1,
                borderColor: '#25323f',
                shadow: false,
                dataLabels: {
                    enabled: true,
                    color: '#FFF',
                    shadow: false
                }
            }]
        });

        $chart.showLoading('Analyzing Data')

        var coach_names = [];
        var series_data = [];

        _.each(self.report_data.new_opportunity.booked, function(metric, index)
        {
            if (!_.includes(coach_names, metric.reviewed_by.name))
                coach_names.push(metric.reviewed_by.name);

            var cem = metric.recording_duration > 0 ? (metric.review_duration / metric.recording_duration) * 100 : null;

            series_data.push([0 , coach_names.indexOf(metric.reviewed_by.name), Math.round(cem)]);
        });

        _.each(self.report_data.new_opportunity.not_booked, function(metric, index)
        {
            if (!_.includes(coach_names, metric.reviewed_by.name))
                coach_names.push(metric.reviewed_by.name);

            var cem = metric.recording_duration > 0 ? (metric.review_duration / metric.recording_duration) * 100 : null;

            series_data.push([1 , coach_names.indexOf(metric.reviewed_by.name), Math.round(cem)]);
        });

        _.each(self.report_data.existing_opportunity.booked, function(metric, index)
        {
            if (!_.includes(coach_names, metric.reviewed_by.name))
                coach_names.push(metric.reviewed_by.name);

            var cem = metric.recording_duration > 0 ? (metric.review_duration / metric.recording_duration) * 100 : null;

            series_data.push([2 , coach_names.indexOf(metric.reviewed_by.name), Math.round(cem)]);
        });

        _.each(self.report_data.existing_opportunity.not_booked, function(metric, index)
        {
            if (!_.includes(coach_names, metric.reviewed_by.name))
                coach_names.push(metric.reviewed_by.name);

            var cem = metric.recording_duration > 0 ? (metric.review_duration / metric.recording_duration) * 100 : null;

            series_data.push([3 , coach_names.indexOf(metric.reviewed_by.name), Math.round(cem)]);
        });

        _.each(self.report_data.not_connected, function(metric, index)
        {
            if (!_.includes(coach_names, metric.reviewed_by.name))
                coach_names.push(metric.reviewed_by.name);

            var cem = metric.recording_duration > 0 ? (metric.review_duration / metric.recording_duration) * 100 : null;

            series_data.push([4 , coach_names.indexOf(metric.reviewed_by.name), Math.round(cem)]);
        });

        _.each(self.report_data.general, function(metric, index)
        {
            if (!_.includes(coach_names, metric.reviewed_by.name))
                coach_names.push(metric.reviewed_by.name);

            var cem = metric.recording_duration > 0 ? (metric.review_duration / metric.recording_duration) * 100 : null;

            series_data.push([5 , coach_names.indexOf(metric.reviewed_by.name), Math.round(cem)]);
        });

        $chart.yAxis[0].setCategories(coach_names);
        $chart.series[0].setData(series_data);

        $chart.series[0].data.length ? $chart.hideLoading() : $chart.showLoading('No Data');
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initDataTables()
    {
        $('table').DataTable({
            'order': [
                [4, 'desc']
            ],
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            bStateSave: true,
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            "sPaginationType": "simple"
        });
    }
})();