(function() {
    namespace("PatientPrism.Modules.Analize.Helpers.Renderers", call_count, calls);

    let look_type_helper = PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType;

    function call_count(data, value, total, type, report_table)
    {
        if (type === 'sort') {
            let first_index = look_type_helper.get_first_index(report_table);

            let percent = 0;
            if (total) {
                percent = Math.round((value / total) * 100);
            }

            let values = [
                value,
                percent
            ];
            return values[first_index];
        }
        return data;
    }

    function calls(data, value, type, second_line, report_table)
    {
        if (type === 'sort') {
            let first_index = look_type_helper.get_first_index(report_table);

            if (typeof second_line === 'undefined') {
                return value;
            }

            let values = [
                value,
                second_line
            ];
            return parseFloat(values[first_index]);
        }

        return data;
    }
})();
