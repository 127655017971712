(function()
{
    namespace("PatientPrism.API.LocationGroups", create, update, destroy, add_location);

    var self = {
        //
    }

    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/location-groups', callback, body)
    }

    function update(location_group_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/location-groups/' + location_group_id , callback, body)
    }

    function destroy(location_group_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/location-groups' + (location_group_id ? '/' + location_group_id : ''), callback, body)
    }

    function add_location(location_group_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/location-groups/' + location_group_id + '/locations' , callback, body)
    }
})();