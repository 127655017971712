(function()
{
    namespace("PatientPrism.Admin.Entities.Companies", init);

    var self = {
        current_row: null
    };

    function init()
    {
        bindUIElements();

        $('#datatable-companies').DataTable({
            'order': [
                [0, 'asc']
            ],
            "columnDefs": [
            {
                "targets": 0,
                "orderable": false
            }],
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            buttons: [
                {
                  extend: "csv",
                  text: '<i class="fa fa-file-text-o"></i> Export CSV',
                  filename: "search-results",
                  className: "btn-sm action-button dt-button",
                  exportOptions: {
                    orthogonal: "export",
                    columns: [":visible :not(.no-export)"],
                  },
                }],
            "sPaginationType": "simple"
        });
    }

    function bindUIElements()
    {
        $('.update_company').on("click", function(e)
        {
            var btn = $(this);

            e.preventDefault();

            swal({
                    title: "Are you sure?",
                    text: "This will " + (btn.data('disabled') ? "restore" : "delete") + " company.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, I'm Sure",
                    cancelButtonText: "No, Cancel",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function(isConfirm)
                {
                    if (isConfirm)
                    {
                        var current_row = btn.closest('tr'),
                            callback = function (data, textStatus, statusCode) {
                                var disabled,
                                    companyName = current_row.find('.link-company-display-name').text(),
                                    resultText;

                                if (textStatus === 'success')
                                {
                                    disabled = !!data.data['deleted_at'];

                                    btn.html(disabled ? 'Enable' : 'Disable');
                                    btn.closest("td").siblings(".company-active").text(disabled ? 'No' : 'Yes');
                                    btn.data('disabled', disabled);

                                    current_row.removeClass('danger').addClass('success');
                                    current_row.find('.btn-group').remove();

                                    resultText = disabled ? 'deleted' : 'restored';

                                    swal("Success", companyName + ' has been ' + resultText + '.', "success");
                                }
                                else if (textStatus === 'error')
                                {
                                    current_row.addClass('danger');

                                    resultText = btn.data('disabled') ? "restored" : "deleted";

                                    swal("Cancelled", companyName + ' has not been ' + resultText + ': ' + data.error.message, "error");
                                }
                            };

                        PatientPrism.API.Companies.update(btn.data('id'), callback, {
                            action: btn.data('disabled') ? "restore" : "revoke",
                            company_id: btn.data('id')
                        });
                    }
                });
        });
    }
})();