(function()
{
    namespace("PatientPrism.Modules.Manage.Locations.GoogleCredentials", init, initSelectOptions);

    var self = {
        location_id: null,
        $select_account: null,
        $select_property: null,
        $select_profile: null,
        $btn_save_settings: null,
        account_id: null,
        property_id: null,
        profile_id: null
    };

    function init()
    {
        bindSelectableOptions();
    }

    function initSelectOptions(location_id)
    {
        self.location_id = location_id;

        self.$select_profile = $('#select-profile');

        var callback = function (data, textstatus, statuscode) {

            _.each(data, function(account)
            {
                $('select', self.$select_account).append('<option value="' + account.id + '">' + account.name + '</option>')
            });

            $(self.$select_account).fadeIn();
        }

        PatientPrismAPI.list_google_accounts(self.location_id, callback);
    }

    function bindSelectableOptions()
    {
        self.$select_account = $('#select-account');
        self.$select_property = $('#select-property');
        self.$select_profile = $('#select-profile');
        self.$btn_save_settings = $('#btn-save-settings');

        $('select', self.$select_account).change(function(e)
        {
            self.account_id = $(this).val();

            doSelectAccount();
        });

        $('select', self.$select_property).change(function(e)
        {
            self.property_id = $(this).val();

            doSelectProperty();
        });

        $('select', self.$select_profile).change(function(e)
        {
            self.profile_id = $(this).val();

            doSelectProfile();
        });
    }

    function doSelectAccount()
    {
        self.$btn_save_settings.hide();
        self.$select_property.fadeOut('fast', function(e)
        {
            $('option', this).not('option[data-default="true"]').remove();
        });

        self.$select_profile.fadeOut('fast', function(e)
        {
            $('option', this).not('option[data-default="true"]').remove();
        });

        if (self.account_id != 'null')
        {
            var callback = function (data, textstatus, statuscode) {
                _.each(data, function(property)
                {
                    $('select', self.$select_property).append('<option value="' + property.id + '">' + property.name + '</option>')
                });

                $(self.$select_property).fadeIn();
            }

            params = {
                account_id: self.account_id
            };

            PatientPrismAPI.list_google_properties(self.location_id, callback, params);
        }
    }

    function doSelectProperty()
    {
        self.$btn_save_settings.hide();
        self.$select_profile.fadeOut('fast', function(e)
        {
            $('option', this).not('option[data-default="true"]').remove();
        });

        if (self.property_id != 'null')
        {
            var callback = function (data, textstatus, statuscode) {
                _.each(data, function(profile)
                {
                    $('select', self.$select_profile).append('<option value="' + profile.id + '">' + profile.name + '</option>')
                });

                $(self.$select_profile).fadeIn();
            }

            params = {
                account_id: self.account_id,
                property_id: self.property_id
            };

            PatientPrismAPI.list_google_profiles(self.location_id, callback, params);
        }
    }

    function doSelectProfile()
    {
        if (self.profile_id == 'null')
        {
            self.$btn_save_settings.hide();
            return;
        }

        self.$btn_save_settings.show();
    }
})();