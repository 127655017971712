(function() {
    namespace("PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType", button, get_first_index, get_second_index);

    let reports_data = [];

    function button(callback, report_table)
    {
        let buttons = [
            {
                text: 'Aggregate',
                attr: {
                    name: 'sub-aggr'
                },
                action: function ( e, dt, node, config ) {
                    new Promise(function (resolve, reject) {
                        $(document).trigger('enterprise_insights_look_type_changed', {
                            index: 0,
                            report_table: report_table
                        });
                        resolve(true);
                    })
                        .then(function (data) {
                            if (true === data) {
                                callback();
                            }
                        });
                }
            },
            {
                text: 'Percentage',
                attr: {
                    name: 'sub-perc'
                },
                action: function ( e, dt, node, config ) {
                    new Promise(function (resolve, reject) {
                        $(document).trigger('enterprise_insights_look_type_changed', {
                            index: 1,
                            report_table: report_table
                        });
                        resolve(true);
                    })
                        .then(function (data) {
                            if (true === data) {
                                callback();
                            }
                        });
                }
            },
        ];

        let filter_index = get_first_index(report_table);

        return {
            extend:    'collection',
            attr: {
                name: 'main'
            },
            text:      `<i class="fa fa-hashtag"></i> ${buttons[filter_index].text} <span class="caret"></span>`,
            className: 'btn-sm action-button dt-button enterprise-button',
            buttons: buttons,
        };
    }

    function init(report_table)
    {
        if (!reports_data.hasOwnProperty(report_table)) {
            reports_data[report_table] = {
                first_index: 0,
                second_index: 1,
            };
        }
    }

    function get_first_index(report_table)
    {
        init(report_table);
        return reports_data[report_table].first_index;
    }

    function get_second_index(report_table)
    {
        init(report_table);
        return reports_data[report_table].second_index;
    }

    $(document).on('enterprise_insights_look_type_changed', function (e, data) {
        init(data.report_table);
        let self = reports_data[data.report_table];
        self.first_index = parseInt(data.index);
        self.second_index = Math.abs(self.first_index - 1);
    });
})();
