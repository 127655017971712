(function()
{
    namespace("PatientPrism.API.Companies", update, paymentSourceAdd, paymentSourceDelete, paymentSourceDefault, paymentSourceUpdate);

    function update(company_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/companies/' + company_id, callback, body)
    }
    
    function paymentSourceAdd(company_id, source_id, callback)
    {
        PatientPrism.API.HTTP.Socket.post('/companies/' + company_id + '/payment-sources/' + source_id, callback)
    }
    
    function paymentSourceUpdate(company_id, source_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/companies/' + company_id + '/payment-sources/' + source_id, callback, body)
    }
    
    function paymentSourceDelete(company_id, source_id, callback)
    {
        PatientPrism.API.HTTP.Socket.destroy('/companies/' + company_id + '/payment-sources/' + source_id, callback)
    }
    
    function paymentSourceDefault(company_id, source_id, callback)
    {
        PatientPrism.API.HTTP.Socket.patch('/companies/' + company_id + '/payment-sources/' + source_id + '/default', callback)
    }
})();