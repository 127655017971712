(function()
{
    namespace("PatientPrism.Vendor.Clipboard", initClipboards);

    var self = {
        clipboard_els: null
    };

    function initClipboards()
    {
        self.clipboard_els = new Clipboard('[data-clipboard-target]');

        self.clipboard_els.on('success', function(e) {
            e.clearSelection();

            $(e.trigger).tooltip({
                placement : 'top',
                title: 'Copied!',
                trigger: 'manual'
            })
            .tooltip('show');

            setTimeout(function(){$(e.trigger).tooltip('hide')}, 1500);
        });

        self.clipboard_els.on('error', function(e) {
            $(e.trigger).tooltip({
                placement : 'top',
                title: 'Error Copying!',
                trigger: 'manual'
            })
            .tooltip('show');

            setTimeout(function(){$(e.trigger).tooltip('hide')}, 1500);
        });
    }
})();