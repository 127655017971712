(function () {
  namespace("PatientPrism.Admin.Billing.Index", init);

  var self = {
    company_usage_datatable: null,
    billing_summary: null,
    location_usage_datatable: null,
  };

  function init(billing_summary) {
    self.billing_summary = JSON.parse(billing_summary);

    bindUIElements();

    buildCompanySide();

    self.location_usage_datatable = $("#location-usage-metrics").DataTable({
      order: [[3, "desc"]],
      columnDefs: [],
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      oLanguage: {
        sLengthMenu: "_MENU_",
        sInfo: "Showing _START_ to _END_ of _TOTAL_ Usage Metrics",
        sInfoEmpty: "",
        sInfoFiltered: " - filtered from _MAX_ Total Usage Metrics",
        sEmptyTable: "No Usage Metrics Available",
        searchPlaceholder: "Search",
        sSearch: "",
      },
      buttons: [
        {
          extend: "csv",
          text: '<i class="fa fa-file-text-o"></i> Export CSV',
          filename: "search-results",
          className: "btn-sm action-button dt-button",
          exportOptions: {
            orthogonal: "export",
            columns: [":visible :not(.no-export)"],
          },
        },
      ],
      sPaginationType: "simple",
      data: self.billing_summary.locations,
      columns: [
        {
          className: "details-control",
          orderable: false,
          data: null,
          defaultContent: '<span class="fa fa-list-ul text-link"></span>',
        },
        { data: "location.name" },
        { data: "location.company.name" },
        {
          data: "calls_inbound",
        },
        {
          data: "minutes_inbound",
        },
        {
          data: "price",
          render: function (data, type, row) {
            return "$ " + data;
          },
        },
      ],
    });

    // Add event listener for opening and closing details
    $("#location-usage-metrics tbody").on(
      "click",
      "td.details-control",
      function () {
        renderNestedTable($(this), self.location_usage_datatable);
      }
    );
  }

  function bindUIElements() {}

  function initTooltips(table) {
    $('[data-toggle="tooltip"]', table.DataTable().rows().nodes()).tooltip();
  }

  function buildCompanySide() {
    self.company_usage_datatable = $("#company-usage-metrics").DataTable({
      order: [[2, "desc"]],
      data: self.billing_summary.companies,
      columnDefs: [],
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      oLanguage: {
        sLengthMenu: "_MENU_",
        sInfo: "Showing _START_ to _END_ of _TOTAL_ Usage Metrics",
        sInfoEmpty: "",
        sInfoFiltered: " - filtered from _MAX_ Total Usage Metrics",
        sEmptyTable: "No Usage Metrics Available",
        searchPlaceholder: "Search",
        sSearch: "",
      },
      buttons: [
        {
          extend: "csv",
          text: '<i class="fa fa-file-text-o"></i> Export CSV',
          filename: "search-results",
          className: "btn-sm action-button dt-button",
          exportOptions: {
            orthogonal: "export",
            columns: [":visible :not(.no-export)"],
          },
        },
      ],
      sPaginationType: "simple",
      columns: [
        {
          className: "details-control",
          orderable: false,
          data: null,
          defaultContent: '<span class="fa fa-list-ul text-link"></span>',
        },
        { data: "company.name" },
        {
          data: "calls_inbound",
        },
        {
          data: "minutes_inbound",
        },
        {
          data: "price",
          render: function (data) {
            return "$ " + data;
          },
        },
      ],
    });

    // Add event listener for opening and closing details
    $("#company-usage-metrics tbody").on(
      "click",
      "td.details-control",
      function () {
        renderNestedTable($(this), self.company_usage_datatable);
      }
    );
  }

  /* Formatting function for row details - modify as you need */
  function format(d, table_id) {
    let html =
      '<table id="' +
      table_id +
      '" class="table table-bordered">' +
      "<thead>" +
      "<th>Category</th>" +
      "<th>Count</th>" +
      "<th>Usage</th>" +
      "<th>Price</th>" +
      "</thead>";
    ("<tbody>");

    _.each(d.billing_categories, function (category) {
      html =
        html +
        "<tr>" +
        "<td>" +
        category.billing_category.category +
        '<span class="fa fa-info-circle text-link" data-toggle="tooltip" data-placement="top" title="' +
        category.billing_category.description +
        '"></span>' +
        "</td>" +
        "<td>" +
        category.count +
        " " +
        (category.billing_category.count_unit == null
          ? ""
          : category.billing_category.count_unit) +
        "</td>" +
        "<td>" +
        category.usage +
        " " +
        (category.billing_category.usage_unit == null
          ? ""
          : category.billing_category.usage_unit) +
        "</td>" +
        "<td>$ " +
        category.price +
        "</td>" +
        "</tr>";
    });

    html = html + "</tbody></table>";

    return html;
  }

  function renderNestedTable(element, table) {
    let tr = element.closest("tr");
    let row = table.row(tr);

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
      tr.removeClass("shown");
    } else {
      let table_id = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "");

      // Open this row
      row.child(format(row.data(), table_id)).show();

      $("#" + table_id).DataTable({
        order: [[3, "desc"]],
        dom:
          "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        columnDefs: [],
        oLanguage: {
          sLengthMenu: "_MENU_",
          sInfo: "Showing _START_ to _END_ of _TOTAL_ Usage Metrics",
          sInfoEmpty: "",
          sInfoFiltered: " - filtered from _MAX_ Total Usage Metrics",
          sEmptyTable: "No Usage Metrics Available",
          searchPlaceholder: "Search",
          sSearch: "",
        },
        sPaginationType: "simple",
      });

      initTooltips($("#" + table_id));

      tr.addClass("shown");
    }
  }
})();
