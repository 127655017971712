(function()
{
    namespace("PatientPrism.Modules.Settings.SelectLocation", init);

    var self = {
        data: {
            location_groups: null,
            locations: null
        },
        el: {
            $container: null,
            $form:      null,
            form:       {
                $locations: null,
                $submit:    null
            }
        }
    }

    function init()
    {
        self.el.$container = $('#settings-chooser');
        self.el.$form      = $('form#location-form', self.el.$container);

        self.el.form.$locations = $('#select-location', self.el.$form);
        self.el.form.$submit    = $('.btn-submit', self.el.$form);

        loadData();
        initForm();
        initLocationSelect();
    }

    function loadData()
    {
        self.data.location_groups = PatientPrism.Common.Session.get('available')['location-groups'];
        self.data.locations       = PatientPrism.Common.Session.get('available')['locations'];
    }

    function initLocationSelect()
    {
        self.el.form.$locations.selectize({
            preload: false,
            placeholder: 'Search Locations and Groups',
            searchField: [
                'name',
                'description'
            ],
            valueField: 'selectize_id',
            delimiter: ',',
            openOnFocus: true,
            selectOnTab: true,
            optgroups: [
                { value: 'location-group', label: 'Groups' },
                { value: 'location', label: 'Locations' },
            ],
            optgroupField: 'type',
            optgroupOrder: [
                'location-group',
                'locaiton'
            ],
            onInitialize: function ()
            {
                $(this.$dropdown).css('display', 'block');
                $(this.$dropdown).css('position', 'initial');
                $(this.$dropdown).css('margin', '-5px 0 10px 0');
                $(this.$dropdown).css('text-align', 'left');
                $(this.$dropdown).css('font-size', '1.2em');
                $(this.$dropdown).css('line-height', '1.5em');
            },
            onDropdownClose: function (dropdown) {
                $(dropdown).css('display', 'block');
            },
            onChange: function (value) {
                // Count unselected items and hide dropdown if no items remain, otherwise show
                Object.keys(this.options).length - this.items.length ? $(this.$dropdown).css('display', 'block') : $(this.$dropdown).css('display', 'none');

                self.el.form.$submit.attr('disabled', this.items.length ? false : true);
            },
            render: {
                // Search result group titles
                optgroup_header: function(data, escape)
                {
                    return '<div class="optgroup-header">' + escape(data.label) + '</div>';
                },

                // Search result single object
                option: function(data, escape)
                {
                    return '<div>' +
                                '<span class="title">' +
                                    '<span class="name">' + data.name + '</span>' +
                                '</span>' +
                            '</div>';
                },

                // Input control single object
                item: function(data, escape)
                {
                    return '<div data-type="' + escape(data.type) + '" class="item recipient">' + data.name + '</div>';
                }
            }
        });

        // Add location group options
        self.el.form.$locations[0].selectize.addOption($.map(self.data.location_groups, function (item)
        {
            item.type = 'location-group';
            item.selectize_id = Math.random().toString(36).replace(/[^a-z]+/g, '');

            return item;
        }));

        // Add location options
        self.el.form.$locations[0].selectize.addOption($.map(self.data.locations, function (item)
        {
            item.type = 'location';
            item.selectize_id = Math.random().toString(36).replace(/[^a-z]+/g, '');

            return item;
        }));

        // Enable control
        self.el.form.$locations[0].selectize.enable();

        self.el.form.$locations[0].selectize.open();
    }

    function initForm()
    {
        self.el.form.$submit.click(function (e)
        {
            e.preventDefault();

            $(this).attr('disabled', true);

            $.each(self.el.form.$locations[0].selectize.items, function (index, value)
            {
                switch(self.el.form.$locations[0].selectize.options[value].type)
                {
                    case 'location':
                        self.el.$form.append('<input type="hidden" name="locations[]" value=' + self.el.form.$locations[0].selectize.options[value].id + ' />');
                        break;

                    case 'location-group':
                        self.el.$form.append('<input type="hidden" name="location-groups[]" value=' + self.el.form.$locations[0].selectize.options[value].id + ' />');
                        break;
                }
            });

            self.el.$form.submit();
        });
    }
})();