//should be out of document ready
$(window).on('hashchange', function () {
	PatientPrism.Modules.Manage.Users.Dashboard.hashChange();
});

(function()
{
    namespace("PatientPrism.Modules.Manage.Users.Dashboard", init, hashChange, initEnablingUser);

    var self = {
        modal: {
            $add_user: null
        }
    };

    function hashChange()
    {
        var hash = window.location.hash;
        //set default hash
        if (!hash) {
        	hash = '#not-deleted';
        }
        //search by hashtag
        $('#datatable-users').DataTable().column(6).search(hash, false, false, false).draw();
    }

    function initEnablingUser($users_table) {
        $users_table.on('click', '.delete-link', function (e) {
            var that = $(this);
            var disabled = that.text() == 'Enable';
            var method = disabled ? 'PATCH' : 'GET';
            var form = disabled ? $("#datatable-users-restore-form") : $("#datatable-users-delete-form");
            var url = form.attr('action').replace("!--id--!", that.data('id'));
            swal({
                    title: "Are you sure?",
                    text: "This user will be " + (disabled ? "restored." : "disabled.") + "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, I'm Sure",
                    cancelButtonText: "No, Cancel",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var current_row = that.closest('tr');
                        var name = current_row.find('.td:first').text();
                        $.ajax({
                            url: url,
                            data: form.serialize(),
                            method: method
                        }).done(function () {
                            disabled = !disabled;
                            var text = 'Disable';
                            var resultText = "restored";
                            var resultHash = "#not-deleted";
                            if (disabled) {
                                resultText = "disabled";
                                resultHash = "#deleted";
                                text = 'Enable';
                            }
                            that.html(text);
                            current_row.removeClass('danger').addClass('success');
                            var cell = that.parent();
                            //renew hashtags for datatable search
                            cell.attr('data-search', "#not-deleted " + resultHash);
                            //invalidate cell to reparse cell attributes from the DOM to Datatable
                            $('#datatable-users').DataTable().cell(cell).invalidate();
                            swal({
                                title: "Success",
                                text: 'The user has been ' + resultText + '.',
                                type: "success"
                            }, function () {
                                //if successs search again in one second, to show output according to search criteria
                                setTimeout(hashChange, 1000);
                            });
                        }).fail(function () {
                            current_row.addClass('danger');
                            resultText = disabled ? "restored" : "disabled";
                            swal("Cancelled", name + ' has not been ' + resultText + ': ' + data.message, "error");
                        });
                    }
                });
            return false;
        });
    }

    function init(role_index, title_index)
    {
        self.modal.$add_user = $('#add-user.modal');

        $('#datatable-users').DataTable().destroy();

        $('#datatable-users').DataTable({
                responsive: true,
                deferRender: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                bStateSave: true,
                buttons: [
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'search-results',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:    'collection',
                    text:      '<i class="fa fa-user"></i> User Type <span class="caret"></span>',
                    className: 'btn-sm action-button dt-button',
                    autoClose: true,
                    buttons:   [
                        {
                            text: 'Active Users',
                            action: function ( e, dt, node, config ) {
                                window.location.hash = 'not-deleted';
                            }
                        },
                        {
                            text: 'Inactive Users',
                            action: function ( e, dt, node, config ) {
                                window.location.hash = 'deleted';
                            }
                        }
                    ]
                }],
                language: {
                    "sLengthMenu": "Show _MENU_ users per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Users",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total users",
                    "sEmptyTable": "No Users Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                initComplete: function(settings, json) {
                   //
                }
            });

        hashChange();

        $('#select-locations', self.modal.$add_user).selectize({
            mode: "multi"
        });

        $('#select-languages', self.modal.$add_user).selectize({
            mode: "multi"
        });

        $('#select-roles', self.modal.$add_user).selectize({
            valueField: 'name',
            labelField: 'display_name',
            searchField: ['display_name', 'description'],
            options: JSON.parse(role_index),
            render: {
                option: function(item, escape) {
                    var label = item.display_name;
                    var caption = item.description;
                    return '<div>' +
                        '<span class="label">' + escape(label) + '</span>' +
                        (caption ? '<span class="caption">' + escape(caption) + '</span>' : '') +
                    '</div>';
                }
            }
        });

        $('#select-titles', self.modal.$add_user).selectize({
            valueField: 'id',
            labelField: 'name',
            searchField: ['name', 'description'],
            options: JSON.parse(title_index),
            maxItems: 1,
            render: {
                option: function(item, escape) {
                    var label = item.name;
                    var caption = item.description;
                    return '<div>' +
                        '<span class="label">' + escape(label) + '</span>' +
                        (caption ? '<span class="caption">' + escape(caption) + '</span>' : '') +
                    '</div>';
                }
            }
        });

        //click on enable/disable link under the datatable
        initEnablingUser($('#datatable-users'));

        //add new user
        $('#form-add-user').submit(function(e) {
        	e.preventDefault();
        	var that = $(this);
        	$.post(that.attr('action'),that.serialize()).done(function() {
        		location.href = that.find('[name=redirect-success]').val();
        	}).fail(function(data) {
        		var response = data.responseJSON;
        		var message = response.message;
        		//user exists in the company but is deleted
        		if (data.status == 422) {
        			//id of the user
        			var id = response.id;
        			swal({
        	            title: message,
        	            text: "Do you want to enable existing user?",
        	            type: "warning",
        	            showCancelButton: true,
        	            confirmButtonColor: "#DD6B55",
        	            confirmButtonText: "Yes, I'm Sure",
        	            cancelButtonText: "No, Cancel",
        	            showLoaderOnConfirm: true,
        	            closeOnConfirm: false,
        	            closeOnCancel: true
        	        },
        	        function(isConfirm)
        	        {
        	            if (isConfirm)
        	            {
        	            	var form = $("#datatable-users-restore-form");
        	            	var url = form.attr('action').replace("!--id--!",id);
        	            	//restore user request
            	            $.ajax({
        	                      url: url,
        	                      data: form.serialize(),
        	                      method: "PATCH"
        	                }).done(function() {
        	                	//if success redirect to user profile to further edit
        	                	location.href = that.find('[name=redirect-profile]').val().replace("!--id--!",id);
        	                });
        	            }
        	        });
        			return;
        		}
        		//some other error occured
        		swal("Error", message, "error");
        	});
        	return false;
        });
    }
})();
