(function()
{
    namespace("PatientPrism.Admin.Telephony.AvailablePhoneNumbers", init);

    var self = {
        companies: null,
        current_row: null
    };

    function init(companies)
    {
        self.companies = companies;

        bindUIElements();

        $('#available-phone-numbers').DataTable({
            'order': [
                [2, 'asc']
            ],
            "columnDefs": [
            {
                "targets": 0,
                "orderable": false
            }],
            "oLanguage": {
                "sLengthMenu": "Show _MENU_ phone numbers per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Phone Numbers",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ Total Phone Numbers",
                "sEmptyTable": "No Phone Numbers Available"
            },
            "sPaginationType": "simple"
        });
    }

    function bindUIElements()
    {
        $('#btn-assign-number').click( function(e)
        {
            e.preventDefault();

            PatientPrism.Common.UI.Loader.show();

            var callback = function (data, textStatus, jqXHR)
            {
                PatientPrism.Common.UI.Loader.hide();
                $('#modal-add-available-phone-number').modal('hide');

                if (textStatus === 'success')
                {

                    current_row.removeClass('danger').addClass('success');
                    current_row.find('.btn-group').remove();
                }
                else if (textStatus === 'error')
                {

                    current_row.addClass('danger');
                }
            }

            var body = {
                location_id:    $('#select-location').val(),
                sid:            $('#add-tracking-sid').val(),
                name:           $('#add-tracking-number-display-name').val(),
                singleton:      $('#add-tracking-singleton').val(),
                rings_to:       $('#add-tracking-number-rings-to').val(),
                phone_number:   $('#add-tracking-phone-number').val()
            };

            PatientPrism.API.PhoneNumbers.create(callback, body);
        });

        $('.assign-phone-number').click(function(e)
        {
            current_row = $(this).closest('tr');

            var self = $(this);

            $('#number-friendly-name').text(self.attr('data-friendly-name'));
            $('#number-phone-number').text(self.attr('data-phone-number'));
            $('#add-tracking-phone-number').val(self.attr('data-phone-number'));
            $('#add-tracking-sid').val(self.attr('data-sid'));

            $('#modal-add-available-phone-number').modal('show');
        });

        $('#select-company').change(function(e)
        {
            var company_id = $(this).val();

            $('#select-location')
            .empty()
            .append('<option selected="selected">Select a Location</option>')
            .attr('disabled', true);

            $('#btn-assign-number').attr('disabled', 'disabled');

            if (company_id)
            {
                _.each(self.companies, function(company)
                {
                    if (company.id == company_id)
                    {
                        _.each(company.locations, function(location)
                        {
                            $('#select-location').append('<option value="' + location.id + '">' + location.name + '</option>').attr('disabled', false);
                            $('#btn-assign-number').attr('disabled', false);
                        });
                    }
                });
            }
        });

        $('#select-telephony-company').change(function(e)
        {
            var company_id = $(this).val();

            $('#select-telephony-location')
            .empty()
            .append('<option selected="selected">Select a Location</option>')
            .attr('disabled', true);

            $('#btn-assign-number').attr('disabled', 'disabled');

            if (company_id)
            {
                _.each(self.companies, function(company)
                {
                    if (company.id == company_id)
                    {
                        _.each(company.locations, function(location)
                        {
                            var selected = '';

                            if (location.id == $('#select-telephony-location').attr('data-selected-id'))
                                selected = 'selected';

                            $('#select-telephony-location').append('<option value="' + location.id + '"' + selected + '>' + location.name + '</option>').attr('disabled', false);
                        });
                    }
                });
            }
        });

        if ($('#select-telephony-company').val() != '')
            $('#select-telephony-company').trigger('change');

        $('#select-telephony-location').change(function(e)
        {
            var url = window.location.href.split('?')[0]+"?company_id="+$('#select-telephony-company').val()+"&location_id="+$(this).val();
            document.location = url;
        })
    }
})();