(function()
{
    namespace("PatientPrism.Vendor.DateTimePicker", linkPickers, initPicker, initMonthPicker, initFullPicker);

    function initPicker( $dp )
    {
        if (!$dp.length)
        {
            console.warn('DateTimePicker(initPicker): Unable to find DOM element');
            return;
        }

        $dp.datetimepicker({
            format: PatientPrism.Config.get('datetimepicker.format'),
            showClose: true
        });
    }

    function initMonthPicker( $dp )
    {
        if (!$dp.length)
        {
            console.warn('DateTimePicker(initMonthPicker): Unable to find DOM element');
            return;
        }

        $dp.datetimepicker({
            format: PatientPrism.Config.get('datetimepicker.month_format'),
            viewMode: "months",
            showClose: true
        });
    }

    function initFullPicker( $dp, custom )
    {
        if (!$dp.length)
        {
            console.warn('DateTimePicker(initMonthPicker): Unable to find DOM element');
            return;
        }

        var defaults = {
            useCurrent: false,
            format: PatientPrism.Config.get('datetimepicker.full_format'),
            sideBySide: true
        }, params = custom ? $.extend(defaults, custom) : defaults;

        $dp.datetimepicker(params);
    }

    function linkPickers( $dp_start, $dp_end)
    {
        if (!$dp_start.length || !$dp_end.length)
        {
            console.warn('DateTimePicker(linkPickers): Unable to find one or more DOM elements');
            return;
    }
        // Set max date to date of dp_end
        $dp_start.data("DateTimePicker")
            .maxDate( $dp_end.data("DateTimePicker").date());

        // Set min date to date of dp_start
        $dp_end.data("DateTimePicker")
            .minDate($dp_start.data("DateTimePicker").date());

        // Update min date
        $dp_start.on("dp.change", function (e)
        {
            $dp_end.data("DateTimePicker")
                .minDate(e.date);
        });

        // Update max date
        $dp_end.on("dp.change", function (e)
        {
            $dp_start.data("DateTimePicker")
                .maxDate(e.date);
        });
    }
})();