(function() {
    namespace("PatientPrism.Modules.Analize.Helpers.Formatters", money, percent, call_count, calls);

    let look_type_helper = PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType;

    function validate_link_template(link_template)
    {
        if (typeof link_template != 'object') {
            throw "Link parameters should be provided as an object";
        }
        if (!link_template.hasOwnProperty('route')) {
            throw "The key 'route' in parameters object is necessary";
        }
        if (!link_template.hasOwnProperty('route_params') || typeof link_template.route_params != 'object') {
            throw "The key 'route' in parameters object is necessary and it has to be an object";
        }
    }

    function money(data, type, row, meta)
    {
        if (_.includes(['display', 'filter', 'export'], type)) {
            return data > 0 ? '$' + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
        }

        return data;
    }

    function percent(data, type, row, meta)
    {
        if (_.includes(['display', 'filter', 'export'], type)) {
            return data.toFixed(2) + '%';
        }

        return data;
    }

    function call_count( data, total, type, report_table, link_template)
    {
        if (_.includes(['display', 'filter', 'export'], type)) {

            let first_index = look_type_helper.get_first_index(report_table);
            let second_index = look_type_helper.get_second_index(report_table);

            let percent = Math.round((data/total) * 100);
            let plural = data > 1 ? 's' : '';

            let values = [
                `${data} Call${plural} `,
                `${percent}%`
            ];

            let main_value = values[first_index];

            if ('export' === type) {
                return data > 0 ? `${main_value} (${values[second_index]})` : '-';
            }

            if (link_template && data > 0) {
                validate_link_template(link_template);
                main_value = `<a href="${encodeURI(_router.route(link_template.route, link_template.route_params))}
                    " target="_blank">${main_value}</a>`;
            }

            return data > 0 ? `${main_value} <br/> <small class="text-muted">${values[second_index]}</small>` : '-';
        }

        return data;
    }

    function calls( data, type, second_line, report_table, link_template)
    {
        if (_.includes(['display', 'filter', 'export'], type)) {

            let first_index = look_type_helper.get_first_index(report_table);

            let plural = data > 1 ? 's' : '';
            let formatted = data > 0 ? `${data} Call${plural}` : '-';
            let values = [
                formatted,
                second_line ?`${second_line}` : formatted,
            ];
            let main_value = values[first_index];

            if (!second_line && 'export' === type) {
                return `${main_value}`;
            }

            let second_index = look_type_helper.get_second_index(report_table);

            if ('export' === type) {
                return `${main_value} (${values[second_index]})`;
            }

            if (link_template && data > 0) {
                validate_link_template(link_template);
                main_value = `<a href="${encodeURI(_router.route(link_template.route, link_template.route_params))}
                    " target="_blank">${main_value}</a>`;
            }

            if (!second_line) {
                return main_value;
            }

            return `${main_value} <br/> <small class="text-muted">${values[second_index]}</small>`;
        }

        return data;
    }
})();
