(function()
{
    namespace("PatientPrism.Modules.Calls.Analytics.Keywords", init);

    var self = {
        $table_queries: null,
        $table_terms: null
    };

    function init()
    {
    	initKeywordDataTables();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initKeywordDataTables()
    {
        var oLanguage = {
            "sLengthMenu": "Show _MENU_ records per page",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Analytic Records",
            "sInfoEmpty": "",
            "sInfoFiltered": " - filtered from _MAX_ total records",
            "sEmptyTable": "No Analytic Data Available"
        };

        self.$table_queries = $('table#queries');

        if (self.$table_queries.length)
        {
            self.$table_queries.DataTable({
                'order': [
                    [1, 'desc']
                ],
                "oLanguage": oLanguage,
                "sPaginationType": "simple"
            });
        }

        self.$table_terms = $('table#terms');

        if (self.$table_terms.length)
        {
            self.$table_terms.DataTable({
                'order': [
                    [1, 'desc']
                ],
                "oLanguage": oLanguage,
                "sPaginationType": "simple"
            });
        }
    }
})();