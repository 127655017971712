(function()
{
    namespace("PatientPrism.API.ProfileEntries", create, update, destroy);

    var self = {
        //
    }

    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/profile-entries', callback, body)
    }

    function update(profile_entry_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/profile-entries/' + profile_entry_id , callback, body)
    }

    function destroy(profile_entry_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/profile-entries/' + profile_entry_id , callback, body)
    }
})();