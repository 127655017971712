(function()
{
    namespace("PatientPrism.Common.UI.Filters.Locations", init);

    var self = {
        data: {
            location_groups: null,
            locations: null
        },
        el: {
            action: {
                $container: null,
                $button:    null,
                $text:      null,
                $form:      null,
                form:       {
                    $locations: null,
                    $submit:    null
                }
            },
        }
    }

    function init()
    {
        self.el.action.$container = $('#action-location-container');
        self.el.action.$button    = $('button.action-button', self.el.action.$container);
        self.el.action.$text      = $('span.action-text', self.el.action.$button);
        self.el.action.$form      = $('form.action-form', self.el.action.$container);

        self.el.action.form.$locations = $('#action-location-container-locations', self.el.action.$form);
        self.el.action.form.$submit    = $('.btn-submit', self.el.action.$form);

        self.el.action.$container.on("shown.bs.dropdown", function(event) {
            self.el.action.form.$locations[0].selectize.open();
        });

        loadData();
        initForm();
        initTooltip();
        initLocationSelect();
        checkForceFocus();
    }

    function loadData()
    {
        if (typeof PatientPrism.Common.Session.get('available')['location-groups'] !== 'undefined')
            self.data.location_groups = PatientPrism.Common.Session.get('available')['location-groups'];

        if (typeof PatientPrism.Common.Session.get('available')['locations'] !== 'undefined')
            self.data.locations = PatientPrism.Common.Session.get('available')['locations'];
    }

    function initLocationSelect()
    {
        self.el.action.form.$locations.selectize({
            preload: false,
            placeholder: 'Search Groups or Locations',
            searchField: [
                'name',
                'description'
            ],
            valueField: 'selectize_id',
            delimiter: ',',
            openOnFocus: true,
            selectOnTab: true,
            optgroups: [
                { value: 'location-group', label: 'Groups' },
                { value: 'location', label: 'Locations' },
            ],
            optgroupField: 'type',
            optgroupOrder: [
                'location-group',
                'locaiton'
            ],
            onInitialize: function ()
            {
                $(this.$dropdown).css('display', 'block');
                $(this.$dropdown).css('position', 'initial');
            },
            onDropdownClose: function (dropdown) {
                $(dropdown).css('display', 'block');
            },
            onChange: function (value) {
                // Count unselected items and hide dropdown if no items remain, otherwise show
                Object.keys(this.options).length - this.items.length ? $(this.$dropdown).css('display', 'block') : $(this.$dropdown).css('display', 'none');

                self.el.action.form.$submit.attr('disabled', this.items.length ? false : true);
            },
            render: {
                // Search result group titles
                optgroup_header: function(data, escape)
                {
                    return '<div class="optgroup-header">' + escape(data.label) + '</div>';
                },

                // Search result single object
                option: function(data, escape)
                {
                    return '<div>' +
                                '<span class="title">' +
                                    '<span class="name">' + data.name + '</span>' +
                                '</span>' +
                            '</div>';
                },

                // Input control single object
                item: function(data, escape)
                {
                    return '<div data-type="' + escape(data.type) + '" class="item">' + data.name + '</div>';
                }
            }
        });

        var selected_items = [];

        // Add location group options
        self.el.action.form.$locations[0].selectize.addOption($.map(self.data.location_groups, function (item)
        {
            item.type = 'location-group';
            item.selectize_id = Math.random().toString(36).replace(/[^a-z]+/g, '');

            if ($.inArray(item.id, PatientPrism.Common.Session.get('filters')['location-groups']) >= 0)
                selected_items.push(item.selectize_id);

            return item;
        }));

        // Add location options
        self.el.action.form.$locations[0].selectize.addOption($.map(self.data.locations, function (item)
        {
            item.type = 'location';
            item.selectize_id = Math.random().toString(36).replace(/[^a-z]+/g, '');

            if ($.inArray(item.id, PatientPrism.Common.Session.get('filters')['locations']) >= 0)
                selected_items.push(item.selectize_id);

            return item;
        }));

        self.el.action.form.$locations[0].selectize.setValue(selected_items);

        // Enable control
        self.el.action.form.$locations[0].selectize.enable();
        
        $('#clear-locations-and-groups').click(function(e) {
        	e.preventDefault();
        	var selectize = self.el.action.form.$locations[0].selectize;
        	//clone array
        	var items = selectize.items.slice(0);
        	for (var i in items) {
        		selectize.removeItem(items[i]);
        	}
        	selectize.refreshOptions();
        	return false;
        });

        $('#select-all-locations').click(function(e) {
            e.preventDefault();
        	var selectize = self.el.action.form.$locations[0].selectize;
            var location_options = _.map(_.filter(selectize.options, function(option) {
                return !('locations' in option)
            }), 'selectize_id');

            selectize.setValue(location_options);
            
            return false;
        })
    }

    function initForm()
    {
        self.el.action.form.$submit.click(function (e)
        {
            e.preventDefault();

            $(this).attr('disabled', true);

            $.each(self.el.action.form.$locations[0].selectize.items, function (index, value)
            {
                switch(self.el.action.form.$locations[0].selectize.options[value].type)
                {
                    case 'location':
                        self.el.action.$form.append('<input type="hidden" name="locations[]" value=' + self.el.action.form.$locations[0].selectize.options[value].id + ' />');
                        break;

                    case 'location-group':
                        self.el.action.$form.append('<input type="hidden" name="location-groups[]" value=' + self.el.action.form.$locations[0].selectize.options[value].id + ' />');
                        break;
                }
            });

            self.el.action.$form.submit();
        });
    }

    function initTooltip()
    {
        if (self.data.locations.length || self.data.location_groups.length)
        {
            var title = '';

            _.each(PatientPrism.Common.Session.get('filters')['locations'], function (location_id, index)
            {
                title = title + _.find(self.data.locations, { id: location_id }).name + '<br/>';
            })

            _.each(PatientPrism.Common.Session.get('filters')['location-groups'], function (location_group_id, index)
            {
                title = title + _.find(self.data.location_groups, { id: location_group_id }).name + '<br/>';
            })
        }

        if (title != '')
        {
            self.el.action.$container.tooltip({
                title: title,
                html: true,
                placement: 'bottom'
            });
        }
    }

    // Checks if location or location group filters are set, otherwise shows and overlay and forces use to select filters
    function checkForceFocus()
    {
        if (typeof PatientPrism.Common.Session.get('filters')['locations'] == 'undefined' && typeof PatientPrism.Common.Session.get('filters')['location-groups'] == 'undefined')
        {
            self.el.action.$button.dropdown('toggle');
            PatientPrism.Common.UI.ElementFocus.focus(self.el.action.$container);
        }
    }
})();