(function()
{
    namespace("PatientPrism.Common.UI.Alerter", init);

    var self = {
    }

    function init()
    {
        initAlertTriggers();
    }

    function initAlertTriggers()
    {
        $( "[trigger-alert]" ).click(function(e)
        {
            e.preventDefault();

            self = $(this);

            if (!self.attr('alert-redirect'))
                return;

            swal({
                title: self.attr('alert-title') ? self.attr('alert-title') : "Are you sure?",
                text: self.attr('alert-text') ? self.attr('alert-text') : "Would you like to continue?",
                type: self.attr('alert-type') ? self.attr('alert-type') : "info",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                closeOnConfirm: false,
                closeOnCancel: false,
                html: true,
            },
            function(isConfirm)
            {
                if (isConfirm)
                {
                   window.location.href = self.attr('alert-redirect');
                }
                else
                {
                    swal.close();
                }
            });
        });
    }
})();