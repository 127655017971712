(function()
{
    namespace("PatientPrism.Admin.Reports.Calls.InferencePredictions", init);

    var self = {
        $inference_predictions_table: null,
        report_data: null
    };

    function init(report)
    {
        self.report_data = JSON.parse(report);
        buildParentInferenceTable();
    }

    function buildParentInferenceTable()
    {
        self.$inference_predictions_table = $('#inference-predictions-table').DataTable({
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
                [1, 'asc']
            ],
            "data" : self.report_data,
            "columnDefs": [],
            "oLanguage": {
                "sLengthMenu": "Show _MENU_ inference metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Inference Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ Total Inference Metrics",
                "sEmptyTable": "No Inference Metrics Available"
            },
            "sPaginationType": "simple",
            "columns": [
                {
                    "className":      'details-control',
                    "orderable":      false,
                    "data":           null,
                    "defaultContent": '<span class="fa fa-list-ul text-link"></span>'
                },
                { "data" : "slug" },
                { "data" : "section" },
                { "data" : "count"},
            ]
        });

        // Add event listener for opening and closing details
        $('#inference-predictions-table tbody').on('click', 'td.details-control', function () {
            renderNestedTable($(this), self.$inference_predictions_table);
        });
    }

    /* Formatting function for row details - modify as you need */
    function format(d, table_id) {
        let html = '<table id="' + table_id + '" class="table table-bordered">'+
            '<thead>' +
            '<th>Key</th>' +
            '<th>Value</th>' +
            '<th>Calls</th>' +
            '</thead>';
        '<tbody>';

        _.each(d.report, function(metadata) {
            html = html +
                '<tr>' +
                '<td>' + metadata.key + '</td>' +
                '<td>' + metadata.value + '</td>' +
                '<td>' + metadata.count + '</td>' +
                '</tr>';
        });

        html = html + '</tbody></table>';

        return html;
    }

    function renderNestedTable(element, table) {
        let tr = element.closest('tr');
        let row = table.row(tr);

        if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
        } else {
            let table_id = Math.random().toString(36).replace(/[^a-z]+/g, '');

            // Open this row
            row.child(format(row.data(), table_id)).show();

            $('#' + table_id).DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                'order': [
                    [2, 'desc']
                ],
                "columnDefs": [],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ metadata per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metadata",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Metadata",
                    "sEmptyTable": "No Metadata Available"
                },
                "sPaginationType": "simple"
            });

            initTooltips($('#' + table_id));

            tr.addClass('shown');
        }
    }

    function initTooltips(table)
    {
        $('[data-toggle="tooltip"]', table.DataTable().rows().nodes()).tooltip();
    }
})();