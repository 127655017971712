(function()
{
    namespace("PatientPrism.Modules.Settings.SelectCompany", init);

    var self = {
        $select_company: null,
    };

    function init()
    {
        bindSelectCompany();
    }

    function bindSelectCompany()
    {
        self.$select_company =
        $('#settings-chooser #select-company').selectize({
            onChange: function(value) {
               window.location.href = value;
            }
        });
    }
})();