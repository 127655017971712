(function()
{
    namespace("PatientPrism.Common.UI.CallBasicsLoader", init, isLoaded, addAfterInitCallback, enableCallBasics, disableCallBasics);

    var self = {
        $el: '#call-basics-score-charts',
        calculate_scores: false,
        call_record_id: null,
        call_basics_summary: {},
        global_call_basics: [],
        loaded: false,
        after_init_callbacks: [] // array of callbacks passed from external modules here
    };

    /**
     * Init module for loading call basics
     *
     * @param calculate_scores
     * @param call_record_id
     * @param global_call_basics
     */
    function init(calculate_scores, call_record_id, global_call_basics)
    {
        self.$el = $(self.$el);
        self.calculate_scores = calculate_scores;
        self.call_record_id = call_record_id;
        self.global_call_basics = global_call_basics;

        fetchCallBasics();
    }

    /**
     * Check if call basics are loaded
     * @returns {boolean}
     */
    function isLoaded() {
        return self.loaded;
    }

    /**
     * Add a callback which is fired after call basics are loaded
     * @param callback
     */
    function addAfterInitCallback(callback) {
        if ($.isFunction(callback)) {
            self.loaded ? callback() : self.after_init_callbacks.push(callback);
        }
    }

    /**
     * Run all assigned callbacks
     */
    function runAfterInitCallbacks() {
        self.after_init_callbacks.forEach(callback => callback());
    }

    function enableCallBasics(selector) {

        // skip enabling call basics that are disabled on a location level
        let $selector = $(selector).not('[data-disable-forever=true]');

        // enable link
        $selector.removeClass('disabled');
        $selector.attr('disabled', false);

        // enable toggle events
        if (!$selector.hasClass('call-basic-toggle')) {
            $selector.addClass('call-basic-toggle');
        }

        // enable input control
        $selector.find('input').prop('name', 'call_basics[]');
        $selector.find('input').attr('disabled', true);
    }

    function disableCallBasics(selector, disableForever) {
        // disable link
        $(selector).removeClass('call-basic-toggle');
        $(selector).addClass('disabled');
        $(selector).removeClass('active');
        $(selector).attr('disabled', true);

        // disable input control
        $(selector).find('input').prop('name', ''); // remove input name attribute in order to prevent sending unchecked call basic value
        $(selector).find('input').attr('disabled', true); // disable input control for the same reason

        // disable call basic if it is disabled on a location level
        disableForever && $(selector).attr('data-disable-forever', true);
    }

    /**
     * Get call basics from API side and fill in all the controllers
     */
    function fetchCallBasics()
    {
        var callback = function (data, textStatus, jqXHR) {
            try {
                if (textStatus === 'success') {

                    // get calculated call basics summary from API
                    var summary = data.data.call_basic_summary;
                    if (!summary)
                        throw new Error('Cannot fetch Call Basics Summary for ' + self.call_record_id);

                    summary = _.keyBy(summary, 'id');

                    // if the view implies displaying the overall score than init controls and highcharts
                    if (self.calculate_scores) {
                        loadScores(summary, data.data.score_average);
                    }

                    // fill in all visual controls with values from API
                    loadCallBasics(summary);

                    // init buttons
                    initCallBasicsControls();
                } else if (textStatus === 'error') {
                    throw new Error(data.error.message);
                }

                self.$el.ajaxLoader('hide'); // hide spinner with overlay
                self.loaded = true;

                runAfterInitCallbacks();

            } catch (e) {
                self.$el.ajaxLoader('error', e.message);
                console.error(e);
            }
        };

        // show spinner with overlay
        self.$el.ajaxLoader('show');
        PatientPrism.API.CallRecords.get(self.call_record_id, callback, ['call_basic_summary']);
    }

    /**
     * Display all visual controls and highcharts with newly fetched summary and score
     *
     * @param summary
     * @param scoreAverage
     */
    function loadScores(summary, scoreAverage)
    {
        $('#overall-score').text(scoreAverage);

        _(summary).each(function (category) {
            $('[data-call-basic-score-category-id="' + category.id + '"] .successful-basics-trigger .label-success').text(category.call_basics.active.length);
            $('[data-call-basic-score-category-id="' + category.id + '"] .unsuccessful-basics-trigger .label-warning').text(category.call_basics.inactive.length);
        });

        initCallBasicsSpeedometers(summary);
    }

    /**
     * Init highcharts and popovers
     *
     * @param summary
     */
    function initCallBasicsSpeedometers(summary)
    {
        if (!$('.col-chart').length)
            return;

        // moved from review_call.js
        $.each($('.col-chart'), function()
        {
            var index = $(this).data('call-basic-score-category-id');

            // init speedometers
            $('.call-basic-speedometer', this).highcharts({
                chart: {
                    zoomType: 'xy',
                    type: 'solidgauge',
                    height: 180,
                    backgroundColor: {
                        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                        stops: [
                            [0, '#FFFFFF'],
                            [1, '#FFFFFF']
                        ]
                    },
                    style: {
                        fontFamily: "'Proxima Nova Bold', sans-serif",
                        textShadow: 'none',
                        color: "#47657B"
                    },
                },
                title: {
                    text: summary[index].name,
                    style: {
                        color: '#47657B',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        textShadow: 'none'
                    }
                },
                pane: {
                    center: ['50%', '85%'],
                    size: '140%',
                    startAngle: -90,
                    endAngle: 90,
                    background: {
                        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                        innerRadius: '60%',
                        outerRadius: '100%',
                        shape: 'arc'
                    }
                },
                // the value axis
                yAxis: {
                    stops: [
                        [0.3, '#DF5353'], // red
                        [0.5, '#F4AC5D'], // yellow
                        [0.75, '#67C474'] // green
                    ],
                    lineWidth: 0,
                    title: {
                        y: -70,
                    },
                    labels: {
                        y: 16
                    },
                    min: 0,
                    max: 100,
                    minorTickInterval: null,
                    tickAmount: 0,
                    showFirstLabel:false,
                    showLastLabel:false,
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            y: 5,
                            borderWidth: 0,
                            useHTML: true
                        }
                    }
                },
                series: [{
                    name: summary[index].name,
                    data: [Number((summary[index].call_basics.score).toFixed(0))],
                    dataLabels: {
                        format: '<div style="text-align:center"><span style="font-size:20px;color:' +
                            '#2B3E4E' + '">{y}%</span>'
                    },
                }],
                tooltip: {
                    enabled: false
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                }
            });

            var success_content = '<table class="table table-striped">';

            if (!summary[index].call_basics.active.length)
                success_content = '<tr><td class="text-center text-muted"><small>No Successful Call Basics</small></td></tr>'

            _.each(summary[index].call_basics.active, function(call_basic)
            {
                success_content += '<tr><td><small>' + call_basic.value + '</small></td></tr>';
            });

            success_content += '</table>';

            // init successful and unsuccessful popovers
            $('.successful-basics-trigger', this).popover({
                content: success_content,
                placement: 'top',
                html: true,
                title: '<div class="text-center">Successful Call Basics</div>'
            });

            var unsuccess_content = '<table class="table table-striped">';

            if (!summary[index].call_basics.inactive.length)
                unsuccess_content = '<tr><td class="text-center text-muted"><small>No Unsuccessful Call Basics</small></td></tr>'

            _.each(summary[index].call_basics.inactive, function(call_basic)
            {
                unsuccess_content += '<tr><td><small>' + call_basic.value + '</small></td></tr>';
            });

            unsuccess_content += '</table>';

            $('.unsuccessful-basics-trigger', this).popover({
                content: unsuccess_content,
                placement: 'top',
                html: true,
                title: '<div class="text-center">Unsuccessful Call Basics</div>'
            })
        });
    }

    /**
     * Disable or enable some call basics list items in order to select/deselect them
     * @param summary
     */
    function loadCallBasics(summary)
    {
        self.global_call_basics.forEach(function (category) {

            // merge active and inactive call basics alltogether
            var category_call_basics = _.keyBy(_.concat(summary[category.id].call_basics.inactive, summary[category.id].call_basics.active), 'id');

            // loop through 4 categories and enable/disable inputs
            $('[data-call-basic-category-id="' + category.id + '"]').each(function() {
                var category_call_basic = category_call_basics[$(this).data('call-basic-id')];

                //remove previously assigned classes
                $(this).removeClass('active');
                $(this).removeClass('disabled');

                if (category_call_basic) {
                    enableCallBasics(this);

                    // check/uncheck it
                    if (category_call_basic.activeStatus === 'active') {
                        $(this).addClass('active');
                        $(this).find('input').attr('disabled', false);
                    } else {
                        $(this).find('input').attr('disabled', true);
                    }
                } else {
                    disableCallBasics(this, true);
                }
            });
        });
    }

    /**
     * Init buttons
     */
    function initCallBasicsControls()
    {
        // check/uncheck call basics. Moved from review_call.js
        $('.panel-call-basic-category').off('click.basics').on('click.basics', '.call-basic-toggle', function (e)
        {
            e.preventDefault();

            $(this).toggleClass('active');

            var $value_input = $(this).find('*[name="call_basics[]"]');

            $value_input.attr('disabled', $value_input.attr('disabled') ? false : true);
        });

        // show 'modify call basics' form
        $('#edit-call-basics').off('click.basics').on('click.basics', function(e)
        {
            e.preventDefault();

            $('#call-basics-score').hide();
            $('#call-basics-modify').show();
            clearCharts();
        });

        // submit selected/unselected call basics directly to API
        $('#submit-call-basics').off('click.basics').on('click.basics', function(e)
        {
            e.preventDefault();

            var api_callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    $('#call-basics-modify').hide();
                    $('#call-basics-score').show();
                    fetchCallBasics(); // reload call basics to show updated values
                }
                else
                {
                    self.$el.ajaxLoader('error', data.error.message);
                }
            };

            var request_body = {
                call_basics: $('#call-basics-modify input').serializeArray().map(a => a.value)
            };

            self.$el.ajaxLoader('show');
            self.loaded = false;

            // update a call review with new call basics
            PatientPrism.API.CallRecords.update_call_basics(self.call_record_id, api_callback, request_body);
        });
    }

    /**
     * Clear all previously filled information
     */
    function clearCharts() {
        $('#overall-score').text('');

        $.each($('.col-chart'), function() {
            $('.call-basic-speedometer', this).highcharts().destroy();
            $('.successful-basics-trigger', this).popover('destroy');
            $('.unsuccessful-basics-trigger', this).popover('destroy');
            $('.successful-basics-trigger .label-success', this).text('');
            $('.unsuccessful-basics-trigger .label-warning', this).text('');
        });
    }
})();