(function()
{
    namespace("PatientPrism.Modules.Calls.Reviews.EscalatedCalls", init);

    var self = {
        $table: null
    };

    function init()
    {
        initEscalatedCallsDataTable();

        PatientPrism.Common.Vendor.DataTable.ActionColumn.CallRecords.init(self.$table);
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initEscalatedCallsDataTable()
    {
        self.$table = $('table#escalated-calls');

        if (self.$table.length)
        {
            self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                responsive: true,
                'order': [
                    [10, 'desc']
                ],
                "columnDefs": [
                    {
                        "targets": [1, 11],
                        "orderable": false
                    }],
                language: {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "No Calls Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "full_numbers"
            });
        }
    }

})();