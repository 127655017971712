(function()
{
    namespace("PatientPrism.Modules.Documents.Archives", init);

    function init()
    {
        initializeFormSubmissionsDataTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initializeFormSubmissionsDataTable()
    {
        self.$activity_table = $('table#form-submissions');

        if (self.$activity_table.length)
        {
            self.$activity_table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                columnDefs: [{
                    orderable: false,
                    targets: -1
                }],
                'order': [
                    [3, 'desc']
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ Submissions Per Page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Submissions",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total submissions",
                    "sEmptyTable": "No Submissions Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }
})();