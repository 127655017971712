(function()
{
    namespace("PatientPrism.API.CallAudioNotes", create, update, destroy, get_all_by_users_and_period, get_call_records_noticed_by_user);

    var self = {
        //
    }

    function create(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/call-records/' + call_record_id + '/call-audio-notes', callback, body)
    }

    function update(call_audio_note_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-audio-notes/' + call_audio_note_id , callback, body)
    }

    function destroy(call_audio_note_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-audio-notes/' + call_audio_note_id , callback, body)
    }

    function get_all_by_users_and_period(callback, body)
    {
        return PatientPrism.API.HTTP.Socket.get('/admin/reports/call-coach-notes', callback, body);
    }

    function get_call_records_noticed_by_user(callback, body)
    {
        return PatientPrism.API.HTTP.Socket.get('/admin/reports/call-coach-notes/call-records-noted-by-user', callback, body);
    }
})();