(function()
{
    namespace("PatientPrism.Modules.Account.LocationGroups", init);

    var self = {
        $table: null,
        add_group: {
            $name: null,
            $description: null,
            $location_select: null,
            $btn_submit: null
        },
        modals: {
            edit_group: {
                $modal: null,
                $name: null,
                $description: null,
                $location_select: null
            }
        },
        locations: null,
        location_groups: null
    };

    function init()
    {
        getLocations(function(locations)
        {
            initAddGroup(locations);
            initEditGroupModal(locations);
        });

        getLocationGroups(function(location_groups)
        {
            initLocationGroupDataTable(location_groups);
        });

    }

    function getLocations(callback)
    {
        var  body = {
            company_id: PatientPrism.Common.Session.get('current').company.id
        };

        var this_callback = function (data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                self.locations = data.data;
                callback(self.locations);
            }
        }

        PatientPrism.API.Locations.all(this_callback, body);
    }

    function getLocationGroups(callback)
    {
        var body = {
            expand: ['locations']
        }

        var this_callback = function (data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                self.location_groups = data.data;
                callback(self.location_groups);
            }
        }

        PatientPrism.API.Users.get_location_groups(PatientPrism.Common.Session.get('current').user.id, this_callback, body);
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initLocationGroupDataTable(location_groups)
    {
        self.$table = $('table#location-groups');

        var oLanguage = {
            "sLengthMenu": "Show _MENU_ groups per page",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Groups",
            "sInfoEmpty": "",
            "sInfoFiltered": " - filtered from _MAX_ total groups",
            "sEmptyTable": "No Groups Available"
        };

        self.$table.DataTable({
            'order': [
                [1, 'asc']
            ],
            "columnDefs": [
            {
                "targets": [0],
                "orderable": false
            },
            {
                "targets": [ 0 ],
                className: "action-column"
            }],
            "oLanguage": oLanguage,
            "sPaginationType": "simple"
        });

        $.each(location_groups, function(index, group)
        {
            addGroupToTable(group);
        });

        PatientPrism.Common.Vendor.DataTable.ActionColumn.LocationGroups.init(self.$table);
    }

    function addGroupToTable(group)
    {
        var row_node =
        self.$table.DataTable().row.add([
            '<input class="selection-checkbox" type="checkbox" name="id[]">',
            '<span class="text-link group-name">' + group.name + '</span>',
            '<span class="text-link location-count">' + group.locations.length + ' Locations' + '</span>'
        ])
        .draw(false)
        .node();

        group.row_node = row_node;

        bindRowNode(group);

        $(group.row_node).attr('data-id', group.id);
    }

    function updateGroup(group, data)
    {
        group.name = data.name;
        group.description = data.description;
        group.locations = data.locations;

        self.$table.DataTable().row(group.row_node).data([
            '<input class="selection-checkbox" type="checkbox" name="id[]">',
            '<span class="text-link group-name">' + group.name + '</span>',
            '<span class="text-link location-count">' + group.locations.length + ' Locations' + '</span>'
        ]).draw(false);

        bindRowNode(group);
    }

    function bindRowNode(group)
    {
        $('.group-name, .location-count', group.row_node).click(function(e)
        {
            editGroup(group);
        });

        PatientPrism.Common.Vendor.DataTable.ActionColumn.LocationGroups.bindCheckboxChange(group.row_node);
    }

    function editGroup(group)
    {
        $('input#edit-group-name').val(group.name);
        $('input#edit-group-description').val(group.description);

        self.modals.edit_group.$location_select[0].selectize.setValue($.map(group.locations, function(location)
        {
            return location.id;
        }));

        self.modals.edit_group.$btn_submit.unbind('click').click(function(e)
        {
            e.preventDefault();

            var callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    location.reload();

                    updateGroup(group, data.data);
                    self.modals.edit_group.$modal.modal('hide');
                }
            };

            var body = {
                name: self.modals.edit_group.$name.val(),
                description: self.modals.edit_group.$description.val(),
                location_id: self.modals.edit_group.$location_select[0].selectize.items,
                expand: ['locations']
            };

            PatientPrism.API.LocationGroups.update(group.id, callback, body);
        });

        self.modals.edit_group.$modal.modal('show');
    }

    function initAddGroup(locations)
    {
        self.add_group.$name = $('input#add-group-name');
        self.add_group.$description = $('input#add-group-description');
        self.add_group.$location_select = $('input#add-group-locations');
        self.add_group.$btn_submit = $('button#btn-add-group-submit');

         // Initialize selectize control
        self.add_group.$location_select.selectize({
            preload: true,
            placeholder: 'Add Locations to Group',
            labelField: 'name',
            searchField: 'name',
            valueField: 'id',
            openOnFocus: true,
            selectOnTab: true
        });

        self.add_group.$location_select[0].selectize.addOption(locations);
        self.add_group.$location_select[0].selectize.enable();

        self.add_group.$btn_submit.click(function(e)
        {
            e.preventDefault();

            var callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    var group = data.data;

                    var callback = function (data, textStatus, jqXHR)
                    {
                        if (textStatus === 'success')
                        {
                            location.reload();

                            group.locations = data.data;
                            addGroupToTable(group);

                            self.add_group.$name.val('');
                            self.add_group.$description.val('');
                            self.add_group.$location_select[0].selectize.clear();

                            getLocationGroups(function(){});
                        }
                    }

                    var body = {
                        location_id: self.add_group.$location_select[0].selectize.items
                    };

                    PatientPrism.API.LocationGroups.add_location(group.id, callback, body);
                }
            };

            var body = {
                name: self.add_group.$name.val(),
                description: self.add_group.$description.val()
            };

            PatientPrism.API.LocationGroups.create(callback, body);
        });
    }

    function initEditGroupModal(locations)
    {
        self.modals.edit_group.$modal = $('#modal-edit-group');
        self.modals.edit_group.$name = $('#edit-group-name', self.modals.edit_group.$modal);
        self.modals.edit_group.$description = $('#edit-group-description', self.modals.edit_group.$modal);
        self.modals.edit_group.$location_select = $('input#edit-group-locations', self.modals.edit_group.$modal);
        self.modals.edit_group.$btn_submit = $('button#btn-edit-group', self.modals.edit_group.$modal);

        // Initialize selectize control
        self.modals.edit_group.$location_select.selectize({
            preload: true,
            placeholder: 'Add Locations to Group',
            labelField: 'name',
            searchField: 'name',
            valueField: 'id',
            openOnFocus: true,
            selectOnTab: true
        });

        self.modals.edit_group.$location_select[0].selectize.addOption(locations);
        self.modals.edit_group.$location_select[0].selectize.enable();
    }

})();