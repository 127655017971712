(function()
{
    namespace("PatientPrism.Modules.Calls.Analytics.Referrals", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init(data)
    {
        self.report_data = JSON.parse(data);

        initReferersChart();
        initTrafficChart();
    	initTrafficDataTable();
    }

    function initTrafficChart() {
        $('#traffic-chart').highcharts({
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Traffic Sources'
            },
            subtitle: {
                text: 'An Analysis of Call Conversion by Traffic Source'
            },
            xAxis: [{
                categories: _.map(self.report_data.traffic_sources, function (item)
                {

                  return item.source
                }),
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                }
            }],
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 100,
                verticalAlign: 'top',
                y: 30,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            series: [{
                name: 'Converted Calls',
                type: 'areaspline',
                data: _.map(self.report_data.traffic_sources, function (item)
                {
                  return item.calls;
                }),
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true
            }]
        });
    }

    function initReferersChart()
    {
        // Radialize the colors
        Highcharts.getOptions().colors = Highcharts.map(Highcharts.getOptions().colors, function (color) {
            return {
                radialGradient: {
                    cx: 0.5,
                    cy: 0.3,
                    r: 0.7
                },
                stops: [
                    [0, color],
                    [1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
                ]
            };
        });

        $('#referers-chart').highcharts({
            chart: {
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                }
            },
            title: {
                text: 'Call Referrers'
            },
            subtitle: {
                text: 'An Overview of Referring Call Sources'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: <b>{point.percentage:.1f}%</b>'
                    },

                }
            },
            series: [{
                type: 'pie',
                name: 'Total Share',
                data: _.map(self.report_data.referers, function(referer, index) {
                        return [referer.value, referer.total] ;
                    }).filter(function(d) {return d[1] > 0})
            }]
        });
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initTrafficDataTable()
    {
        self.$table = $('table#traffic');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [3, 'desc']
                ],
                "columnDefs": [
                    {
                        "className": "text-center", "targets": [1,2,3,4]
                    }
                ],
                "oLanguage": {
                	"sLengthMenu": "Show _MENU_ records per page",
			      	"sInfo": "Showing _START_ to _END_ of _TOTAL_ Analytic Records",
			      	"sInfoEmpty": "",
			      	"sInfoFiltered": " - filtered from _MAX_ total records",
			      	"sEmptyTable": "No Analytic Data Available"
			    },
                "sPaginationType": "simple"
            });
        }
    }
})();