(function()
{
    namespace("PatientPrism.Utility.Format", money);

    function money(value)
    {
    	return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

})();

