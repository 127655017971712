(function()
{
    namespace("PatientPrism.API.Users",
        all, get_location_groups, get_call_audio_notes, get, resend_verification,
        add_call_queues, remove_call_queues, update_call_queues, assigned_companies, forbid_companies, permit_companies
    );

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/users', callback, body)
    }

    function get_location_groups(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/users/' + user_id + '/location-groups', callback, body)
    }

    function get_call_audio_notes(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/users/' + user_id + '/call-audio-notes', callback, body)
    }

    function get(user_id, callback, body)
    {
    	PatientPrism.API.HTTP.Socket.get('/users/' + user_id, callback, body)
    }

    function resend_verification(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/users/verifications', callback, body)
    }

    function add_call_queues(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/users/' + user_id + '/call-queues', callback, body)
    }

    function remove_call_queues(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/users/' + user_id + '/call-queues', callback, body)
    }

    function update_call_queues(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/users/' + user_id + '/call-queues', callback, body)
    }

    function assigned_companies(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/users/' + user_id + '/companies', callback, body);
    }

    function forbid_companies(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/users/' + user_id + '/companies/forbid_companies', callback, body);
    }

    function permit_companies(user_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/users/' + user_id + '/companies/permit_companies', callback, body);
    }
})();
