(function()
{
    namespace("PatientPrism.Modules.Calls.Queue", init, bindUnassigningViewers);

    var self = {
        $table: null,
        viewables: []
    };

    function init()
    {
        PatientPrism.Common.UI.BlockedCallers.initBlockedCallers();
        initUnreviewedCallsDataTable();
        PatientPrism.Common.Vendor.DataTable.ActionColumn.CallRecords.init(self.$table);

        // Enable tooltips
        $('[data-toggle="tooltip"]').tooltip();

        bindReviewButtonViewerChecking();
        bindUnassigningViewers();
        longPollViewers();
    }
    function longPollViewers()
    {
        var api_callback = function (data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                var viewables = _.map(data.data, 'viewable_id');

                if (!_.isEqual(self.viewables.sort(), viewables.sort()))
                {
                    self.viewables = viewables;

                    var row_ids = _.map(self.viewables, function(item){
                        return '#row-' + item;
                    });

                    /*
                    $('#unreviewed-calls')
                    .DataTable()
                    .rows().every(function(){
                        $(this.node()).removeClass('active-viewers');
                        $('.btn-review', this.node()).removeClass('disabled');
                        $('.viewers-cell', this.node()).html('')
                    });
                    */

                    $('#unreviewed-calls')
                    .DataTable()
                    .rows(row_ids).every(function(){
                        $(this.node()).addClass('active-viewers');
                        //$('.btn-review', this.node()).addClass('disabled');
                        $('.viewers-cell', this.node()).html('<span class="active-viewer-icon glyphicon glyphicon-user"></span>')
                    });
                }
            }
            else if (textStatus === 'error')
            {

            }
        }

        PatientPrism.API.Viewables.get(api_callback);

        setInterval(function() {
            PatientPrism.API.Viewables.get(api_callback);
        }, 1000);
    }

    function bindReviewButtonViewerChecking()
    {
        $('#unreviewed-calls tbody').on('click', '.btn-review', function (e)
        {
            e.preventDefault();

            var redirect_url = $(this).attr('href');

            // Disable review buttons
            $('.btn-review').addClass('disabled');

            var api_callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    window.location.href = redirect_url;
                }
                else if (textStatus === 'error')
                {
                    window.location.href = redirect_url;
                }
            }

            PatientPrism.API.CallRecords.checkin_viewer($(this).attr('data-call-record-id'), api_callback);
        });
    }

    function bindUnassigningViewers() {

        $('#unreviewed-calls tbody').on('click', '.btn-unassign', function (e)
        {
            var $btn_unassign = $(this);

            e.preventDefault();

            var api_callback = function (data, textStatus, jqXHR)
            {
                var $current_row = $btn_unassign.closest('tr');

                if (textStatus === 'success')
                {
                    $current_row.find('td.review_in_progress_by').text('');
                    $current_row.find('.btn-unassign').parent('li').remove();

                    swal("Success", 'Assignment has been deleted.', "success");
                }
                else if (textStatus === 'error')
                {
                    swal("Error", 'Assignment has not been deleted: ' + data.error.message, "error");
                }
            };

            swal({
                title: "Are you sure?",
                text: "This will unassign the user from reviewing this call.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }, function(isConfirm) {
                if (isConfirm) {
                    PatientPrism.API.CallRecords.unreview($btn_unassign.attr('data-call-record-id'), api_callback);
                }
            });
        });
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initUnreviewedCallsDataTable()
    {
        self.$table = $('table#unreviewed-calls');

        if (self.$table.length)
        {
            self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                responsive: true,
                "pageLength": 25,
                'order': [
                    [7, 'asc']
                ],
                "columnDefs": [
                {
                    "targets": [1,10],
                    "orderable": false
                }],
                language: {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "No Calls Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }

})();