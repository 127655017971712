(function()
{
    namespace("PatientPrism.API.CallFollowUps", update);

    var self = {
        //
    }

    function update(call_follow_up_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-follow-ups/' + call_follow_up_id , callback, body)
    }
})();