(function()
{
    namespace("PatientPrism.Admin.CallBasics.Index", init);

    var self = {
        categories: null,
        call_basics: null,
        tables: {
            $categories: null,
            $call_basics: null
        },
        modals: {
            $add_category: null,
            $add_call_basic: null
        }
    };

    function init(companies)
    {
        bindUIElements();
        initCategoriesTable();
        initCallBasicsTable();
        initAddCategoryModal();
        initAddCallBasicModal();
    }

    function bindUIElements()
    {
        self.tables.$categories = $('table#call-basic-categories');
        self.tables.$call_basics = $('table#call-basics');
    }

    function initCategoriesTable()
    {
        self.tables.$categories.DataTable({
            'order': [
                [0, 'asc']
            ],
            "columnDefs": [{
                "targets": 1,
                "orderable": false,
                "className": 'text-center text-red'
            }],
            "searching": false,
            "oLanguage": {
                "sLengthMenu": "Show _MENU_ categories per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Categories",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ Total Categories",
                "sEmptyTable": "No Categories Available"
            },
            "sPaginationType": "simple"
        });

        var callback = function(data, textStatus, jqXHR)
        {
            _.each(self.categories, function(category)
            {
                addCategoryToTable(category);
            })
        }

        getCategories(callback);
    }

    function initCallBasicsTable()
    {
        if (!$.fn.dataTable.isDataTable(self.tables.$call_basics))
        {
            self.tables.$call_basics.DataTable({
                'order': [
                    [0, 'asc']
                ],
                "columnDefs": [{
                    "targets": 2,
                    "orderable": false,
                    "className": 'text-center text-red'
                }],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ call basics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Call Basics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Call Basics",
                    "sEmptyTable": "No Call Basics Available"
                },
                "sPaginationType": "simple"
            });
        }

        self.tables.$call_basics.DataTable().clear();

        var callback = function(data, textStatus, jqXHR)
        {
            _.each(self.call_basics, function(call_basic)
            {
                addCallBasicToTable(call_basic);
            });
        }

        getCallBasics(callback);
    }

    function getCategories(optional_callback)
    {
        var callback = function (data, textStatus, jqXHR)
        {
            self.categories = data.data;

            if(typeof optional_callback != 'undefined')
                optional_callback(data, textStatus, jqXHR);
        }

        var body = {};

        PatientPrism.API.CallBasicCategories.all(callback);
    }

    function getCallBasics(optional_callback)
    {
        var callback = function (data, textStatus, jqXHR)
        {
            self.call_basics = data.data;

            if (typeof optional_callback != 'undefined')
                optional_callback(data, textStatus, jqXHR);
        }

        var body = {
            expand: 'category'
        };

        PatientPrism.API.CallBasics.all(callback, body);
    }

    function addCategoryToTable(category)
    {
        var row_node =
        self.tables.$categories.DataTable().row.add([
            '<span class="btn-edit-category text-link">' + category.name + '</span>',
            '<span class="btn-delete-category text-link text-red">Delete</span>'
        ])
        .draw(false)
        .node();

        $('.btn-edit-category', row_node).editable({
            type: 'text',
            title: 'Edit Category Name',
            placement: 'right',
            success: function(response, new_value) {
                var callback = function(data, textStatus, jqXHR)
                {
                    getCategories();
                    initCallBasicsTable();
                };

                var body = {
                    name: new_value
                };

                PatientPrism.API.CallBasicCategories.update(category.id, callback, body);
            }
        });

        $('.btn-delete-category', row_node).click(function(e){
            deleteCategoryFromTable(category, row_node);
        });
    }

    function addCallBasicToTable(call_basic)
    {
        var row_node =
        self.tables.$call_basics.DataTable().row.add([
            '<span class="btn-edit-call-basic text-link">' + call_basic.value + '</span>',
            '<span class="btn-edit-call-basic-category text-link">' +  (call_basic.category != null ? call_basic.category.name : 'Unassigned') + '</span>',
            '<span class="btn-delete-call-basic text-link text-red">Delete</span>'
        ])
        .draw(false)
        .node();

        $('.btn-edit-call-basic', row_node).editable({
            type: 'text',
            title: 'Edit Call Basic Name',
            placement: 'top',
            success: function(response, new_value) {
                var callback = function(data, textStatus, jqXHR)
                {
                    //
                };

                var body = {
                    value: new_value
                };

                PatientPrism.API.CallBasics.update(call_basic.id, callback, body);
            }
        });

        $('.btn-edit-call-basic-category', row_node).editable({
            type: 'select',
            value: call_basic.category_id,
            source: function() {
                var category_map = [];

                _.each(self.categories, function (category)
                {
                    category_map.push({
                        value: category.id,
                        text: category.name
                    });
                });

                return category_map;
            },
            title: 'Edit Call Basic Category',
            placement: 'top',
            success: function(response, new_value) {
                var callback = function(data, textStatus, jqXHR)
                {
                    //
                };

                var body = {
                    category_id: new_value
                };

                PatientPrism.API.CallBasics.update(call_basic.id, callback, body);
            }
        });

        $('.btn-delete-call-basic', row_node).click(function(e){
            deleteCallBasicFromTable(call_basic, row_node);
        });
    }

    function deleteCategoryFromTable(category, row_node)
    {
        swal({
            title: "Are you sure?",
            text: "You will need to recategorize any call basics assigned to this category.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, I'm Sure",
            cancelButtonText: "No, Cancel",
            showLoaderOnConfirm: true,
            closeOnConfirm: false,
            closeOnCancel: false
        },
        function(isConfirm)
        {
            if (isConfirm)
            {
                var callback = function(data, textStatus, jqXHR)
                {
                    getCategories();

                    $(row_node).fadeOut(function()
                    {
                        self.tables.$categories.DataTable().row(row_node).remove().draw(false);
                    });

                    initCallBasicsTable();

                    swal("Success", category.name + ' has been deleted.', "success");
                };

                PatientPrism.API.CallBasicCategories.destroy(category.id, callback);
            }
        });
    }

    function deleteCallBasicFromTable(call_basic, row_node)
    {
        swal({
            title: "Are you sure?",
            text: "This will alter the overall scoring bias for call reviews.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, I'm Sure",
            cancelButtonText: "No, Cancel",
            showLoaderOnConfirm: true,
            closeOnConfirm: false,
            closeOnCancel: false
        },
        function(isConfirm)
        {
            if (isConfirm)
            {
                var callback = function(data, textStatus, jqXHR)
                {
                    $(row_node).fadeOut(function()
                    {
                        self.tables.$call_basics.DataTable().row(row_node).remove().draw(false);
                    });

                    swal("Success", call_basic.value + ' has been deleted.', "success");
                };

                PatientPrism.API.CallBasics.destroy(call_basic.id, callback);
            }
        });
    }

    function initAddCategoryModal()
    {
        self.modals.$add_category = $('#modal-add-category');

        $('#btn-add-category', self.modals.$add_category).click( function(e)
        {
            e.preventDefault();

            PatientPrism.Common.UI.Loader.show();

            var callback = function (data, textStatus, jqXHR)
            {
                getCategories();
                addCategoryToTable(data.data);
                self.modals.$add_category.modal('hide');
                initCallBasicsTable();
                PatientPrism.Common.UI.Loader.hide();
                swal("Success", data.data.name + ' has been added.', "success");
            }

            var body = {
                name: $('*[name="name"]', self.modals.$add_category).val()
            };

            PatientPrism.API.CallBasicCategories.create(callback, body);
        });
    }

    function initAddCallBasicModal()
    {
        self.modals.$add_call_basic = $('#modal-add-call-basic');

        self.modals.$add_call_basic.on('show.bs.modal', function (e) {
            var $select_add_call_basic_category = $('select#add-call-basic-category', self.modals.$add_call_basic);

            $select_add_call_basic_category
                .empty()
                .append('<option selected="selected">Select a Category</option>');

            _.each(self.categories, function(category)
            {
                $select_add_call_basic_category
                .append('<option value="' + category.id + '">' + category.name + '</option>');
            });
        })

        $('#btn-add-call-basic', self.modals.$add_call_basic).click( function(e)
        {
            e.preventDefault();

            PatientPrism.Common.UI.Loader.show();

            var callback = function (data, textStatus, jqXHR)
            {
                initCallBasicsTable();
                self.modals.$add_call_basic.modal('hide');
                PatientPrism.Common.UI.Loader.hide();
                swal("Success", data.data.value + ' has been added.', "success");
            }

            var body = {
                value:       $('*[name="value"]', self.modals.$add_call_basic).val(),
                category_id: $('*[name="category_id"]', self.modals.$add_call_basic).val(),
            };

            PatientPrism.API.CallBasics.create(callback, body);
        });
    }
})();