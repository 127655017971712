(function() {
    namespace("PatientPrism.Common.UI.Filters.PredefinedRules", init, parseRules, initRulesDataTables, showBlinkMessage, transformToText, promiseCallback);

    /**
     * parse json string
     */
    function parseRules(el) {
        var action = el.val();
        if (action) {
            return JSON.parse(action);
        }
        return null;
    }
    
    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initRulesDataTables(table, dtOptions)
    {
        var oLanguage = {
            "sLengthMenu": "Show _MENU_ Rules per page",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Rules",
            "sInfoEmpty": "",
            "sInfoFiltered": " - filtered from _MAX_ total Rules",
            "sEmptyTable": "No Rules"
        };
        if (table.length)
        {
        	var defaults = {
                    "bFilter": false,
                    "oLanguage": oLanguage,
                    "sPaginationType": "simple"
            };
        	//merge objects
        	for (var attrname in dtOptions) { defaults[attrname] = dtOptions[attrname]; }
        	table.DataTable(defaults);
        }
    }
    
    /**
     * shows success or error nitification
     */
    function showBlinkMessage(el,isSuccess, message, callback) {
    	if (callback == undefined) {
    		callback = function() {};
    	}
    	var class1 =  "alert-danger";
    	var class2 =  "alert-success";
    	//swap
    	if (!isSuccess) {
        	var class2 =  "alert-danger";
        	var class1 =  "alert-success";
    	}
    	el.removeClass(class1).addClass(class2).text(message);
    	//close modal with 1 sec delay
    	setTimeout(function() {
    		el.removeClass(class1).removeClass(class2).html('');
    		callback();
    	},1000); 
    }
    
    /**
     * parse JSON query builder object to human readable format
     */
    function transformToText(el, filters) {
        el.find('.rule-block').each(function() {
	        var that = $(this);
	        //only one field is inside the rule either action or result
	        var rules = parseRules(that.find('[name=action],[name=result]'));
	        var length = rules.rules.length;
	        var text = '';
	        //iterate through QB rules
	        for (var i = 0;i < length; ++i) {
	        	var rule = rules.rules[i];
	        	var id = rule.id;
	        	var label = rule.field;
	        	var value = rule.value;
	        	var values = []; // storing set of values

				//override operator
				var operator = {
                    'equal': "equals",
                    'not_equal': "doesn't equal",
                    'in': "equal",
                    'not_in': "doesn't equal",
                }[rule.operator] || rule.operator;

	        	//iterate through filters
	        	for (var j =0; j < filters.length; ++j) {
	        		var filter = filters[j];
	        		//if filter match the rule's filter
	        		if (id == filter.id) {
	        			label = filter.label;
	        			//if filter has values
	        			if (filter.values != undefined && filter.values[value] != undefined) {
	        				value = filter.values[value];
	        				//if filter has plugin
	        			} else if (filter.plugin_config != undefined) {
	        				//iterate through filter plugin options
	        				for (var k =0; k < filter.plugin_config.options.length; ++k) {
	        					var option = filter.plugin_config.options[k];
	        					//igf option id match expected value
	        					if (option.id == value) {
	        						//set value to option value
	        						//and special fallback for keyword plugin
	        						value = id == "keyword" ? option.keyword : option.value;
	        						break;
	        					} else if ($.isArray(value) && value.length) {
	        						value.filter(function (v) {
										if (v == option.id) {
                                            values.push(option.value);
											return false;
										}
										return true;
                                    });
								}
	        				}

	        				if (values.length) {
	        					value = values.join(', ');
							}
	        			}
	        			break;
	        		}
	        	}

	        	value = (value === null) ? '' : value; // cast null values to empty string, i. e. on custom 'disabled' operator
	        	text += "<p>" + (i === 0 ? "" : "AND ") + label +" <b>" + operator + "</b> " + value  + "</p>";
	        }
	        that.append('<div class="rule-text">' + text + '</div>');
        });
    }

    /**
     * Init the page
     */
    function init() {
    	//define variable
    	var table = $('#datatable-predefined-rules');
        var token = $('#predefined-rules-form [name=_token]').val();
        
        
        //call global hangdler to launch the promicess
        //and pass the callback
        //filters - filters got from promices
        PatientPrism.Common.UI.Filters.BuilderGlobal.init(function(filters) {
        	promiseCallback(table, filters);

            //this should be called after all query builder elevemnts  rendered
        	initRulesDataTables(table, {
        		columnDefs: [
        	        { "orderable": false, "targets": [0,1,2]}
        	    ]
        	});
        });
    }
    
    /**
     * gets filters from promises and format them
     */
    function promiseCallback(table, filters) {
    	//exclude filters by id
        var exclude = [ 'relo_notification', 'target_call',
                'follow_up_recommended', 'phone_number' ];
        var filters = _.filter(filters, function(item) {
            return exclude.indexOf(item.id) == -1;
        });
        filters = _.map(filters, function(item) {
	        //allow only unique selectors
        	item.unique = true;
        	return item;
        });
        //init query builder to all data table elements
        transformToText(table, filters);
        return filters;
    }
})();