(function()
{
    namespace("PatientPrism.Common.UI.CallBasicsExplorer", init);

    var self = {
        data: {
            performance: {
                call_basic_categories: null,
                call_basics:           null,
                staff:                 null
            }
        },
        el: {
            table: {
                $staff:       null,
                $call_basics: null
            }
        }
    }

    function init(call_basic_category_performance, call_basic_performance, staff_performance)
    {
        self.data.performance.call_basic_categories = call_basic_category_performance;
        self.data.performance.call_basics           = call_basic_performance;
        self.data.performance.staff                 = staff_performance;

        initCallBasicsSpeedometers();
        initStaffTable();
        initCallBasicsTable();
    }

    function initCallBasicsSpeedometers()
    {
        if (!$('.col-chart').length)
            return;

        $.each($('.col-chart'), function( index, value )
        {
            $('.call-basic-explorer-speedometer', this).highcharts({
                chart: {
                    zoomType: 'xy',
                    type: 'solidgauge',
                    height: 180,
                    backgroundColor: {
                        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                        stops: [
                            [0, '#FFFFFF'],
                            [1, '#FFFFFF']
                        ]
                    },
                    style: {
                        fontFamily: "'Proxima Nova Bold', sans-serif",
                        textShadow: 'none',
                        color: "#47657B"
                    },
                },
                title: {
                    text: self.data.performance.call_basic_categories[index].call_basic_category.name,
                    style: {
                        color: '#47657B',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        textShadow: 'none'
                    }
                },
                pane: {
                    center: ['50%', '85%'],
                    size: '140%',
                    startAngle: -90,
                    endAngle: 90,
                    background: {
                        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                        innerRadius: '60%',
                        outerRadius: '100%',
                        shape: 'arc'
                    }
                },
                // the value axis
                yAxis: {
                    stops: [
                        [0.3, '#DF5353'], // red
                        [0.5, '#F4AC5D'], // yellow
                        [0.75, '#67C474'] // green
                    ],
                    lineWidth: 0,
                    title: {
                        y: -70,
                    },
                    labels: {
                        y: 16
                    },
                    min: 0,
                    max: 100,
                    minorTickInterval: null,
                    tickAmount: 0,
                    showFirstLabel:false,
                    showLastLabel:false,
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            y: 5,
                            borderWidth: 0,
                            useHTML: true
                        }
                    }
                },
                series: [{
                    name: self.data.performance.call_basic_categories[index].call_basic_category.name,
                    data: [Number((self.data.performance.call_basic_categories[index].percent).toFixed(0))],
                    dataLabels: {
                        format: '<div style="text-align:center"><span style="font-size:20px;color:' +
                             '#2B3E4E' + '">{y}%</span>'
                    },
                }],
                tooltip: {
                    enabled: false
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                }
            });

            $('.call-basic-explorer-speedometer', this).click(function(e)
            {
                e.preventDefault();

                alert($(this).attr('data-category-id'));
            });
        });
    }

    function initStaffTable()
    {
        self.el.table.$staff = $('[call-basic-explorer-element="staff-table"]');

        if (self.el.table.$staff.length)
        {
            self.el.table.$staff.DataTable({
                responsive: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                'order': [
                    [0, 'asc']
                ],
                columnDefs: [
                    {
                        className: 'text-center',
                        targets:   [1]
                    }
                ],
                columns: [
                    {data: 'name'},
                    {data: 'score'},
                ],
                createdRow: function ( row_node, data, index )
                {
                    $(row_node).addClass('staff-row text-link');

                    $(row_node).click(function(e)
                    {
                        e.preventDefault();

                        // Remove current active class and set current row to active
                        $('.staff-row', self.el.table.$staff).removeClass('info');
                        $(row_node).addClass('info');

                        document.activeElement.blur();

                        // Clear previous records from table
                        self.el.table.$call_basics.DataTable().clear();

                        // Add each user's call basic performance record to the table
                        _.each(data.call_basic_performance, function (metric)
                        {
                            addItemToCallBasicsTable(metric);
                        })
                    })
                },
                language: {
                    "sLengthMenu": "Show _MENU_ users per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Users",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total users",
                    "sEmptyTable": "No Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });

            _.each(self.data.performance.staff, function (user)
            {
                addUserToStaffTable(user);
            });
        }
    }

    function addUserToStaffTable (performance_record)
    {
        // Add row to table
        self.el.table.$staff.DataTable().row.add({
            id:                     performance_record.user.id,
            name:                   performance_record.user.name,
            call_basic_performance: performance_record.call_basic_performance,
            score:                  Math.round(
                    _.meanBy(performance_record.call_basic_performance, function(o)
                    {
                        return o.percent;
                    })
                ) + '%',
        });

        self.el.table.$staff.DataTable().draw();
    }

    function initCallBasicsTable()
    {
        self.el.table.$call_basics = $('[call-basic-explorer-element="call-basics-table"]');

        if (self.el.table.$call_basics.length)
        {
            self.el.table.$call_basics.DataTable({
                responsive: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                'order': [
                    [0, 'asc']
                ],
                columnDefs: [
                    {
                        className: 'text-center',
                        targets:   [2]
                    }
                ],
                columns: [
                    {data: 'call_basic'},
                    {data: 'category'},
                    {data: 'score'},
                ],
                createdRow: function ( row_node, data, index )
                {
                    //
                },
                language: {
                    "sLengthMenu": "Show _MENU_ call basics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Call Basics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total call basics",
                    "sEmptyTable": "Select a Staff Member",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }

    function addItemToCallBasicsTable(performance_record)
    {
        // Add row to table
        self.el.table.$call_basics.DataTable().row.add({
            id:    performance_record.call_basic.id,
            call_basic:  performance_record.call_basic.value,
            category:  performance_record.category.name,
            score: performance_record.percent + '%',
        });

        self.el.table.$call_basics.DataTable().draw();
    }
})();