(function()
{
    namespace("PatientPrism.API.HTTP.Socket", get, post, patch, destroy);

    let self = {
        type: {
            api: {
                url: 'https://api.patientprism.com',
                beforeSend: function (request)
                {
                    request.setRequestHeader("Authorization", 'Bearer ' + $.cookie('token'));
                },
            },
            web: {
                url: 'https://web.patientprism.com',
                beforeSend: function (request)
                {
                    request.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
                },
            },
        },
    };

    function get(url, callback, body, type)
    {
        call('GET', url, callback, body, type);
    }

    function post(url, callback, body, type)
    {
        call('POST', url, callback, body, type);
    }

    function patch(url, callback, body)
    {
        call('PATCH', url, callback, body);
    }

    function destroy(url, callback, body)
    {
        call('DELETE', url, callback, body);
    }

    function call(method, url, callback, body, type)
    {
        type = type || 'api';

        let server_url = 'patientprism_' + type + '_url';
    	server_url = (window.hasOwnProperty(server_url) ? window[server_url] : self[type].url) + url;

        let ajax_parameters = {
            dataType: 'json',
            url: server_url,
            type: method,
            data: body,
            beforeSend: function (request) {
                self.type[type].beforeSend(request);
            },
        };

        $.ajax(ajax_parameters)
        .done(function(data, textStatus, jqXHR)
        {
            callback(data, textStatus, jqXHR);
        })
        .fail(function(jqXHR, textStatus, errorThrown)
        {
            callback(jqXHR.responseJSON, textStatus, jqXHR);
        });
    }
})();
