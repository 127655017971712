(function() {
    namespace("PatientPrism.Common.UI.RenderHelper", init, friendly_phone, isReservedPhoneNumber, seconds_to_hms, local_time);

    let self = {
        currentUserTimezone: null
    };

    const reservedNumbers = {
        '+266696687': 'anonymous',
        '+7378742883': 'restricted',
        '+2562533': 'blocked',
        '+8656696': 'unknown',
        '+86282452253': 'unavailable'
    };

    function init(currentUserTimezone)
    {
        self.currentUserTimezone = currentUserTimezone;
    }

    function friendly_phone(phone_number)
    {
        if (isReservedPhoneNumber(phone_number)) {
            return ucfirst(reservedNumbers[phone_number]);
        }

        let regExp = /^\+\d(\d{3})(\d{3})(\d{4})$/;
        let matches = regExp.exec(phone_number);
        if (matches && matches.length) {
            return "({0}) {1} {2}".format(matches[1], matches[2], matches[3]);
        }

        return phone_number;
    }

    function isReservedPhoneNumber(phoneNumber)
    {
        return reservedNumbers.hasOwnProperty(phoneNumber);
    }


    function seconds_to_hms(seconds, separator = ':')
    {
        seconds = parseFloat(seconds);
        return "{0}{1}{2}{3}{4}".format(
            ('0' + Math.floor(seconds / 3600)).slice(-2),
            separator,
            ('0' + Math.floor((seconds / 60) % 60)).slice(-2),
            separator,
            ('0' + (seconds % 60)).slice(-2)
        );
    }

    function local_time(utc_datetime = null, format = null, timezone = null)
    {
        if (!utc_datetime) {
            return '';
        }

        let date = moment.utc(utc_datetime);

        if (!timezone) {
            timezone = self.currentUserTimezone;
        }

        format = format ? format : 'MM/DD/YYYY hh:mm A';

        return "{0} {1}".format(
            date.tz(timezone).format(format),
            timezone ? date.tz(timezone).format('z') : ''
        );
    }

    function ucfirst(s)
    {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    String.prototype.format = function() {
        let formatted = this;
        for (let arg in arguments) {
            formatted = formatted.replace("{" + arg + "}", arguments[arg]);
        }
        return formatted;
    };
})();
