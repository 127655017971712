(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.Trends", init);

    var self = {
        $table: null,
        report_data: null,
        locations: null,
        location_groups: null
    };


    function init(data)
    {
        self.report_data = JSON.parse(data);

        self.locations = self.report_data.locations_list;
        self.location_groups = self.report_data.location_groups;

    	Highcharts.setOptions({exporting: {
            chart: {
                zoomType: 'xy'
            },
            tooltip: {
                shared: true
            },
            xAxis: [{
                crosshair: true
            }],
    		enabled: true,
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 100,
                verticalAlign: 'top',
                y: 30,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            buttons: {
                contextButton: {
                    menuItems: [{
                	    text: "Yearly",
                	    onclick: function() {
                	    	makeData(this, "Yearly");
                	    }
                	},{
                	    text: "Monthly",
                	    onclick: function() {
                	    	makeData(this, "Monthly");
                	    }
                	},{
                	    text: "Weekly",
                	    onclick: function() {
                	    	makeData(this, "Weekly");
                	    }
                	}]
                }
            }
    	}});
        initNewOpportunitiesChart();
        initExistingOpportunitiesChart();
        initLocationScoreChart();
        initSellablesChart();
        initInsightsPerformanceTable('new_opportunity.booked.monthly', 'New Opportunity: Booked', 'new_opportunity.not_booked.monthly');
        initInsightsRevenueTable('won', 'Opportunities Won');
        initNotBookedTable();
        initNotConnectedTable();
        initReasonsNotConnectedTable();
    }


    function initExistingOpportunitiesChart()
    {
        var booked = self.report_data.existing_opportunity.booked;
        var not_booked = self.report_data.existing_opportunity.not_booked;
        var not_connected = self.report_data.not_connected;
        var total = self.report_data.total;

        initOpportunitiesChart( $('#new-opportunities-chart'), 'Existing Opportunities', booked, not_booked, not_connected, total );
    }

    function initNewOpportunitiesChart()
    {
        var booked = self.report_data.new_opportunity.booked;
        var not_booked = self.report_data.new_opportunity.not_booked;
        var not_connected = self.report_data.not_connected;
        var total = self.report_data.total;

        initOpportunitiesChart( $('#existing-opportunities-chart'), 'New Opportunities', booked, not_booked, not_connected, total );
    }



    function initOpportunitiesChart(selector, title, booked, not_booked, not_connected, total)
    {
        selector.highcharts({
            title: {
                text: title
            },
            subtitle: {
                text: 'Booked vs. Not Booked',
            },
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                },
                stackLabels: {
                    enabled: true,
                    useHTML:true,
                    style: {
                        fontWeight: 'bold',
                        color: '#586b75',
                        textOutline: false
                    },
                    formatter: function() {
                    return '<b> <span class="fa fa-phone"></span> '+ Math.round(this.total*Math.pow(10,2))/Math.pow(10,2)+'</b>'
                    },

                    y: -10
                },
            }],
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span>{series.name}: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            series: [{
                name: 'Booked',
                stack: 'booking_status',
                type: 'column',
                tooltip: {
                    valueSuffix: ' Calls',
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[11]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[11], 0)]
                    ]
                }
            }, {
                name: 'Not Booked',
                stack: 'booking_status',
                type: 'column',
                tooltip: {
                    valueSuffix: ' Calls'
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[8]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[8], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            },
            {
                name: 'Not Connected (During Business Hours)',
                stack: 'not_connected',
                type: 'column',
                tooltip: {
                    valueSuffix: ' Calls'
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[3]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[3], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            }]
        });

        selector = selector.highcharts();
        selector.seriesData = [
			booked,
			not_booked,
			not_connected,
    	];
    	makeData(selector, "Monthly");
    }

    function makeData(selector, range) {
    	let data = selector.seriesData;

    	for (let i in data) {
    		if (!data[i]) {
    			return false;
    		}
    	}

    	let seriesCount = data.length;
    	const monthNames = [null, "January", "February", "March", "April", "May", "June",
    		  "July", "August", "September", "October", "November", "December"
    	];
    	let categories = [];
    	let seriesData = _.range(seriesCount).map(function () { return [] });
    	if (range == "Yearly") {
    		for (let year in data[0].yearly) {
	    		categories.push(year);
	    		for (let j in data) {
	    			seriesData[j].push(data[j].yearly[year]);
	    		}
    		}
    	} else {
    		let callback = range == "Weekly" ? function(year, i) {
    			return year + " week #" + i
    		} : function(year, i) {
    			return monthNames[i * 1] + " " + year;
    		};
    		let index = range == "Weekly" ? 'weekly' : 'monthly';
    		for (let year in data[0][index]) {
    			let yearly = data[0][index][year];
    			//fixed bug with incorrect foreach in js
    			let yearly_keys = Object.keys(yearly).sort();
	    		for (let m = 0; m < yearly_keys.length; ++m) {
	    			let i = yearly_keys[m];
	    			categories.push(callback(year, i));
	    			for (let j in data) {
	    				let value = data[j][index][year][i];

	    				seriesData[j].push((typeof value !== 'undefined' ? value : 0));
	    			}
	    		}
    		}
    	}

    	//this is highchart object in this context
	    selector.xAxis[0].setCategories(categories);
    	for (let i = 0; i < seriesCount; ++i) {
    		selector.series[i].setData(seriesData[i] , true);
    	}
    }

    function initLocationScoreChart() {
    	var selector = $('#location-score-chart');
    	selector.highcharts({
            title: {
                text: 'Average Call Score'
            },
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Average Score',
                },
            }],
            series: [{
                name: 'Average Score',
                type: 'areaspline',
                tooltip: {
                    valueSuffix: ''
                },
                shadow: true,
                dataLabels: {
                    enabled: true,
                    color: '#586b75',
                    style: {
                        textOutline: false
                    },
                    formatter: function() {
                        return this.y + ' %';
                    }
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[9]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[9], 0)]
                    ]
                }
            }]
        });
        selector = selector.highcharts();
        selector.seriesData = [self.report_data.call_scores];
    	makeData(selector, "Monthly");
    }

    function initSellablesChart() {
    	var selector = $('#sellables-chart');
    	selector.highcharts({
            title: {
                text: 'Revenue Trends'
            },
            subtitle: {
                text: 'Oportunities Won vs. Lost',
            },
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '$ {value}',
                },
                title: {
                    text: 'Opportunity Value',
                }
            }],
            series: [{
                name: 'Won Opportunities',
                type: 'spline',
                tooltip: {
                    valuePrefix: '$ '
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[11]],
                        [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[11], 0)]
                    ]
                }
            }, {
                name: 'Lost Opportunities',
                type: 'spline',
                tooltip: {
                    valuePrefix: '$ '
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[8]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[8], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            }
            , {
                name: 'Revenue',
                type: 'spline',
                tooltip: {
                    valuePrefix: '$ '
                },
                dataLabels: {
                    enabled: true,
                    color: '#586b75',
                    style: {
                        textOutline: false
                    },
                    formatter: function() {
                        return '$ ' + this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[5]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[5], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true,
            }],
        });

        selector = selector.highcharts();

        let sellables = self.report_data.sellables;
        if (!sellables) {
        	return;
        }
        //cloning object structure
        let won = JSON.parse(JSON.stringify(sellables));
        let lost = JSON.parse(JSON.stringify(sellables));
        let revenue = JSON.parse(JSON.stringify(sellables));

        for (let year in sellables.yearly) {
        	let item = sellables.yearly[year][0];
        	won.yearly[year] = item.won;
        	lost.yearly[year] = item.lost;
        	revenue.yearly[year] = item.won - item.lost;
        }
        for (let year in sellables.monthly) {
            for (let month in sellables.monthly[year]) {
            	let item = sellables.monthly[year][month][0];
            	won.monthly[year][month] = item.won;
            	lost.monthly[year][month] = item.lost;
            	revenue.monthly[year][month] = item.won - item.lost;
            }
        }
        for (let year in sellables.weekly) {
            for (let week in sellables.weekly[year]) {
            	let item = sellables.weekly[year][week][0];
            	won.weekly[year][week] = item.won;
            	lost.weekly[year][week] = item.lost;
            	revenue.weekly[year][week] = item.won - item.lost;
            }
        }

        selector.seriesData = [won, lost, revenue];
    	makeData(selector, "Monthly");
    }

    let formatters = PatientPrism.Modules.Analize.Helpers.Formatters;
    let renderers = PatientPrism.Modules.Analize.Helpers.Renderers;

    let filter_status = {
        'insights_performance_table': [],
        'insights_revenue_table': [],
        'reason_not_booked_table': [],
        'not_connected_table': [],
        'reason_not_connected_table': [],
    };

    function initInsightsPerformanceTable(location_data_key, filter_name, percent_against)
    {
        let data = [];
        var columns = [];

        filter_status.insights_performance_table = [
            location_data_key || 'new_opportunity.booked.monthly',
            filter_name || 'New Opportunity: Booked',
            percent_against || 'new_opportunity.not_booked.monthly'
        ];
        location_data_key = filter_status.insights_performance_table[0];

        let classification = filter_name.split(':')[0].trim();
        let booking_status = filter_name.split(':')[1].trim();

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_performance',
            self.locations,
            self.location_groups,
            self.report_data.locations,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let report_data = data_converter.get_data('insights_performance');

        _.each(report_data, function(location)
        {
            var location_data = {};
            location_data.data = location;
            location_data.location = location.location;

            var key_data = location_data_key.split('.').reduce(function(p, c) {
                return p.hasOwnProperty(c) && p[c] || p;
            }, location);

            _.each(key_data, function (months, year)
            {
                _.each (months, function (metric, month)
                {
                    let period = get_period(month, year);

                    var column_title = month + '/' + year;

                    if (typeof _.find(columns, ['title', column_title]) === 'undefined') {

                        let getData = function (row, column_title) {
                            return typeof row[column_title] === 'undefined' ? 0 : row[column_title];
                        };

                        let getTotal = function (row, data) {
                            let percent_key_value = percent_against.split('.').reduce(function (p, c) {
                                return p.hasOwnProperty(c) && p[c] || p;
                            }, row.data);

                            let percent_value = 0;

                            if (typeof percent_key_value[year] !== 'undefined' &&
                                typeof percent_key_value[year][month] !== 'undefined'
                            ) {
                                percent_value = percent_key_value[year][month];
                            }
                            return (data + percent_value);
                        };

                        columns.push({
                            title: column_title,
                            data: function (row, type, val, meta) {
                                let data = getData(row, column_title);
                                let total = getTotal(row, data);
                                let v = (!total ? 0 : (data / total) * 100);

                                let route_params = {
                                    ignore_locations: 1,
                                    date_start: period.date_start,
                                    date_end: period.date_end,
                                    metadata: {
                                        classification: classification,
                                        booking_status: booking_status,
                                    },
                                    weighted: 1,
                                };
                                route_params[data_converter.get_first_index_key('insights_performance')] = row.location.id;

                                return formatters.calls(
                                    data,
                                    type,
                                    v.toFixed(2) + '%',
                                    'insights_performance',
                                    {
                                        route: 'calls.search',
                                        route_params: route_params,
                                    },

                                );
                            },
                            className: 'text-center sum-column',
                            render: function (data, type, row, meta) {
                                let value = getData(row, column_title);
                                let total = getTotal(row, value);
                                let v = (!total ? 0 : (value / total) * 100);
                                return renderers.calls(data, value, type, v, 'insights_performance');
                            }
                        });
                    }

                    location_data[column_title] = metric;
                });
            });

            data.push(location_data);
        });

        columns =
        _.sortBy(columns, function(item)
        {
            var date_params = item.title.split('/');
            return moment(date_params[0] + '/01/' + date_params[1]).unix();
        });

        columns.unshift({
            title: 'Location',
            data: 'location.name'
        });

        buildInsightsPerformanceTable(columns, data, filter_name);
    }

    function buildInsightsPerformanceTable(columns, data, filter_name)
    {
        self.$insights_performance_table = $('table#insights-performance');

        if (!self.$insights_performance_table.length)
            return;

        if ($.fn.DataTable.isDataTable('table#insights-performance'))
        {
            self.$insights_performance_table.DataTable().destroy();
            self.$insights_performance_table.empty();
        }

        self.$insights_performance_table.DataTable({
            data: data,
            columns: columns,
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(function() {
                        initInsightsPerformanceTable(
                            ...filter_status.insights_performance_table
                        );
                    },
                    'insights_performance'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(function() {
                        initInsightsPerformanceTable(
                            ...filter_status.insights_performance_table
                        );
                    },
                    'insights_performance'
                ),
                {
                    extend:    'collection',
                    text:      '<i class="fa fa-filter"></i> ' + filter_name + ' <span class="caret"></span>',
                    className: 'btn-sm action-button dt-button enterprise-button',
                    buttons:   [
                        {
                            text: 'New Opportunity: Booked',
                            action: function ( e, dt, node, config ) {
                                filter_status.insights_performance_table = [
                                    'new_opportunity.booked.monthly',
                                    'New Opportunity: Booked',
                                    'new_opportunity.not_booked.monthly'
                                ];
                                initInsightsPerformanceTable(...filter_status.insights_performance_table);
                            }
                        },
                        {
                            text: 'New Opportunity: Not Booked',
                            action: function ( e, dt, node, config )
                            {
                                filter_status.insights_performance_table = [
                                    'new_opportunity.not_booked.monthly',
                                    'New Opportunity: Not Booked',
                                    'new_opportunity.booked.monthly'
                                ];
                                initInsightsPerformanceTable(...filter_status.insights_performance_table);
                            }
                        },
                        {
                            text: 'Existing Opportunity: Booked',
                            action: function ( e, dt, node, config ) {
                                filter_status.insights_performance_table = [
                                    'existing_opportunity.booked.monthly',
                                    'Existing Opportunity: Booked',
                                    'existing_opportunity.not_booked.monthly'
                                ];
                                initInsightsPerformanceTable(...filter_status.insights_performance_table);
                            }
                        },
                        {
                            text: 'Existing Opportunity: Not Booked',
                            action: function ( e, dt, node, config )
                            {
                                filter_status.insights_performance_table = [
                                    'existing_opportunity.not_booked.monthly',
                                    'Existing Opportunity: Not Booked',
                                    'existing_opportunity.booked.monthly'
                                ];
                                initInsightsPerformanceTable(...filter_status.insights_performance_table);
                            }
                        }
                    ]
                },
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {
                var api = this.api();
                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = {
                    //
                };

                this.api().columns().every(function ()
                {
                    var column = this;
                    var data = '';

                    if ($(column.header()).hasClass('sum-column'))
                    {
                        var sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        var plural = sum > 1 ? 's' : '';

                        if ($(column.header()).hasClass('percent-column'))
                        {
                            var percent = Math.round((sum/totals[column.index()]) * 100);
                            data = `${sum} Call${plural} <br/> <small class="text-muted">${percent}%</small>`
                        }
                        else
                        {
                            data = `${sum} Call${plural}`
                        }

                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            }
        });
    }

    function initInsightsRevenueTable(location_data_key, filter_name)
    {
        var data = [];
        var columns = [];

        filter_status.insights_revenue_table = [
            location_data_key || 'won',
            filter_name || 'Opportunities Won'
        ];
        location_data_key = filter_status.insights_revenue_table[0];

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_revenue',
            self.locations,
            self.location_groups,
            self.report_data.locations,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let report_data = data_converter.get_data('insights_revenue');

        _.each(report_data, function(location)
        {
            var location_data = {};
            location_data.location = location.location;

            _.each(location.sellables.monthly, function (months, year)
            {
                _.each (months, function (metric, month)
                {
                    var column_title = month + '/' + year;

                    if (typeof _.find(columns, ['title', column_title]) === 'undefined')
                        columns.push({
                            title: column_title,
                            data: function(row)
                            {
                                return typeof row[column_title] === 'undefined' ? 0 : row[column_title];
                            },
                            className: 'text-center sum-column money-column',
                            render: formatters.money
                        });

                    location_data[column_title] = typeof metric[location_data_key] === 'undefined' ? 0 : metric[location_data_key];
                });
            });

            data.push(location_data);
        });

        columns =
        _.sortBy(columns, function(item)
        {
            var date_params = item.title.split('/');
            return moment(date_params[0] + '/01/' + date_params[1]).unix();
        });

        columns.unshift({
            title: 'Location',
            data: 'location.name'
        });

        buildInsightsRevenueTable(columns, data, filter_name);
    }

    function buildInsightsRevenueTable(columns, data, filter_name)
    {
        self.$insights_revenue_table = $('table#insights-revenue');

        if (!self.$insights_revenue_table.length)
            return;

        if ($.fn.DataTable.isDataTable('table#insights-revenue'))
        {
            self.$insights_revenue_table.DataTable().destroy();
            self.$insights_revenue_table.empty();
        }

        self.$insights_revenue_table.DataTable({
            data: data,
            columns: columns,
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(function() {
                        initInsightsRevenueTable(
                            ...filter_status.insights_revenue_table
                        );
                    },
                    'insights_revenue'
                ),
            {
                extend:    'collection',
                text:      '<i class="fa fa-filter"></i> ' + filter_name + ' <span class="caret"></span>',
                className: 'btn-sm action-button dt-button enterprise-button',
                buttons:   [
                    {
                        text: 'Opportunities Won',
                        action: function ( e, dt, node, config ) {
                            initInsightsRevenueTable('won', 'Opportunities Won');
                        }
                    },
                    {
                        text: 'Opportunities Lost',
                        action: function ( e, dt, node, config )
                        {
                            initInsightsRevenueTable('lost', 'Opportunities Lost');
                        }
                    }
                ]
            },
            {
                extend:        'csv',
                text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                filename:      'custom-report',
                className:     'btn-sm action-button dt-button',
                exportOptions: {
                    orthogonal: 'export',
                    columns: [ ':visible :not(.no-export)' ]
                },
            },
            {
                extend:        'colvis',
                text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                className:     'btn-sm action-button dt-button',
                columns:       ':not(.no-toggle-visibility)'

            }],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {
                var api = this.api();
                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = {
                    //
                };

                this.api().columns().every(function ()
                {
                    var column = this;
                    var data = '';

                    if ($(column.header()).hasClass('sum-column'))
                    {
                        var sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        var plural = sum > 1 ? 's' : '';

                        if ($(column.header()).hasClass('percent-column'))
                        {
                            var percent = Math.round((sum/totals[column.index()]) * 100);
                            data = `${sum} Call${plural} <br/> <small class="text-muted">${percent}%</small>`;
                        }
                        else if ($(column.header()).hasClass('money-column'))
                        {
                            data = '$' + sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                        else
                        {
                            data = `${sum} Call${plural}`
                        }

                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            }
        });
    }

    function initNotBookedTable(filter_name)
    {
        var button_array = [];

        _.each(self.report_data.locations, function(location)
        {
            _.each(location.reason_not_booked, function(data, key)
            {
                if (typeof _.find(button_array, ['text', key]) === 'undefined')
                {
                    button_array.push({
                        text: key,
                        action: function ( e, dt, node, config ) {
                            filter_status.reason_not_booked_table = [
                                config.text
                            ];
                            initNotBookedTable(...filter_status.reason_not_booked_table);
                        }
                    });
                }
            });
        });

        if (!button_array.length)
            return;

        filter_status.reason_not_booked_table = [
            filter_name || button_array[0].text
        ];

        if (typeof filter_name === 'undefined' || !filter_name)
            filter_name = button_array[0].text;

        var data = [];
        var columns = [];

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_not_booked',
            self.locations,
            self.location_groups,
            self.report_data.locations,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let report_data = data_converter.get_data('insights_not_booked');

        _.each(report_data, function(location)
        {
            var location_data = {};
            location_data.data = location;
            location_data.location = location.location;

            if (typeof location.reason_not_booked[(filter_name)] === 'undefined')
                return;

            _.each(location.reason_not_booked[(filter_name)].monthly, function (months, year)
            {
                _.each (months, function (metric, month)
                {
                    let period = get_period(month, year);

                    var column_title = month + '/' + year;

                    if (typeof _.find(columns, ['title', column_title]) === 'undefined') {
                        let getData = function (row, column_title) {
                            return typeof row[column_title] === 'undefined' ? 0 : row[column_title];
                        };
                        let getTotal = function (row) {
                            let total_reasons = 0;

                            _.each(row.data.reason_not_booked, function (value, key) {
                                if (typeof value['monthly'][year] !== 'undefined' && typeof value['monthly'][year][month] !== 'undefined')
                                    total_reasons += value['monthly'][year][month];
                            });

                            return total_reasons;
                        };
                        columns.push({
                            title: column_title,
                            data: function (row, type, val, meta) {
                                let data = getData(row, column_title);
                                let total = getTotal(row);
                                let v = (!total ? 0 : (data / total) * 100);

                                let route_params = {
                                    ignore_locations: 1,
                                    date_start: period.date_start,
                                    date_end: period.date_end,
                                    metadata: {
                                        reason_not_booked: filter_name,
                                    },
                                    weighted: 1,
                                };
                                route_params[data_converter.get_first_index_key('insights_not_booked')] = row.location.id;

                                return formatters.calls(
                                    data,
                                    type,
                                    v.toFixed(2) + '%',
                                    'insights_not_booked',
                                    {
                                        route: 'calls.search',
                                        route_params: route_params,
                                    },
                                );
                            },
                            className: 'text-center sum-column',
                            render: function (data, type, row, meta) {
                                let value = getData(row, column_title);
                                let total = getTotal(row);
                                let v = (!total ? 0 : (value / total) * 100);
                                return renderers.calls(data, value, type, v, 'insights_not_booked');
                            }
                        });
                    }

                    location_data[column_title] = metric;
                });
            });

            data.push(location_data);
        });

        columns =
        _.sortBy(columns, function(item)
        {
            var date_params = item.title.split('/');
            return moment(date_params[0] + '/01/' + date_params[1]).unix();
        });

        columns.unshift({
            title: 'Location',
            data: 'location.name'
        });

        buildNotBookedTable(columns, data, filter_name, button_array);
    }

    function buildNotBookedTable(columns, data, filter_name, buttons)
    {
        self.$insights_not_booked_table = $('table#insights-not-booked');

        if (!self.$insights_not_booked_table.length)
            return;

        if ($.fn.DataTable.isDataTable('table#insights-not-booked'))
        {
            self.$insights_not_booked_table.DataTable().destroy();
            self.$insights_not_booked_table.empty();
        }

        self.$insights_not_booked_table.DataTable({
            data: data,
            columns: columns,
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(function() {
                        initNotBookedTable(
                            ...filter_status.reason_not_booked_table
                        );
                    },
                    'insights_not_booked'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(function() {
                        initNotBookedTable(
                            ...filter_status.reason_not_booked_table
                        );
                    },
                    'insights_not_booked'
                ),
                {
                    extend:    'collection',
                    text:      '<i class="fa fa-filter"></i> ' + filter_name + ' <span class="caret"></span>',
                    className: 'btn-sm action-button dt-button enterprise-button',
                    buttons:   buttons,
                },
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {

                var api = this.api();
                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = {
                    //
                };

                this.api().columns().every(function ()
                {
                    var column = this;
                    var data = '';

                    if ($(column.header()).hasClass('sum-column'))
                    {
                        var sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        var plural = sum > 1 ? 's' : '';

                        if ($(column.header()).hasClass('percent-column'))
                        {
                            var percent = Math.round((sum/totals[column.index()]) * 100);
                            data = `${sum} Call${plural} <br/> <small class="text-muted">${percent}%</small>`
                        }
                        else
                        {
                            data = `${sum} Call${plural}`
                        }

                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            }
        });
    }

    function initNotConnectedTable()
    {
        var data = [];
        var columns = [];

        filter_status.not_connected_table = [
            // Do not remove this definition
        ];

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_not_connected',
            self.locations,
            self.location_groups,
            self.report_data.locations,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let report_data = data_converter.get_data('insights_not_connected');

        _.each(report_data, function(location)
        {
            var location_data = {};
            location_data.data = location;
            location_data.location = location.location;

            if (typeof location.not_connected === 'undefined')
                return;

            _.each(location.not_connected.monthly, function (months, year)
            {
                _.each (months, function (metric, month)
                {
                    let period = get_period(month, year);

                    var column_title = month + '/' + year;

                    if (typeof _.find(columns, ['title', column_title]) === 'undefined') {

                        let getData = function (row, column_title) {
                            return typeof row[column_title] === 'undefined' ? 0 : row[column_title];
                        };
                        let getTotal = function (row) {
                            let total_calls = 0;

                            if (typeof row.data.total.monthly[year] !== 'undefined' && typeof row.data.total.monthly[year][month] !== 'undefined')
                                total_calls = row.data.total.monthly[year][month];

                            return total_calls;
                        };
                        columns.push({
                            title: column_title,
                            data: function (row, type, val, meta) {
                                let data = getData(row, column_title);
                                let total = getTotal(row);
                                let v = (!total ? 0 : (data / total) * 100);

                                let route_params = {
                                    ignore_locations: 1,
                                    date_start: period.date_start,
                                    date_end: period.date_end,
                                    metadata: {
                                        classification: 'Not Connected',
                                    },
                                    weighted: 1,
                                };
                                route_params[data_converter.get_first_index_key('insights_not_connected')] = row.location.id;

                                return formatters.calls(
                                    data,
                                    type,
                                    v.toFixed(2) + '%',
                                    'insights_not_connected',
                                    {
                                        route: 'calls.search',
                                        route_params: route_params,
                                    },
                                );
                            },
                            className: 'text-center sum-column',
                            render: function (data, type, row, meta) {
                                let value = getData(row, column_title);
                                let total = getTotal(row);
                                let v = (!total ? 0 : (value / total) * 100);
                                return renderers.calls(data, value, type, v, 'insights_not_connected');
                            }
                        });
                    }

                    location_data[column_title] = metric;
                });
            });

            data.push(location_data);
        });

        columns =
        _.sortBy(columns, function(item)
        {
            var date_params = item.title.split('/');
            return moment(date_params[0] + '/01/' + date_params[1]).unix();
        });

        columns.unshift({
            title: 'Location',
            data: 'location.name'
        });

        buildNotConnectedTable(columns, data);
    }

    function buildNotConnectedTable(columns, data)
    {
        self.$insights_not_connected_table = $('table#insights-not-connected');

        if (!self.$insights_not_connected_table.length)
            return;

        if ($.fn.DataTable.isDataTable('table#insights-not-connected'))
        {
            self.$insights_not_connected_table.DataTable().destroy();
            self.$insights_not_connected_table.empty();
        }

        self.$insights_not_connected_table.DataTable({
            data: data,
            columns: columns,
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(function() {
                        initNotConnectedTable(
                            ...filter_status.not_connected_table
                        );
                    },
                    'insights_not_connected'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(function() {
                        initNotConnectedTable(
                            ...filter_status.not_connected_table
                        );
                    },
                    'insights_not_connected'
                ),
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {

                var api = this.api();
                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = {
                    //
                };

                this.api().columns().every(function ()
                {
                    var column = this;
                    var data = '';

                    if ($(column.header()).hasClass('sum-column'))
                    {
                        var sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        var plural = sum > 1 ? 's' : '';

                        if ($(column.header()).hasClass('percent-column'))
                        {
                            var percent = Math.round((sum/totals[column.index()]) * 100);
                            data = `${sum} Call${plural} <br/> <small class="text-muted">${percent}%</small>`
                        }
                        else
                        {
                            data = `${sum} Call${plural}`
                        }

                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            }
        });
    }

    function initReasonsNotConnectedTable(filter_name)
    {
        var button_array = [];

        _.each(self.report_data.locations, function(location)
        {
            _.each(location.reason_not_connected, function(data, key)
            {
                if (typeof _.find(button_array, ['text', key]) === 'undefined')
                {
                    button_array.push({
                        text: key,
                        action: function ( e, dt, node, config ) {
                            filter_status.reason_not_connected_table = [
                                config.text
                            ];
                            initReasonsNotConnectedTable(...filter_status.reason_not_connected_table);
                        }
                    });
                }
            });
        });

        if (!button_array.length)
            return;

        if (typeof filter_name === 'undefined')
            filter_name = button_array[0].text;

        filter_status.reason_not_connected_table = [
            filter_name
        ];

        var data = [];
        var columns = [];

        let data_converter = PatientPrism.Modules.Analize.Helpers.DataConverter;
        data_converter.init(
            'insights_reason_not_connected',
            self.locations,
            self.location_groups,
            self.report_data.locations,
            function (data, location) {
                return data[location.id];
            },
            function (data, location) {
                return data[location.id];
            },
            self.report_data.all.user_fact_groups,
        );
        let report_data = data_converter.get_data('insights_reason_not_connected');

        _.each(report_data, function(location)
        {
            var location_data = {};
            location_data.data = location;
            location_data.location = location.location;

            if (typeof location.reason_not_connected[(filter_name)] === 'undefined')
                return;

            _.each(location.reason_not_connected[(filter_name)].monthly, function (months, year)
            {
                _.each (months, function (metric, month)
                {
                    let period = get_period(month, year);

                    var column_title = month + '/' + year;

                    if (typeof _.find(columns, ['title', column_title]) === 'undefined') {

                        let getData = function (row, column_title) {
                            return typeof row[column_title] === 'undefined' ? 0 : row[column_title];
                        };
                        let getTotal = function (row) {
                            let total_reasons = 0;

                            _.each(row.data.reason_not_connected, function (value, key) {
                                if (typeof value['monthly'][year] !== 'undefined' && typeof value['monthly'][year][month] !== 'undefined')
                                    total_reasons += value['monthly'][year][month];
                            });

                            return total_reasons;
                        };
                        columns.push({
                            title: column_title,
                            data: function (row, type, val, meta) {
                                let data = getData(row, column_title);
                                let total = getTotal(row);
                                let v = (!total ? 0 : (data / total) * 100);

                                let route_params = {
                                    ignore_locations: 1,
                                    date_start: period.date_start,
                                    date_end: period.date_end,
                                    metadata: {
                                        reason_not_connected: filter_name,
                                    },
                                    weighted: 1,
                                };
                                route_params[data_converter.get_first_index_key('insights_reason_not_connected')] = row.location.id;

                                return formatters.calls(
                                    data,
                                    type,
                                    v.toFixed(2) + '%',
                                    'insights_reason_not_connected',
                                    {
                                        route: 'calls.search',
                                        route_params: route_params,
                                    },
                                );
                            },
                            className: 'text-center sum-column',
                            render: function (data, type, row, meta) {
                                let value = getData(row, column_title);
                                let total = getTotal(row);
                                let v = (!total ? 0 : (value / total) * 100);
                                return renderers.calls(data, value, type, v, 'insights_reason_not_connected');
                            }
                        });
                    }

                    location_data[column_title] = metric;
                });
            });

            data.push(location_data);
        });

        columns =
        _.sortBy(columns, function(item)
        {
            var date_params = item.title.split('/');
            return moment(date_params[0] + '/01/' + date_params[1]).unix();
        });

        columns.unshift({
            title: 'Location',
            data: 'location.name'
        });

        buildReasonsNotConnectedTable(columns, data, filter_name, button_array);
    }

    function buildReasonsNotConnectedTable(columns, data, filter_name, buttons)
    {
        self.$insights_reasons_not_connected_table = $('table#insights-reasons-not-connected');

        if (!self.$insights_reasons_not_connected_table.length)
            return;

        if ($.fn.DataTable.isDataTable('table#insights-reasons-not-connected'))
        {
            self.$insights_reasons_not_connected_table.DataTable().destroy();
            self.$insights_reasons_not_connected_table.empty();
        }

        self.$insights_reasons_not_connected_table.DataTable({
            data: data,
            columns: columns,
            responsive: true,
            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            'order': [
            ],
            bStateSave: true,
            buttons: [
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsLookType.button(function() {
                        initReasonsNotConnectedTable(
                            ...filter_status.reason_not_connected_table
                        );
                    },
                    'insights_reason_not_connected'
                ),
                PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations.button(function() {
                        initReasonsNotConnectedTable(
                            ...filter_status.reason_not_connected_table
                        );
                    },
                    'insights_reason_not_connected'
                ),
                {
                    extend:    'collection',
                    text:      '<i class="fa fa-filter"></i> ' + filter_name + ' <span class="caret"></span>',
                    className: 'btn-sm action-button dt-button enterprise-button',
                    buttons:   buttons,
                },
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'custom-report',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }
            ],
            language: {
                "sLengthMenu": "Show _MENU_ metrics per page",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total metrics",
                "sEmptyTable": "No Metric Data Available",
                search: "_INPUT_",
                searchPlaceholder: "Search Table..."
            },
            sPaginationType: "simple",
            initComplete: function(row, data, start, end, display) {

                var api = this.api();
                var footer = $(this).append('<tfoot><tr></tr></tfoot>');
                var totals = {
                    //
                };

                this.api().columns().every(function ()
                {
                    var column = this;
                    var data = '';

                    if ($(column.header()).hasClass('sum-column'))
                    {
                        var sum = column
                            .data()
                            .reduce(function(a, b) {
                                var x = parseFloat(a) || 0;
                                var y = parseFloat(b) || 0;
                                return x + y;
                            }, 0);

                        var plural = sum > 1 ? 's' : '';

                        if ($(column.header()).hasClass('percent-column'))
                        {
                            var percent = Math.round((sum/totals[column.index()]) * 100);
                            data = `${sum} Call${plural} <br/> <small class="text-muted">${percent}%</small>`
                        }
                        else
                        {
                            data = `${sum} Call${plural}`
                        }

                    }

                    $('tfoot tr', footer).append('<th class="text-center bg-muted">' + data + '</th>');
                });
            }
        });
    }

    function get_period(month, year)
    {
        let format = "YYYY-MM-DD HH:mm:ss";
        // suggest that looking period begins from the 1st day
        let date_start = PatientPrism.Common.Session.get('filters')['date-start']['date'];
        let date_end = PatientPrism.Common.Session.get('filters')['date-end']['date'];

        let fact_date_start = moment(`${year}-${month}-01 ${date_start.split(' ')[1]}`);
        let fact_date_end = moment(`${year}-${month}-01 ${date_end.split(' ')[1]}`).add(1, 'months');
        date_start = moment(date_start);
        date_end = moment(date_end);
        let intersection = [];

        while (fact_date_start <= fact_date_end) {
            if (fact_date_start >= date_start && fact_date_start <= date_end) {
                intersection.push(moment(fact_date_start));
            }
            fact_date_start.add(1, 'hours');
        }

        return {
            date_start: intersection[0].format(format),
            date_end: intersection[intersection.length - 1].add(1, 'hours').subtract(1, 'seconds').format(format),
        };
    }
})();
