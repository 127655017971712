(function()
{
    namespace("PatientPrism.API.CallRecords", all, get, update, destroy, add_keyword, remove_keyword, update_keyword,
        list_viewers, checkin_viewer, add_metadata, remove_metadata, add_metanotes, remove_metanotes, admin_unreview,
        admin_review, get_call_queue, set_call_queue, remove_call_queue, update_review, unreview, update_call_basics,
        caller_history
    );

    let self = {
        //
    };

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/call-records', callback, body)
    }

    function get(call_record_id, callback, append, expand)
    {
        let params = [];

        if (append instanceof Array ) {
            params.push('append=' + append.join(','));
        }

        if (expand instanceof Array ) {
            params.push('expand=' + expand.join(','));
        }

        PatientPrism.API.HTTP.Socket.get('/call-records/'
            + call_record_id
            + '?' + params.join('&'),
            callback);
    }

    function update(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-records/' + call_record_id, callback, body)
    }

    function destroy(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-records' + (call_record_id ? '/' + call_record_id : ''), callback, body)
    }

    function add_keyword(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/call-records/' + call_record_id + '/call-keywords', callback, body)
    }

    function remove_keyword(call_record_id, pivot_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-records/' + call_record_id + '/call-keywords/' + pivot_id, callback, body)
    }

    function update_keyword(call_record_id, pivot_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-records/' + call_record_id + '/call-keywords/' + pivot_id, callback, body)
    }

    function list_viewers(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.get('/call-records/' + call_record_id + '/viewers', callback)
    }

    function checkin_viewer(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.post('/call-records/' + call_record_id + '/viewers', callback, null)
    }

    function add_metadata(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/call-records/' + call_record_id + '/metadata', callback, body)
    }

    function remove_metadata(call_record_id, metadata_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-records/' + call_record_id + '/metadata/' + metadata_id, callback, body)
    }

    function add_metanotes(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/call-records/' + call_record_id + '/metanotes', callback, body)
    }

    function remove_metanotes(call_record_id, metanote_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-records/' + call_record_id + '/metanotes/' + metanote_id, callback, body)
    }

    function admin_review(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.post('/call-records/' + call_record_id + '/admin-review', callback)
    }

    function admin_unreview(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-records/' + call_record_id + '/admin-review', callback)
    }

    function get_call_queue(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.get('/call-records/' + call_record_id + '/call-queue', callback)
    }

    function set_call_queue(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-records/' + call_record_id + '/call-queue', callback, body)
    }

    function remove_call_queue(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-records/' + call_record_id + '/call-queue', callback)
    }

    function update_review(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-records/' + call_record_id + '/call-reviews', callback, body)
    }

    function unreview(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-records/' + call_record_id + '/review', callback)
    }

    function update_call_basics(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-records/' + call_record_id + '/call-reviews/call-basics', callback, body)
    }

    function caller_history(call_record_id, callback)
    {
        PatientPrism.API.HTTP.Socket.get('/call-records/' + call_record_id + '/caller-history?append=metanotes', callback);
    }
})();
