(function()
{
    namespace("PatientPrism.Modules.Documents.Common", init);

    var self = {
        $activity_table: null
    };

    function init()
    {
        initializeFormSubmissionsDataTable();
        bindDeleteLink();
    }

     /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initializeFormSubmissionsDataTable()
    {
        self.$activity_table = $('table#form-submissions');

        if (self.$activity_table.length)
        {
            self.$activity_table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                columnDefs: [{
                    orderable: false,
                    targets: -1
                }],
                'order': [
                    [4, 'desc']
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ Submissions Per Page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Submissions",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total submissions",
                    "sEmptyTable": "No Submissions Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }

    function bindDeleteLink()
    {
        $('table#form-submissions .delete-submission').click(function(e)
        {
            e.preventDefault();

            var $this = $(this);

            swal({
                title: "Are you sure?",
                text: "This will permanently delete this submission.<br/>This action cannot be undone.",
                html: true,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: false
            }, function(isConfirm) {
                if (isConfirm) {
                    swal("Stand By", "We're deleting the submission.", "success");
                    window.location.href = $this.attr('data-delete-url')
                } else {
                    swal("Cancelled", "The submission was not deleted.", "error");
                }
            });
        });
    }

})();