(function()
{
    namespace("PatientPrism.Modules.Calls.Search", init);

    var self = {
        is_admin: false,
        filters: null,
        $table: null,
        live_mode: false,
        viewables: null,
        pusher: null,
        last_activity: null,
        intervals: {
            live_mode: null,
            calls: null
        }
    };

    function init(filters, hasAdminRole)
    {
        self.helper = PatientPrism.Common.UI.RenderHelper;
        self.helper.init(PatientPrism.Common.Session.all().current.user.timezone);
        self.filters = filters ? JSON.parse(filters) : null;
        self.is_admin = hasAdminRole ? hasAdminRole : false;
        
        initCallRecordsDataTable();
        initActivityWatcher();
        fetchCallRecords();
    }

    function initActivityWatcher() {
        $(document).on('keyup keypress blur change mousemove',function(){
            self.last_activity = Date.now();
        });
    }

    function startPusher() {
        // Enable pusher logging
        Pusher.logToConsole = false;

        self.pusher = new Pusher(pusher_key, {
            cluster: 'mt1'
        });

        var channel = self.pusher.subscribe('company-' + PatientPrism.Common.Session.get('current').company.id);
        
        channel.bind("App\\Events\\ViewablesUpdated", function(data) {
            fetchViewables();
        });
    }

    function stopPusher() {
        if (!self.pusher) {
            return;
        }

        self.pusher.disconnect();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initCallRecordsDataTable(data)
    {
        self.$table = $('table#call-records');

        if (self.$table.length)
        {
            let columns = [
                {
                    name: 'viewers',
                    title: '',
                    className: 'text-center',
                    data: 'has_viewers',
                    render: function (data, type) {
                        if (type === 'display') {
                            if (data) {
                                return '<span class="active-viewer-icon glyphicon glyphicon-user"></span>';
                            }

                            return '';
                        }

                        return data;
                    }
                },
                {
                    name: 'location',
                    title: 'Location',
                    data: 'location.name',
                },
                {
                    name: 'answered_by',
                    title: 'Answered By',
                    data: function ( data ) { 
                        if (data.answered_by) {
                            return data.answered_by.name
                        } 
                        else if (typeof data.metanotes.unlisted_suggested_name !== 'undefined') {
                            return data.metanotes.unlisted_suggested_name;
                        }
                    },
                    className: 'text-center',
                    visible: false
                },
                {
                    name: 'phone_number',
                    title: 'Phone Number',
                    data: 'parent_phone_number.name',
                },
                {
                    name: 'caller',
                    title: 'Caller',
                    data: function ( data ) {
                        var caller = 
                        data.metanotes.caller_name ?? '-';

                        caller += '<br/>';
                        caller += self.helper.friendly_phone(data.From);

                        return caller;
                    },
                    visible: false
                },
                {
                    name: 'classification',
                    title: 'Classification',
                    data: 'metadata.classification',
                    className: 'text-center',
                },
                {
                    name: 'booking_status',
                    title: 'Booking Status',
                    data: 'metadata.booking_status',
                    className: 'text-center',
                },
                {
                    name: 'reason_not_booked',
                    title: 'Reason Not Booked',
                    data: 'metadata.reason_not_booked',
                    className: 'text-center',
                    visible: false
                },
                {
                    name: 'connection_status',
                    title: 'Connection Status',
                    data: 'metadata.connection_status',
                    className: 'text-center',
                    visible: false
                },
                {
                    name: 'reason_not_connected',
                    title: 'Reason Not Connected',
                    data: 'metadata.reason_not_connected',
                    visible: false
                },
                {
                    name: 'benefits_coverage',
                    title: 'Insurance',
                    data: 'metadata.benefits_coverage',
                    className: 'text-center',
                    visible: false
                },
                {
                    name: 'call_referrer',
                    title: 'Referrer',
                    data: 'metadata.call_referrer',
                    className: 'text-center',
                    visible: false
                },
                {
                    name: 'gender',
                    title: 'Gender',
                    data: 'metadata.gender',
                    className: 'text-center',
                    visible: false
                },
                {
                    name: 'language',
                    title: 'Language',
                    className: 'text-center',
                    data: 'language',
                    render: function (data) {
                        var map = {
                            en: 'English',
                            es: 'Spanish',
                            fr: 'French'
                        };

                        return typeof map[data] !== 'undefined' ? map[data] : data;
                    },
                    visible: false
                },
                {
                    name: 'follow_up_recommended',
                    title: 'Follow Up Recommended',
                    className: 'text-center',
                    data: function ( data ) {
                        return typeof data.metadata.follow_up_recommended !== 'undefined' ? 'Yes' : 'No';
                    }
                },
                {
                    name: 'follow_up',
                    title: 'Follow Ups',
                    className: 'text-center',
                    data: function ( data ) {
                        return data.has_follow_up ? 'Yes' : 'No';
                    }
                },
                {
                    name: 'time_to_follow_up',
                    title: 'Time to Follow Up',
                    data: 'time_to_follow_up',
                    className: 'text-center',
                    render: function(data, type) {
                        if (type === 'display' && data !== null) {
                            return self.helper.seconds_to_hms(data);
                        }
                        
                        return data;
                    },
                    visible: false
                },
                {
                    name: 'follow_up_conversion',
                    title: 'Follow Up Conversion',
                    className: 'text-center',
                    data: function ( data ) {
                        return typeof data.metadata.follow_up_conversion !== 'undefined' ? 'Yes' : 'No';
                    }
                },
                {
                    name: 'score_average',
                    title: 'Score',
                    data: 'score_average',
                    className: 'text-center',
                    visible: false
                },
                {
                    name: 'weight',
                    title: 'Weight',
                    className: 'text-center',
                    data: function ( data ) {
                        return typeof data.metadata.unweighted !== 'undefined' ? 'Unweighted' : 'Weighted';
                    },
                    visible: false
                },
                {
                    name: 'duration',
                    title: 'Duration',
                    className: 'text-center',
                    data: 'RecordingDuration',
                    render: function(data, type) {
                        if (type === 'display' && data !== null) {
                            return self.helper.seconds_to_hms(data);
                        }
                        
                        return data;
                    }
                },
                {
                    name: 'created_at',
                    title: 'Date',
                    data: 'created_at',
                    render: function (data, type, row) {
                        if (type === 'display') {
                            return self.helper.local_time(data, null, row.location.timezone)
                        }
                        
                        return data;
                    }
                },
                {
                    name: 'view_call',
                    title: '',
                    data: 'id',
                    className: 'no-export no-toggle-visibility',
                    searchable: false,
                    orderable: false,
                    render: function (data, type, row) {
                        let url = _router.route('calls.show', {
                            call_record_id: data
                        });

                        let btnClass = row.has_viewers ? 'btn-default' : 'btn-primary';

                        return `<a class="btn btn-sm ${btnClass}" href="${url}" target="_blank">View Call</a>`
                    }
                }
            ];

            // Admin columns
            if (self.is_admin) {
                columns.splice(1, 0, {         
                    name: 'company',
                    title: 'Company',
                    data: 'location.company.name',
                    visible: false
                
            });
                columns.splice(22, 0, {
                    name: 'review-duration',
                    title: 'Review Duration',
                    className: 'text-center',
                    data: 'review_duration',
                    visible: false,
                    render: function(data, type) {
                        if (type === 'display' && data !== null) {
                            return self.helper.seconds_to_hms(data);
                        }
                        
                        return data;
                    }
                    
                });
            }


            self.$table.DataTable({
                responsive: true,
                deferRender: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                bStateSave: true,
                buttons: [
                    {
                        attr: {
                            id: 'btn-toggle-live-mode'
                        },
                        text:          '<i class="fa fa-refresh fa-fw"></i> Toggle Live Mode</span></span>',
                        className:     'btn-sm action-button dt-button',
                        action: function ( e, dt, node, config ) {
                            toggleLiveMode();
                        }
                    },
                    {
                        extend:        'csv',
                        text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                        filename:      'search-results',
                        className:     'btn-sm action-button dt-button',
                        exportOptions: {
                            orthogonal: 'export',
                            columns: [ ':visible :not(.no-export)' ]
                        },
                    },
                    {
                        extend:        'colvis',
                        text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                        className:     'btn-sm action-button dt-button',
                        columns:       ':not(.no-toggle-visibility)'

                    }
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total calls",
                    "sEmptyTable": "No Call Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                columnDefs: [
                    {
                        data: null,
                        defaultContent: '-',
                        targets: '_all',
                    },
                ],
                columns: columns,
                rowCallback: function (row, data, index) {
                    if (data.relo_submitted_at) {
                        $(row).addClass('relo');
                    }

                    if (data.has_viewers) {
                        $(row).addClass('active-viewers');
                    } else {
                        $(row).removeClass('active-viewers');
                    }
                },
                initComplete: function(settings, json) {
                   this.show();
                }
            });
        }
    }

    function fetchCallRecords() {
        var api_callback = function (data, textStatus, jqXHR) {
            if (textStatus === "success") {
                _.each(data.data, function (call_record) {
                    if (self.viewables) {
                        call_record.has_viewers = self.viewables.includes(call_record.id);
                    }
                })
                
                self.$table.DataTable().clear();
                self.$table.DataTable().rows.add(data.data);
                self.$table.DataTable().draw();
    
            } else if (textStatus === "error") {
                //
            }
        };    

        
        var search_criteria = _.merge(self.filters, {
            reviewed: 1,
            date_start: PatientPrism.Common.Session.get('filters')['date-start'].date,
            date_end: PatientPrism.Common.Session.get('filters')['date-end'].date,
            expand: [
                'parent_phone_number', 
                'blocked_caller', 
                'location',
                'location.company', 
                'answered_by'
            ],
            append: [
                'metadata', 
                'metanotes',
                'has_follow_up'
            ]
          });
          
          PatientPrism.API.CallRecords.all(api_callback, search_criteria);
    }

    function fetchViewables()
    {
        var api_callback = function (data, textStatus, jqXHR) {
            if (textStatus === "success") {
                updateViewables(data.data);
            } else if (textStatus === "error") {
                //
            }
        };

        PatientPrism.API.Viewables.get(api_callback);
    }

    function updateViewables(viewables)
    {   
        viewables = _.map(viewables, function(viewable) {
            return viewable.viewable_id;
        });

        if (_.isEqual(viewables, self.viewables)) {
            return;
        }

        self.viewables = viewables;

        self.$table.DataTable().rows().every( function ( rowIdx, tableLoop, rowLoop ) {
            var rowData = this.data();
            
            if (self.viewables.includes(rowData.id)) {
                if (!rowData.has_viewers) {
                    rowData.has_viewers = true;
                    this.data(rowData).invalidate().draw();
                }
            }
            else if (rowData.has_viewers) {
                rowData.has_viewers = false;
                this.data(rowData).invalidate().draw();
            }
        });
    }

    function activateLiveMode() {
        self.live_mode = true;
        $('#header-live-mode').slideDown();
        fetchViewables();
        startPusher();
        setIntervals();
    }

    function deactivateLiveMode() {
        self.live_mode = false;
        $('#header-live-mode').slideUp();
        stopPusher();
        clearIntervals();
    }

    function toggleLiveMode() {
        self.live_mode ? deactivateLiveMode() : activateLiveMode();
    }
    
    function setIntervals() {
        self.intervals.calls = setInterval(function() {
            fetchCallRecords();
        }, 10000);

        // Check if the user's last activity was greater than 1.5 hours ago. 
        // If it was, deactivate live mode
        self.intervals.live_mode = setInterval(function() {
            if (self.last_activity && ((Date.now() - self.last_activity) / 1000 > 5400 )) {
                deactivateLiveMode();
            }
        }, 10000);
    }

    function clearIntervals() {
        clearInterval(self.intervals.calls);
        clearInterval(self.intervals.live_mode);
    }
})();