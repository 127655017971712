(function()
{
    namespace("PatientPrism.Common.UI.Filters.DateRange", initDateTimePickers, setDateRange, getDateRange);

    var self = {
        date_start: null,
        date_end: null,
        el: {
            action: {
                $container: null,
                $button:    null,
                $text:      null,
                $form:      null,
                form: {
                    $date_start: null,
                    $date_end:   null
                }
            },
        }
    }

    function initDateTimePickers()
    {
        self.el.action.$container = $('#action-date-range-container');
        self.el.action.$button    = $('button.action-button', self.el.action.$container);
        self.el.action.$text      = $('span.action-text', self.el.action.$button);
        self.el.action.$form      = $('form.action-form', self.el.action.$container);

        self.el.action.form.$date_start = $('[name="date-start"]', self.el.action.$form);
        self.el.action.form.$date_end   = $('[name="date-end"]', self.el.action.$form);

        var callback = function (date_start, date_end)
        {
            self.el.action.form.$date_start.val(date_start.format('MM/DD/YYYY'));
            self.el.action.form.$date_end.val(date_end.format('MM/DD/YYYY'));

            self.el.action.$text.html(date_start.format('MMMM D, YYYY') + ' - ' + date_end.format('MMMM D, YYYY'));

            PatientPrism.Common.UI.Loader.show();

            self.el.action.$form.submit();
        }

        self.el.action.$button.daterangepicker({
            startDate: moment(self.el.action.form.$date_start.val()),
            endDate: moment(self.el.action.form.$date_end.val()),
            ranges: {
               'Today': [moment(), moment()],
               'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               'Last 7 Days': [moment().subtract(6, 'days'), moment()],
               'Last 30 Days': [moment().subtract(29, 'days'), moment()],
               'This Month': [moment().startOf('month'), moment().endOf('month')],
               'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            showDropdowns:       true,
            autoApply:           true,
            alwaysShowCalendars: true,
            linkedCalendars:     false,
            opens:               'left'
        }, callback);

        // Hide mini inputs
        $('.daterangepicker_input').hide();
    }

    function setDateRange(date_start, date_end)
    {
        self.date_start = date_start;
        self.date_end = date_end;
    }

    function getDateRange()
    {
        return [self.date_start, self.date_end];
    }
})();