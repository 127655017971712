(function () {
  namespace("PatientPrism.Modules.Calls.Reviews.ReviewCall", init);

  var self = {
    $call_keywords_select: null,
    $review_form: null,
    $btn_submit_review: null,
    $btn_submit_review_with_relo: null,
    $chkbx_target_call: null,
    $txtarea_target_call_note: null,
    $appointment_datetime_picker: null,
    $follow_up_datetime_picker: null,
    target_call: {
      character_limit: 300,
      $container: null,
      $character_count: null,
      $target_call_note: null,
    },
    call_basics_count: null,
    timer: {
      iterator: null,
      start: null,
      elapsed: null,
    },
    auto_comment: {
      greeting: false,
      region_size: 0.068,
    },
    viewables: null,
    call_record: null,
    current_user: null,
    call_queues: null,
    view_only: null,
  };

  function init(
    call_record,
    current_user,
    is_reviewer,
    global_predefined_rules,
    view_only
  ) {
    self.$review_form = $("#call-review-form");
    self.call_record = call_record;
    self.current_user = current_user;
    self.view_only = view_only;
    self.helper = PatientPrism.Common.UI.RenderHelper;

    initPusher();
    PatientPrism.Common.UI.BlockedCallers.initBlockedCallers();
    initAppointmentDatetimePicker();
    initHighlightDefault();
    initSubmit();
    initSubmitWithRELO();
    initClassificationQuestions();
    initTrainingVideos();
    initTargetCallCounter();
    initCallEscalation();
    initReportCall();
    initMoveToQueue();
    initRequestAdminReview();
    initReleaseAdminReview();
    !view_only && initCallBasicsAutoCommentRegion();
    bindUIActions();
    initCallHandlers();
    initFollowUp();
    checkCallReferrer();
    initFollowUpReminder();
    initCallerHistory();
    

    $("#add-sellables").selectize({
      mode: "multi",
      duplicates: true,
      hideSelected: false,
      items: call_record.sellables.map(function (item) {
        return item.id;
      }),
    });

    global_predefined_rules &&
      initActionsStackForPredefinedRules(global_predefined_rules);

    PatientPrism.Common.UI.CallBasicsLoader.addAfterInitCallback(
      initNewOpportunityAndBookingEvents
    );

    $("#reset-call-review").click(function (e) {
      e.preventDefault();

      PatientPrism.Modules.Calls.Reviews.Fields.AnsweredBy.reset();

      $("[data-should-reset]")
        .val("")
        .prop("disabled", "")
        .css("background-color", "")
        .removeClass("label-info predefined-rule-applied highlight-precoached");

      $("select.selectized").each(function () {
        this.selectize.enable();
        this.selectize.clear();
        this.selectize.refreshItems();
      });

      // remove grenn or red highlighting of the opportunities element
      $("#opportunity-panel")
        .removeClass("panel-success panel-danger")
        .addClass("panel-default");

      //reset call basics
      PatientPrism.Common.UI.CallBasicsLoader.enableCallBasics(
        "[data-call-basic-id]"
      );

      return false;
    });

    initHighlightPrecoached();
    init_coach_notebook_background();
    
    var viewers = _.filter(call_record.viewers, function (viewer) {
      return viewer.id !== self.current_user.id;
    });
    
    if (viewers.length > 0) {
      var message = "The following users are currently viewing this call:<br/>";

      _.each(viewers, function(viewer) {
        message += `<strong>${viewer.name}</strong><br/>`;
      });

      swal(
        {
          title: "Review In Progress",
          text: message + "<br/>Are you sure you want to continue?",
          type: "warning",
          html: true,
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Continue",
          cancelButtonText: "Go Back",
          closeOnConfirm: true,
          closeOnCancel: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            if (is_reviewer) {
              initCallReviewTimer();
            }
            initViewerCheckIn(call_record.id);
          } else {
            history.go(-1);
          }
        }
      );
    } else {
      if (is_reviewer) {
        initCallReviewTimer();
      }
      initViewerCheckIn(call_record.id);
    }
  }

  function initAppointmentDatetimePicker() {
    self.$appointment_datetime_picker = $("#appointment-datetime");

    PatientPrism.Vendor.DateTimePicker.initFullPicker(
      self.$appointment_datetime_picker
    );
    
    self.$follow_up_datetime_picker = $("#follow-up-datetime");

    PatientPrism.Vendor.DateTimePicker.initFullPicker(
      self.$follow_up_datetime_picker
    );
  }

  function initPusher() {
    // Enable pusher logging
    Pusher.logToConsole = false;

    self.pusher = new Pusher(pusher_key, {
        cluster: 'mt1'
    });

    var channel = self.pusher.subscribe(`call-record-${self.call_record.id}`);
    
    channel.bind("App\\Events\\ViewablesUpdated", function(data) {
        fetchViewables();
    });
  }
function fetchViewables()
{
    var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
            checkViewables(data.data);
        } else if (textStatus === "error") {
            //
        }
    };

    PatientPrism.API.Viewables.get(api_callback);
}

  function checkViewables(viewables) {
    var call_record_viewables = _.filter(viewables, function(viewable) {
        if (viewable.viewable_id !== self.call_record.id) 
            return false;
        
        if (viewable.user_id === self.current_user.id)
            return false;

        return true;
    });

    if (_.isEqual(call_record_viewables, self.viewables)) {
        return;
    }

    self.viewables = call_record_viewables;
    var number_of_viewers = self.viewables.length;

    if (number_of_viewers) {
        var namesString = '';

        _.each(self.viewables, function(viewable, index) {
            if (number_of_viewers > 1 && index === number_of_viewers - 1) {
                namesString += ' &amp; ';
            }

            namesString += `<strong>${viewable.user_name}</strong>`;
        });      

        PatientPrism.Common.UI.Notification.create('info', `${namesString} is now viewing this call`, -1);
    }
  }

  function initCallReviewTimer() {
    self.timer.start = new Date().getTime();

    self.timer.iterator = setInterval(function () {
      var time = new Date().getTime() - self.timer.start;
      var difference = Math.round(Math.floor(time / 100) / 10);

      if (self.timer.elapsed != difference) {
        self.timer.elapsed = difference;
        $("#review-duration").val(difference);

        var timer_class = "";

        if (difference >= 300 && difference < 360) {
          timer_class = "warning";
        } else if (difference >= 360) {
          timer_class = "danger";
        }

        // Display minutes and seconds in 00:00 format
        var minutes = "0" + Math.floor(difference / 60);
        var seconds = "0" + (difference - minutes * 60);

        $("#call-review-timer")
          .text(minutes.substr(-2) + ":" + seconds.substr(-2))
          .removeClass()
          .addClass(timer_class);
      }
    }, 100);
  }

  function initHighlightDefault() {
    $(".highlight-default").each(function (index, item) {
      $(this)
        .attr("default-value", $(this).val())
        .css("background-color", "#faebcc");
    });

    $(".highlight-default").on("change", function (e) {
      $(this).css(
        "background-color",
        $(this).val() != $(this).attr("default-value") ? "" : "#faebcc"
      );
    });
  }

  function initHighlightPrecoached() {
    $(".highlight-precoached").on("change", function (e) {
      $(this).removeClass("highlight-precoached");
    });
  }

  function initSubmit() {
    var $classificationSelectBox = $("#classification"),
      $bookingStatusSelectBox = $("#booking-status");

    self.$btn_submit_review = $("#btn-submit-review");

    if (self.$btn_submit_review.length) {
      self.$btn_submit_review.click(function (e) {
        e.preventDefault();

        var callback = function (meets_requirements) {
          if (meets_requirements) self.$review_form.submit();
        };

        reviewMeetsRequirements(callback, false);
      });
    }

    // in case if a call is classified as "New Opportunity - Not Booked" the "Submit" button should be hidden
    // '27' stands for 'New Opportunity'
    // '6' stands for 'Not Booked'
    var doHideSubmitBtn = function () {
      /*
            if ($classificationSelectBox.val() == '27' && $bookingStatusSelectBox.val() == '6') {
                self.$btn_submit_review.hide();
            } else {
                self.$btn_submit_review.show();
            }
            */
    };

    $classificationSelectBox.on("change", doHideSubmitBtn);
    $bookingStatusSelectBox.on("change", doHideSubmitBtn);
  }

  function initSubmitWithRELO() {
    self.$btn_submit_review_with_relo = $("#btn-submit-review-with-relo");

    if (self.$btn_submit_review_with_relo.length) {
      self.$btn_submit_review_with_relo.click(function (e) {
        e.preventDefault();

        if (
          $("#booking-status option:selected").text() === "Booked" &&
          $("#classification option:selected").text() === "New Opportunity"
        ) {
          swal({
            title: "An Error Occurred",
            text:
              "RELO notifications for New Opportunity - Booked are not allowed.",
            type: "error",
            html: true,
          });
          return;
        }

        var callback = function (meets_requirements) {
          if (meets_requirements) {
            swal(
              {
                title: "Are you sure?",
                text:
                  "This will submit the review and send Email and SMS notifications. This action cannot be undone.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: false,
              },
              function (isConfirm) {
                if (isConfirm) {
                  $("#relo-notification").attr("name", "metadata[]");
                  self.$review_form.submit();

                  swal(
                    "Stand By",
                    "We're submitting the review and notifying users.",
                    "success"
                  );
                } else {
                  swal("Cancelled", "Nothing has been submitted", "error");
                }
              }
            );
          }
        };

        reviewMeetsRequirements(callback, true);
      });
    }
  }

  function bindUIActions() {
    self.$chkbx_target_call = $("#checkbox-target-call");
    self.$txtarea_target_call_note = $("#target-call-note");

    if (self.$chkbx_target_call.length) {
      self.$chkbx_target_call.click(function () {
        if ($(this).is(":checked")) {
          $(this).val(1);
          $("#target-call-note-container").slideDown();
          $("#target-call").attr("name", "metadata[]");
          self.$btn_submit_review.fadeOut();
        } else {
          $(this).val(0);
          $("#target-call-note-container").slideUp();
          $("#target-call").attr("name", "");
          self.$btn_submit_review.fadeIn();
        }
      });
    }

    $("#classification.form-control")
      .change(function (e) {
        initAutomaticTargetCalls();
      })
      .change();

    $("#select-language").change(function (e) {
      if (!$(this).val()) return;

      var that = this;

      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          swal(
            {
              title: "Language Updated",
              text: "Would you like to continue reviewing this call?",
              type: "success",
              html: true,
              showCancelButton: true,
              confirmButtonText: "Yes, Continue",
              cancelButtonText: "No",
            },
            function (isConfirm) {
              if (isConfirm) {
                swal.close();
              } else {
                var api_callback = function (data, textStatus, jqXHR) {
                  PatientPrism.Common.UI.Loader.hide();

                  if (textStatus === "success") {
                      location.href = encodeURI(
                        _router.route("calls.all-unreviewed")
                      );
                  } else {
                    swal({
                      title: "An Error Occurred",
                      text:
                        "There was an error while trying to update this call.",
                      type: "error",
                      html: true,
                    });
                  }
                };

                language_queue = _.find(self.call_queues, function (
                  call_queue
                ) {
                  return (
                    call_queue.name == $("option:selected", that).text()
                  );
                });

                var request_body = {
                  review_in_progress: 0,
                  call_queue_id: language_queue.id
                };

                PatientPrism.API.CallRecords.update_review(
                  self.call_record.id,
                  api_callback,
                  request_body
                );
              }
            }
          );
        } else if (textStatus === "error") {
          swal({
            title: "An Error Occurred",
            text:
              "There was an error while trying to modify the language of this call.",
            type: "error",
            html: true,
          });
        }
      };

      var request_body = {
        language: $(this).val(),
      };

      PatientPrism.API.CallRecords.update(
        self.call_record.id,
        api_callback,
        request_body
      );
    });

    $("#unweighted").change(function (e) {
      if ($(this).val() != "") {
        self.$btn_submit_review.show();
      }
    });

    $("#reset-call-basics").click(function (e) {
      e.preventDefault();

      uncheckAllCallBasics();
    });
  }

  function reviewMeetsRequirements(callback, submit_with_relo) {

    $("#follow-up-recommended").attr("name", "");
    $("#missed-call-notification").attr("name", "");

    checkTargetCall();

    // All RELOs should have a recommended follow up
    if (submit_with_relo || $("#follow-up-recommended").attr("keep") === "1")
      $("#follow-up-recommended").attr("name", "metadata[]");

    if (self.call_record.location.basic_coaching) {
      callback(true);
      return;
    }
    
    // If the call is unweighted, do not require any review checks
    if ($("#unweighted").val() != "") {
      callback(true);
      return;
    }

    // Empty Classification
    if (!$("#classification", self.$review_form).val()) {
      doErrorAlert(
        "A call review must have a<br/><strong>Classification</strong>."
      );

      callback(false);
      return;
    }

    // Empty Connection Status
    if (!$("#connection-status", self.$review_form).val()) {
      doErrorAlert(
        "A call review must have a<br/><strong>Connection Status</strong>."
      );

      callback(false);
      return;
    }

    // New Patient Opportunity
    if (
      $("#classification option:selected", self.$review_form).text() ===
      "New Opportunity"
    ) {
      if (self.call_record.location.call_center === 1) {
        if (!submit_with_relo) {
          if (
            $("#location-id", self.$review_form).val() ==
              self.call_record.location.id ||
            !$("#location-id", self.$review_form).val()
          ) {
            doWarningAlert(
              "Do you want to submit this call without reassigning the location?",
              callback
            );

            return;
          }
        }
      }

      if ($("#booking-status").val() === "") {
        doErrorAlert(
          'A <strong>"New Opportunity"</strong><br/>must have a <strong>Booking Status</strong>.'
        );

        callback(false);
        return;
      }

      if (!$("#add-sellables").val() || $("#add-sellables").val().length < 1) {
        doErrorAlert(
          'A <strong>"New Opportunity"</strong><br/>must have at least <strong>1 Opportunity</strong>.'
        );
        callback(false);
        return;
      }

      // Not Booked
      if ($("#booking-status option:selected").text() == "Not Booked") {
        if (
          $("#answered-by-id").val() === "" &&
          $("#checkbox-unlisted-answered-by:checked").length == 0
        ) {
          doErrorAlert(
            'A <strong>"New Opportunity - Not Booked"</strong><br/>must have an <strong>Answered By</strong>.'
          );
  
          callback(false);
          return;
        }
        
        // Add follow-up-recommendation to form
        if ($("#unweighted").val() == "")
          $("#follow-up-recommended").attr("name", "metadata[]");

        if (
          ($("#search-training-videos").val() === null ||
            $("#search-training-videos").val().length < 2) &&
          $("#unweighted").val() == ""
        ) {
          doErrorAlert(
            'A <strong>"New Opportunity - Not Booked"</strong><br/>must have at least <strong>2 Training Videos</strong>.'
          );

          callback(false);
          return;
        }

        if ($("#reason-not-booked").val() === "") {
          doErrorAlert(
            'A <strong>"New Opportunity - Not Booked"</strong><br/>must have a <strong>Reason Not Booked</strong>.'
          );

          callback(false);
          return;
        }

        if (!submit_with_relo) {
          doWarningAlert(
            'Do you want to submit this<br/><strong>"New Opportunity - Not Booked"</strong><br/>without a <strong>RELO Notification</strong>?',
            callback
          );

          return;
        }
      }
    }

    // Existing Patient Opportunity
    if (
      $("#classification option:selected", self.$review_form).text() ==
      "Existing Opportunity"
    ) {

      if ($("#booking-status").val() === "") {
        doErrorAlert(
          'An <strong>"Existing Opportunity"</strong><br/>must have a <strong>Booking Status</strong>.'
        );

        callback(false);
        return;
      }

      // Not Booked
      if (
        $("#booking-status option:selected").text() == "Not Booked" &&
        $("#unweighted").val() == ""
      ) {
        $("#follow-up-recommended").attr("name", "metadata[]");
      }
    }

    // Not Connected
    if (
      $("#classification option:selected", self.$review_form).text() ==
      "Not Connected"
    ) {
      $("#missed-call-notification").attr("name", "metadata[]");
    }

    // General Call
    if (
      $("#classification option:selected", self.$review_form).text() ===
      "General Call"
    ) {
      //
    }

    // Cancellation
    if (
      $("#classification option:selected", self.$review_form).text() ===
      "Cancellation"
    ) {
      //
    }

    // Calls With Booking Status
    if (
      $("#booking-status option:selected", self.$review_form).text() ==
        "Booked" ||
      $("#booking-status option:selected", self.$review_form).text() ==
        "Not Booked"
    ) {
      var classification = $(
        "#classification option:selected",
        self.$review_form
      ).text();

      if (
        classification != "New Opportunity" &&
        classification != "Existing Opportunity"
      ) {
        doErrorAlert(
          'A call classified as <strong>"' +
            classification +
            '"</strong><br/> cannot have a booking status.'
        );

        callback(false);
        return;
      }
    }

    if (self.$chkbx_target_call.is(":checked")) {
      if (!$("#target-point-1").val()) {
        doErrorAlert(
          'A <strong>"Target Call"</strong> must have<br/> a <strong>"Target Point"</strong>.'
        );

        callback(false);
        return;
      }
    }

    callback(true);
  }

  function initAutomaticTargetCalls() {
    // If the call is unweighted, do not require any review checks
    if ($("#unweighted").val() != "") {
      return;
    }

    self.call_basics_count = $(".call-basics-checkbox").length;

    var checkCallBasicRatio = function () {
      if ($("#classification option:selected").text() != "New Opportunity") {
        self.$chkbx_target_call.prop("checked", true).trigger("click");
        $("#automatic-call-target").hide();
        $("#target-call-checkbox").show();
        return;
      }

      if (
        $(".call-basics-checkbox:checked").length / self.call_basics_count <
        0.5
      ) {
        self.$chkbx_target_call.prop("checked", false).trigger("click");
        $("#target-call-checkbox").hide();
        $("#automatic-call-target").show();
      } else {
        self.$chkbx_target_call.prop("checked", true).trigger("click");
        $("#automatic-call-target").hide();
        $("#target-call-checkbox").show();
      }
    };

    checkCallBasicRatio();

    $(".call-basics-checkbox")
      .unbind("click")
      .click(function (e) {
        checkCallBasicRatio();
      });
  }

  function initClassificationQuestions() {
    $(".set-classification-new-opportunity").click(function (e) {
      $('#classification option:contains("Reschedule")').remove();
      $('#classification option:contains("General Call")').remove();
      $('#classification option:contains("Not Connected")').remove();
      $('#classification option:contains("Cancellation")').remove();
      $('#classification option:contains("Solicitation")').remove();
    });

    $(".set-classification-reschedule").click(function (e) {
      $('#classification option:contains("New Opportunity")').remove();
      $('#classification option:contains("Existing Opportunity")').remove();
      $('#classification option:contains("Not Connected")').remove();
      $('#classification option:contains("General Call")').remove();
      $('#classification option:contains("Not Connected")').remove();
      $('#classification option:contains("Cancellation")').remove();
      $('#classification option:contains("Solicitation")').remove();
    });

    $(".set-classification-cancellation").click(function (e) {
      $('#classification option:contains("New Opportunity")').remove();
      $('#classification option:contains("Existing Opportunity")').remove();
      $('#classification option:contains("Not Connected")').remove();
      $('#classification option:contains("General Call")').remove();
      $('#classification option:contains("Not Connected")').remove();
      $('#classification option:contains("Reschedule")').remove();
      $('#classification option:contains("Solicitation")').remove();
    });
  }

  function initTrainingVideos() {
    $("#search-training-videos").selectize({
      valueField: "id",
      labelField: "title",
      searchField: "title",
      preload: true,
      create: false,
      render: {
        option: function (item, escape) {
          return (
            "<div>" +
            '<img  height="50px" src="' +
            escape(item.thumbnail_uri) +
            '" alt="">' +
            '<span class="title">' +
            '<span class="name">' +
            escape(item.title) +
            "</span>" +
            "</span>" +
            '<span class="description">' +
            escape(item.description || "No description.") +
            "</span>" +
            "</div>"
          );
        },
      },
      load: function (query, callback) {
        var api_callback = function (data, textStatus, jqXHR) {
          if (textStatus === "success") {
            callback(data.data);
          } else if (textStatus === "error") {
          }
        };

        var search_criteria = {};

        PatientPrism.API.TrainingVideos.all(api_callback, search_criteria);
      },
    });

    var $view_training_videos = $("#view-all-training-videos");

    if ($view_training_videos.length) {
      $view_training_videos.selectize({
        valueField: "id",
        labelField: "title",
        searchField: "title",
        options: [],
        preload: true,
        create: false,
        render: {
          option: function (item, escape) {
            return (
              "<div>" +
              '<span class="title">' +
              '<span class="name">' +
              escape(item.title) +
              "</span>" +
              "</span>" +
              "</div>"
            );
          },
        },
        load: function (query, callback) {
          var api_callback = function (data, textStatus, jqXHR) {
            if (textStatus === "success") {
              callback(data.data);
            } else if (textStatus === "error") {
            }
          };

          PatientPrism.API.TrainingVideos.all(api_callback, null);
        },
      });

      $view_training_videos[0].selectize.on("change", function (value) {
        var option = $("#view-all-training-videos")[0].selectize.options[value];

        PatientPrism.Common.UI.VideoPlayer.play(
          option.file_uri,
          option.thumbnail_uri,
          option.title,
          null
        );
      });
    }
  }

  function initViewerCheckIn(call_record_id) {
    var api_callback = function (data, textStatus, jqXHR) {
      if (textStatus === "success") {
        //
      } else if (textStatus === "error") {
      }
    };

    PatientPrism.API.CallRecords.checkin_viewer(call_record_id, api_callback);

    setInterval(function () {
      PatientPrism.API.CallRecords.checkin_viewer(call_record_id, api_callback);
    }, 15000);
  }

  function initTargetCallCounter() {
    self.target_call.$container = $("#target-call-note-container");
    self.target_call.$character_count = $(
      "#character-count",
      self.target_call.$container
    );
    self.target_call.$target_call_note = $(
      "#target-call-note",
      self.target_call.$container
    );

    $(".target-point", self.target_call.$container).on(
      "change keyup paste",
      function () {
        // Limit character input in textareas
        if (
          self.target_call.character_limit - countTargetCallCharacters() <=
          0
        ) {
          $(this).val(
            $(this)
              .val()
              .substring(
                0,
                $(this).val().length -
                  (countTargetCallCharacters() -
                    self.target_call.character_limit)
              )
          );
        }

        self.target_call.$character_count.text(
          self.target_call.character_limit - countTargetCallCharacters()
        );

        var target_point_1 = $(
          "#target-point-1",
          self.target_call.$container
        ).val();
        var target_point_2 = $(
          "#target-point-2",
          self.target_call.$container
        ).val();
        var target_point_3 = $(
          "#target-point-3",
          self.target_call.$container
        ).val();

        var target_call_note =
          (target_point_1 ? "1. " + target_point_1 + " " : "") +
          (target_point_2 ? "2. " + target_point_2 + " " : "") +
          (target_point_3 ? "3. " + target_point_3 + " " : "");

        self.target_call.$target_call_note.val(target_call_note);
      }
    );
  }

  function initRequestAdminReview() {
    $("#btn-request-admin-review").click(function (e) {
      e.preventDefault();

      // Get list of metanotes to get admin review object
      var metanote = null;

      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          metanote = _.find(data.data, function (item) {
            return item.key == "admin_review_reason";
          });
        }
      };

      PatientPrism.API.Metanotes.all(api_callback);

      // Show escalation popup
      swal(
        {
          title: "Request Admin Review",
          text: "Please provide a reason the review:",
          type: "input",
          showCancelButton: true,
          closeOnConfirm: false,
          inputPlaceholder: "Reason for Review",
          confirmButtonText: "Request Review",
          confirmButtonColor: "#337AB7",
          showLoaderOnConfirm: true,
        },
        function (inputValue) {
          if (inputValue === false) return false;

          if (inputValue === "") {
            swal.showInputError(
              "A review reason is required to request an admin review."
            );
            return false;
          }

          var metadata_success = false;
          var callnote_success = false;

          // Add metadata to call record
          var api_callback = function (data, textStatus, jqXHR) {
            if (textStatus === "success") {
              metadata_success = true;
            } else if (textStatus === "error") {
              swal({
                title: "An Error Occurred",
                text: "There was an error while trying to escalate this call.",
                type: "error",
                html: true,
              });
            }
          };

          PatientPrism.API.CallRecords.admin_unreview(
            self.call_record.id,
            api_callback
          );

          // Add call note to call record
          var api_callback = function (data, textStatus, jqXHR) {
            if (textStatus === "success") {
              callnote_success = true;
            } else if (textStatus === "error") {
              swal({
                title: "An Error Occurred",
                text: "There was an error while trying to request the review.",
                type: "error",
                html: true,
              });
            }
          };

          var request_body = {
            data: inputValue,
          };

          PatientPrism.API.CallNotes.create(
            self.call_record.id,
            api_callback,
            request_body
          );

          var checkMetaStatus = setInterval(function () {
            if (callnote_success && metadata_success) {
              clearInterval(checkMetaStatus);

              location.href = _router.route("coach.index");
            }
          }, 500);
        }
      );
    });
  }

  function initReleaseAdminReview() {
    $("#btn-release-admin-review").click(function (e) {
      e.preventDefault();

      var metadata_success = false;

      // Update admin review
      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          location.href = _router.route("calls.pending-admin-review");
        } else if (textStatus === "error") {
          swal({
            title: "An Error Occurred",
            text: "There was an error while trying to update this call.",
            type: "error",
            html: true,
          });
        }
      };

      PatientPrism.API.CallRecords.admin_review(
        self.call_record.id,
        api_callback
      );
    });

    // add call note in order to respond to admin review request. ISSUE-144
    $('#add_call_note button[type="submit"]').click(function (e) {
      e.preventDefault();

      var data = $('#add_call_note input[name="data"]').val();

      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          location.reload();
        } else if (textStatus === "error") {
          swal({
            title: "An Error Occurred",
            text: "There was an error while trying to add a call note.",
            type: "error",
            html: true,
          });
        }
      };

      data &&
        PatientPrism.API.CallNotes.create(self.call_record.id, api_callback, {
          data: data,
        });

      return false;
    });
  }

  function initReportCall() {}

  function initMoveToQueue() {
    // Add metadata to call record
    var api_callback = function (data, textStatus, jqXHR) {
      if (textStatus === "success") {
        self.call_queues = data.data;
      } else if (textStatus === "error") {
        //
      }
    };

    PatientPrism.API.CallQueues.all(api_callback);

    $("[trigger-move-to-queue]").click(function (e) {
      var that = this;
      var parent = $(this).closest("li");

      e.preventDefault();

      if ($(parent).hasClass("active-tick")) return;

      PatientPrism.Common.UI.Loader.show();

      $("#btn-move-to-queue .active-tick").removeClass(
        "active-tick disabled"
      );
      $(parent).addClass("active-tick disabled");

      var api_callback = function (data, textStatus, jqXHR) {
        PatientPrism.Common.UI.Loader.hide();

        if (textStatus === "success") {
          swal(
            {
              title: "Call Moved",
              text:
                "This call has been moved to the <strong>" +
                $(that).attr("data-call-queue-name") +
                "</strong> queue.",
              type: "success",
              html: true,
              showCancelButton: false,
              confirmButtonText: "Continue",
              closeOnConfirm: false,
            },
            function (isConfirm) {
              if (isConfirm) window.location = "/";
            }
          );
        } else {
          swal({
            title: "An Error Occurred",
            text: "There was an error while trying to move this call.",
            type: "error",
            html: true,
          });
        }
      };

      var request_body = {
        review_in_progress: 0,
        call_queue_id: $(that).attr("data-call-queue-id")
      };

      PatientPrism.API.CallRecords.update_review(
        self.call_record.id,
        api_callback,
        request_body
      );
     
    });
  }

  function initCallEscalation() {
    $('ul[aria-labelledby="btn-escalate-call"] li').on("click", function (e) {
      e.preventDefault();

      // highlight selected reason and save it as a part of the form data
      $(this).addClass("active").siblings().removeClass("active");
      $('input[name="call_escalation_reason_id"]').val(
        $(this).find("a").data("call-escalation-reason-id")
      );

      $("#btn-escalate-call span.fa").show();
    });
  }

  function initCallBasicsAutoCommentRegion() {
    $(".call-basic-toggle").click(function (e) {
      e.preventDefault();

      if ($(this).attr("data-call-basic-category-id") == 1) {
        var total = $('[data-call-basic-category-id="1"]').length;
        var active = $('[data-call-basic-category-id="1"].active').length;

        if ((active / total) * 100 >= 60 && !self.auto_comment.greeting) {
          var audio_duration = PatientPrism.Modules.Calls.CallViz.getPlayer().getDuration();

          PatientPrism.Modules.Calls.CallViz.autoCommentRegion({
            type: "positive",
            time_start: 0,
            time_end: audio_duration * self.auto_comment.region_size,
            data: "Greeting & First Impression Phase Successful!",
          });

          self.auto_comment.greeting = true;
        }
      }
    });
  }

  function checkAllCallBasics() {
    $(".call-basic-toggle").each(function (e) {
      $(this).addClass("active");

      var $value_input = $(this).find('*[name="call_basics[]"]');

      $value_input.attr("disabled", false);
    });
  }

  function uncheckAllCallBasics() {
    $(".call-basic-toggle").each(function (e) {
      $(this).removeClass("active");

      var $value_input = $(this).find('*[name="call_basics[]"]');

      $value_input.attr("disabled", true);
    });
  }

  function initCallHandlers() {
    if ($("#add-call-handlers").length) {
      $("#add-call-handlers").selectize({
        mode: "multi",
        duplicates: false,
        hideSelected: true,
      });
    }
  }

  function initFollowUp() {
    $('[data-toggle="tooltip"]', "#follow-ups").tooltip();
  }

  function countTargetCallCharacters() {
    if (self.target_call.$character_count.length) {
      var target_1_length = $("#target-point-1", self.target_call.$container)
        .val()
        .replace(/ /g, "").length;
      var target_2_length = $("#target-point-2", self.target_call.$container)
        .val()
        .replace(/ /g, "").length;
      var target_3_length = $("#target-point-3", self.target_call.$container)
        .val()
        .replace(/ /g, "").length;

      return target_1_length + target_2_length + target_3_length;
    }
  }

  function checkCallReferrer() {
    var online_source = $("#online-source").text();
  }

  function checkTargetCall() {
    $("#target-call").attr("name", "");

    var total = $('[name="call_basics[]"]').length;
    var selected = $('[name="call_basics[]"]').not("[disabled]").length;

    var percent_selected = (selected / total) * 100;

    if (percent_selected < 55 && $("#unweighted").val() == "") {
      if (
        $("#classification option:selected", self.$review_form).text() ===
        "New Opportunity"
      ) {
        if ($("#booking-status option:selected").text() == "Not Booked") {
          $("#target-call").attr("name", "metadata[]");

          // All target calls should have a follow up
          $("#follow-up-recommended").attr("name", "metadata[]");
        }
      }
    }
  }

  function doErrorAlert(message) {
    swal({
      title: "Review Error",
      text: message,
      type: "error",
      html: true,
    });
  }

  function doWarningAlert(message, callback) {
    swal(
      {
        title: "Are You Sure?",
        text: message,
        type: "warning",
        html: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      },
      function (isConfirm) {
        callback(isConfirm);
      }
    );
  }

  function initFollowUpReminder() {
    $('input[name="reminder"]').click(function (e) {
      if ($(this).is(":checked")) {
        $("#follow-up-reminder-datetime").parent().show();
      } else {
        $("#follow-up-reminder-datetime").parent().hide();
      }
    });

    PatientPrism.Vendor.DateTimePicker.initFullPicker(
      $("#follow-up-reminder-datetime"),
      {
        useCurrent: true,
        minDate: moment().add(1, "h").startOf("hour"),
        stepping: 15,
      }
    );

    $(".disable-follow-up-reminder").click(function (e) {
      e.preventDefault();

      var $self = $(this);

      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          $self.closest('.follow-up-reminder').html(`
          <span class="glyphicon glyphicon-bell"></span> Reminder Dismissed
          `);
        } else if (textStatus === "error") {
          swal({
            title: "An Error Occurred",
            text: "There was an error while trying to disable the reminder.",
            type: "error",
            html: true,
          });
        }
      };

      var request_body = {
        acknowledge: 1,
      };

      PatientPrism.API.CallFollowUps.update(
        $(this).closest('.follow-up-reminder').attr("data-call-follow-up-id"),
        api_callback,
        request_body
      );
    });

    $("#submit-follow-up").click(function (e) {
      e.preventDefault();

      var pickedReminderDate,
        $form = $("#form-add-follow-up"),
        formValues = _.keyBy($form.serializeArray(), "name"),
        doSubmit = true;

      var submitFollowUp = function (doSubmit) {
        doSubmit && $form.submit() && $form.submit(false); // prevent from submitting more than once. ISSUE-601
      }

      if (!formValues["data"].value) {
        doErrorAlert("Follow Up Note message cannot be empty");
        doSubmit = false;
      }

      if ($('input[name="reminder"]').is(":checked")) {
        pickedReminderDate = moment(
          $("#follow-up-reminder-datetime").val(),
          PatientPrism.Config.get("datetimepicker.full_format")
        );

        $("#follow-up-reminder-datetime").val(
          pickedReminderDate.utc().format("YYYY-MM-DD HH:mm:ss")
        );
      } else {
        $("#follow-up-reminder-datetime").val(null);
      }

      if (
        formValues["booking_status"].value == "Booked" &&
        !formValues["appointment_date"].value
      ) {
        let callback = function (isConfirm) {
          submitFollowUp(isConfirm);
        };

        doWarningAlert(
          "You are submitting a <strong>Booked</strong> Follow Up without an appointment time",
          callback
        );
        doSubmit = false;
      }

      submitFollowUp(doSubmit);
    });
  }

  function initNewOpportunityAndBookingEvents() {
    var callback = function () {
      var opportunityClass = "panel panel-default";
      var classification = $("#classification option:selected").text();
      var booking = $("#booking-status option:selected").text();
      if (classification === "New Opportunity") {
        if (booking === "Booked") {
          opportunityClass = "panel panel-success";
          checkAllCallBasics();
        } else if (booking === "Not Booked") {
          opportunityClass = "panel panel-danger";
        }
      } else {
        var reason_not_connected = $("#reason-not-connected :selected").text();
        if (
          reason_not_connected == "VM - w/Msg - Bus Hrs" ||
          reason_not_connected == "VM - w/Msg - Non Bus Hrs"
        ) {
          opportunityClass = "panel panel-danger";
        }
      }
      $("#opportunity-panel").attr("class", opportunityClass);
    };
    $("#classification, #booking-status, #reason-not-connected").change(
      callback
    );
    callback();
  }

  function initActionsStackForPredefinedRules(global_predefined_rules) {
    var map = {
        classification: {
          selector: "#classification",
        },
        benefits_coverage: {
          selector: "#benefits-coverage",
        },
        booking_status: {
          selector: "#booking-status",
        },
        connection_status: {
          selector: "#connection-status",
        },
        reason_not_booked: {
          selector: "#reason-not-booked",
        },
        reason_not_connected: {
          selector: "#reason-not-connected",
        },
        call_referer: {
          selector: "#call-referer",
        },
        gender: {
          selector: "#gender",
        },
        language: {
          selector: "#select-language",
        },
        keyword: {
          selector: '[callviz-role="keyword-menu"]',
          compare: function (asserted) {
            var get_keyword_index = function () {
              return Object.values(
                PatientPrism.Modules.Calls.CallViz.getPlayer().keywords.list
              ).findIndex(function (keywordEvent) {
                return keywordEvent.data.keyword_id == asserted.value;
              });
            };

            return {
              equal: get_keyword_index() > -1,
              not_equal: get_keyword_index() == -1,
            }[asserted.operator];
          },
        },
        weight: {
          selector: "#unweighted",
          compare: function (asserted) {
            asserted.value = !!+asserted.value;
            return compare_if_equal(!!+$(this.selector).val(), asserted);
          },
          setValue: function (value) {
            var id = $(this.selector + " option:contains(Unweighted)").val();
            $(this.selector).val(value ? id : "");
          },
        },
        call_basic: {
          selector: ".panel-call-basic-category .list-group", //.call-basic-toggle
          event: "click",
          compare: function (asserted) {
            var selected_call_basic_ids = $(
              this.selector + " .call-basic-toggle.active"
            )
              .map(function () {
                return $(this).data("call-basic-id");
              })
              .get();

            return {
              in: asserted.value.every((val) =>
                selected_call_basic_ids.includes(val)
              ),
              not_in: !asserted.value.some((val) =>
                selected_call_basic_ids.includes(val)
              ),
            }[asserted.operator];
          },
          setValue: function (values) {
            if (Array.isArray(values)) {
              values.forEach(function (call_basic_value) {
                var $call_basic_element = $(
                  '[data-call-basic-id="' + call_basic_value + '"]'
                );
                if (!$call_basic_element.hasClass("active")) {
                  $call_basic_element.click();
                }
              });
            }
          },
          highlight: function (value) {},
          disableElement: function () {
            let call_basics_loaded = function () {
              PatientPrism.Common.UI.CallBasicsLoader.disableCallBasics(
                "[data-call-basic-id]"
              );
            };

            PatientPrism.Common.UI.CallBasicsLoader.addAfterInitCallback(
              call_basics_loaded
            );
          },
          enableElement: function () {
            let call_basics_loaded = function () {
              PatientPrism.Common.UI.CallBasicsLoader.enableCallBasics(
                "[data-call-basic-id]"
              );
            };

            PatientPrism.Common.UI.CallBasicsLoader.addAfterInitCallback(
              call_basics_loaded
            );
          },
        },
        answered_by: {
          selector: "#answered-by-id",
          disableElement: function (selector) {
            PatientPrism.Modules.Calls.Reviews.Fields.AnsweredBy.getSelectize()[0].selectize.disable();
            PatientPrism.Modules.Calls.Reviews.Fields.AnsweredBy.getSelectize()[0].selectize.clear();
            $("#suggested-staff-name").prop("disabled", "true");
            $("#checkbox-unlisted-answered-by").prop("disabled", "true");
            $("#suggested-staff-name").val("");
          },
          enableElement: function (selector) {
            PatientPrism.Modules.Calls.Reviews.Fields.AnsweredBy.getSelectize()[0].selectize.enable();
            $("#checkbox-unlisted-answered-by").prop("disabled", "");

            let $unlisted_name = $("#suggested-staff-name");
            $unlisted_name.prop(
              "disabled",
              $unlisted_name.attr("data-is-coach") ? "true" : ""
            );
          },
        },
        apt_time: {
          selector: "#appointment-datetime",
        },
        caller_name: {
          selector: "#caller-name",
        },
        opportunity_panel: {
          selector: "#add-sellables",
          disableElement: function (selector) {
            if ($(selector)[0].selectize) {
              $(selector)[0].selectize.clear();
              $(selector)[0].selectize.disable();
            }
          },
          enableElement: function (selector) {
            if ($(selector)[0].selectize) {
              $(selector)[0].selectize.enable();
            }
          },
        },

        btn_submit_review_with_relo: {
          selector: "#btn-submit-review-with-relo",
        },
      },
      global_action_rules_parsed = [];

    var compare_if_equal = function (value, asserted) {
      return {
        equal: asserted.value == value,
        not_equal: asserted.value != value,
      }[asserted.operator];
    };

    var compare = function (selector, action_rule) {
      // use custom comparator if it is set
      return $.isFunction(map[action_rule.id].compare)
        ? map[action_rule.id].compare(action_rule)
        : compare_if_equal($(selector).val(), action_rule);
    };

    var setValue = function (selector, result_rule) {
      // use custom setter if it is set
      $.isFunction(map[result_rule.id].setValue)
        ? map[result_rule.id].setValue(result_rule.value)
        : $(selector).val(result_rule.value);

      // use custom highlighter if it is set
      if ($.isFunction(map[result_rule.id].highlight)) {
        map[result_rule.id].highlight(result_rule.value);
      } else {
        // highlight the element
        $(selector).css("background-color", "");
        $(selector).addClass("label-info predefined-rule-applied");

        // remove highlighting classes
        $(selector).one("change", function () {
          clear_highlighting_status.call(this);
        });
      }
    };

    var parse_rules = function (call_record_action) {
      var result_rules = {};

      JSON.parse(call_record_action).rules.forEach(function (rule) {
        // Skip rules that don't exist
        if (!map[rule.id]) {
          return;
        }

        result_rules[map[rule.id].selector] = {
          id: rule.id,
          operator: rule.operator,
          value: rule.value,
        };
      });

      return result_rules;
    };

    var clear_highlighting_status = function () {
      $(this).removeClass("label-info predefined-rule-applied");
    };

    let shouldOverrideGlobalRule = function (local_action_rules_map) {
      let global_result = false;
      let result = global_action_rules_parsed.some(function (
        global_action_rules_map
      ) {
        let global_keys = Object.keys(global_action_rules_map).sort(),
          local_keys = Object.keys(local_action_rules_map).sort();

        if (global_keys.length != local_keys.length) {
          return false;
        }

        for (let i = 0; i < global_keys.length; i++) {
          let globalValue, localValue;

          // compare local and global keys
          if (global_keys[i] !== local_keys[i]) {
            return false;
          }

          // then compare local and global values
          globalValue = global_action_rules_map[global_keys[i]].value;
          localValue = local_action_rules_map[local_keys[i]].value;

          // sort value if it is array
          globalValue = Array.isArray(globalValue)
            ? globalValue.sort()
            : globalValue;
          localValue = Array.isArray(localValue)
            ? localValue.sort()
            : localValue;

          if (_.isEqual(globalValue, localValue)) {
            global_result = JSON.parse(
              JSON.stringify(global_action_rules_map[global_keys[i]].result)
            );

            let local_result = local_action_rules_map[local_keys[i]].result;

            _.forEach(local_result, function (value, key) {
              global_result[key] = value;
            });

            let global_result_index = -1;
            global_result_index = _.findIndex(
              global_action_rules_parsed,
              function (item) {
                let key = Object.keys(item)[0];
                return (
                  key === global_keys[0] && item[key].value === globalValue
                );
              }
            );
            if (global_result_index >= 0) {
              global_action_rules_parsed[global_result_index][
                global_keys[0]
              ].result = global_result;
            }

            return true;
          }
          return false;
        }
      });
      if (result) {
        return global_result;
      }
      return false;
    };

    /**
     * Check if there's a rule for disabling an element
     * @param result_rule
     * @returns {boolean}
     */
    var shouldBeDisabled = function (result_rule) {
      return result_rule.operator == "disabled";
    };

    /**
     * Disable element based on a rule and remember which rule (by index) disabled an element
     * @param selector
     * @param index
     * @param result_rule
     */
    var disableElement = function (selector, index, result_rule) {
      var disabledByRules = $(selector).data("disabledBy") || [];

      if (disabledByRules.indexOf(index) === -1) {
        disabledByRules.push(index);
        $(selector).data("disabledBy", disabledByRules);
      }

      if (
        map[result_rule.id] &&
        $.isFunction(map[result_rule.id].disableElement)
      ) {
        map[result_rule.id].disableElement(selector);
      } else {
        $(selector).prop("disabled", "true");
        $(selector).prop("selectedIndex", 0);
      }
    };

    /**
     * Enable an element based on a previously disabling rule (by index)
     * @param selector
     * @param index
     * @param result_rule
     */
    var enableElement = function (selector, index, result_rule) {
      var disabledByRules = $(selector).data("disabledBy"),
        indexToDelete;

      if (!Array.isArray(disabledByRules)) {
        return false;
      }

      indexToDelete = disabledByRules.indexOf(index);

      if (indexToDelete > -1) {
        disabledByRules.splice(indexToDelete, 1);
      } else {
        return false;
      }

      if (disabledByRules.length === 0) {
        $(selector).removeData("disabledBy");

        if (
          map[result_rule.id] &&
          $.isFunction(map[result_rule.id].enableElement)
        ) {
          map[result_rule.id].enableElement(selector);
        } else {
          $(selector).prop("disabled", "");
        }
      }
    };

    var processActionRules = function (action_rules) {
      let match = true;

      // compare all values
      $.each(action_rules, function (selector, action_rule) {
        match &= compare(selector, action_rule);

        if (!match) return false;
      });

      return match;
    };

    let build_all_rules = function (call_record_actions, namespace) {
      // loop through ALL call record actions and subscribe to "change" event
      call_record_actions.forEach(function (
        call_record_action,
        call_record_action_index
      ) {
        var action_rules,
          result_rules,
          should_override_global = false;

        call_record_action_index++;

        // parse all action and result rules ignoring duplicates
        action_rules = parse_rules(call_record_action.action);
        result_rules = parse_rules(call_record_action.result);

        action_rules[Object.keys(action_rules)[0]]["result"] = result_rules;

        // local predefined rule must override global one if rule.id and rule.value are equal
        if (namespace === "call_actions_global") {
          global_action_rules_parsed.push(action_rules);
        } else {
          should_override_global = shouldOverrideGlobalRule(action_rules);
          if (false !== should_override_global) {
            result_rules = should_override_global;
            action_rules[Object.keys(action_rules)[0]]["result"] = result_rules;
          } else {
            global_action_rules_parsed.push(action_rules);
          }
        }
      });
    };

    /**
     * Apply predefined rules based on a namespace: local or global
     *
     * @param call_record_actions
     * @param namespace call_actions_global or call_actions_local
     */
    let bind_call_record_actions_handlers = function () {
      let namespace = "call_actions_global";

      // unbind all possible rules
      $.each(map, function (id, element) {
        $(element.selector).off((element.event || "change") + "." + namespace);
        clear_highlighting_status.call($(element.selector)[0]);
      });

      // loop through ALL call record actions and subscribe to "change" event
      global_action_rules_parsed.forEach(function (
        call_record_action,
        call_record_action_index
      ) {
        var action_rules,
          result_rules,
          should_override_global = false;

        call_record_action_index++;

        action_rules = call_record_action;
        result_rules = action_rules[Object.keys(action_rules)[0]].result;

        $.each(action_rules, function (action_selector, rule) {
          let eventName = map[rule.id].event || "change";

          // bind 'change' or 'click' event
          $(action_selector).on(eventName + "." + namespace, function () {
            let match = processActionRules(action_rules);

            // process result rules
            $.each(result_rules, function (result_selector, result_rule) {
              // skip setting the value if such selector exists in actions list
              if (action_rules[result_selector]) {
                return true;
              }

              // set value if an element shouldn't be disabled
              if (shouldBeDisabled(result_rule)) {
                let toggleFunc = match ? disableElement : enableElement;

                // disable/enable element based on global/local namespace and rule index
                toggleFunc(
                  result_selector,
                  namespace + "_" + call_record_action_index,
                  result_rule
                );
              } else if (match) {
                setValue(result_selector, result_rule);
              }
            });
          });

          // ISSUE-396: disable fields according to action rules on "modify" view
          let match = processActionRules(action_rules);

          match &&
            $.each(result_rules, function (result_selector, result_rule) {
              // set value if an element shouldn't be disabled
              if (shouldBeDisabled(result_rule)) {
                // disable element based on global/local namespace and rule index
                disableElement(
                  result_selector,
                  namespace + "_" + call_record_action_index,
                  result_rule
                );
              } else {
                setValue(result_selector, result_rule);
              }
            });
        });
      });
    };

    var bind_all = function (global_predefined_rules, local_predefined_rules) {
      $.each(map, function (item) {
        $(item.selector).removeData("disabledBy");
        $(item.selector).prop("disabled", "");
      });

      build_all_rules(global_predefined_rules, "call_actions_global");
      build_all_rules(local_predefined_rules, "call_actions_local");

      bind_call_record_actions_handlers();
    };

    // bind change location handler
    $("#location-id").on("change", function () {
      var location_id = $(this).val();

      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          // re-apply global predefined rules and then local ones again
          bind_all(global_predefined_rules, data.data.call_record_actions);
        } else if (textStatus === "error") {
          swal({
            title: "An Error Occurred",
            text: "There was an error while trying to get the location.",
            type: "error",
            html: true,
          });
        }
      };

      // get all call record actions related to currently selected location
      PatientPrism.API.Locations.get(location_id, api_callback, [
        "call_record_actions",
      ]);
    });

    // apply global predefined rules and then local ones step by step
    bind_all(
      global_predefined_rules,
      self.call_record.location.call_record_actions
    );
  }

  function init_coach_notebook_background() {
    let colors = {
      "": "alert alert-warning",
      Booked: "alert alert-success",
      "Not Booked": "alert alert-danger",
    };

    let coach_notebook_panel_footer = $("div#follow-ups > div.panel-footer");
    let follow_up_status_select = coach_notebook_panel_footer.find(
      "select#follow-up-status"
    );

    follow_up_status_select.on("change", function () {
      coach_notebook_panel_footer.removeClass().addClass(colors[$(this).val()]);
    });

    follow_up_status_select.trigger("change");
  }

  function initCallerHistory() {
    $('#btn-caller-history').click(function(e) {
      e.preventDefault();

      $('#caller-history').slideDown();
      $('#caller-history-loader').show();
    $('#caller-history-table').hide();
      var api_callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
            var call_records = data.data;

            if (!call_records.length) {
                $('#caller-history-loader').hide();
                $('#caller-history-table').slideDown();
                return;
            }

            $('tbody', '#caller-history-table').empty();

            _.each(call_records, function(call_record) {
                var current_call = call_record.id === self.call_record.id ? '<br/><small class="text-strong text-muted">Current Call</small>' : '';
                var classification = _.find(call_record.metadata, {'key': 'classification'});
                var booking_status = _.find(call_record.metadata, {'key': 'booking_status'});
                var has_follow_ups = call_record.call_follow_ups.length ? 'Yes' : 'No';

                booking_status = booking_status ? '<br/><small>' + booking_status.value + '</small>' : '';
                

                $('tbody', '#caller-history-table').append(`
                <tr>
                    <td style="vertical-align: middle">${self.helper.local_time(call_record.created_at, null, call_record.location.timezone)}${current_call}</td>
                    <td style="vertical-align: middle">${classification.value}${booking_status}</td>
                    <td class="text-center" style="vertical-align: middle">${has_follow_ups}</td>
                    <td class="text-center" style="vertical-align: middle"><a href="/calls/${call_record.id}" target="_blank" class="btn btn-xs btn-primary">View Call</a></td>
                </tr>
                `);
            });

            $('#caller-history-loader').hide();
            $('#caller-history-table').slideDown();

        } else if (textStatus === "error") {
            $('#caller-history-loader').hide();
            $('#caller-history-error').show();
        }
      };

      var search_criteria = {
        from: self.call_record.From,
        location_id: self.call_record.location_id,
        order_by_desc: 'created_at',
        take: 5,
        reviewed: 1,
        expand: [
            'location',
            'metadata',
            'call_follow_ups'
        ]
      };

      PatientPrism.API.CallRecords.all(api_callback, search_criteria);
    })
  }
})();
