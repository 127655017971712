(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.PhoneNumber", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init(data)
    {
        self.report_data = JSON.parse(data);

        initDailyCallSummaryChart();
        initHourlyCallSummaryChart();
        initMultiselectFilters();
        initSetReportFiltersButton();
        initTooltips();
    }

    function initSetReportFiltersButton()
    {
        $('#apply-report-filters').click(function(e)
        {
            e.preventDefault();

            var params = [];

            var filter_phone_numbers = $('#filters-phone-numbers').val() ? $('#filters-phone-numbers').val().join() : null;

            if (filter_phone_numbers)
                params.push('filter-phone-numbers=' + filter_phone_numbers);

            var filter_types         = $('#filters-type').val() ? $('#filters-type').val().join() : null;

            if (filter_types)
                params.push('filter-types=' + filter_types);

            window.location.href =
                "//" +
                window.location.host +
                window.location.pathname +
                '?' + params.join('&');
        });
    }

    function initMultiselectFilters()
    {
        $('#filters-phone-numbers').multiselect({
            buttonClass: 'btn btn-link',
            nonSelectedText: 'All Phone Numbers',
            numberDisplayed: 2,
            nSelectedText: ' Phone Numbers',
            allSelectedText: 'All Phone Numbers'
        });

        $('#filters-type').multiselect({
            buttonClass: 'btn btn-link',
            nonSelectedText: 'All Call Types',
            numberDisplayed: 2,
            nSelectedText: ' Call Types',
            allSelectedText: 'All Call Types'
        });
    }

    function initDailyCallSummaryChart() {
        var series = [];

        _.each(self.report_data, function(item)
        {
            var random_color = Math.floor(Math.random()*11) + 1;

            var series_settings = {
                name: item.name,
                type: 'spline',
                data: [
                    item.daily_performance.hasOwnProperty('Mon') ? item.daily_performance.Mon.total_calls : 0,
                    item.daily_performance.hasOwnProperty('Tue') ? item.daily_performance.Tue.total_calls : 0,
                    item.daily_performance.hasOwnProperty('Wed') ? item.daily_performance.Wed.total_calls : 0,
                    item.daily_performance.hasOwnProperty('Thu') ? item.daily_performance.Thu.total_calls : 0,
                    item.daily_performance.hasOwnProperty('Fri') ? item.daily_performance.Fri.total_calls : 0,
                    item.daily_performance.hasOwnProperty('Sat') ? item.daily_performance.Sat.total_calls : 0,
                    item.daily_performance.hasOwnProperty('Sun') ? item.daily_performance.Sun.total_calls : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[random_color]]
                    ]
                }
            };

            series.push(series_settings);
        });

        $('#daily-call-summary-chart').highcharts({
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Daily Call Summary'
            },
            xAxis: [{
                categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                }
            }],
            tooltip: {
                shared: true
            },
            series: series
        });
    }

    function initHourlyCallSummaryChart() {
        var series = [];

        _.each(self.report_data, function(item)
        {
            var random_color = Math.floor(Math.random()*11) + 1;

            var series_settings = {
                name: item.name,
                type: 'spline',
                data: [
                    item.hourly_performance.hasOwnProperty('12 AM') ? item.hourly_performance['12 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('01 AM') ? item.hourly_performance['01 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('02 AM') ? item.hourly_performance['02 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('03 AM') ? item.hourly_performance['03 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('04 AM') ? item.hourly_performance['04 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('05 AM') ? item.hourly_performance['05 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('06 AM') ? item.hourly_performance['06 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('07 AM') ? item.hourly_performance['07 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('08 AM') ? item.hourly_performance['08 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('09 AM') ? item.hourly_performance['09 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('10 AM') ? item.hourly_performance['10 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('11 AM') ? item.hourly_performance['11 AM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('12 PM') ? item.hourly_performance['12 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('01 PM') ? item.hourly_performance['01 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('02 PM') ? item.hourly_performance['02 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('03 PM') ? item.hourly_performance['03 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('04 PM') ? item.hourly_performance['04 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('05 PM') ? item.hourly_performance['05 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('06 PM') ? item.hourly_performance['06 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('07 PM') ? item.hourly_performance['07 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('08 PM') ? item.hourly_performance['08 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('09 PM') ? item.hourly_performance['09 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('10 PM') ? item.hourly_performance['10 PM'].total_calls : 0,
                    item.hourly_performance.hasOwnProperty('11 PM') ? item.hourly_performance['11 PM'].total_calls : 0
                ],
                tooltip: {
                    valueSuffix: ' Calls'
                },
                shadow: true,
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[random_color]]                    ]
                }
            };

            series.push(series_settings);
        });

        $('#hourly-call-summary-chart').highcharts({
            chart: {
                zoomType: 'xy',
            },
            title: {
                text: 'Hourly Call Summary'
            },
            xAxis: [{
                categories: [
                    '12 AM', '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM', '09 AM', '10 AM', '11 AM',
                    '12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM'
                ],
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                }
            }],
            tooltip: {
                shared: true
            },
            series: series
        });
    }

    function initTooltips()
    {
        $('[data-toggle="tooltip"]').tooltip();
    }
})();