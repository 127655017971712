(function() {
    namespace("PatientPrism.Admin.CallData.ActivityTimeline", init);

    function init(call_record)
    {
        call_record = JSON.parse(call_record);
        let callback = function (data, textStatus, jqXHR)
        {
            let timeline = null;

            if ('error' === data.status) {
                timeline = [];
                put_rendered_data();
                return;
            } else {
                timeline = (data.data.analytic_token ? data.data.analytic_token.web_records : []);
            }

            timeline.push(call_record);

            timeline = _.sortBy(timeline, ['created_at']);

            let body = {
                template: 'calls.parts.activity-timeline',
                parameters: {
                    'timeline': JSON.stringify(timeline),
                },
            };

            PatientPrism.API.HTTP.Socket.post('/template/render', put_rendered_data, body, 'web');
        };

        PatientPrism.API.CallRecords.get(
            call_record.id,
            callback,
            null,                                   // appends
            ['analytic_token.web_records.terms']    // expands
        );
    }

    function put_rendered_data(data, textStatus, jqXHR)
    {
        let block = $('div#activity-timeline');

        block.find("img.loader").remove();

        if (!data || data.status === 'error') {
            block.append($('<div class="text-center">No data</div>'));
            return;
        }

        block.append(data);
    }
})();
