(function()
{
    namespace("PatientPrism.Common.UI.CopyButton", init);

    var self = {
        $copybutton: null
    }

    function init()
    {
        self.$copybutton = new Clipboard('.copy-button');

        self.$copybutton.on('success', function(e)
        {
            $(e.trigger).tooltip({
                placement: 'bottom',
                trigger: 'manual'
            })

            $(e.trigger).tooltip('show');

            setTimeout(function()
            {
                $(e.trigger).tooltip('hide');
            }, 1500);

            e.clearSelection();
        });

        self.$copybutton.on('error', function(e) {
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
        });
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initProfileTooltip()
    {
        $('#navbar-location-profile', self.$navbar).popover({
            'html': true,
            'template': '<div class="popover location-profile-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
        });
    }
})();