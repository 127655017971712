(function()
{
    namespace("PatientPrism.API.CallAudioScores", create, update, destroy);

    var self = {
        //
    }

    function create(call_record_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/call-records/' + call_record_id + '/call-audio-scores', callback, body)
    }

    function update(call_audio_score_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-audio-scores/' + call_audio_score_id , callback, body)
    }

    function destroy(call_audio_score_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-audio-scores/' + call_audio_score_id , callback, body)
    }
})();