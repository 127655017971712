(function()
{
    namespace("PatientPrism.API.MicroReports", sellables, bookings, opportunities, call_records, location_scores, leaderboard, not_connected);

    var self = {
        //
    }

    function call_records(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/call-records', callback, body)
    }

    function bookings(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/bookings', callback, body)
    }

    function opportunities(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/opportunities', callback, body)
    }

    function sellables(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/sellables', callback, body)
    }

    function location_scores(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/location-scores', callback, body)
    }

    function leaderboard(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/leaderboard', callback, body)
    }

    function not_connected(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/locations/micro-reports/not-connected', callback, body)
    }

})();