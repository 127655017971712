(function()
{
    namespace("PatientPrism.Modules.Calls.Reviews.UnlistedStaffCalls", init);

    var self = {
        $table: null
    };

    function init()
    {
        PatientPrism.Common.UI.BlockedCallers.initBlockedCallers();
        initUnlistedStaffCallsDataTable();

        PatientPrism.Common.Vendor.DataTable.ActionColumn.CallRecords.init(self.$table);
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initUnlistedStaffCallsDataTable()
    {
        self.$table = $('table#unlisted-staff-calls');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [8, 'desc']
                ],
                "columnDefs": [
                {
                    "targets": [1,9],
                    "orderable": false
                }],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "No Calls Available"
                },
                "sPaginationType": "simple"
            });
        }
    }

})();