(function()
{
    namespace("PatientPrism.Modules.KeywordExplorer.Index", init);

    var self =
    {
        data: {
            keywords: []
        },

        three: {
            scene:     null,
            renderer:  null,
            camera:    null,
            raycaster: null,
            mouse:     null,
            objects:   [],
            targets: {
                table:  [],
                sphere: [],
                helix:  [],
                grid:   []
            }
        },

        hover_timer: null,
        hover_delay: 500

    };

    function init()
    {
        var callback = function()
        {
            initScene();
            bindTargetButtons();
            initTableTarget();
            initSphereTarget();
            initHelixTarget();
            initGridTarget();
            bindWindowResize();

            transformTargets( self.three.targets.table, 2000 );

            animateScene();
        }

        getKeywords(callback);
    }

    function getKeywords(callback)
    {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://api.patientprism.com/callbacks/keywords');

        xhr.onload = function() {
            if (xhr.status === 200) {
                self.data.keywords = JSON.parse(xhr.responseText).data;
                callback();
            }
            else {
                alert('Request failed.  Returned status of ' + xhr.status);
            }
        };

        xhr.send();
    }

    function initScene()
    {
        self.three.scene    = new THREE.Scene();

        self.three.renderer = new THREE.CSS3DRenderer();
        self.three.renderer.setSize( window.innerWidth, window.innerHeight );
        self.three.renderer.domElement.style.position = 'absolute';
        document.getElementById( 'keyword-explorer-container' ).appendChild( self.three.renderer.domElement );

        self.three.camera            = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 10000 );
        self.three.camera.position.z = 3000;

        self.three.raycaster = new THREE.Raycaster();
        self.three.mouse     = new THREE.Vector2();

        self.three.controls = new THREE.TrackballControls( self.three.camera, self.three.renderer.domElement );
        self.three.controls.rotateSpeed = 0.5;
        self.three.controls.minDistance = 500;
        self.three.controls.maxDistance = 6000;
        self.three.controls.addEventListener( 'change', renderScene );

    }

    function bindTargetButtons()
    {
        var button = document.getElementById( 'table' );
        button.addEventListener( 'click', function ( event ) {

            transformTargets( self.three.targets.table, 2000 );

        }, false );

        var button = document.getElementById( 'sphere' );
        button.addEventListener( 'click', function ( event ) {

            transformTargets( self.three.targets.sphere, 2000 );

        }, false );

        var button = document.getElementById( 'helix' );
        button.addEventListener( 'click', function ( event ) {

            transformTargets( self.three.targets.helix, 2000 );

        }, false );

        var button = document.getElementById( 'grid' );
        button.addEventListener( 'click', function ( event ) {

            transformTargets( self.three.targets.grid, 2000 );

        }, false );
    }

    function initTableTarget()
    {
        for ( var i = 0; i < self.data.keywords.length; i++ )
        {
            var element = document.createElement( 'div' );
            element.className = 'element';
            element.style.backgroundColor = 'rgba(0,127,127,' + ( Math.random() * 0.5 + 0.25 ) + ')';
            element.style.backgroundColor = 'rgba(0,127,127,0.7)';
            element.setAttribute("data-keyword-id", self.data.keywords[i].call_keyword_id);

            var number = document.createElement( 'div' );
            number.className = 'number';
            number.textContent = i;
            //element.appendChild( number );

            var symbol = document.createElement( 'div' );
            symbol.className = 'symbol';
            symbol.textContent = self.data.keywords[i].total;
            element.appendChild( symbol );

            var details = document.createElement( 'div' );
            details.className = 'details';
            details.innerHTML = self.data.keywords[i].keyword;
            element.appendChild( details );

            var object = new THREE.CSS3DObject( element );
            object.position.x = Math.random() * 4000 - 2000;
            object.position.y = Math.random() * 4000 - 2000;
            object.position.z = Math.random() * 4000 - 2000;
            object.keyword = {};
            object.keyword.id = self.data.keywords[i].call_keyword_id
            object.keyword.keyword = self.data.keywords[i].keyword;
            object.keyword.total = self.data.keywords[i].total;

            element.parent = object;

            self.three.scene.add( object );
            self.three.objects.push( object );

            bindObjectClick(object);
            bindObjectMouseOver(object);
            bindObjectMouseLeave(object);

            //

            var row_length = 15;
            var object = new THREE.Object3D();
            object.position.x = ( ( i % row_length ) * 140 ) - 1330;
            object.position.y = - ( Math.floor(i/row_length) * 180 ) + 990;

            self.three.targets.table.push( object );
        }
    }

    function initSphereTarget()
    {
        var vector = new THREE.Vector3();
        var spherical = new THREE.Spherical();

        for ( var i = 0, l = self.three.objects.length; i < l; i ++ )
        {
            var phi = Math.acos( -1 + ( 2 * i ) / l );
            var theta = Math.sqrt( l * Math.PI ) * phi;

            var object = new THREE.Object3D();

            spherical.set( 800, phi, theta );

            object.position.setFromSpherical( spherical );

            vector.copy( object.position ).multiplyScalar( 2 );

            object.lookAt( vector );

            self.three.targets.sphere.push( object );
        }
    }

    function initHelixTarget()
    {
        var vector = new THREE.Vector3();
        var cylindrical = new THREE.Cylindrical();

        for ( var i = 0, l = self.three.objects.length; i < l; i ++ )
        {
            var theta = i * 0.175 + Math.PI;
            var y = - ( i * 8 ) + 450;

            var object = new THREE.Object3D();

            cylindrical.set( 900, theta, y );

            object.position.setFromCylindrical( cylindrical );

            vector.x = object.position.x * 2;
            vector.y = object.position.y;
            vector.z = object.position.z * 2;

            object.lookAt( vector );

            self.three.targets.helix.push( object );
        }
    }

    function initGridTarget()
    {
        for ( var i = 0; i < self.three.objects.length; i ++ )
        {
            var object = new THREE.Object3D();

            object.position.x = ( ( i % 5 ) * 400 ) - 800;
            object.position.y = ( - ( Math.floor( i / 5 ) % 5 ) * 400 ) + 800;
            object.position.z = ( Math.floor( i / 25 ) ) * 1000 - 2000;

            self.three.targets.grid.push( object );
        }
    }

    function bindObjectClick(object)
    {
        object.element.onclick = function() {
            /*
            // Move forward 200 px
            new TWEEN.Tween( this.parent.position )
                .to( {z: this.parent.position.z == 0 ? 200 : 0}, 500 )
                .easing( TWEEN.Easing.Quadratic.Out)
                .start();

            new TWEEN.Tween( this )
                .to( {}, 500 )
                .onUpdate( render )
                .start();
            */

            var that = this;
            var coordinates = createVector(that.parent.position.x, that.parent.position.y, that.parent.position.z, self.three.camera, window.innerWidth, window.innerHeight);

            $('#keyword-explorer-keyword').css({top: coordinates.y, left: coordinates.x});
            $('#keyword-explorer-keyword #name').text(that.parent.keyword.keyword);
            $('#keyword-explorer-keyword #total').text(that.parent.keyword.total);

            bindExploreKeywordBtn(that.parent.keyword);

            $('#keyword-explorer-keyword').fadeIn('fast');
        };
    }

    function bindObjectMouseOver(object)
    {
        object.element.onmouseover = function()
        {
            var that = this;
            clearTimeout(self.hover_timer );
            $('#keyword-explorer-keyword').fadeOut('fast');
            self.hover_timer = setTimeout(function(){
                /* Do Some Stuff*/
                var coordinates = createVector(that.parent.position.x, that.parent.position.y, that.parent.position.z, self.three.camera, window.innerWidth, window.innerHeight);
                $('#keyword-explorer-keyword').css({top: coordinates.y, left: coordinates.x});
                $('#keyword-explorer-keyword #name').text(that.parent.keyword.keyword);
                $('#keyword-explorer-keyword #total').text(that.parent.keyword.total);

                bindExploreKeywordBtn(that.parent.keyword);

                $('#keyword-explorer-keyword').fadeIn('fast');
            }, self.hover_delay);


        }
    }

    function bindExploreKeywordBtn(keyword)
    {
        $('#btn-explore').unbind('click')
            .click(function(e)
            {
                e.preventDefault();

                initKeywordChart(keyword);
                $('#explore-keyword-wrapper').fadeIn();
            });

        $('#close-explore-keyword').unbind('click')
            .click(function(e)
            {
                e.preventDefault();

                $('#explore-keyword-wrapper').fadeOut();
            })
    }

    function bindObjectMouseLeave (object)
    {
        object.element.onmouseleave = function()
        {
             clearTimeout(self.hover_timer);
        }
    }

    function transformTargets(targets, duration)
    {
        TWEEN.removeAll();

        for ( var i = 0; i < self.three.objects.length; i ++ )
        {
            var object = self.three.objects[ i ];
            var target = targets[ i ];

            new TWEEN.Tween( object.position )
                .to( { x: target.position.x, y: target.position.y, z: target.position.z }, Math.random() * duration + duration )
                .easing( TWEEN.Easing.Exponential.InOut )
                .start();

            new TWEEN.Tween( object.rotation )
                .to( { x: target.rotation.x, y: target.rotation.y, z: target.rotation.z }, Math.random() * duration + duration )
                .easing( TWEEN.Easing.Exponential.InOut )
                .start();
        }

        new TWEEN.Tween( this )
            .to( {}, duration * 2 )
            .onUpdate( renderScene )
            .start();
    }

    function animateScene()
    {
        requestAnimationFrame( animateScene );
        TWEEN.update();
        self.three.controls.update();
    }

    function renderScene()
    {
        self.three.renderer.render( self.three.scene, self.three.camera );
    }

    function createVector(x, y, z, camera, width, height)
    {
        var p = new THREE.Vector3(x, y, z);
        var vector = p.project(camera);

        vector.x = (vector.x + 1) / 2 * width;
        vector.y = -(vector.y - 1) / 2 * height;

        return vector;
    }

    function bindWindowResize()
    {
        self.three.camera.aspect = window.innerWidth / window.innerHeight;
        self.three.camera.updateProjectionMatrix();

        self.three.renderer.setSize( window.innerWidth, window.innerHeight );

        renderScene();
    }

    function initKeywordChart(keyword)
    {
        $('#explore-keyword-wrapper #keyword-name').text(keyword.keyword);

        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://api.patientprism.com/callbacks/keywords/' + keyword.id);

        xhr.onload = function() {
            if (xhr.status === 200)
            {
                var data = JSON.parse(xhr.responseText).data;

                $('#explore-keyword-chart').highcharts({
                    chart: {
                        zoomType: 'x',
                        backgroundColor: null
                    },
                    title: {
                        text: 'Keyword Occurrence Over Time'
                    },
                    subtitle: {
                        text: document.ontouchstart === undefined ?
                            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
                    },
                    xAxis: {
                        type: 'datetime',
                        labels: {
                            formatter: function(){

                              return moment.unix(this.value).format('MMM YY'); // example for moment.js date library

                              //return this.value;
                            },
                            style: {
                                color: '#FFFFFF'
                            }
                        },
                    },
                    yAxis: {
                        title: {
                            text: '',
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        area: {
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, '#FFFFFF'],
                                    [1, Highcharts.Color('#FFFFFF').setOpacity(0).get('rgba')]
                                ]
                            },
                            marker: {
                                radius: 2
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                    },

                    series: [{
                        type: 'area',
                        name: 'Occurrences',
                        data: _.map(data, function (item)
                            {
                              return [item.unix, item.total]
                            }),
                        shadow: true,
                        color: '#FFFFFF'
                    }]
                });
            }
            else {
                alert('Request failed.  Returned status of ' + xhr.status);
            }
        };

        xhr.send();
    }
})();