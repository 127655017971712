(function() {
    namespace("PatientPrism.Admin.LastAppointment.LastAppointment", init);

    function init(call_record_id)
    {
        PatientPrism.API.CallRecords.caller_history(call_record_id, function (data) {
            if (!data || !data.data.length) {
                return;
            }

            let footer = $("div#call-data-panel > div.panel-footer");
            if (!footer) {
                return false;
            }

            _.each(data.data, function(caller_call_record) {
                if (caller_call_record.metanotes.hasOwnProperty('appointment_datetime')) {
                    let html =
                        '        <small>\n' +
                        '            <span class="glyphicon glyphicon-calendar" style="margin-left: 10px;"></span>\n' +
                        '            <strong>Most Recent Appt.:</strong>\n' +
                        _router.link_to_route('calls.show', caller_call_record.metanotes.appointment_datetime, {
                            'call_record_id': call_record_id
                        }) +
                        '        </small>';
                    footer.append(html);
                    return false;
                }
            });
        });
    }
})();
