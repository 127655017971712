(function()
{
    namespace("PatientPrism.API.SignificantEvents", all, create, update, destroy);

    var self = {
        //
    }

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/significant-events', callback, body)
    }

    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/significant-events', callback, body)
    }

    function update(significant_event_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/significant-events/' + significant_event_id , callback, body)
    }

    function destroy(significant_event_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/significant-events' + (significant_event_id ? '/' + significant_event_id : ''), callback, body)
    }
})();