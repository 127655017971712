(function()
{
    namespace("PatientPrism.API.Sellables", create, destroy);

    var self = {
        //
    }
    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/sellables', callback, body)
    }

    function destroy(sellable_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/sellables' + (sellable_id ? '/' + sellable_id : ''), callback, body)
    }
})();