(function()
{
    namespace("PatientPrism.Admin.Reports.Staff.CoachNotes", init);

    let self = {
        filters: null,
        report_data: null,
        helper: PatientPrism.Common.UI.RenderHelper,
        table: null,
    };
    
    function init(filters, currentUserTimezone = null)
    {
        self.filters = JSON.parse(filters);
        self.helper.init(currentUserTimezone);

        return new Promise(function (resolve, reject) {

            self.$table = $('table#call-record-notes');

            if (!self.$table.length) {
                reject("Can't find table element");
                return;
            }

            let datatable = self.$table.DataTable({
                columnDefs:[
                    {
                        "targets": [0],
                        "orderable": false
                    },
                    {
                        className: 'text-center',
                        targets: [0,2,3,4,5,6,7],
                        "orderable": true
                    }
                ],
                responsive: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                // 'order': [],
                bStateSave: true,
                buttons: [],
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "Loading data...",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                sPaginationType: "simple",
            });

            // Add event listener for opening and closing details
            datatable.on('click', 'span.user-details-control', function () {
                render_call_records_table($(this), datatable);
            });

            let callback = function(data) {

                if (!data || data.data.length === 0) {
                    datatable.settings()[0].oLanguage.sEmptyTable = 'No metrics available';
                    datatable.draw();
                    return;
                }

                self.report_data = data.data;

                _.each(data.data, function(metric)
                {
                    let row = datatable.row.add([
                        '<span class="fa fa-list-ul text-link user-details-control"></span>',
                        metric.user.name,
                        metric.calls,
                        metric.notes.tip,
                        metric.notes.hold,
                        metric.notes.negative,
                        metric.notes.positive,
                        metric.notes.total,
                    ])
                        .nodes()
                        .to$();

                    row.attr('id', 'row-' + metric.user.id)
                        .attr('data-id', metric.user.id);
                });
                datatable.draw();
                resolve(datatable);
            };

            PatientPrism.API.CallAudioNotes.get_all_by_users_and_period(callback, self.filters);
        });
    }

    function render_call_records_table(element, datatable)
    {
        let tr = element.closest('tr');
        let row = datatable.row(tr);

        let user_id = tr.data('id');

        if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass('shown');
        } else {
            return new Promise(function (resolve) {
                // if data has been already gotten and a table has been already built then just show the nested table
                if (!self.report_data[user_id]['call_records']) {
                    let filters = JSON.parse(JSON.stringify(self.filters));
                    filters['user_id'] = user_id;
                    PatientPrism.API.CallAudioNotes.get_call_records_noticed_by_user(function (data) {

                        if (!data.data || data.data.length === 0) {
                            return;
                        }
                        let container = self.report_data[user_id];
                        container['call_records'] = {};
                        _.each(data.data, function (call_record) {
                            container['call_records'][call_record.id] = call_record;
                        });
                        resolve(user_id);
                    }, filters);
                } else {
                    resolve(user_id);
                }
            }).then(function (result) {
                if (result) {
                    let table_id = `call-records-table-${user_id}`;
                    row.child(format_call_record_table(self.report_data[user_id], table_id)).show();

                    let call_record_datatable = $('#' + table_id).DataTable({
                        'order': [
                        ],
                        "columnDefs": [
                            {
                                "targets": [0,7,8],
                                "orderable": false
                            },
                        ],
                        "oLanguage": {
                            "sLengthMenu": "Show _MENU_ usage call records per page",
                            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Call Records",
                            "sInfoEmpty": "",
                            "sInfoFiltered": " - filtered from _MAX_ Total Call Records",
                            "sEmptyTable": "No Call Records Available"
                        },
                        "sPaginationType": "simple"
                    });

                    // Add event listener for opening and closing details
                    call_record_datatable.on('click', 'span.call-record-details-control', function () {
                        render_notes_table($(this), call_record_datatable, result);
                    });

                    tr.addClass('shown');
                }
            });
        }
    }

    /* Formatting function for row details - modify as you need */
    function format_call_record_table(data, table_id)
    {
        let html = '<table class="table table-bordered" id="' + table_id + '">\n' +
            '                <thead>\n' +
            '                    <tr>\n' +
            '                        <th></th>\n' +
            '                        <th>Company</th>\n' +
            '                        <th>Location</th>\n' +
            '                        <th>Phone Number</th>\n' +
            '                        <th>Caller</th>\n' +
            '                        <th>Duration</th>\n' +
            '                        <th>Date</th>\n' +
            '                        <th>Notes</th>\n' +
            '                        <th></th>\n' +
            '                    </tr>\n' +
            '                </thead>\n' +
            '                <tbody>';

        _.each(data.call_records, function(call_record) {
            html = html +
                `<tr id="row-${call_record.id}" data-id="${call_record.id}">` +
                '<td><span class="fa fa-list-ul text-link call-record-details-control"></span></td>' +
                '<td>' + call_record.location.company.name + '</td>' +
                '<td>' + call_record.location.name + '</td>' +
                '<td>' + call_record.parent_phone_number.name + '</td>' +
                '<td>' + (call_record.From ? self.helper.friendly_phone(call_record.From) : 'Unknown') + '</td>' +
                '<td>' + (call_record.RecordingDuration ? self.helper.seconds_to_hms(call_record.RecordingDuration) : 'Unknown') + '</td>' +
                '<td>' + self.helper.local_time(call_record.created_at, null, call_record.location.timezone) + '</td>' +
                '<td>' +
                '<span class="label label-warning cursor-pointer" title="Tips">' + (call_record.notes.hasOwnProperty('tip') ? call_record.notes.tip.length : 0) + '</span>' +
                '<span class="label label-danger cursor-pointer" title="Holds">' + (call_record.notes.hasOwnProperty('hold') ? call_record.notes.hold.length : 0) + '</span>' +
                '<span class="label label-danger cursor-pointer" title="Negatives">' + (call_record.notes.hasOwnProperty('negative') ? call_record.notes.negative.length : 0) + '</span>' +
                '<span class="label label-success cursor-pointer" title="Positives">' + (call_record.notes.hasOwnProperty('positive') ? call_record.notes.positive.length : 0) + '</span>' +
                '</td>' +
                '<td><a class="btn-review btn btn-sm btn-primary" target="_blank" href="/calls/' + call_record.id + '" data-call-record-id="3195828">View Call</a></td>' +
                '</tr>';
        });

        html = html + '</tbody></table>';

        return html;
    }

    function render_notes_table(element, datatable, user_id)
    {
        let tr = element.closest('tr');
        let row = datatable.row(tr);

        let call_record_id = tr.data('id');

        if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass('shown');
        } else {
            let table_id = `notes-table-${call_record_id}`;
            row.child(format_note_table(self.report_data[user_id]['call_records'][call_record_id], table_id)).show();

            $('#' + table_id).DataTable({
                'order': [
                    // [3, 'desc']
                ],
                "columnDefs": [
                    {
                        "targets": [0],
                        "orderable": false
                    },
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ usage notes per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Notes",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Notes",
                    "sEmptyTable": "No Notes Available"
                },
                "sPaginationType": "simple"
            });

            tr.addClass('shown');
        }
    }

    function format_note_table(data, table_id)
    {
        let html = '<table class="table table-bordered">\n' +
            '                <thead>\n' +
            '                    <tr>\n' +
            '                        <th>Type</th>\n' +
            '                        <th>Comment</th>\n' +
            '                        <th>Time start</th>\n' +
            '                        <th>Time end</th>\n' +
            '                    </tr>\n' +
            '                </thead>\n' +
            '                <tbody>';

        _.each(data.notes, function(type) {
            _.each(type, function (note) {

                let label_class = '';
                switch (note.type) {
                    case 'tip':
                        label_class = 'label-warning';
                        break;
                    case 'hold':
                        label_class = 'label-danger';
                        break;
                    case 'negative':
                        label_class = 'label-danger';
                        break;
                    case 'positive':
                        label_class = 'label-success';
                        break;
                }

                html = html +
                    '<tr>' +
                    '<td><span class="label ' + label_class + ' cursor-pointer" title="Tips">&nbsp;</span></td>' +
                    '<td>' + (note.data !== '' ? note.data : '(not set)') + '</td>' +
                    '<td>' + self.helper.seconds_to_hms(note.time_start) + '</td>' +
                    '<td>' + self.helper.seconds_to_hms(note.time_end) + '</td>' +
                    '</tr>';
            });
        });

        html = html + '</tbody></table>';

        return html;
    }
})();
