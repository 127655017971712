(function()
{
    namespace("PatientPrism.Modules.Calls.Analytics.Behavior", init);

    var self = {
        $table: null
    }

    function init()
    {
    	initTrafficDataTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initTrafficDataTable()
    {
        self.$table = $('table#traffic');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [1, 'desc']
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ records per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Analytic Records",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total records",
                    "sEmptyTable": "No Analytic Data Available"
                },
                "sPaginationType": "simple"
            });
        }
    }
})();