(function()
{
    namespace("PatientPrism.Common.Session", put, push, get, all);

    var session = {};

    function put(key, value)
    {
        session[key] = value;
    }

    function push(key, value)
    {
        _.merge(session[key], value);
    }

    function get(key)
    {
        return session[key];
    }

    function all()
    {
        return session;
    }
})();