(function()
{
    namespace("PatientPrism.API.CallBasicCategories", all, create, update, destroy);

    var self = {
        //
    }

    function all(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/call-basic-categories', callback, body)
    }

    function create(callback, body)
    {
        PatientPrism.API.HTTP.Socket.post('/call-basic-categories', callback, body)
    }

    function update(call_basic_category_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.patch('/call-basic-categories/' + call_basic_category_id , callback, body)
    }

    function destroy(call_basic_category_id, callback, body)
    {
        PatientPrism.API.HTTP.Socket.destroy('/call-basic-categories/' + call_basic_category_id , callback, body)
    }
})();