(function()
{
    namespace("PatientPrism.Modules.Coach.Dashboard", init);

    function init(user_id) {
        //list of indexes to show after table would be filtered
        //(3 first, two above and two below)
        var indexes = [];
        var table = $('#coach-datatable');
        var t = table.DataTable({
                columnDefs: [
                   {
                        className: 'text-center',
                        targets: "_all"
                    },
                 ],
                 order: [[ 1, "desc" ]],
                 paging: false,
                 searching: false,
                 info: false
            });
    }

    function calcPercentDifference(val1, val2)
    {
        if (val1 > val2) {
            //calculate positive delta
            return parseInt(Math.round(val1 / val2  * 10000) / 100) - 100;
        }
        //calculate negative delta
        return - parseInt(Math.round((val2 - val1) / val2  * 10000) / 100);
    }

    function prepareHtml(val)
    {
        var clazz = 'glyphicon-arrow-down text-danger';

        if (val > 0) {
            val = "+"+val;
            clazz = 'glyphicon-arrow-up text-success';
        }

        return '<i class="glyphicon '+clazz+'"></i><br/>('+val+'%)';
    }
})();