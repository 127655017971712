(function()
{
    namespace("PatientPrism.Admin.Reports.Staff.CallReviewPerformance", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init(data)
    {
        self.report_data = JSON.parse(data);

        initStaffMetricsDataTable();
        initDailyCallSummaryChart();
        initStaffPerformanceHourlyChart();
        initGeneralCallsCheckbox();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initStaffMetricsDataTable()
    {
        self.$table = $('table#staff-metrics');
        
        if (self.$table.length)
        {
            self.$table.DataTable({
                responsive: true,
                deferRender: true,
                'scrollX': true,
                fixedColumns:   {
                    leftColumns: 1
                },
                'order': [
                    [0, 'desc']
                ],
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                     "<'row'<'col-sm-12'tr>>" +
                     "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                bStateSave: true,
                buttons: [
                {
                    extend:        'csv',
                    text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                    filename:      'search-results',
                    className:     'btn-sm action-button dt-button',
                    exportOptions: {
                        orthogonal: 'export',
                        columns: [ ':visible :not(.no-export)' ]
                    },
                },
                {
                    extend:        'colvis',
                    text:          '<i class="fa fa-columns"></i> Toggle Columns <span class="caret"></span>',
                    className:     'btn-sm action-button dt-button',
                    columns:       ':not(.no-toggle-visibility)'

                }],
                "columnDefs": [
                    {
                        "targets": [],
                        "orderable": false,
                        "className": 'no-toggle-visibility'
                    },
                    {
                        "targets": [],
                        "visible": false,
                        "searchable": true
                    },
                    {
                        "targets": [],
                        "className": 'text-center'
                    }
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                }
            });
        }
    }

    function secondsToHMS(seconds)
    {
        if (!seconds)
            seconds = 0;

        return new Date(seconds * 1000).toISOString().substr(11, 8)
    }

    function initDailyCallSummaryChart() {

        $('#staff-call-review-performance-chart').highcharts({
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Call Review Performance'
            },
            subtitle: {
                text: 'Total Reviews'
            },
            xAxis: [{
                categories: _.map(self.report_data.staff_performance, function(record)
                    {
                        return record.user.name;
                    }),
                crosshair: true
            }],
            yAxis: [{
                title: {
                    text: 'Reviews'
                }
            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 100,
                verticalAlign: 'top',
                y: 30,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            series: [{
                name: 'Calls Reviewed',
                type: 'areaspline',
                data:
                    _.map(self.report_data.staff_performance, function(record)
                    {
                        return record.reviews.total;
                    })
                ,
                tooltip: {
                    valueSuffix: ' Calls'
                },
                color: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[7]],
                        [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[7], -0.5)]
                    ]
                },
                borderColor: 'rgba(200,240,255,0.6)',
                borderWidth: 1,
                shadow: true
            }]
        });
    }

    function initStaffPerformanceHourlyChart()
    {
        new Promise(function(resolve, reject) {
            PatientPrism.Common.UI.Filters.Service.Filter.init(drawStaffPerformanceHourlyChart);
            PatientPrism.Common.UI.Filters.Service.Filter.registerFilter('chart-user');
            PatientPrism.Common.UI.Filters.Service.Filter.registerFilter('chart-day');
            resolve(true);
        }).then(function (success) {
            if (success === true) {
                PatientPrism.Common.UI.Filters.Service.Filter.applyFilters();
            }
        });
    }

    function drawStaffPerformanceHourlyChart(filter_params)
    {
        let chart_element = $('#staff-performance-hourly-chart');
        if (chart_element.highcharts()) {
            chart_element.highcharts().destroy();
        }

        let user = filter_params['ChartUser'];
        let day = filter_params['ChartDay']['key'];

        if (!self.report_data.staff_performance_hourly.calendar.hasOwnProperty(day)) {
            return;
        }

        let data = JSON.parse(JSON.stringify(self.report_data.staff_performance_hourly.calendar[day]));

        data = _.each(data, function (row) {
            let sum = 0;
            let count = 0;
            _.each(row, function (item, userId) {
                if (userId != user.key && userId != 1) {
                    sum += item;
                    count++;
                }
            });

            if (count) {
                row['average'] = parseFloat((sum / count).toFixed(2));
            } else {
                row['average'] = 0;
            }
        });

        let chart = chart_element.highcharts({
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: 'Hourly Call Summary'
            },
            subtitle: {
                text: 'A Breakdown of Calls Per Hour'
            },
            xAxis: [{
                categories: [
                    '12 AM', '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM', '09 AM', '10 AM', '11 AM',
                    '12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM'
                ],
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: 'Number of Calls',
                }
            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 100,
                verticalAlign: 'top',
                y: 30,
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
            series: [
                {
                    zIndex: 2,
                    name: user.value,
                    type: 'areaspline',
                    data: [
                        data.hasOwnProperty('12 AM') && data['12 AM'].hasOwnProperty(user.key) ? data['12 AM'][user.key] : 0,
                        data.hasOwnProperty('1 AM') && data['1 AM'].hasOwnProperty(user.key) ? data['1 AM'][user.key] : 0,
                        data.hasOwnProperty('2 AM') && data['2 AM'].hasOwnProperty(user.key) ? data['2 AM'][user.key] : 0,
                        data.hasOwnProperty('3 AM') && data['3 AM'].hasOwnProperty(user.key) ? data['3 AM'][user.key] : 0,
                        data.hasOwnProperty('4 AM') && data['4 AM'].hasOwnProperty(user.key) ? data['4 AM'][user.key] : 0,
                        data.hasOwnProperty('5 AM') && data['5 AM'].hasOwnProperty(user.key) ? data['5 AM'][user.key] : 0,
                        data.hasOwnProperty('6 AM') && data['6 AM'].hasOwnProperty(user.key) ? data['6 AM'][user.key] : 0,
                        data.hasOwnProperty('7 AM') && data['7 AM'].hasOwnProperty(user.key) ? data['7 AM'][user.key] : 0,
                        data.hasOwnProperty('8 AM') && data['8 AM'].hasOwnProperty(user.key) ? data['8 AM'][user.key] : 0,
                        data.hasOwnProperty('9 AM') && data['9 AM'].hasOwnProperty(user.key) ? data['9 AM'][user.key] : 0,
                        data.hasOwnProperty('10 AM') && data['10 AM'].hasOwnProperty(user.key) ? data['10 AM'][user.key] : 0,
                        data.hasOwnProperty('11 AM') && data['11 AM'].hasOwnProperty(user.key) ? data['11 AM'][user.key] : 0,
                        data.hasOwnProperty('12 PM') && data['12 PM'].hasOwnProperty(user.key) ? data['12 PM'][user.key] : 0,
                        data.hasOwnProperty('1 PM') && data['1 PM'].hasOwnProperty(user.key) ? data['1 PM'][user.key] : 0,
                        data.hasOwnProperty('2 PM') && data['2 PM'].hasOwnProperty(user.key) ? data['2 PM'][user.key] : 0,
                        data.hasOwnProperty('3 PM') && data['3 PM'].hasOwnProperty(user.key) ? data['3 PM'][user.key] : 0,
                        data.hasOwnProperty('4 PM') && data['4 PM'].hasOwnProperty(user.key) ? data['4 PM'][user.key] : 0,
                        data.hasOwnProperty('5 PM') && data['5 PM'].hasOwnProperty(user.key) ? data['5 PM'][user.key] : 0,
                        data.hasOwnProperty('6 PM') && data['6 PM'].hasOwnProperty(user.key) ? data['6 PM'][user.key] : 0,
                        data.hasOwnProperty('7 PM') && data['7 PM'].hasOwnProperty(user.key) ? data['7 PM'][user.key] : 0,
                        data.hasOwnProperty('8 PM') && data['8 PM'].hasOwnProperty(user.key) ? data['8 PM'][user.key] : 0,
                        data.hasOwnProperty('9 PM') && data['9 PM'].hasOwnProperty(user.key) ? data['9 PM'][user.key] : 0,
                        data.hasOwnProperty('10 PM') && data['10 PM'].hasOwnProperty(user.key) ? data['10 PM'][user.key] : 0,
                        data.hasOwnProperty('11 PM') && data['11 PM'].hasOwnProperty(user.key) ? data['11 PM'][user.key] : 0
                    ],
                    tooltip: {
                        valueSuffix: ' Calls'
                    },
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[1]],
                            [1, PatientPrism.Utility.Color.hexToRgba(Highcharts.getOptions().colors[1], 0)]
                        ]
                    },
                    shadow: true
                },
                {
                    zIndex: 1,
                    name: "Average of All Other Coaches",
                    type: 'column',
                    data: [
                        data.hasOwnProperty('12 AM') ? data['12 AM']['average'] : 0,
                        data.hasOwnProperty('1 AM') ? data['1 AM']['average'] : 0,
                        data.hasOwnProperty('2 AM') ? data['2 AM']['average'] : 0,
                        data.hasOwnProperty('3 AM') ? data['3 AM']['average'] : 0,
                        data.hasOwnProperty('4 AM') ? data['4 AM']['average'] : 0,
                        data.hasOwnProperty('5 AM') ? data['5 AM']['average'] : 0,
                        data.hasOwnProperty('6 AM') ? data['6 AM']['average'] : 0,
                        data.hasOwnProperty('7 AM') ? data['7 AM']['average'] : 0,
                        data.hasOwnProperty('8 AM') ? data['8 AM']['average'] : 0,
                        data.hasOwnProperty('9 AM') ? data['9 AM']['average'] : 0,
                        data.hasOwnProperty('10 AM') ? data['10 AM']['average'] : 0,
                        data.hasOwnProperty('11 AM') ? data['11 AM']['average'] : 0,
                        data.hasOwnProperty('12 PM') ? data['12 PM']['average'] : 0,
                        data.hasOwnProperty('1 PM') ? data['1 PM']['average'] : 0,
                        data.hasOwnProperty('2 PM') ? data['2 PM']['average'] : 0,
                        data.hasOwnProperty('3 PM') ? data['3 PM']['average'] : 0,
                        data.hasOwnProperty('4 PM') ? data['4 PM']['average'] : 0,
                        data.hasOwnProperty('5 PM') ? data['5 PM']['average'] : 0,
                        data.hasOwnProperty('6 PM') ? data['6 PM']['average'] : 0,
                        data.hasOwnProperty('7 PM') ? data['7 PM']['average'] : 0,
                        data.hasOwnProperty('8 PM') ? data['8 PM']['average'] : 0,
                        data.hasOwnProperty('9 PM') ? data['9 PM']['average'] : 0,
                        data.hasOwnProperty('10 PM') ? data['10 PM']['average'] : 0,
                        data.hasOwnProperty('11 PM') ? data['11 PM']['average'] : 0
                    ],
                    tooltip: {
                        valueSuffix: ' Calls'
                    },
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[11]],
                            [1, PatientPrism.Utility.Color.shadeColor(Highcharts.getOptions().colors[11], -0.5)]
                        ]
                    },
                    borderColor: 'rgba(200,240,255,0.6)',
                    borderWidth: 1,
                    shadow: true
                }
            ]
        });
    }

    function initGeneralCallsCheckbox()
    {
        $('#checkbox-exclude-general').on('change', function(event){
            if ($(this).is(':checked'))
            {
                window.location.href = window.location.href + '?exclude_general=1';
            }
            else
            {
                window.location.href = window.location.href.split("?")[0];
            }
        });
    }
})();