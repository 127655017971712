(function()
{
    namespace("PatientPrism.Common.UI.Loader", init, show, hide);

    var self = {
        $loading_overlay: null
    }

    function init()
    {
        self.$loading_overlay = $('#loading-overlay-container');
        initLoadingTriggers();
    }

    /**
     * Show the loading overlay.
     *
     */
    function show()
    {
        $("html, body").animate({ scrollTop: 0 }, "fast");
        $('body').css('overflow', 'hidden');
        self.$loading_overlay.fadeIn();
    }

    /**
     * Hide the loading overlay.
     *
     */
    function hide()
    {
        $('body').css('overflow', 'auto');
        self.$loading_overlay.fadeOut();
    }

    function initLoadingTriggers()
    {
        $( "[trigger-loading]" ).click(function()
        {
            show();
        });
    }
})();