(function()
{
    namespace("PatientPrism.API.Viewables", get);

    var self = {
        //
    }

    function get(callback, body)
    {
        PatientPrism.API.HTTP.Socket.get('/viewables', callback)
    }

})();