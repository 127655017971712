(function()
{
    namespace("PatientPrism.Modules.Manage.Users.Profile", init);

    var self = {

    };

    function init()
    {
        /**
         * Converts the HTML table into a DataTable.
         *
         */
        $('#checkbox_account_disabled').change(function()
        {
            $('#account_disabled').val($(this).is(":checked") ? 1 : 0);
        });

        $('#select-languages').selectize({
            mode: "multi"
        });
    }
})();
