(function() {
    namespace("PatientPrism.Modules.Analize.Helpers.EnterpriseInsightsDisplayByGroupsOrLocations", button);

    function button(callback, report_table)
    {
        let buttons = [
            {
                text: 'Locations',
                action: function ( e, dt, node, config ) {
                    new Promise(function (resolve, reject) {
                        $(document).trigger('enterprise_insights_display_by_groups_or_locations_changed', {
                            index: 0,
                            report_table: report_table
                        });
                        resolve(true);
                    })
                        .then(function (data) {
                            if (true === data) {
                                callback();
                            }
                        });
                }
            },
            {
                text: 'Groups',
                action: function ( e, dt, node, config ) {
                    new Promise(function (resolve, reject) {
                        $(document).trigger('enterprise_insights_display_by_groups_or_locations_changed', {
                            index: 1,
                            report_table: report_table
                        });
                        resolve(true);
                    })
                        .then(function (data) {
                            if (true === data) {
                                callback();
                            }
                        });
                }
            },
        ];

        let filter_index = PatientPrism.Modules.Analize.Helpers.DataConverter.get_first_index(report_table);

        return {
            extend:    'collection',
            text:      `<i class="fa fa-eye"></i> ${buttons[filter_index].text} <span class="caret"></span>`,
            className: 'btn-sm action-button dt-button enterprise-button',
            buttons: buttons,
        };
    }
})();
