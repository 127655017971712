(function() {
    namespace("PatientPrism.Modules.Manage.Company.PaymentMethods", init, api_callback);

    function init(companyId) {

        // Floating labels
        let inputs = document.querySelectorAll('.payment-form .input');
        Array.prototype.forEach.call(inputs, function(input) {
            input.addEventListener('focus', function() {
                input.classList.add('focused');
            });
            input.addEventListener('blur', function() {
                input.classList.remove('focused');
            });
            input.addEventListener('keyup', function() {
                if (input.value.length === 0) {
                    input.classList.add('empty');
                } else {
                    input.classList.remove('empty');
                }
            });
        });

        $('.delete-payment-source').click(function() {
            let that = $(this);
            swal({
                    title: "Are you sure?",
                    text: "This will permanently delete the payment source?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    showLoaderOnConfirm: true,
                    closeOnCancel: false
                },
                function(isConfirm) {
                    isConfirm ? PatientPrism.API.Companies.paymentSourceDelete(companyId, that.closest('tr').data('id'), api_callback) : swal.close();
                });
        });

        $('.set-default-payment-source').click(function() {
            let that = $(this);
            PatientPrism.API.Companies.paymentSourceDefault(companyId, that.closest('tr').data('id'), api_callback);
        });
        
        let table = $('#datatable-payment-methods');

        let oLanguage = {
                "sLengthMenu": "Show _MENU_ payment sources",
                "sInfo": "Showing _START_ to _END_ of _TOTAL_ payment sources",
                "sInfoEmpty": "",
                "sInfoFiltered": " - filtered from _MAX_ total payment sources",
                "sEmptyTable": "No payment sources Available"
        };

        table.DataTable({
                "columnDefs": [
                {
                    "targets": [0, 1, 2],
                    "orderable": false
                }],
                "oLanguage": oLanguage,
                "sPaginationType": "simple"
        });
    }

    function api_callback(data, textStatus, jqXHR) {
        if (textStatus === 'success') {
            location.reload();
        } else if (textStatus === 'error') {
            PatientPrism.Common.UI.Loader.hide();
            swal({
                title: "An Error Occurred",
                text: "There was an error while processing you payment source, please try again.",
                type: "error",
                html: true
            });
        }
    };

})();