(function()
{
    namespace("PatientPrism.Common.UI.Filters.Service.Filter", init, registerFilter, applyFilters);

    let storage = null;

    function init(callback)
    {
        if (typeof callback !== 'function') {
            throw "The specified callback has to be a function";
        }

        storage = {
            callback: callback,
            filters_params: {}
        };
    }

    function registerFilter(filter_name)
    {
        filter_name = _.upperFirst(_.camelCase(filter_name));

        let filter = PatientPrism.Common.UI.Filters[filter_name];
        if (typeof filter === 'undefined') {
            throw "Can't find the filter \"" + filter_name + "\"";
        }

        if (typeof filter['init'] === 'undefined') {
            throw "Can't initialize the filter \"" + filter_name + "\" because it doesn't have the method \"init\"";
        }

        let setParameters = function (data) {
            if (!data.hasOwnProperty('key') || !data.hasOwnProperty('value')) {
                throw "The `filter.chart-user.init-complete`'s data don't have either \"key\" or \"value\" keys or both";
            }
            storage['filters_params'][filter_name] = {
                params: data
            };
        };

        $(document).on('filter.' + filter_name + '.init-complete', function (e, data) {
            setParameters(data);
        });

        $(document).on('filter.' + filter_name + '.change', function (e, data) {
            setParameters(data);
            applyFilters();
        });

        filter.init();
    }

    function applyFilters()
    {
        let callback = storage.callback;
        let callback_params = {};

        _.each(storage.filters_params, function (item, filter_name) {
            callback_params[filter_name] = item['params'];
        });

        callback(callback_params);
    }
})();
