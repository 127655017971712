(function()
{
    namespace("PatientPrism.Modules.Manage.Locations.Users", init);

    var self = {
        $table: null,
        $select_user: null,
        forms: {
            $add_user: null
        }
    };

    function init()
    {
        initUsersDataTables();
        initSelectUser();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initUsersDataTables()
    {
        var oLanguage = {
            "sLengthMenu": "Show _MENU_ users per page",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ Users",
            "sInfoEmpty": "",
            "sInfoFiltered": " - filtered from _MAX_ total users",
            "sEmptyTable": "No Users Added to Location"
        };

        self.$table = $('table#users');

        if (self.$table.length)
        {
            self.$table.DataTable({
                columnDefs: [{
                    orderable: false,
                    targets: 1
                }],
                'order': [
                    [0, 'asc']
                ],
                "oLanguage": oLanguage,
                "sPaginationType": "simple"
            });
        }
    }

    function initSelectUser()
    {
        self.$select_user = $('select#select-user');
        self.forms.$add_user = $('form#add-user');

        self.$select_user.change( function(e)
        {
            self.forms.$add_user.attr('action', $('option:selected', this).attr('data-url'));
        });
    }
})();