(function()
{
    namespace("PatientPrism.Modules.Calls.MyFollowUps", init);


    function init()
    {
        let table = $('#my-follow-ups');

        if (table.length)
        {
        	table.DataTable({
	            dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
		            "<'row'<'col-sm-12'tr>>" +
		            "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                responsive: true,
                "pageLength": 25,
                'order': [
                    [3, 'asc']
                ],
                "columnDefs": [
                {
                    "targets": [5],
                    "orderable": false
                }],
                language: {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "No Calls Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }

})();