/**
 * Dark theme for Highcharts JS
 * @author Torstein Honsi
 */

// Load the fonts
Highcharts.createElement('link', {
	href: '//fonts.googleapis.com/css?family=Unica+One',
	rel: 'stylesheet',
	type: 'text/css'
}, null, document.getElementsByTagName('head')[0]);

Highcharts.theme = {
	exporting: {
		enabled: false
	},
	colors: [
		"#af8dd7", // Lavender/Violet
		"#40b3d2", // Blue/Cyan
		"#ff997e", // Orange/Salmon
		"#e1c13f", // Yellow
		"#8e93ab", // Blue-Gray
		"#94c2f3", // Light Blue
		"#fb708a", // Pink
		"#a89dcc", // Lavender/Violet
		"#DF5353", // Red
		"#7798BF", // Muted Blue
		"#aaeeee", // Light Green Blue
		"#26B894" // Green
	],
	lang: {
		noData: "No Data to Display",
	},
	noData: {
		style: {
			color: '#c1c3c9',
			fontFamily: "'Proxima Nova Bold', sans-serif",
			fontSize: 16
		}
	},
	chart: {
		backgroundColor: {
			linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
			stops: [
				[0, '#243140'],
				[1, '#243140']
			]
		},
		style: {
			fontFamily: "'Unica One', sans-serif",
			textShadow: '0px 1px 2px #243140'
		},
		plotBorderColor: '#606063',
	},
	title: {
		style: {
			color: '#c8f0ff',
			textTransform: 'uppercase',
			fontSize: '30px',
			textShadow: '0px 2px 2px #243140'
		}
	},
	subtitle: {
		style: {
			color: '#E0E0E3',
			textTransform: 'uppercase'
		}
	},
	xAxis: {
		gridLineColor: '#707073',
		labels: {
			style: {
				color: '#c8f0ff',
				fontFamily: "'Proxima Nova Bold', sans-serif"
			}
		},
		lineColor: '#c8f0ff',
		minorGridLineColor: '#505053',
		tickColor: '#c8f0ff',
		title: {
			style: {
				color: '#A0A0A3'

			}
		}
	},
	yAxis: {
		gridLineColor: 'rgba(78,94,100, 0.7)',
		labels: {
			style: {
				color: '#c8f0ff',
				fontFamily: "'Proxima Nova Bold', sans-serif"
			}
		},
		stackLabels: {
			style: {
				fontFamily: "'Proxima Nova Bold', sans-serif",
                fontWeight: 'bold',
                color: '#c8f0ff'
            },
		},
		lineColor: '#c8f0ff',
		minorGridLineColor: '#505053',
		tickColor: '#c8f0ff',
		tickWidth: 1,
		title: {
			style: {
				color: '#A0A0A3',
				fontFamily: "'Proxima Nova Condensed', sans-serif"
			}
		}
	},
	tooltip: {
		backgroundColor: 'rgba(60, 81, 106, 0.85)',
		style: {
			color: '#c8f0ff',
			fontFamily: "'Proxima Nova Condensed', sans-serif"
		}
	},
	plotOptions: {
		series: {
			shadow: true,
			dataLabels: {
				color: '#fff',
				style: {
					fontFamily: "'Proxima Nova Bold', sans-serif",
				}
			},
			marker: {
				lineColor: '#c8f0ff'
			},
			animation: {
                duration: 2000
            }
		},
		boxplot: {
			fillColor: '#505053'
		},
		candlestick: {
			lineColor: 'white'
		},
		errorbar: {
			color: 'white'
		}
	},
	legend: {
		itemStyle: {
			color: '#E0E0E3',
			fontFamily: "'Proxima Nova Regular', sans-serif"
		},
		itemHoverStyle: {
			color: '#FFF'
		},
		itemHiddenStyle: {
			color: '#606063'
		}
	},
	credits: {
		enabled: false
	},
	labels: {
		style: {
			color: '#707073'
		}
	},

	drilldown: {
		activeAxisLabelStyle: {
			color: '#F0F0F3'
		},
		activeDataLabelStyle: {
			color: '#F0F0F3'
		}
	},

	navigation: {
		buttonOptions: {
			symbolStroke: '#DDDDDD',
			theme: {
				fill: '#505053'
			}
		}
	},

	// scroll charts
	rangeSelector: {
		buttonTheme: {
			fill: '#505053',
			stroke: '#000000',
			style: {
				color: '#CCC'
			},
			states: {
				hover: {
					fill: '#707073',
					stroke: '#000000',
					style: {
						color: 'white'
					}
				},
				select: {
					fill: '#000003',
					stroke: '#000000',
					style: {
						color: 'white'
					}
				}
			}
		},
		inputBoxBorderColor: '#505053',
		inputStyle: {
			backgroundColor: '#333',
			color: 'silver'
		},
		labelStyle: {
			color: 'silver'
		}
	},

	navigator: {
		handles: {
			backgroundColor: '#666',
			borderColor: '#AAA'
		},
		outlineColor: '#CCC',
		maskFill: 'rgba(255,255,255,0.1)',
		series: {
			color: '#7798BF',
			lineColor: '#A6C7ED'
		},
		xAxis: {
			gridLineColor: '#505053'
		}
	},

	scrollbar: {
		barBackgroundColor: '#808083',
		barBorderColor: '#808083',
		buttonArrowColor: '#CCC',
		buttonBackgroundColor: '#606063',
		buttonBorderColor: '#606063',
		rifleColor: '#FFF',
		trackBackgroundColor: '#404043',
		trackBorderColor: '#404043'
	},

	// special colors for some of the
	legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
	background2: '#505053',
	dataLabelsColor: '#B0B0B3',
	textColor: '#C0C0C0',
	contrastTextColor: '#F0F0F3',
	maskColor: 'rgba(255,255,255,0.3)'
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);