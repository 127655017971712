(function () {
  namespace("PatientPrism.Modules.Manage.CallTracking.PhoneNumbers", init);

  var self = {
    $table: null,
    $tracking_number_modal: null,
  };

  function init() {
    self.$table = $("table#phone-numbers");
    initPhoneNumbersDataTable();
    bindEditPhoneNumberModal();
    bindFindPhoneNumbersBtn();
    PatientPrism.Vendor.Clipboard.initClipboards();
  }

  function advertismeteTagCallback() {
    var options = self.$table
      .DataTable()
      .columns(".advertising")
      .data()
      .eq(0) // Reduce the 2D array into a 1D array of data
      .sort() // Sort data alphabetically
      .unique()
      .map(function (item) {
        return { text: item, value: item };
      })
      .toArray(); // Reduce to unique values
    return {
      persist: false,
      options: options,
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    };
  }

  /**
   * Converts the HTML table into a DataTable.
   *
   */
  function initPhoneNumbersDataTable() {
    if (self.$table.length) {
      self.$table.DataTable({
        order: [[5, "asc"]],
        columnDefs: [
          {
            targets: [0],
            orderable: false,
          },
        ],
        oLanguage: {
          sLengthMenu: "Show _MENU_ phone numbers per page",
          sInfo: "Showing _START_ to _END_ of _TOTAL_ Phone Numbers",
          sInfoEmpty: "",
          sInfoFiltered: " - filtered from _MAX_ Total Phone Numbers",
          sEmptyTable: "No Phone Numbers Available",
          sSearch: "_INPUT_",
          sSearchPlaceholder: "Search Table...",
        },
        dom:
          "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        buttons: [
          {
            extend: "csv",
            text: '<i class="fa fa-file-text-o"></i> Export CSV',
            filename: "custom-report",
            className: "btn-sm action-button dt-button",
            exportOptions: {
              orthogonal: "export",
              columns: [":visible :not(.no-export)"],
            },
          },
        ],
        sPaginationType: "simple",
      });

      PatientPrism.Common.Vendor.DataTable.ActionColumn.PhoneNumbers.init(
        self.$table
      );
    }
  }

  function bindEditPhoneNumberModal() {
    $("#modal-manage-edit-tracking-number").on("show.bs.modal", function (
      event
    ) {
      var $data_row = $(event.relatedTarget).closest(".phone-number-row");
      var $modal = $(this);

      $("#id", $modal).attr("value", $data_row.data("id"));
      $("#display-name", $modal).val($data_row.data("name"));
      $("#rings-to", $modal).val($data_row.data("rings-to"));
      $("#location", $modal).val($data_row.data("location-id"));
      $("#call_referrer", $modal).val($data_row.data("call-referer-id"));
      $("#whisper", $modal).val($data_row.data("whisper"));
      $("#sid", $modal).val($data_row.data("sid"));
      $("#advertisement-tag", $modal).val($data_row.data("advertisement-tag"));
    });
  }

  function bindFindPhoneNumbersBtn() {
    var $modal = $("#modal-add-tracking-number");
    var $btn_find_numbers = $("#btn-find-numbers", $modal);
    var $input_area_code = $("#input-area-code", $modal);
    var $select_country_code = $("#select-country-code", $modal);
    var $input_contains = $("#input-contains", $modal);
    var el_search_results = $("#add-tracking-number-search-results");
    var el_area_code = $("#add-tracking-number-area-code");
    var el_contains = $("#add-tracking-number-contains");

    $btn_find_numbers.click(function (e) {
      e.preventDefault();

      // Disable inputs
      $input_area_code.blur();
      $input_contains.blur();
      $select_country_code.blur();

      var ladda_btn = Ladda.create($btn_find_numbers[0]);
      // Set button to loading state
      ladda_btn.start();

      $("#add-tracking-number-additional-options").slideUp();
      $("#error-message", $modal).fadeOut();
      $(el_search_results).slideUp().empty();

      var callback = function (data, textStatus, jqXHR) {
        if (textStatus === "success") {
          if (data.data.length > 0) {
            data.data.forEach(function (number) {
              $(el_search_results)
                .append(
                  '<button class="btn btn-default btn-lg search-result" data-phone-number="' +
                    number.phoneNumber +
                    '">' +
                    number.friendlyName +
                    "</button>"
                )
                .slideDown();
            });

            $(".search-result").click(function (e) {
              e.preventDefault() && e.stopPropagation();

              $("#add-tracking-number-search-results").slideUp();
              $("#add-tracking-number-additional-options").slideDown();
              $("#add-tracking-number-selected-number").html($(this).text());
              $("#add-tracking-selected-phone-number").val(
                $(this).attr("data-phone-number")
              );
            });
          } else {
            $("#error-message", $modal)
              .text("No numbers available matching search criteria")
              .fadeIn();
          }
        } else if (textStatus === "error") {
          $("#error-message", $modal).text(data.error.message).fadeIn();
        }

        ladda_btn.stop();
      };

      var search_criteria = {
        area_code: $input_area_code.val(),
        contains: $input_contains.val(),
        country_code: $select_country_code.val(),
      };

      PatientPrism.API.PhoneNumbers.search(callback, search_criteria);
    });
  }
})();
