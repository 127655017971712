(function() {
	namespace("PatientPrism.Modules.Manage.Locations.Hours", init);

	let self = {
		'pickers' : {}
	};

	function addExceptionRow(values) {
		let id = 'picker' + Date.now();
		let html = $($('#exceptions-row').html());
		html.find('.action-button').attr('id', id)
		$('#exception-dates').append(html);
		self.pickers[id] = true;

		let options =
		{
			showDropdowns : true,
			autoApply : true,
			alwaysShowCalendars : true,
			linkedCalendars : false,
			opens : 'left',
			timePicker : false
		};
		if (values != undefined) {
			options.startDate = moment(values[0]);
			options.endDate = moment(values[1]);
		}
		
		$('#' + id).daterangepicker(options, applyText(id)).on('showCalendar.daterangepicker', function(ev, picker) {
			let endDate = $('[name=daterangepicker_start]', picker.container).val();
			let startDate = $('[name=daterangepicker_end]', picker.container).val();
			let calendarTimes = $('.calendar-time select', picker.container);
			calendarTimes.attr('disabled', 'disabled');
			if (!endDate || !startDate) {
				return;
			}
			// if we select exact the same date
			if (endDate.substr(0, 5) == startDate.substr(0, 5)) {
				calendarTimes.removeAttr('disabled');
			}
		});
		if (values != undefined) {
			applyText(id)(options.startDate, options.endDate);
		}
		return id;
	}
	
	function applyText(id) {
		return function(start, end, label) {
			let dates = formateDate(start, end, true);
			let text = dates.join("-");
			$('#' + id + ' .action-text').text(text);
		}
	}

	function init(days, exceptions) {
		$('#location-hours-form').submit(function(e) {
			e.preventDefault();
			let businessHours = {};
			let times = $('[name="times[]"]',this);
			let openingTimes = [];
			let j = 0;
			for ( let i = 0; i < days.length; ++i ) {
				let day = days[i];
			    start = $(times[j]).val();
			    ++j;
	            end = $(times[j]).val();
			    ++j;
	            if (!start || !end) {
	            	businessHours[day] = [];
	                continue;
	            }
            	businessHours[day] = [start + "-" + end];
			}
			//get reverted timezone offset to apply to the exceptions, 
			//since daterangepicker stores data in utc, but considering local users timezone
			//so revering the timezone - should fix the date back to UTC
			let timezoneOffset = -new Date().getTimezoneOffset();
			let exceptions = {};
			$('.action-button').each(function() {
				let start = $(this).data('daterangepicker').startDate.utcOffset(timezoneOffset);
				let end = $(this).data('daterangepicker').endDate.utcOffset(timezoneOffset);
				//conting single day
				if (start.format('MM-DD') == end.format('MM-DD')) {
					//whole day
					if (start.format('HH:mm') == "00:00" && end.format('HH:mm') == "23:59") {
						exceptions[start.format('MM-DD')] = [];
						return true;
					}
					//add one second for the backend
					exceptions[start.format('MM-DD')] = [start.format('HH:mm') + "-" + end.add("1","second").format('HH:mm')];
					return true;
				}
				//counting range
				let increment = start.clone();
				while (increment <= end) {
					//if not exist (may be overlapped
					if (!exceptions[increment.format('MM-DD')]) {
						exceptions[increment.format('MM-DD')] = [];
					}
					increment = increment.add("1", "day");
				}
			}).toArray();
			businessHours.exceptions = exceptions;
			let body = $(this).serialize() + "&business_hours=" + JSON.stringify(businessHours);
			PatientPrism.API.Locations.setBusinessHours($('#location-id').val(), function(data) {
				if (data.status == "error") {
					swal("Something went wrong", data.error.message, "error");
					return;
				}
				swal("You changes have been saved", " ", "success");
			},body);
			return false;
		});
		$('#exceptions').on('click', '.add-exception', function() {addExceptionRow();});
		$('#exceptions').on('click', '.remove-exception', function() {
			let container = $(this).closest('.row');
			let id = container.find('button').attr('id');
			delete self.pickers[id];
			container.remove();
		});
		if (exceptions.length) {
			for (let i in exceptions) {
				addExceptionRow(exceptions[i]);
			}
		}
	}
	
	function formateDate(start, end, is12H) {
		let dateFormat = 'MM/DD';
		let hourFormat = dateFormat + " " + (is12H ? 'hh:mm a': 'HH:mm');
		return start.format(dateFormat) == end.format(dateFormat) 
			? [start.format(hourFormat) , end.format(hourFormat)]
			: [start.format(dateFormat) , end.format(dateFormat)];
	}
})();