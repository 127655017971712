(function()
{
    namespace("PatientPrism.Admin.Reports.Staff.CallReviewRaw", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init()
    {
        initStaffMetricsDataTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initStaffMetricsDataTable()
    {
        self.$table = $('table#staff-metrics');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [3, 'desc']
                ],
                "columnDefs": [
                {
                    "targets": 4,
                    "orderable": false
                }],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total calls",
                    "sEmptyTable": "No Call Data Available"
                },
                "sPaginationType": "simple"
            });
        }
    }
})();