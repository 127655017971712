(function() {
	namespace("PatientPrism.Common.UI.Filters.Builder", init);

	var self = {
		data : {
			metadata : null,
			phone_numbers : null,
			keywords : null,
			rules : null
		},
		el : {
			action : {
				$container : null,
				$button : null,
				$text : null,
				$builder : null,
				$btn_submit : null,
				$form : null,
				form : {
					$builder_rules : null
				}
			},
			modal : {
				$modal : null,
				$builder : null,
				$btn_submit : null
			}
		}
	};

	function init() {
		self.el.action.$container = $('#action-builder-container');
		self.el.action.$button = $('button.action-button',
				self.el.action.$container);
		self.el.action.$text = $('span.action-text', self.el.action.$button);
		self.el.action.$form = $('form.action-form', self.el.action.$container);

		self.el.action.form.$builder_rules = $('[name="builder-rules"]',
				self.el.action.$form);

		self.el.modal.$modal = $('#action-builder-modal');
		self.el.modal.$builder = $('#filter-builder', self.el.modal.$modal);
		self.el.modal.$btn_submit = $('.btn-submit', self.el.modal.$modal);

		bindSubmit();

		if (typeof PatientPrism.Common.Session.get('filters')['builder-rules'] !== 'undefined')
			self.data.rules = JSON.parse(PatientPrism.Common.Session
					.get('filters')['builder-rules']);

		PatientPrism.Common.UI.Filters.BuilderGlobal.init(function(filters) {
			filters = _.filter(filters, function (item) {
				return !_.includes(
					[
						'answered_by',
						'apt_time',
						'caller_name',
						'opportunity_panel',
						'btn_submit_review_with_relo'
					],
					item.id
				);
			});
			self.el.modal.$builder.queryBuilder({
				conditions : [ 'AND' ],
				allow_groups : 1,
				rules : self.data.rules,
				filters : filters
			});
		});
	}

	function bindSubmit() {
		self.el.modal.$btn_submit.click(function(e) {
			self.el.action.form.$builder_rules
					.val(JSON.stringify(self.el.modal.$builder
							.queryBuilder('getRules')));
			self.el.action.$form.submit();
		})
	}
})();