(function()
{
    namespace("PatientPrism.Modules.Calls.Reports.Keywords", init);

    let self = {
        $table: null,
        report_data: null,
        broad_matches_report_data: null,
        $keywords_insights_table: null,
        filters: null,
        formatters:  PatientPrism.Modules.Analize.Helpers.Formatters,
        renderers: PatientPrism.Modules.Analize.Helpers.Renderers
    };

    function init(data, filters)
    {
        self.report_data = JSON.parse(data);
        self.filters = JSON.parse(filters);
        
        $('#tag-cloud-search').keyup(function() {
        	let value = $(this).val();
        	$( "#tagcloud a:contains('"+value+"')" ).show();
        	$( "#tagcloud a:not(:contains('"+value+"'))" ).hide();
        });

        initKeywordsInsightsDataTable();
    }

    function initKeywordsInsightsDataTable()
    {
        return new Promise(function (resolve, reject) {

            self.$table = $('table#keywords-insights');

            if (!self.$table.length) {
                reject("Can't find table element");
                return;
            }

            let formatters = self.formatters;

            let datatable = self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                order: [
                    [2, 'asc']
                ],
                columnDefs:[
                    {
                        targets: [0],
                        orderable: false,
                    },
                    {
                        className: 'text-center sum-column',
                        targets: [3, 4],
                    },
                    {
                        className: 'text-center money-column',
                        targets: [6, 7],
                    },
                    {
                        className: 'text-center average-percent',
                        targets: [5],
                    },
                    {
                        targets: [6],
                        createdCell: function (td, cellData, rowData, row, col) {
                            if (cellData > 0) {
                                $(td).addClass('positive');
                            }
                        }
                    },
                    {
                        targets: [7],
                        createdCell: function (td, cellData, rowData, row, col) {
                            if (cellData > 0) {
                                $(td).addClass('negative');
                            }
                        }
                    }
                ],
                "columns": [
                    {
                        title: '',
                    },
                    {
                        title: 'Broad Match Keyword',
                    },
                    {
                        title: 'Location',
                    },
                    {
                        title: 'Total',
                    },
                    {
                        title: 'Booked',
                    },
                    {
                        title: 'Booking %',
                    },
                    {
                        title: 'Opp. Won',
                        render: formatters.money,
                    },
                    {
                        title: 'Opp. Lost',
                        render: formatters.money,
                    }
                ],
                bStateSave: true,
                buttons: [
                    {
                        extend:        'csv',
                        text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                        filename:      'custom-report',
                        className:     'btn-sm action-button dt-button',
                        exportOptions: {
                            orthogonal: 'export',
                            columns: [ ':visible :not(.no-export)' ]
                        },
                    },
                ],
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "Loading Data...",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple",
                "pageLength": 10,
            });

            // Add event listener for opening and closing details
            datatable.on('click', 'span.broad_match-details-control', function () {
                render_exact_keyword_table($(this), datatable);
            });

            let callback = function(data) {

                if (!data || data.data.length === 0) {
                    datatable.settings()[0].oLanguage.sEmptyTable = 'No Metric Data Available';
                    datatable.draw();
                    return;
                }

                self.broad_matches_report_data = data.data;

                _.each(data.data, function(metric)
                {
                    let type = 'display';
                    let row = datatable.row.add([
                        '<span class="fa fa-list-ul text-link broad_match-details-control"></span>',
                        metric.broad_match_keyword,
                        metric.location.name,
                        (function ( row, type, val, meta ) {
                            let route_params = {
                                ignore_locations: 1,
                                location_id: metric.location.id,
                                call_record_id: _.map(row.call_records, 'id'),
                                weighted: 1,
                            };
                            return formatters.calls(row.total, type, null, 'insights_broad_matches', {
                                route: 'calls.search',
                                route_params: route_params,
                            });
                        })(metric, type),
                        (function ( row, type, val, meta ) {
                            let route_params = {
                                ignore_locations: 1,
                                location_id: metric.location.id,
                                call_record_id: _.map(row.call_records, 'id'),
                                metadata: {
                                    booking_status: 'Booked',
                                },
                                weighted: 1,
                            };
                            return formatters.calls(row.booked, type, null, 'insights_broad_matches', {
                                route: 'calls.search',
                                route_params: route_params,
                            });
                        })(metric, type),
                        (function ( row, type, val, meta ) {
                            let percent = (row.total ? row.booked / row.total : 0);
                            return formatters.percent(percent, type, null, 'insights_broad_matches');
                        })(metric, type),
                        metric.won,
                        metric.lost,
                    ])
                        .nodes()
                        .to$();

                    let id = `word-${metric.broad_match_keyword}-location-${metric.location.id}`;
                    row.attr('id', `row-${id}`)
                        .attr('data-location_id', metric.location.id)
                        .attr('data-broad_match_keyword', metric.broad_match_keyword);
                });
                datatable.draw();
                resolve(datatable);
            };

            PatientPrism.API.CallKeywords.broad_matches(callback, self.filters);
        });
    }

    /**
     * Renders a nested table
     *
     * @param element
     * @param datatable
     * @returns Promise
     */
    function render_exact_keyword_table(element, datatable)
    {
        let html = $('table#broad_match-details').closest('div').html();

        let tr = element.closest('tr');
        let row = datatable.row(tr);

        let broad_match_id = tr.attr('id');

        let table = $(html).attr('id', `${broad_match_id}-details-table`);

        let formatters = self.formatters;

        if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass('shown');
        } else {
            tr.addClass('shown');
            row.child(table).show();

            datatable = table.DataTable({
                responsive: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                order: [
                    [0, 'asc']
                ],
                columnDefs: [
                    {
                        className: 'text-center sum-column',
                        targets: [1, 2],
                    },
                    {
                        className: 'text-center money-column',
                        targets: [4, 5],
                    },
                    {
                        className: 'text-center average-percent',
                        targets: [3],
                    },
                    {
                        targets: [4],
                        createdCell: function (td, cellData, rowData, row, col) {
                            if (cellData > 0) {
                                $(td).addClass('positive');
                            }
                        }
                    },
                    {
                        targets: [5],
                        createdCell: function (td, cellData, rowData, row, col) {
                            if (cellData > 0) {
                                $(td).addClass('negative');
                            }
                        }
                    }
                ],
                "columns": [
                    {
                        title: 'Exact Keyword',
                    },
                    {
                        title: 'Total',
                    },
                    {
                        title: 'Booked',
                    },
                    {
                        title: 'Booking %',
                    },
                    {
                        title: 'Revenues Won',
                        render: formatters.money,
                    },
                    {
                        title: 'Revnues Lost',
                        render: formatters.money,
                    },
                ],
                buttons: [],
                bStateSave: true,
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "Loading data...",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple",
                "pageLength": 10,
            });

            return new Promise(function (resolve, reject) {
                let location_id = tr.data('location_id');
                let broad_match_keyword = tr.data('broad_match_keyword');
                let index = _.findIndex(self.broad_matches_report_data, function (o) {
                    return o.broad_match_keyword === broad_match_keyword
                        && o.location.id === location_id;
                });
                let broad_match_data = self.broad_matches_report_data[index];

                if (broad_match_data.hasOwnProperty('broad_match_keyword_details')) {
                    resolve(broad_match_data['broad_match_keyword_details']);
                } else {
                    let filters = JSON.parse(JSON.stringify(self.filters));
                    filters['location_id'] = location_id;
                    filters['keywords'] = _.map(broad_match_data['related_keywords'], 'id');
                    filters['call_records'] = _.map(broad_match_data['call_records'], 'id');

                    PatientPrism.API.CallKeywords.broad_matches_details(function (data) {
                        if (!data || data.data.length === 0) {
                            datatable.settings()[0].oLanguage.sEmptyTable = 'No Metric Data Available';
                            datatable.draw();
                            return;
                        }

                        broad_match_data['broad_match_keyword_details'] = data.data;

                        resolve(broad_match_data['broad_match_keyword_details']);

                    }, filters);
                }
            })
                .then(function (data) {
                    _.each(data, function(metric)
                    {
                        let type = 'display';
                        datatable.row.add([
                            metric.exact_keyword,
                            (function ( row, type, val, meta ) {
                                let route_params = {
                                    ignore_locations: 1,
                                    location_id: metric.location.id,
                                    keywords: metric.exact_keyword_id,
                                    weighted: 1,
                                };
                                return formatters.calls(row.total, type, null, 'insights_broad_matches', {
                                    route: 'calls.search',
                                    route_params: route_params,
                                });
                            })(metric, type),
                            (function ( row, type, val, meta ) {
                                let route_params = {
                                    ignore_locations: 1,
                                    location_id: metric.location.id,
                                    keywords: metric.exact_keyword_id,
                                    metadata: {
                                        booking_status: 'Booked',
                                    },
                                    weighted: 1,
                                };
                                return formatters.calls(row.booked, type, null, 'insights_broad_matches', {
                                    route: 'calls.search',
                                    route_params: route_params,
                                });
                            })(metric, type),
                            (function ( row, type, val, meta ) {
                                let percent = (row.total ? row.booked / row.total : 0);
                                return formatters.percent(percent, type, null, 'insights_broad_matches');
                            })(metric, type),
                            metric.won,
                            metric.lost,
                        ])
                            .nodes()
                            .to$();
                    });

                    datatable.draw();
                });
        }
    }
})();
