(function()
{
    namespace("PatientPrism.Admin.Reports.Calls.CallActions", init);

    var self = {
        $table: null,
        report_data: null
    };

    function init()
    {
        initDeletedCallsTable();
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initDeletedCallsTable()
    {
        self.$table = $('table#call-actions');

        if (self.$table.length)
        {
            self.$table.DataTable({
                'order': [
                    [4, 'desc']
                ],
                responsive: true,
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'Bf>>" +
                 "<'row'<'col-sm-12'tr>>" +
                 "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                buttons: [
                    {
                        extend:        'csv',
                        text:          '<i class="fa fa-file-text-o"></i> Export CSV',
                        filename:      'custom-report',
                        className:     'btn-sm action-button dt-button',
                        exportOptions: {
                            orthogonal: 'export',
                            columns: [ ':visible :not(.no-export)' ]
                        },
                    }
                ],
                "columnDefs": [
                {
                    "targets": 5,
                    "orderable": false
                }],
                language: {
                    "sLengthMenu": "Show _MENU_ metrics per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Metrics",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ total metrics",
                    "sEmptyTable": "No Metric Data Available",
                    search: "_INPUT_",
                    searchPlaceholder: "Search Table..."
                },
                "sPaginationType": "simple"
            });
        }
    }
})();