(function()
{
    namespace("PatientPrism.Common.UI.Notification", create);

    var self = {
    }

    function create(status, message, delay = 5000)
    {
        var notification_title = '';
        var notification_type = '';

        switch(status) {
            case 'success':
                notification_title = 'Success:';
                notification_type = 'success'
                break;

            case 'error':
                notification_title = 'Error:';
                notification_type = 'danger';
                break;

            case 'warning':
                notification_title = 'Warning:';
                notification_type = 'warning';
                break;

            case 'info':
                notification_title = 'Info:';
                notification_type = 'info';
                break;

            default:
                notification_title = '';
        }

        $.growl({
            title: '<strong>' + '</strong>',
            message: message,
        },{
            element: 'body',
            type: notification_type,
            allow_dismiss: true,
            placement: {
                from: "top",
                align: "right"
            },
            offset: {
                x: 30,
                y: 70
            },
            spacing: 10,
            z_index: 1031,
            delay: delay,
            timer: 1000,
            url_target: '_blank',
            mouse_over: false,
            animate: {
                enter: 'animated fadeInDown',
                exit: 'animated fadeOutUp'
            },
            icon_type: 'class',
            template: '<div data-growl="container" class="alert" role="alert">' +
                '<button type="button" class="close" data-growl="dismiss">' +
                    '<span aria-hidden="true">×</span>' +
                    '<span class="sr-only">Close</span>' +
                '</button>' +
                '<span data-growl="icon"></span>' +
                '<span data-growl="title"></span>' +
                '<span data-growl="message"></span>' +
                '<a href="#" data-growl="url"></a>' +
            '</div>'
        });
    }
})();