(function()
{
    namespace("PatientPrism.Modules.Calls.QuickSelect", init);

    var self = {
        $quick_select: null,
        options:       [],
        $modal:        null
    };

    function init()
    {
        self.$modal = $('#modal-quick-select');
        self.$quick_select = $('#input-quick-select');

        $('.quick-select-control').each(function (index, control)
        {
            $('option', control).each(function(index, option)
            {
                if (!$(option).val())
                    return true;

                self.options.push({
                    id:      $(option).val(),
                    label:   $(option).text(),
                    group:   $(control).attr('data-label'),
                    control: $(control)
                });
            })
        });

        // Initialize selectize control
        self.$quick_select.selectize({
            preload:       true,
            valueField:    'id',
            labelField:    'label',
            searchField:   'label',
            sortField:     'label',
            optgroupField: 'group',
            optgroups: _.uniq(_.map(self.options, function(option)
            {
                return {
                    value: option.group,
                    label: option.group
                }
            })),
            render: {
                // Search result group titles
                optgroup_header: function(data, escape)
                {
                    return '<div class="optgroup-header"><strong>' + escape(data.label) + '</strong></div>';
                },
            },
            delimiter:   ',',
            openOnFocus: true,
            selectOnTab: true,
            maxItems:    1,
            options: self.options,
            onItemAdd: function (value, $item) {
                var data = this.options[value];

                data.control
                    .val(data.id)
                    .trigger('change');

                this.clear();
                self.$modal.modal('hide');
            }
        });

        window.addEventListener('keydown', function(e)
        {
            if (e.ctrlKey && e.key === 'b')
            {
                self.$modal.modal('show');
                self.$quick_select[0].selectize.focus();
            }
        }, true);
    }

})();