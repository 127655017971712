(function()
{
    namespace("PatientPrism.Modules.Calls.CallViz", getPlayer, init, autoCommentRegion);

    var self = {
        $callviz_container:               null,
        callviz_player:                   null,
        legacy_player:                    null,
        callviz_timeline:                 null,
        callviz_comment_editor:           null,
        callviz_comment_editor_controls:  null,
        $callviz_comment_list:            null,
        $active_comment_region:           null,
        $callviz_comment_character_count: null,
        callviz_comment_character_limit:  400,
        $callviz_keyword_menu_container:  null,
        $callviz_keyword_menu:            null,
        $callviz_keyword_container:       null,
        $callviz_detected_keyword_container:  null,
        $callviz_comments_container:      null,
        $callviz_timecode:                null,
        $callviz_timecode_hold:           null,
        audio_file_url:                   null,
        call_record_id:                   null,
        call_comments:                    null,
        call_keywords:                    null,
        available_keywords:               null,
        read_only:                        null,
        current_time:                     null,
        waveform_amplitude:               null
    };

    function getPlayer()
    {
        return self.callviz_player;
    }

    function init ($dom_el, call_record_id, audio_file_url, call_comments, call_keywords, available_keywords, waveform_amplitude, read_only, transcript_url)
    {
        // Sanity checks
        if(!$dom_el.length)
        {
            console.warn('CallViz(initCallVizPlayer): Unable to find DOM element');
            return;
        }

        if(!call_record_id)
        {
            console.warn('CallViz(initCallVizPlayer): A call_record_id is required');
            return;
        }

        if(!audio_file_url)
        {
            console.warn('CallViz(initCallVizPlayer): An audio file url is required');
            return;
        }

        // Set dom elements for reference
        self.$callviz_container = $dom_el;
        self.call_record_id     = call_record_id;
        self.audio_file_url     = audio_file_url;
        self.call_comments      = call_comments;
        self.call_keywords      = call_keywords;
        self.available_keywords = available_keywords;
        self.read_only          = !read_only ? false : read_only;
        self.waveform_amplitude = !waveform_amplitude ? null : waveform_amplitude;
        self.transcript_url     = transcript_url;
        self.max_keywords       = 6;

        // Check if the web browser supports CallViz
        if (!browserIsCompatible())
        {
            initLegacyPlayer();
            return;
        }

        // Detect mobile browser
        var isMobile = false;

        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

        var audio_backend = isMobile ? 'WebAudio' : 'MediaElement';

        // Create a new WaveSurfer object
        self.callviz_player = new WaveSurfer({
            backend:       audio_backend , // Fallback to MediaElement to support playback speed that isn't pitch shifted
            container:     $('[callviz-role="player"]', self.$callviz_container).get(0), // Get the raw javascript DOM element
            waveColor:     PatientPrism.Config.get('callviz.waveColor'),
            progressColor: PatientPrism.Config.get('callviz.progressColor'),
            cursorColor:   PatientPrism.Config.get('callviz.cursorColor'),
            normalize:     PatientPrism.Config.get('callviz.normalize'),
            skipLength:    PatientPrism.Config.get('callviz.skipLength'),
            responsive:    PatientPrism.Config.get('callviz.responsive'),
            plugins: [
                initTimeline(),
                window.WaveSurfer.regions.create(),
                window.WaveSurfer.keywords.create()
            ]
        });

        // Initialize the player with config settings
        self.callviz_player.init();

        // Set on('ready') behavior
        self.callviz_player.on('ready', function ()
        {
            // Workaround for getting audio element buffer percentage when using prerendered peak data
            if (self.callviz_player.params.backend === 'MediaElement' && $('audio', self.$callviz_container).length)
            {
                var $audio_element = $('audio', self.$callviz_container);

                function update_audio_buffer_progress() {
                    var buffered = $audio_element[0].buffered;
                    var loaded;

                    if (buffered.length)
                    {
                        percent_loaded = 100 * buffered.end(0) / $audio_element[0].duration;
                        setBufferedPercent(percent_loaded);
                    }

                    if (Math.round(percent_loaded) === 100)
                    {
                        setBufferedPercentVisibility('hidden');
                        return;
                    }

                    setTimeout(update_audio_buffer_progress, 50);
                }

                update_audio_buffer_progress();
            }
            else
            {
                setBufferedPercentVisibility('hidden');
            }

            bindPlaybackControls();

            // Enable drag selection
            if (!self.read_only)
            {
                self.callviz_player.enableDragSelection({
                    color: PatientPrism.Config.get('callviz.dragSelectionColor')
                });
            }

            // Initialize the regions plugin
            initCommentRegions();

            // If call comments already exist, create the regions
            if (call_comments)
                loadCommentRegions(call_comments);

            initKeywords();
            initDetectedKeywords();

            // Init keyword system
            if (call_keywords)
                loadKeywords(call_keywords);

            // Init hold time responses
            loadHoldTimeResponses(PatientPrism.Config.get('callviz.hold_time_responses'))

            setPlaybackControlsVisibility('visible');

            PatientPrism.Common.UI.CopyButton.init();

            // Initialize tooltips
            $('[data-toggle="tooltip"]', self.$callviz_container).tooltip({
                container: '.callviz-player'
            });
        });

        self.callviz_player.on('loading', function (percent)
        {
            // Update progress bar percentage while the file downloads
            setBufferedPercent(percent);
        });

        self.callviz_player.on('destroy', function ()
        {
            //
        });

        self.callviz_player.on('error', function ()
        {
            setBufferedPercentVisibility('hidden');
        });

        self.callviz_player.on('audioprocess', function (seconds)
        {
            setTimecode(seconds);
        });

        self.callviz_player.on('seek', function (seconds)
        {
            setTimecode(self.callviz_player.getCurrentTime());
        });

        // Download the audio file
        self.callviz_player.load(self.audio_file_url, self.waveform_amplitude);
    }

    function initLegacyPlayer()
    {
        self.legacy_player = true;

        self.$callviz_container.addClass('legacy-mode');

        // Show a legacy mode alert
        var legacy_warning_element =
        '<div class="alert alert-warning text-center"><strong>Legacy Mode:</strong> You are using an outdated browser which does not support the latest CallViz&trade; player.</div>';

        self.$callviz_container.prepend(legacy_warning_element);

        // Build the audio element
        var audio_element =
        '<audio callviz-role="player" preload controls style="max-width:100%;">' +
            '<source src="' + self.audio_file_url + '" type="audio/mp3">' +
        '</audio>';

        $('.callviz-player', self.$callviz_container).addClass('legacy-mode');
        $('[callviz-role="player"]', self.$callviz_container).html(audio_element);

        // Create the fallback media element
        self.callviz_player =
        new MediaElementPlayer($('audio[callviz-role="player"]', self.$callviz_container).get(0),
        {
            stretching: true,
            pluginPath: '../boo/',
            success: function (media) {
                var renderer = document.getElementById(media.id + '-rendername');

                media.addEventListener('loadedmetadata', function () {
                    console.log('Using legacy CallViz player through fallback method: ' + media.rendererName)
                });

                media.addEventListener('error', function (e) {
                    renderer.querySelector('.error').innerHTML = '<strong>Error</strong>: ' + e.message;
                });
            }
        });

        self.$callviz_audio_comments_container = $('[callviz-element="comments-container"]', self.$callviz_container);
        self.$callviz_comment_list            = $('[callviz-role="comment-list"]', self.$callviz_container);

        // Add call comments to comment list
        _.each(self.call_comments, function (comment)
        {
            var $comment_el = $('<div class="call-audio-note-comment" callviz-comment-type="' + comment.type + '" callviz-element="comment-list-comment">' + comment.data + '</div>');

            $comment_el.appendTo(self.$callviz_comment_list);

            $comment_el.click(function(e)
            {
                e.preventDefault();

                self.callviz_player.setCurrentTime(comment.time_start);
                self.callviz_player.play();
            });
        });

        if (self.call_comments.length)
            $('[callviz-element="no-audio-notes-indicator"]', self.$callviz_container).hide();

        self.$callviz_audio_comments_container.slideDown();
    }

    function initTimeline()
    {
        var $dom_el = $('[callviz-role="timeline"]', self.$callviz_container);

        if (!$dom_el.length)
        {
            console.warn('CallViz(initTimeline): Unable to find DOM element [callviz-role="timeline"]');
            return;
        }

        self.$callviz_timecode = $('[callviz-role="timecode"]', self.$callviz_container);

        if (!self.$callviz_timecode.length)
        {
            console.warn('CallViz(initTimeline): Unable to find DOM element [callviz-role="timecode"]');
            return;
        }
        else
        {
            if (PatientPrism.Config.get('callviz.showTimecode'))
            {
                $(self.$callviz_timecode).slideDown();
            }
        }

        self.$callviz_timecode_hold = $('[callviz-role="timecode-hold"]', self.$callviz_container);

        if (!self.$callviz_timecode_hold.length)
        {
            console.warn('CallViz(initTimeline): Unable to find DOM element [callviz-role="timecode-hold"]');
            return;
        }

        // Instantiate the plugin object
        self.callviz_timeline = window.WaveSurfer.timeline;

        // Initialize and return the timeline plugin object
        return window.WaveSurfer.timeline.create({
            container:  $dom_el.get(0)
        });
    }

    function initCommentRegions()
    {
        self.callviz_comment_editor = $('[callviz-role="comment-editor"]', self.$callviz_container);
        self.callviz_comment_editor_controls = $('[callviz-role="comment-editor-controls"]', self.$callviz_container);
        self.$callviz_comment_list = $('[callviz-role="comment-list"]', self.$callviz_container);
        self.$callviz_comment_character_count = $('[callviz-role="comment-character-limit"]', self.$callviz_container);

        self.callviz_player.on('region-created', function (region) // Comment Region Created
        {
            setRegionPopover(region);
            addCommentListComment(region);
        });

        self.callviz_player.on('region-removed', function (region) // Comment Region Removed
        {
            updateHoldTimecode();
        });

        self.callviz_player.on('region-click', function (region) // Comment Region Clicked
        {
            //
        });

        self.callviz_player.on('region-dblclick', function (region) // Comment Region Double Clicked
        {
            if (!self.read_only)
            {
                editCommentRegion(region);
            }
        });

        self.callviz_player.on('region-mouseenter', function (region) // Comment Region Mouse Enter
        {
            showCommentPopover(region);
        });

        self.callviz_player.on('region-mouseleave', function (region) // Comment Region Mouse Leave
        {
            hideCommentPopover(region);
        });

        self.callviz_player.on('region-in', function (region) // Comment Region Region In
        {
            showCommentPopover(region);
        });

        self.callviz_player.on('region-out', function (region)  // Comment Region Region Out
        {
            hideCommentPopover(region);
        });

        self.callviz_player.on('region-updated', function (region)  // Comment Region Region Updated
        {
            hideCommentPopover(region);
        });

        self.callviz_player.on('region-update-end', function (region)  // Comment Region Update End
        {
            // region-update-end seems to get called whenever the region is clicked once
            if (self.read_only)
                return;

            var callback = function(data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    region.update({
                        data : _.extend({}, region.data, {
                            type: 'positive',
                            audio_note_id: data.data.id
                        })
                    });

                    editCommentRegion(region);
                }
                else
                {
                    create_notification('error', 'Comment region could not be created');
                    region.remove();
                }
            }

            var audio_duration = self.callviz_player.getDuration();
            var region_duration = region.end - region.start;
            var minimum_region_duration = (PatientPrism.Config.get('callviz.region_min_duration')*.01) * audio_duration;

            // Check if the region is at least the minimum duration and adjust to fit
            if (region_duration < minimum_region_duration )
            {
                var duration_to_add = minimum_region_duration - region_duration;

                if ((region.end + duration_to_add) > audio_duration)
                {
                    region.start = region.start - duration_to_add;
                }
                else
                {
                    region.end = region.end + duration_to_add;
                }
            }

            if (!region.data.audio_note_id)
            {
                // The region does not have an existing audio_note_id, thus it is newly created
                var comment_data =
                {
                    type:       'positive',
                    time_start: region.start,
                    time_end:   region.end
                };

                PatientPrism.API.CallAudioNotes.create(self.call_record_id, callback, comment_data);
            }
            else
            {
                // The region has an existing audio_note_id, thus it already exists
                updateCommentRegion(region);
            }

            updateHoldTimecode();
        });

        self.callviz_player.on('keyword-in', function (keyword)  // Comment Region Region Updated
        {
            //
        });

        self.callviz_player.on('keyword-out', function (keyword)  // Comment Region Region Updated
        {
            //
        });

        self.callviz_player.on('keyword-over', function (keyword)  // Comment Region Region Updated
        {
            if (keyword.tooltip_visible)
                return;

            keyword.update({tooltip_visible: true });

            setTimeout(function()
            {
                hideKeywordTooltip(keyword);
                keyword.update({tooltip_visible: false });
            }, 2000)

            showKeywordTooltip(keyword);
        });
    }

    function loadCommentRegions (comment_data)
    {
        if(!comment_data)
        {
            console.warn('CallViz(initComments): comment_data must be an object');
            return;
        }

        _.each(comment_data, function(comment)
        {
            // Build region data
            var comment_region =
            {
                "start": comment.time_start,
                "end":   comment.time_end,
                "data":
                {
                    "note":          comment.data,
                    "type":          comment.type,
                    "audio_note_id": comment.id
                },
                "drag":   self.read_only ? false : true,
                "resize": self.read_only ? false : true
            };

            // Determine comment type (color)
            switch(comment.type)
            {
                case 'positive':
                    comment_region.color = PatientPrism.Config.get('callviz.region_colors.positive');
                    break;

                case 'negative':
                    comment_region.color = PatientPrism.Config.get('callviz.region_colors.negative');
                    break;

                case 'relo':
                    comment_region.color = PatientPrism.Config.get('callviz.region_colors.relo');
                    break;

                case 'tip':
                    comment_region.color = PatientPrism.Config.get('callviz.region_colors.tip');
                    break;

                case 'hold':
                    comment_region.color = PatientPrism.Config.get('callviz.region_colors.hold');
                    break;

                default:
                    comment_region.color = PatientPrism.Config.get('callviz.region_colors.neutral');
            }

            // Add the region to the CallViz player
            var region = self.callviz_player.addRegion(comment_region);

            $(region.element).attr('data-comment-type', comment.type);

            // initialize popover
        });

        updateHoldTimecode();
    }

    function editCommentRegion (region)
    {
        // Reset comment data input
        var $comment_data = $('[callviz-element="comment-data"]', self.callviz_comment_editor);
        $comment_data.val('');

        // Reset hold time comment select
        $('[callviz-element="hold-time-comment-data"]', self.callviz_comment_editor).prop('selectedIndex', 0);

        // Show correct comment selection/input type based on region type
        if (typeof region.data.type !== 'undefined' && region.data.type === 'hold')
        {
            $('[callviz-element="comment-container"]', self.callviz_comment_editor).hide();
            $('[callviz-element="hold-time-comment-container"]', self.callviz_comment_editor).show();
            $('[callviz-element="hold-time-comment-data"]', self.callviz_comment_editor).off('change');
            $('#select-hold-time-reason')[0].selectize.setValue(region.data.note, true);
        }
        else
        {
            $('[callviz-element="hold-time-comment-container"]', self.callviz_comment_editor).hide();
            $('[callviz-element="comment-container"]', self.callviz_comment_editor).show();
        }

        // Reset comment type select
        var $comment_type = $('[callviz-element="comment-type"]', self.callviz_comment_editor);
        $comment_type.val('positive');

        setRegionActive(region);

        // If the region has audio note data, load it
        if(region.data.audio_note_id)
        {
            $comment_data.val(region.data.note).trigger('change');
            $comment_type.val(region.data.type);
        }

        $('[callviz-element="comment-type"]', self.callviz_comment_editor).unbind('change')
        .change(function(e)
        {
            e.preventDefault();

            region.update({
                color: PatientPrism.Config.get('callviz.region_colors.' + $(this).val()),
                data : _.extend({}, region.data, {
                    type: $(this).val()
                })
            });

            $(region.element).attr('data-comment-type', region.data.type);

            updateCommentRegion(region);

            if ($(this).val() === 'hold')
            {
                $('[callviz-element="comment-container"]', self.callviz_comment_editor).hide();
                $('[callviz-element="hold-time-comment-container"]', self.callviz_comment_editor).show();
                $('#select-hold-time-reason')[0].selectize.open();
            }
            else
            {
                $('[callviz-element="hold-time-comment-container"]', self.callviz_comment_editor).hide();
                $('[callviz-element="comment-container"]', self.callviz_comment_editor).show();
            }

        });

        $('[callviz-element="comment-data"]', self.callviz_comment_editor).unbind('keydown')
        .keydown(function(e)
        {
            if (e.keyCode == 13 || e.which == 13)
            {
                region.update({
                    data : _.extend({}, region.data, {
                        note: $(this).val()
                    })
                });

                updateCommentRegion(region);

                return false;
            }
        });

        $('[callviz-element="comment-data"]', self.callviz_comment_editor).unbind('change keyup paste')
            .on('change keyup paste', function()
            {
                var character_length = $(this).val().replace(/ /g,"").length;

                self.$callviz_comment_character_count.text(self.callviz_comment_character_limit - character_length)

                // Limit character input
                if ((self.callviz_comment_character_limit - character_length) <= 0)
                {
                    $(this).val(
                        $(this).val().substring(0, $(this).val().length - (character_length - self.callviz_comment_character_limit))
                    );
                }
            });

        $('[callviz-element="hold-time-comment-data"]', self.callviz_comment_editor).unbind('change')
            .on('change', function()
            {
                $('[callviz-element="comment-data"]', self.callviz_comment_editor).val($(this).val());
                $('[callviz-element="save-comment"]', self.callviz_comment_editor_controls).trigger('click');
            })

        $('[callviz-element="save-comment"]', self.callviz_comment_editor_controls).unbind('click')
        .click(function(e)
        {
            e.preventDefault();

            if ($comment_data.val() === '')
            {
                create_notification('error', 'A comment note must be set');
                return;
            }

            region.update({
                data : _.extend({}, region.data, {
                    note: $comment_data.val()
                })
            });

            updateCommentRegion(region);
        });

        $('[callviz-element="delete-comment"]', self.callviz_comment_editor_controls).unbind('click')
        .click(function(e)
        {
            e.preventDefault();
            deleteCommentRegion(region);
        });

        $('[callviz-element="close-comment"]', self.callviz_comment_editor_controls).unbind('click')
        .click(function(e)
        {
            e.preventDefault();
            setCommentEditorVisibility('hidden');
        });

        setCommentEditorVisibility('visible');
    }

    function updateCommentRegion (region)
    {
        hideCommentPopover(region);

        var callback = function(data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                setRegionPopover(region);
                updateCommentListComment(region);

                // create_notification('success', 'Comment saved');
            }
            else
            {
                create_notification('error', 'An error occurred while saving');
            }
        }

        var audio_note_data =
        {
            type:       region.data.type,
            data:       region.data.note,
            time_start: region.start,
            time_end:   region.end
        };

        PatientPrism.API.CallAudioNotes.update(region.data.audio_note_id, callback, audio_note_data);

        updateHoldTimecode();
    }

    function deleteCommentRegion (region)
    {
        var callback = function(data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                destroyCommentPopover(region);
                deleteCommentListComment(region);
                region.remove();
                setCommentEditorVisibility('hidden');

                create_notification('success', 'Deleted Comment');
            }
            else
            {
                create_notification('error', 'An error occurred while saving');
            }
        }

        PatientPrism.API.CallAudioNotes.destroy(region.data.audio_note_id, callback);
    }

    function autoCommentRegion(comment_data)
    {
        var audio_duration          = self.callviz_player.getDuration();
        var region_duration         = comment_data.time_end - comment_data.time_start;
        var minimum_region_duration = (PatientPrism.Config.get('callviz.region_min_duration')*.01) * audio_duration;

        // Check if the region is at least the minimum duration and adjust to fit
        if (region_duration < minimum_region_duration )
        {
            var duration_to_add = minimum_region_duration - region_duration;

            if ((comment_data.time_end + duration_to_add) > audio_duration)
            {
                comment_data.time_start = comment_data.time_start - duration_to_add;
            }
            else
            {
                comment_data.time_end = comment_data.time_end + duration_to_add;
            }
        }

        var callback = function(data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                loadCommentRegions([data.data]);
            }
            else
            {
                create_notification('error', 'Comment region could not be created');
            }
        }

        if (typeof comment_data !== 'undefined')
            PatientPrism.API.CallAudioNotes.create(self.call_record_id, callback, comment_data);
    }

    function setCommentEditorVisibility (visibility)
    {
        if (!visibility)
            console.warn('CallViz(setCommentEditorVisibility): A visibility state must be passed');

        var $dom_el = $('[callviz-role="comment-editor"],[callviz-role="comment-editor-controls"]', self.$callviz_container);

        if (!$dom_el.length)
        {
            console.warn('CallViz(setCommentEditorVisibility): Unable to find DOM element [callviz-role="comment-editor"]');
            return;
        }

        switch(visibility)
        {
            case 'visible':
                $dom_el.slideDown();
                $('[callviz-element="comment-data"]', $dom_el).focus();
                break;

            case 'hidden':
                $dom_el.slideUp();
                setRegionsInactive();
                break;

            default:
                console.warn('CallViz(setCommentEditorVisibility): "' + state + '" is not a state');
        }
    }

    function setRegionPopover (region)
    {
        $(region.element).popover({
            trigger: 'manual',
            animate: 'false',
            placement: 'top',
            html: true,
            container: $('body'),
            content: function()
            {
                return region.data.audio_note_id && region.data.note ? region.data.note : 'No Comment';
            }
        });
    }

    function showCommentPopover (region)
    {
        // Hide all region popovers except the current region
        _.each(self.callviz_player.regions.list, function(e)
        {
            $(e.element).not(region.element).popover('hide');
        });

        // Show the current region popover
        $(region.element).popover('show');
    }

    function hideCommentPopover(region)
    {
        $(region.element).popover('hide');
    }

    function destroyCommentPopover(region)
    {
        $(region.element).popover('destroy');
    }

    function showKeywordTooltip (keyword)
    {
        // Hide all region popovers except the current region
        _.each(self.callviz_player.keywords.list, function(e)
        {
            $(e.element).not(keyword.element).tooltip('hide');
        });

        // Show the current region tooltip
        $('keywordbox', keyword.element).tooltip('show');
    }

    function hideKeywordTooltip(keyword)
    {
        $('keywordbox', keyword.element).tooltip('hide');
    }

    function setBufferedPercent (percent)
    {
        if (!percent)
            console.warn('CallViz(setBufferedPercent): A progress bar percent must be passed');

        var $dom_el = $('[callviz-role="buffered-percent"]', self.$callviz_container);

        if (!$dom_el.length)
        {
            console.warn('CallViz(setBufferedPercent): Unable to find DOM element [callviz-role="buffered-percent"]');
            return;
        }

        // Update the percent of the progress indicator
        $dom_el.text(Math.round(percent) + '%');
    }

    function setBufferedPercentVisibility (visibility)
    {
        if (!visibility)
            console.warn('CallViz(setBufferedPercentVisibility): A visibility must be passed');

        var $dom_el = $('[callviz-role="buffered-percent"]', self.$callviz_container);

        if (!$dom_el.length)
        {
            console.warn('CallViz(setBufferedPercentVisibility): Unable to find DOM element [callviz-role="buffered-percent"]');
            return;
        }

        switch(visibility)
        {
            case 'visible':
                $dom_el.fadeIn();
                break;

            case 'hidden':
                $dom_el.fadeOut();
                break;
        }
    }

    function setRegionActive (region)
    {
        setRegionsInactive();

        $(region.element).addClass('active');
    }

    function setRegionsInactive()
    {
        _.each(self.callviz_player.regions.list, function(e)
        {
            $(e.element).removeClass('active');
        });
    }

    function bindPlaybackControls()
    {
        $('[callviz-action]').unbind('click')
        .click(function(e)
        {
            e.preventDefault();

            doPlayerActions($(this).attr('callviz-action'));
        });

        $('[callviz-playback-rate]').unbind('click')
        .click(function(e)
        {
            e.preventDefault();

            $('[callviz-playback-rate].active', self.$callviz_container).removeClass('active');
            $(this).addClass('active');

            var rate = $(this).attr('callviz-playback-rate');
            $.cookie('callviz-playback-rate', rate, {
                expires: 365,
                path: '/'
            });

            self.callviz_player.setPlaybackRate(rate);
        });

        var rate = null;

        if (rate = $.cookie('callviz-playback-rate'))
        	$('[callviz-playback-rate="'+rate+'"]').click();
    }

    function setPlaybackControlsVisibility (visibility)
    {
        if (!visibility)
            console.warn('CallViz(setPlaybackControlsVisibility): A visibility state must be passed');

        var $dom_el = $('[callviz-role="playback-controls"]', self.$callviz_container);

        if (!$dom_el.length)
        {
            console.warn('CallViz(setPlaybackControlsVisibility): Unable to find DOM element [callviz-role="playback-controls"]');
            return;
        }

        switch(visibility)
        {
            case 'visible':
                $dom_el.slideDown();
                break;

            case 'hidden':
                $dom_el.slideUp();
                break;

            default:
                console.warn('CallViz(setPlaybackControlsVisibility): "' + state + '" is not a state');
        }
    }

    function doPlayerActions (action, $e)
    {
        switch(action)
        {
            case 'play-pause':
                self.callviz_player.playPause();
                break;

            case 'skip-backward':
                self.callviz_player.skipBackward();
                break;

            case 'skip-forward':
                self.callviz_player.skipForward();
                break;

            case 'toggle-mute':
                self.callviz_player.toggleMute();
                break;

            case 'playback-speed':
                self.callviz_player.setPlaybackRate(2);
                break;

            case 'toggle-direct-link':
                $('[callviz-role="direct-link"]', self.$callviz_container).val(self.audio_file_url);

                $('[callviz-element="direct-link"]', self.$callviz_container)
                    .slideToggle();
                break;

            case 'show-transcript':
                window.open(self.transcript_url, 'transcript','height=500,width=500')
                break;

            default:
                console.warn('CallViz(playerActions): "' + action + '" is not a valid player action');
        }
    }

    function addCommentListComment (region)
    {
        if (!region.data.audio_note_id)
            return;

        var comment_type = region.data.type;
        var comment_data = region.data.note ? region.data.note : 'No Comment';

        var $comment_el = $('<div class="call-audio-note-comment" callviz-comment-type="' + comment_type + '" callviz-element="comment-list-comment">' + comment_data + '</div>');

        $comment_el.appendTo(self.$callviz_comment_list);

        region.update({
            data : _.extend({}, region.data, {
                comment_list_el: $comment_el
            })
        });

        $comment_el.click(function(e){
            region.play();
        });

        if (!self.read_only)
        {
            var $delete_el = $('<span href="#" class="call-audio-note-comment-delete glyphicon glyphicon-trash pull-right text-muted" style="display:none"></span>');

            $delete_el.appendTo($comment_el);

            $delete_el.click(function(e){
                e.stopPropagation();
                deleteCommentRegion(region);
            });
        }

        $('[callviz-element="no-audio-notes-indicator"]', self.$callviz_comment_list).slideUp();
    }

    function updateCommentListComment (region)
    {
        if (!region.data.comment_list_el)
        {
            addCommentListComment(region);
            return;
        }

        var comment_type = region.data.type;
        var comment_data = region.data.note ? region.data.note : 'No Comment';

        region.data.comment_list_el.attr('callviz-comment-type', comment_type);
        region.data.comment_list_el.html(comment_data);
    }

    function deleteCommentListComment (region)
    {
        $(region.data.comment_list_el).slideUp('fast', function() {
            $(this).remove();

            if ($('[callviz-element="comment-list-comment"]', self.$callviz_comment_list).length === 0)
                $('[callviz-element="no-audio-notes-indicator"]', self.$callviz_comment_list).slideDown();
        });
    }

    function loadHoldTimeResponses(responses)
    {
        $.each(responses, function (index, response)
        {
            $('[callviz-element="hold-time-comment-data"]', self.callviz_comment_editor).append(
                '<option value="' + response + '">' + response + '</option>'
            );
        });

        $('#select-hold-time-reason').selectize({
            create: false,
            hideSelected: false,
            openOnFocus: true
        });
    }

    function initDetectedKeywords()
    {
        if (!self.read_only)
        {
            self.$callviz_detected_keyword_container = $('[callviz-element="detected-keyword-container"]', self.$callviz_container);

            addDetectedKeywordsOptions();
        }
    }

    function initKeywords()
    {
        if (!self.read_only)
        {
            self.$callviz_keyword_menu_container = $('[callviz-element="keyword-menu-container"]', self.$callviz_container);
            self.$callviz_keyword_menu = $('[callviz-role="keyword-menu"]', self.$callviz_keyword_menu_container);
            self.$callviz_keyword_container = $('[callviz-element="keyword-container"]', self.$callviz_keyword_menu_container);

            if (self.$callviz_keyword_menu.length)
            {
                window.addEventListener('keydown', function(e)
                {
                    if (e.ctrlKey && e.key === 'k')
                    {
                        self.$callviz_keyword_menu_container.show();
                        self.$callviz_keyword_menu[0].selectize.focus();
                    }
                }, true);

                $(self.$callviz_keyword_menu).selectize({
                    hideSelected: true,
                    create: false
                });

                _.each(self.available_keywords, function(keyword)
                {
                    self.$callviz_keyword_menu[0].selectize.addOption({
                        text: keyword.keyword,
                        value: keyword.id
                    });
                });

                // A user has chosen to create a new keyword
                self.$callviz_keyword_menu[0].selectize.on('option_add', function(keyword_value, keyword_object)
                {
                    var callback = function(data, textStatus, jqXHR)
                    {
                        if (textStatus === 'success')
                        {
                            // Determine if the keyword already
                            // exists in the keyword menu
                            if (!(data.data.id in self.$callviz_keyword_menu[0].selectize.options))
                            {
                                // Update the existing option in the dropdown to set the new id
                                self.$callviz_keyword_menu[0].selectize.updateOption( data.data.keyword, {
                                    text: data.data.keyword,
                                    value: data.data.id
                                });

                                // Set the new option to trigger a change
                                self.$callviz_keyword_menu[0].selectize.setValue(data.data.id);
                            }
                        }
                        else
                        {
                            // Remove the option from the dropdown menu
                            self.$callviz_keyword_menu[0].selectize.removeOption(keyword_value);
                            create_notification('error', 'Keyword could not be created');
                        }
                    }
                    // The keyword does not have an existing pivot_id, thus it is newly created
                    var keyword_data =
                    {
                        keyword: keyword_value
                    };

                    PatientPrism.API.CallKeywords.create( callback, keyword_data );
                });

                self.$callviz_keyword_menu[0].selectize.on('change', function(keyword_id)
                {
                    if (keyword_id === '' || !Number(keyword_id))
                    {
                        self.$callviz_keyword_menu[0].selectize.blur();
                        return;
                    }

                    var keyword_data = {
                        keyword: self.$callviz_keyword_menu[0].selectize.getItem(keyword_id)[0].innerText,
                        data: {
                            keyword_id: keyword_id
                        },
                        start: self.callviz_player.getCurrentTime(),
                        drag: self.read_only ? false : true
                    };

                    self.callviz_player.addKeyword(keyword_data);
                });
            }
        }

        self.callviz_player.on('keyword-created', function (keyword) // Keyword Created
        {
            // keyword-update-end seems to get called whenever the region is clicked once
            if (self.read_only)
                return;

            var callback = function(data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    keyword.update({
                        data : _.extend({}, keyword.data, {
                            "pivot_id": data.data.pivot.id,
                            "call_record_id": data.data.pivot.call_record_id
                        })
                    });
                }
                else
                {
                    create_notification('error', 'Keyword could not be created');
                    keyword.remove();
                }

                self.$callviz_keyword_menu[0].selectize.clear();
            }

            if (keyword.data.detected) {
                updateKeyword(keyword, function (data) {
                    _(self.call_keywords).keyBy('id').get(data.data.id).pivot = data.data.pivot;
                    keyword.update({
                        data : _.extend({}, keyword.data, {
                            "pivot_id": data.data.pivot.id,
                            "call_record_id": data.data.pivot.call_record_id,
                            "pivot": data.data.pivot
                        })
                    });
                });
            } else if (typeof keyword.data.pivot_id === 'undefined') {
                // The keyword does not have an existing pivot_id, thus it is newly created
                var keyword_data =
                {
                    'time-start':        keyword.start,
                    'call-keyword-id': keyword.data.keyword_id
                };

                PatientPrism.API.CallRecords.add_keyword( self.call_record_id, callback, keyword_data );
            }

            var button_template = `
            <div class="btn-group" callviz-keyword="${keyword.id}">
                <button type="button" class="btn btn-xs btn-warning" callviz-action="remove-keyword" style="margin:0 5px;">${keyword.keyword} <span class="fa fa-times"></span></button>
            </div>
            `;

            var template = document.createElement('template');
            button_template = button_template.trim();
            template.innerHTML = button_template;

            var element = template.content.firstChild;
            
            element.querySelectorAll('[callviz-action="remove-keyword"]')[0].addEventListener('click', function(e)
            {
                deleteKeyword(keyword);
            });

            self.$callviz_keyword_container.append(element);

            $('[data-toggle="tooltip"]', self.$callviz_container).tooltip({
                container: '.callviz-player'
            });

            checkKeywordCount();
        });

        self.callviz_player.on('keyword-removed', function (keyword) // Keyword Double Clicked
        {                
            if (!self.read_only)
            {   
                $(`[callviz-keyword="${keyword.id}"]`, self.$callviz_keyword_container).remove();

                checkKeywordCount();
            }
        });

        self.callviz_player.on('keyword-dblclick', function (keyword) // Keyword Double Clicked
        {
            if (!self.read_only)
            {
                deleteKeyword(keyword);
            }
        });

        self.callviz_player.on('keyword-update-end', function (keyword)  // Keyword Update End
        {
            // keyword-update-end seems to get called whenever the region is clicked once
            if (self.read_only)
                return;

            updateKeyword(keyword);
        });

        $('[callviz-role="keyword-menu-toggle"]', self.callviz_container).unbind('click')
        .click(function(e)
        {
            $(this).blur();
            self.$callviz_keyword_menu_container.slideToggle();
        });

        self.$callviz_audio_comments_container = $('[callviz-element="comments-container"]', self.$callviz_container);

        $('[callviz-role="comments-toggle"]', self.callviz_container).unbind('click')
        .click(function(e)
        {
            $(this).blur();
            self.$callviz_audio_comments_container.slideToggle();
        });
    }

    function loadKeywords (call_keywords)
    {
        if(!call_keywords)
        {
            console.warn('CallViz(loadKeywords): call_keywords must be an object');
            return;
        }

        _.each(call_keywords, function(keyword)
        {
            if (!keyword.pivot.visible)
                return;

            // Build region data
            var keyword_data =
            {
                "start": keyword.pivot.time_start,
                "data":
                {
                    "pivot_id": keyword.pivot.id,
                    "keyword_id": keyword.pivot.call_keyword_id,
                    "call_record_id": keyword.pivot.call_record_id
                },
                "keyword": keyword.keyword,
                "drag": self.read_only ? false : true,
            };

            // Add the keyword to the CallViz player
            self.callviz_player.addKeyword(keyword_data);
        });
    }

    function updateKeyword (keyword, callback)
    {
        let onUpdateCompleted = function(data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                if ($.isFunction(callback))
                    callback(data);
            }
            else
            {
                create_notification('error', 'An error occurred while saving');
            }
        }

        let keyword_data =
        {
            'time-start': keyword.start,
            'visible'   : 1
        };

        let pivot_id = keyword.data.pivot_id || keyword.data.pivot.id;

        PatientPrism.API.CallRecords.update_keyword( self.call_record_id, pivot_id, onUpdateCompleted, keyword_data );
    }

    function deleteKeyword (keyword)
    {
        var callback = function(data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                keyword.remove();
                _.pull(self.call_keywords, _(self.call_keywords).keyBy('id').get(keyword.data.keyword_id));

                create_notification('success', 'Deleted Keyword');
            }
            else
            {
                create_notification('error', 'An error occurred while deleting');
            }
        }

        PatientPrism.API.CallRecords.remove_keyword( self.call_record_id, keyword.data.pivot_id, callback );
    }

    function addDetectedKeywordsOptions() {
        var count = 0;

        _.each(self.call_keywords, function(keyword)
        {
            if (keyword.pivot.visible)
                return;
                
            var button_template = `
            <div class="btn-group detected-keyword">
                <button type="button" class="btn btn-xs btn-default" callviz-action="play-keyword"><span class="fa fa-play"></span> ${keyword.keyword}</button>
                <button type="button" class="btn btn-xs btn-warning" callviz-action="add-keyword"><span class="fa fa-plus"></span></button>
            </div>
            `;

            var template = document.createElement('template');
            button_template = button_template.trim();
            template.innerHTML = button_template;

            var element = template.content.firstChild;
            
            element.querySelectorAll('[callviz-action="play-keyword"]')[0].addEventListener('click', function(e)
            {
                // Start playing 3 seconds before keyword happens
                self.callviz_player.setCurrentTime(keyword.pivot.time_start - 3);
                self.callviz_player.play(keyword.pivot.time_start - 3);
            });

            element.querySelectorAll('[callviz-action="add-keyword"]')[0].addEventListener('click', function(e)
            {
                self.callviz_player.addKeyword({
                    keyword: keyword.keyword,
                    data: {
                        detected: true,
                        keyword_id: keyword.id,
                        pivot: keyword.pivot
                    },
                    start: keyword.pivot.time_start,
                    drag: !self.read_only
                });

                element.remove();
            });

            self.$callviz_detected_keyword_container.append(element);

            count++;
        });

        if (count)
        {
            self.$callviz_detected_keyword_container.show();
        }
    }

    function checkKeywordCount() {
        if ($('[callviz-keyword]').length >= self.max_keywords) {
            self.$callviz_keyword_menu[0].selectize.disable();
        } else {
            self.$callviz_keyword_menu[0].selectize.enable();
        }
    }

    function setTimecode(seconds)
    {
        d = Number(seconds);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var time = (h > 0 ? h + ":" : "") + (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;

        // Check if current time is same as formatted micro time (avoid unnecessary paints)
        if(self.current_time != time)
        {
            $(self.$callviz_timecode).html(time);
            self.current_time = time;
        }
    }

    function updateHoldTimecode()
    {
        var seconds = 0;

        if (_.has(self.callviz_player, 'regions'))
        {
            _.each(self.callviz_player.regions.list, function(e)
            {
                if (e.data.type == 'hold')
                {
                    seconds = seconds + (e.end - e.start);
                }
            });
        }

        d = Number(seconds);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var time = (h > 0 ? h + ":" : "") + (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;

        self.$callviz_timecode_hold.html(time);

        if (seconds <= 0)
        {
            self.$callviz_timecode_hold.slideUp();
        }
        else
        {
            self.$callviz_timecode_hold.slideDown()
        }
    }

    function browserIsCompatible()
    {
        // Web Audio not supported
        if (!window.AudioContext && !window.webkitAudioContext)
        {
            console.warn('CallViz(browserIsCompatible): This browser is not compatible with the full version of CallViz');
            return false;

            self.$callviz_container.html('<h3 class="text-muted text-center"><strong>Error:</strong> Your Browser Does Not Support CallViz</h3>');
        }

        return true;
    }
})(this);
