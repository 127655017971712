(function()
{
    namespace("PatientPrism.Modules.Calls.Reviews.AllUnreviewedCalls", init, bindUnassigningViewers);

    var self = {
        $table: null,
        viewables: [],
        helper: PatientPrism.Common.UI.RenderHelper,
        interval: null,
        userHasRoleCallCoach: false
    };

    function init(userHasRoleCallCoach = false, currentUserTimezone = null)
    {
        self.userHasRoleCallCoach = userHasRoleCallCoach;
        self.helper.init(currentUserTimezone);

        initUnreviewedCallsDataTable()
        .then(function (table) {
            PatientPrism.Common.UI.BlockedCallers.initBlockedCallers();

            PatientPrism.Common.Vendor.DataTable.ActionColumn.CallRecords.init(table);

            // Enable tooltips
            $('[data-toggle="tooltip"]').tooltip();

            bindReviewButtonViewerChecking();
            bindUnassigningViewers();
            longPollViewers();

        });
    }

    function longPollViewers()
    {
        var api_callback = function (data, textStatus, jqXHR)
        {
            if (textStatus === 'success')
            {
                var viewables = _.map(data.data, 'viewable_id');

                if (!_.isEqual(self.viewables.sort(), viewables.sort()))
                {
                    self.viewables = viewables;

                    var row_ids = _.map(self.viewables, function(item){
                        return '#row-' + item;
                    });

                    /*
                    $('#unreviewed-calls')
                    .DataTable()
                    .rows().every(function(){
                        $(this.node()).removeClass('active-viewers');
                        $('.btn-review', this.node()).removeClass('disabled');
                        $('.viewers-cell', this.node()).html('')
                    });
                    */

                    $('#unreviewed-calls')
                    .DataTable()
                    .rows(row_ids).every(function(){
                        $(this.node()).addClass('active-viewers');
                        //$('.btn-review', this.node()).addClass('disabled');
                        $('.viewers-cell', this.node()).html('<span class="active-viewer-icon glyphicon glyphicon-user"></span>')
                    });
                }
            }
            else if (textStatus === 'error')
            {

            }
        };

        PatientPrism.API.Viewables.get(api_callback);

        self.interval = setInterval(function() {
            PatientPrism.API.Viewables.get(api_callback);
        }, 15000);

        $("a[id^='call_review_']").bind('click', function () {
            clearInterval(self.interval);
        });
    }

    function bindReviewButtonViewerChecking()
    {
        $('#unreviewed-calls tbody').on('click', '.btn-review', function (e)
        {
            e.preventDefault();

            var redirect_url = $(this).attr('href');

            // Disable review buttons
            $('.btn-review').addClass('disabled');

            var api_callback = function (data, textStatus, jqXHR)
            {
                if (textStatus === 'success')
                {
                    window.location.href = redirect_url;
                }
                else if (textStatus === 'error')
                {
                    window.location.href = redirect_url;
                }
            }

            PatientPrism.API.CallRecords.checkin_viewer($(this).attr('data-call-record-id'), api_callback);
        });
    }

    function bindUnassigningViewers() {

        $('#unreviewed-calls tbody').on('click', '.btn-unassign', function (e)
        {
            var $btn_unassign = $(this);

            e.preventDefault();

            var api_callback = function (data, textStatus, jqXHR)
            {
                var $current_row = $btn_unassign.closest('tr');

                if (textStatus === 'success')
                {
                    $current_row.find('td.review_in_progress_by').text('');
                    $current_row.find('.btn-unassign').parent('li').remove();

                    swal("Success", 'Assignment has been deleted.', "success");
                }
                else if (textStatus === 'error')
                {
                    swal("Error", 'Assignment has not been deleted: ' + data.error.message, "error");
                }
            };

            swal({
                title: "Are you sure?",
                text: "This will unassign the user from reviewing this call.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, I'm Sure",
                cancelButtonText: "No, Cancel",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            }, function(isConfirm) {
                if (isConfirm) {
                    PatientPrism.API.CallRecords.unreview($btn_unassign.attr('data-call-record-id'), api_callback);
                }
            });
        });
    }

    /**
     * Converts the HTML table into a DataTable.
     *
     */
    function initUnreviewedCallsDataTable()
    {
        return new Promise(function (resolve) {
            self.$table = $('table#unreviewed-calls');

            let datatable = self.$table.DataTable({
                dom: "<'row'<'col-sm-6'l><'col-sm-6 text-right'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",
                responsive: true,
                "pageLength": 25,
                'order': [
                    [7, 'asc']
                ],
                "columnDefs": [
                    {
                        "targets": [1,11],
                        "orderable": false
                    },
                    {
                        className: 'text-center viewers-cell',
                        targets: [0],
                        width: "15px"
                    },
                    {
                        className: 'action-column no-export',
                        targets: [1],
                        width: "15px"
                    },
                    {
                        targets: [5],
                        width: "130px"
                    },
                    {
                        targets: [6],
                        width: "80px"
                    },
                    {
                        targets: [7],
                        width: "160px"
                    },
                    {
                        targets: [8],
                        width: "65px"
                    },
                    {
                        className: 'review_in_progress_by',
                        targets: [9]
                    },
                    {
                        className: 'text-center',
                        targets: [11],
                        width: "90px"
                    }
                ],
                "oLanguage": {
                    "sLengthMenu": "Show _MENU_ calls per page",
                    "sInfo": "Showing _START_ to _END_ of _TOTAL_ Calls",
                    "sInfoEmpty": "",
                    "sInfoFiltered": " - filtered from _MAX_ Total Calls",
                    "sEmptyTable": "Loading Calls...",
                    "sSearch": "_INPUT_",
                    "sSearchPlaceholder": "Search Table..."
                },
                "sPaginationType": "simple"
            });

            let body = {
                reviewed: 0,
                period: 'standard',
                expand: ['parent_phone_number', 'location.company', 'viewers', 'blocked_caller', 'review_in_progress_by', 'call_queue']
            };

            let callback = function(data) {
                if (!data.data || data.data.length === 0) {
                    datatable.settings()[0].oLanguage.sEmptyTable = 'No Calls Available';
                    datatable.draw();

                    return;
                }

                _.each(data.data, function(call_record)
                {
                    let row = datatable.row.add([
                        render_viewers(call_record),
                        '<input class="selection-checkbox" type="checkbox" name="id[]">',
                        call_record.location.company.name,
                        call_record.location.name,
                        '<a href="#">' + call_record.parent_phone_number.name + '</a>',
                        render_caller(call_record),
                        call_record.RecordingDuration ? self.helper.seconds_to_hms(call_record.RecordingDuration) : 'Unknown',
                        self.helper.local_time(call_record.created_at, null, call_record.location.timezone),
                        call_record.language,
                        call_record.review_in_progress_by ? call_record.review_in_progress_by.name : '',
                        call_record.call_queue ? call_record.call_queue.name : '',
                        renderButtons(call_record)
                    ])
                    .nodes()
                    .to$();

                    row.attr('id', 'row-' + call_record.id)
                        .attr('data-id', call_record.id);

                    if (call_record.viewers.length) {
                        row.addClass('active-viewers');
                    }
                });
                datatable.draw();
                resolve(self.$table);
            };

            let render_viewers = function(call_record) {
                let view = '';

                if (call_record.viewers.length) {
                    view += '<span class="active-viewer-icon glyphicon glyphicon-user" data-toggle="tooltip" data-placement="right"' +
                        ' title="' +
                        (call_record.viewers.length > 1 ? call_record.viewers.length + ' Viewers' : call_record.viewers[0].name) +
                        '"></span>';
                }
                return view;
            };

            let render_caller = function(call_record) {
                let view = '<a href="#">' + (call_record.From ? self.helper.friendly_phone(call_record.From) : 'Unknown') + '</a>';
                if (call_record.From && 1 /*UserAbility::hasRole('call-coach')*/) {
                    if (call_record.blocked_caller) {
                        view += '<a class="unblock-caller" data-blocked-caller-id="' +
                            call_record.blocked_caller.id + '"><small>Blocked</small></a>';
                    } else if (!self.helper.isReservedPhoneNumber(call_record.From)) {
                        view += '<a href="#" class="block-caller" data-phone-number="' +
                            call_record.From + '"><small>Block</small></a>'
                    }
                }
                return view;
            };

            let renderButtons = function (call_record) {
                let route = _router.route('calls.show', {
                    call_record_id: call_record.id
                });

                let view = '<div class="btn-group">';

                // Coach button
                view += '<a class="btn-review btn btn-sm ' + (call_record.viewers.length ? 'btn-default' : 'btn-primary') +
                    '" href="' + route + '" data-call-record-id="' + call_record.id + '">View</a>';

                // Toggle dropdown
                view += '<button type="button" ' +
                    'class="btn btn-sm ' + (call_record.viewers.length ? 'btn-default' : 'btn-primary') + ' dropdown-toggle" ' +
                    'data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                    '<span class="caret"></span>' +
                    '<span class="sr-only">Toggle Dropdown</span>' +
                    '</button>';

                // Unassign
                route = _router.route('calls.coach-with-id', {
                    call_record_id: call_record.id
                });
                view += '<ul class="dropdown-menu dropdown-menu-right">' +
                    '<li><a href="' + route + '">Coach</a></li>' +
                    (call_record.review_in_progress_by_id
                        ? '<li><a class="btn-unassign" href="#" data-call-record-id="' + call_record.id + '">Unassign</a></li>'
                    : '') +
                    '</ul>';

                view += '</div>';

                return view;
            };

            PatientPrism.API.CallRecords.all(callback, body);
        });
    }
})();
